import _ from 'lodash';

export const PasswordHelper = {
  pattern: /[a-zA-Z0-9_\-+.]/,

  getRandomByte() {
    // http://caniuse.com/#feat=getrandomvalues
    if (window.crypto && window.crypto.getRandomValues) {
      const result = new Uint8Array(1);
      window.crypto.getRandomValues(result);
      return result[0];
    }
    if (window.msCrypto && window.msCrypto.getRandomValues) {
      const result = new Uint8Array(1);
      window.msCrypto.getRandomValues(result);
      return result[0];
    }

    return Math.floor(Math.random() * 256);
  },

  generate(length) {
    // eslint-disable-next-line prefer-spread
    return Array.apply(null, { length })
      .map(function () {
        let result;
        while (true) {
          result = String.fromCharCode(this.getRandomByte());
          if (this.pattern.test(result)) {
            return result;
          }
        }
      }, this)
      .join('');
  },

  generatePinCode() {
    return _.random(1000, 9999);
  },
};
