import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { withSnackbar } from 'notistack';
import { Grid, TextField, InputLabel, FormControl, MenuItem, Select } from '@material-ui/core';
import { Prompt, withRouter } from 'react-router';
import ContentPaper from '../../components/content-paper';
import { translate } from '../../i18n';
import { venueApi } from '../../api/venue';
import FormActions from '../../components/form-actions';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginRight: theme.spacing(2),
  },
  buttonBlock: {
    display: 'block',
    width: '140px',
    margin: '0 auto',
    marginBottom: theme.spacing(1),
  },
  buttonDelete: {
    margin: theme.spacing(1),
    color: theme.palette.error.dark,
  },
  caption: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    display: 'flex',
  },
  descriptionField: {
    width: '100%',
  },
  formContainer: {
    maxWidth: 640,
  },
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  helperText: {
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  imagePreview: {
    width: '100px',
    height: '100px',
    backgroundColor: '#fafafa',
    margin: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  img: {
    width: 'auto',
    maxHeight: '90%',
    maxWidth: '90%',
    marginTop: '50%',
    transform: 'translateY(-50%)',
  },
  listContainer: {
    maxHeight: '70vh',
    overflow: 'auto',
    background: '#f2f2f2',
    padding: theme.spacing(0, 2),
  },
});

@withSnackbar
@withStyles(styles)
@withRouter
class VenueForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : null,
      data: props.data
        ? props.data
        : {
            name: '',
          },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { id } = this.state;
    if (!id && nextProps.id) {
      this.setState({
        id: nextProps.id,
        data: nextProps.data,
      });
    }
  }

  handleSubmit = async ev => {
    if (ev) {
      ev.preventDefault();
    }

    const { data, id } = this.state;
    const { onNotification, onSave } = this.props;

    try {
      const response = !id ? await venueApi.add(data) : await venueApi.update(id, data);

      this.setState({ hasUnsavedChanges: false });
      onNotification('Venue saved');

      if (onSave) {
        onSave(response);
      }
    } catch (e) {
      onNotification('Could not save the venue!', { variant: 'error' });
    }

    return false;
  };

  handleChange = (name, value) => event => {
    const { data } = this.state;

    value = typeof value !== 'undefined' ? value : event.target.value;
    this.setState({ data: { ...data, [name]: value }, hasUnsavedChanges: true });
  };

  handleDelete = async () => {
    const { id } = this.state;
    const { onNotification, history } = this.props;

    // Reset form
    this.setState({ hasUnsavedChanges: false });

    // Delete AJAX
    try {
      await venueApi.deleteById(id);

      onNotification('Venue deleted');
      history.push('/venue');
    } catch (e) {
      onNotification('Could not delete the venue', { options: { variant: 'error' } });
    }
  };

  render() {
    const { classes, onCancel } = this.props;

    const { data, hasUnsavedChanges } = this.state;

    const name = data && data.name ? data.name : '';

    return (
      <>
        <Prompt
          when={!!hasUnsavedChanges}
          message={translate('You have unsaved changes. Are you sure you want to leave?')}
        />
        <ContentPaper className="FormBoxPaper">
          <Grid container spacing={5} alignItems="flex-end">
            <Grid item xs={12} sm={6}>
              <TextField
                required
                autoComplete="off"
                name="name"
                label={translate('Venue name')}
                className={classes.textField}
                value={name || ''}
                onChange={this.handleChange('name')}
                InputProps={{
                  classes: {
                    input: 'LargeTitleInput',
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="off"
                name="phone"
                label={translate('Phone number')}
                className={classes.textField}
                value={data.phone || ''}
                onChange={this.handleChange('phone')}
              />
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="city"
                label={translate('City')}
                className={classes.textField}
                value={data.city || ''}
                onChange={this.handleChange('city')}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="state"
                label={translate('State')}
                className={classes.textField}
                value={data.state || ''}
                onChange={this.handleChange('state')}
              />
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="country-label-placeholder">{translate('Country')}</InputLabel>
                <Select
                  value={data.country || ''}
                  onChange={this.handleChange('country')}
                  inputProps={{
                    name: 'country',
                    id: 'country-label-placeholder',
                  }}
                  displayEmpty
                  name="country">
                  <MenuItem value="Mexico">{translate('Mexico')}</MenuItem>
                  <MenuItem value="USA">{translate('United States')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="off"
                name="postal_code"
                label={translate('Postcode')}
                className={classes.textField}
                value={data.postal_code || ''}
                onChange={this.handleChange('postal_code')}
              />
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="country-label-placeholder">{translate('Measure unit system')}</InputLabel>
                <Select
                  value={data.unit_system || ''}
                  onChange={this.handleChange('unit_system')}
                  inputProps={{
                    name: 'unit_system',
                    id: 'unit_system-label-placeholder',
                  }}
                  displayEmpty
                  name="unit_system">
                  <MenuItem value="metric">{translate('Metric')}</MenuItem>
                  <MenuItem value="imperial">{translate('Imperial')}</MenuItem>
                  <MenuItem value="usa">{translate('USA')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Grid item xs={12} md={12} lg={8}>
                <TextField
                  name="description"
                  label={translate('Description')}
                  className={classes.descriptionField}
                  value={data.description || ''}
                  onChange={this.handleChange('description')}
                />
              </Grid>
            </Grid>
          </Grid>
        </ContentPaper>

        <FormActions onDelete={this.handleDelete} onCancel={onCancel} onSubmit={this.handleSubmit} />
      </>
    );
  }
}

export default VenueForm;
