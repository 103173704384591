import { makeActionCreator } from '../../../store/actions';

export const USERS_FETCH_REQUEST = '@users / FETCH REQUEST';
export const USERS_FETCH_SUCCESS = '@users / FETCH SUCCESS';
export const USERS_FETCH_ERROR = '@users / FETCH ERROR';
export const USERS_FETCH_USER_REQUEST = '@users / FETCH USER REQUEST';
export const USERS_FETCH_USER_ERROR = '@users / FETCH USER ERROR';
export const USERS_FETCH_USER_SUCCESS = '@users / FETCH USER SUCCESS';
export const USERS_SHOW_ADD_DIALOG = '@users / SHOW ADD DIALOG';
export const USERS_HIDE_ADD_DIALOG = '@users / HIDE ADD DIALOG';
export const USERS_SET_TABLE_FILTER = '@users / SET TABLE FILTER';
export const USERS_LOADING_START = '@users / LOADING START';
export const USERS_LOADING_STOP = '@users / LOADING STOP';

export const fetchUsers = makeActionCreator(USERS_FETCH_REQUEST);
export const fetchUsersError = makeActionCreator(USERS_FETCH_ERROR, 'error');
export const fetchUsersSuccess = makeActionCreator(USERS_FETCH_SUCCESS, 'data');
export const showUserAddDialog = makeActionCreator(USERS_SHOW_ADD_DIALOG);
export const hideUserAddDialog = makeActionCreator(USERS_HIDE_ADD_DIALOG);

export const fetchUserById = makeActionCreator(USERS_FETCH_USER_REQUEST, 'id');
export const fetchUserByIdError = makeActionCreator(USERS_FETCH_USER_ERROR, 'error');
export const fetchUserByIdSuccess = makeActionCreator(USERS_FETCH_USER_SUCCESS, 'id', 'data');
export const rowStartLoading = makeActionCreator(USERS_LOADING_START, 'id');
export const rowStopLoading = makeActionCreator(USERS_LOADING_STOP, 'id');

// Table filters
export const setTableFilter = makeActionCreator(USERS_SET_TABLE_FILTER, 'name', 'value');
