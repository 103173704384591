import produce from 'immer';
import { denormalize, normalize, schema } from 'normalizr';
import _ from 'lodash';

import {
  PRINTERS_FETCH_REQUEST,
  PRINTERS_FETCH_SUCCESS,
  PRINTERS_FETCH_ERROR,
  PRINTERS_HIDE_ADD_DIALOG,
  PRINTERS_SHOW_ADD_DIALOG,
  PRINTERS_SET_TABLE_FILTER,
  PRINTERS_FETCH_PRINTER_START,
  PRINTERS_FETCH_PRINTER_SUCCESS,
} from '../actions';

const INITIAL_STATE = {
  entities: {
    printers: {},
  },
  entitiesIds: [],
  selected: null,

  // Filters
  filters: {
    search: null,
    printer_type: null,
  },

  // Other state
  showAddDialog: false,
  loading: false,
  initialLoaded: false,
  loadingId: null,
  loadingIds: [],

  fetchError: null,
  saveError: null,
};

// Define the order schema so we can normalize the data and index it properly
const printerOptionsSchema = new schema.Entity('options');
const printersSchema = new schema.Entity('printers', /*{
  options: [printerOptionsSchema],
}*/);
const printerListSchema = new schema.Array(printersSchema);

export default function printers(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case PRINTERS_FETCH_SUCCESS:
        // Normalize the data for better access
        const { data } = action.payload;
        const normalized = normalize(data, printerListSchema);
        draft.entities = normalized.entities;
        draft.entitiesIds = normalized.result;
        draft.loading = false;
        draft.initialLoaded = true;
        draft.fetchError = null;
        break;

      case PRINTERS_FETCH_REQUEST:
        draft.loading = true;
        draft.fetchError = null;
        break;

      case PRINTERS_FETCH_ERROR:
        draft.fetchError = action.payload.error;
        break;

      case PRINTERS_HIDE_ADD_DIALOG:
        draft.showAddDialog = false;
        break;

      case PRINTERS_SHOW_ADD_DIALOG:
        draft.showAddDialog = true;
        break;

      case PRINTERS_SET_TABLE_FILTER:
        draft.filters[action.payload.name] = action.payload.value;
        break;

      case PRINTERS_FETCH_PRINTER_START:
        draft.loadingId = action.payload.id;
        break;

      case PRINTERS_FETCH_PRINTER_SUCCESS:
        // eslint-disable-next-line no-use-before-define
        draft = addOrUpdatePrinterInStore(state, action, draft);

        break;

      default:
    }
  });
}

// Selectors
export function getFilteredPrinters(state) {
  const { entities, filters } = state.printers;

  return _.filter(entities.printers, o => {
    if (filters.search && o.name.toLowerCase().indexOf(filters.search.toLowerCase()) === -1) {
      return false;
    }

    if (filters.printer_type && o.printer_type !== filters.printer_type) {
      return false;
    }

    return true;
  });
}

export const getPrinterByIdSelector = (state, id) => {
  return state.printers && state.printers.entities && state.printers.entities.printers
    ? state.printers.entities.printers[id]
    : {};
};

// Data management functions
const addOrUpdatePrinterInStore = (state, action, draft) => {
  let printersArr = denormalize(state.entitiesIds, printerListSchema, state.entities);
  const rowIndex = state.entitiesIds.indexOf(parseInt(action.payload.id, 10));

  if (rowIndex === -1) {
    printersArr = [...printersArr, action.payload.data];
  } else {
    printersArr = [...printersArr.slice(0, rowIndex), action.payload.data, ...printersArr.slice(rowIndex + 1)];
  }

  const singlePrinter = normalize(printersArr, printerListSchema);
  draft.entities = singlePrinter.entities;
  draft.entitiesIds = singlePrinter.result;

  // Reset loading
  if (state.loadingId === action.payload.id) {
    draft.loadingId = null;
  }

  return draft;
};
