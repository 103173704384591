import moment from 'moment-timezone';
require('moment/locale/ro');
require('moment/locale/es');

let localeConfig = '{ "language": "en" }';
if (localStorage.getItem('localeConfig')) {
  localeConfig = localStorage.getItem('localeConfig');
}
const locale = JSON.parse(localeConfig);
moment.locale(locale.language);

export const convert = (utcTime, format, timezone = locale.timezone) => {
  return moment(utcTime).tz(timezone).format(format);
};

export const time = (utcTime, timezone) => convert(utcTime, 'LT', timezone);
export const date = (utcTime, timezone) => convert(utcTime, 'L', timezone);
export const datetime = (utcTime, timezone) => convert(utcTime, 'LT L ', timezone);
