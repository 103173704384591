import memoize from 'lodash/memoize';
import converter from 'convert-units';
import i18n from './i18n';
import { availableUnits, units } from './units';

const translationGetters = {
  en: () => require('./locales/en.json'),
  es: () => require('./locales/es.json'),
};

/**
 * Translate strings
 */
export const translate = memoize(
  (key, config) => i18n.t(key, config),
  (key, config) => (config ? key + JSON.stringify(config) : key),
);

/**
 * Localize numbers, percent, currency
 */
export const localize = memoize(
  (key, value, config) => i18n.l(key, value, config),
  (key, value, config) => (config ? key + JSON.stringify(config) + (value && value.toString()) : key + (value && value.toString())),
);

export const localizeNoCache = i18n.l;

/**
 * Convert volume between ml and other units
 * @param value
 * @param toMetric
 * @returns {*}
 */
export const volume = memoize(
  (value, from, to = 'ml') => {
    // Convert from ml to the user unit measure
    return converter(value).from(from).to(to);
  },
  (value, from, to) => `${from}-${to}-${value ? value.toString() : ''}`,
);

const getUnitType = unit => {
  if (availableUnits.volume.findIndex(u => u.value === unit) !== -1) {
    return 'volume';
  }

  if (availableUnits.weight.findIndex(u => u.value === unit) !== -1) {
    return 'weight';
  }

  if (availableUnits.other.findIndex(u => u.value === unit) !== -1) {
    return 'other';
  }

  return null;
};

export const convertUnits = memoize(
  (value, from, to = null) => {
    // Determine type of source unit
    if (from === null && to) {
      if (getUnitType(to) === 'volume') {
        return converter(value).from('ml').to(to);
      }

      if (getUnitType(to) === 'weight') {
        return converter(value).from('g').to(to);
      }

      if (getUnitType(to) === 'other') {
        let piecesPerCase = parseFloat(to.replace('case-', ''));
        if (!piecesPerCase) {
          piecesPerCase = 1;
        }

        return value / piecesPerCase;
      }

      return value;
    }

    // Unit which does not convert?
    if (availableUnits.other.findIndex(u => u.value === from) !== -1) {
      if (from === 'pz') {
        return value;
      }

      // Figure out the number of pieces in a case
      let piecesPerCase = parseFloat(from.replace('case-', ''));
      if (!piecesPerCase) {
        piecesPerCase = 1;
      }
      return value * piecesPerCase;
    }

    if (availableUnits.volume.findIndex(u => u.value === from) !== -1) {
      return converter(value)
        .from(from)
        .to(to || 'ml');
    }

    if (availableUnits.weight.findIndex(u => u.value === from) !== -1) {
      return converter(value)
        .from(from)
        .to(to || 'g');
    }

    return value;
  },
  (value, from, to) => `convertUnits-${from}-${to}-${value ? value.toString() : ''}`,
);

/**
 * Convert value from the specified unit to base unit (ml or grams)
 * @param value
 * @param fromUnit
 * @param format
 * @returns {*}
 */
export const convertToBaseUnit = (value, fromUnit, format = 'numberInput', options = {}) =>
  localizeNoCache(format, convertUnits(value, fromUnit, null, options));

/**
 * Convert value from base unit (ml or grams) to the specified unit
 * @param value
 * @param toUnit
 * @param format
 * @returns {*}
 */
export const convertFromBaseUnit = (value, toUnit, format = 'numberInput', options = {}) =>
  localizeNoCache(format, convertUnits(value, null, toUnit, options));

/**
 * Convert length between cm and other units
 * @param value
 * @param toMetric
 * @returns {*}
 */
export const length = memoize(
  (value, toMetric = null) => {
    if (toMetric) {
      // Convert to ml
      return converter(value).from(i18n.measureSystem.length.value).to('cm');
    }

    // Convert from ml to the user unit measure
    return converter(value).from('cm').to(i18n.measureSystem.length.value);
  },
  (value, toMetric) => (toMetric ? `${value.toString()}-toMetric` : value),
);

export const setI18nConfig = async ({ language, measureSystem = 'metric' }) => {
  if (!language) {
    language = 'en';
  }

  // Clear the cache to make sure language switching works
  translate.cache.clear();

  // Set i18n-js global config
  i18n.fallbacks = true;
  i18n.translations = { [language]: translationGetters[language]() };
  i18n.defaultLocale = 'en';
  i18n.locale = language;

  // Configure the unit measure convert system
  i18n.measureSystem = units[measureSystem || 'metric'];

  return language;
};

export { i18n };
