import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';

import _ from 'lodash';
import { Typography, Button, Grid, Input, InputLabel, InputAdornment, FormControl, Box } from '@material-ui/core';
import ApiClient from '../../components/ApiClient';
import BeverageService from '../../services/BeverageService';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonDelete: {
    margin: theme.spacing(1),
    color: theme.palette.error.dark,
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    margin: theme.spacing(2),
    /* marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit, */
    flexBasis: 150,
    width: 150,
  },
});

class Sizes extends React.Component {
  state = {
    customData: null,
    sizeModel: {
      size: '',
      unit: 'ml',
      price: '',
    },
    newSizes: [],
    sizes: [
      {
        label: '300 ml',
        size: '300',
        unit: 'ml',
        price: '',
      },
      {
        label: '355 ml',
        size: '355',
        unit: 'ml',
        price: '',
      },
      {
        label: '473 ml',
        size: '473',
        unit: 'ml',
        price: '',
      },
    ],
  };

  constructor(props) {
    super(props);

    if (props.data.sizes) {
      for (const i in props.data.sizes) {
        this.state.sizes[i].price = props.data.sizes[i].price;
      }
    }
  }

  handleSubmit = async event => {
    const { sizes } = this.state;
    const { id } = this.props.data;
    // Remove empty sizes
    // let cleaned = _.cloneDeep(sizes);

    const cleaned = _.filter(sizes, function (o) {
      return o.size;
    });

    await BeverageService.saveSizes(id, cleaned, () => {});

    //this.props.onSizesUpdated && this.props.onSizesUpdated(this.props.index, { sizes: cleaned });
    this.props.onSizesChanged && this.props.onSizesChanged(cleaned);

    // this.pushNewSize();
  };

  handleChange = (index, prop) => event => {
    const { sizes } = this.state;
    sizes[index][prop] = event.target.value;
    this.setState({ sizes }, () => {
      if (index === sizes.length - 1 && sizes[index].size && sizes[index].price) {
        // this.pushNewSize();
      }
    });
  };

  handleDelete = index => event => {
    const { sizes } = this.state;
    sizes.splice(index, 1);
    this.setState({ sizes });
  };

  pushNewSize = () => {
    const { sizes, sizeModel } = this.state;
    const sizeModelClone = _.cloneDeep(sizeModel);
    sizes.push(sizeModelClone);
    this.setState({ sizes });
  };

  componentDidMount() {
    if (this.state.sizes.length < 1 /* || this.state.sizes[this.state.sizes.length-1].size */) {
      // this.pushNewSize();
    }
  }

  render() {
    const { sizes } = this.state;
    const { classes, data } = this.props;

    return (
      <Box px={0} py={0}>
        {sizes.map((d, i) => {
          return (
            <Grid key={`gssaf${i}`} container direction="row" justify="flex-start" alignItems="center" spacing={2}>
              <Grid item xs={4} md={2}>
                <FormControl fullWidth className={classes.textField} margin="normal">
                  <InputLabel htmlFor={`size-size${i}`}>Size</InputLabel>
                  <Input
                    id={`size-size${i}`}
                    value={d.size}
                    onChange={this.handleChange(i, 'size')}
                    disabled
                    startAdornment={<InputAdornment position="start">{d.unit}</InputAdornment>}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} md={2}>
                <FormControl fullWidth className={classes.textField} margin="normal">
                  <InputLabel htmlFor="size-price">Price</InputLabel>
                  <Input
                    id={`size-price${i}`}
                    value={d.price}
                    onChange={this.handleChange(i, 'price')}
                    onBlur={this.handleSubmit}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  />
                </FormControl>
              </Grid>
              {/* <Grid item xs={4} md={2}>
                                <Button tabIndex="-1" className={classes.buttonDelete} onClick={this.handleDelete(i)}>
                                    <DeleteIcon/>
                                </Button>
                            </Grid> */}
            </Grid>
          );
        })}

        {/*<Button variant="contained" color="primary" onClick={this.handleSubmit} className={classes.button}>
          Save sizes
        </Button>*/}
      </Box>
    );
  }
}

Sizes.propTypes = {
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(Sizes);
