

import React from "react";

import {withStyles} from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

const styles = theme => ({
    root: {
        padding: theme.spacing(3),
        position: 'absolute',
        background: 'rgba(255,255,255,0.6)',
        left: '0',
        right: '0',
        textAlign: 'center',
        top: '0',
        bottom: '0',
        zIndex: 999,
        verticalAlign: 'middle',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },

    progress: {
        margin: theme.spacing(3),
    }
});

@withStyles(styles)
class TableLoadingOverlay extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <Box className={classes.root}>
                <CircularProgress className={classes.progress} />
            </Box>
        )
    }
}

export default TableLoadingOverlay;