import { connect } from 'react-redux';
import _ from 'lodash';
import InventoryInvoiceInput from '../components/incoming-invoice';
import { setPageTitle } from '../../ui/actions';
import { fetchVendors } from '../../vendor/actions';
import { fetchIngredients, showIngredientAddDialog } from '../../ingredient/actions';
import { translate } from '../../../i18n';
import { enqueueSnackbar } from '../../snackbar/actions';

function mapStateToProps(state) {
  const { vendors } = state.ingredients.entities;
  let { ingredients } = state.ingredients.entities;

  // Sort by ingredient name
  ingredients = _.sortBy(Object.values(ingredients) || {}, 'name');

  // Group report rows by vendor
  let groupedByVendor = _(ingredients)
    .groupBy(p => p.vendor_id)
    .map(value => {
      const vendorId = value[0] && value[0].vendor_id ? value[0].vendor_id : null;
      let vendor = {
        id: vendorId,
        name: translate(' - No vendor -'),
      };
      if (vendorId) {
        vendor = { ...vendors[vendorId] };
      }

      return { vendor: vendor.name, id: vendor.id, vendor_id: vendor.id, ingredients: value };
    })
    .value();

  // Sort by vendor name
  groupedByVendor = _.sortBy(Object.values(groupedByVendor) || {}, 'vendor');

  return {
    groupedByVendor,
    ingredients,
    vendors,
    showAddIngredient: state.ingredients.showAddDialog,
  };
}

export default connect(mapStateToProps, {
  setPageTitle,
  fetchVendors,
  fetchIngredients,
  enqueueSnackbar,
  showIngredientAddDialog,
})(InventoryInvoiceInput);
