import React from 'react';
import { withStyles } from '@material-ui/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import TvIcon from '@material-ui/icons/Tv';
import { AppBar, MenuItem, Menu, IconButton, Toolbar, Typography, Divider, ListSubheader } from '@material-ui/core';
import AdminProtectedComponent from '../../../components/AdminProtectedComponent';
import { translate } from '../../../i18n';
import ApiClient from '../../../components/ApiClient';

import { styles } from '../styles';
import UserStore from '../../../lib/stores/UserStore';
import { authApi } from '../../../api/authApi';

@withStyles(styles)
class ApplicationBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      displayAnchorEl: null,
      mobileMoreAnchorEl: null,
    };
  }

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleDisplayMenuOpen = event => {
    this.setState({ displayAnchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null, displayAnchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleLogout = async () => {
    try {
      await authApi.logout();
      UserStore.logoutUser();
      window.location = '/login';
    } catch (err) {
      UserStore.logoutUser();
      window.location = '/login';
    }

    setTimeout(() => (window.location = '/login'), 500);
  };

  setLanguage = async language => {
    const { history } = this.props;

    await ApiClient.post({
      endpoint: '/api/user/change-language',
      data: {
        language,
      },
    });

    history.go();
  };

  renderMenu = () => {
    const { anchorEl } = this.state;
    const isDrawerOpen = Boolean(anchorEl);

    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isDrawerOpen}
        onClose={this.handleMenuClose}>
        <ListSubheader>{translate('Language')}</ListSubheader>
        <MenuItem onClick={() => this.setLanguage('en')}>English</MenuItem>
        <MenuItem onClick={() => this.setLanguage('es')}>Español</MenuItem>
        <Divider />
        <MenuItem onClick={this.handleLogout}>{translate('Logout')}</MenuItem>
      </Menu>
    );
  };

  renderMenuDisplay = () => {
    const { displayAnchorEl } = this.state;
    const isDisplayMenuOpen = Boolean(displayAnchorEl);

    return (
      <Menu
        anchorEl={displayAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isDisplayMenuOpen}
        onClose={this.handleMenuClose}>
        <MenuItem component="a" href="/display" target="_blank">
          {translate('Preview menu')}
        </MenuItem>
      </Menu>
    );
  };

  renderMobileMenu = () => {
    const { mobileMoreAnchorEl } = this.state;
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    return (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}>
        <MenuItem onClick={this.handleProfileMenuOpen}>
          <p>{translate('Account')}</p>
        </MenuItem>
      </Menu>
    );
  };

  render() {
    const { classes, pageTitle, drawerOpen, closeDrawer, openDrawer } = this.props;

    // Set page title
    document.title = `${pageTitle ? `${pageTitle} - ` : ''}Campfire Bar Manager`;

    return (
      <>
        <AppBar position="fixed" elevation={0} className={classes.appBar}>
          <Toolbar variant="dense">
            <IconButton
              onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                if (drawerOpen) {
                  closeDrawer();
                  localStorage.setItem('isDrawerOpen', '0');
                } else {
                  openDrawer();
                  localStorage.setItem('isDrawerOpen', '1');
                }
              }}
              className={classes.menuButton}
              color="inherit">
              <MenuIcon />
            </IconButton>
            <Typography className={classes.title} variant="h6" color="inherit" noWrap>
              {pageTitle}
            </Typography>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <AdminProtectedComponent>
                <IconButton onClick={this.handleDisplayMenuOpen} color="inherit">
                  <TvIcon />
                </IconButton>
              </AdminProtectedComponent>
              <IconButton onClick={this.handleProfileMenuOpen} color="inherit">
                <AccountCircle />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton onClick={this.handleMobileMenuOpen} color="inherit">
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {this.renderMenuDisplay()}
        {this.renderMenu()}
        {this.renderMobileMenu()}
      </>
    );
  }
}

export default ApplicationBar;
