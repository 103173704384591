import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withMobileDialog,
} from '@material-ui/core';

class ConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open || true,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({ open: false });
    this.props.cancel();
  };

  handleConfirm = event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({ open: false });
    this.props.confirm();
  };

  render() {
    const { fullScreen, title, message } = this.props;

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title">
          {!title ? null : <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>}
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.handleConfirm} color="primary" autoFocus>
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ConfirmationDialog.defaultProps = {
  title: 'Confirm action',
};

ConfirmationDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default withMobileDialog()(ConfirmationDialog);
