import { connect } from 'react-redux';
import CategoryForm from '../components/category-form';
import { setPageTitle } from '../../ui/actions';
import { enqueueSnackbar as enqueueSnackbarAction } from '../../snackbar/actions';
import { getCategoryByIdSelector } from '../reducers';
import { fetchCategoryById, fetchCategories } from '../actions';
import { fetchIngredients as fetchIngredientsAction } from '../../ingredient/actions';

const mapStateToProps = (state, ownProps) => {
  const data = getCategoryByIdSelector(state, ownProps.id);

  return {
    data,
  };
};

const mapDispatchToProps = {
  setPageTitle,
  fetchData: fetchCategories,
  fetchCategoryById,
  enqueueSnackbar: enqueueSnackbarAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryForm);
