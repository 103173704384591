import { createMuiTheme } from '@material-ui/core';
import defaultTheme from '@material-ui/core/styles/defaultTheme';

export const palette = {
  /* primary: { main: '#cb0022' },
    secondary: {
        main: '#212121'
    } */

  primary: {
    light: '#ff4f4b',
    main: '#cc0022',
    dark: '#cc0022',
    contrastText: '#fff',
  },

  // Dark grey
  secondary: {
    light: '#484848',
    main: '#212121',
    dark: '#000000',
    contrastText: '#fff',
  },

  warning: {
    main: '#ff8e27',
  },

  background: {
    default: '#ffffff',
  },
};
export const themeName = 'Campfire';

const typography = {};

const overrides = {
  '@global': {
    '.d-table': {
      display: 'table',
    },
  },
  MuiApp: {
    root: {
      paddingTop: 52,
    },
  },
  MuiAutocomplete: {
    clearIndicator: {
      padding: '2px !important',
    },
    popupIndicator: {
      padding: '2px !important',
    },
  },
  MuiPaper: {
    root: {
      '&.FiltersPaper': {
        padding: '8px 16px',
        marginBottom: '16px',
      },

      '&.FormBoxPaper': {
        padding: defaultTheme.spacing(3, 3, 2),
        marginBottom: '24px',

        [defaultTheme.breakpoints.down('sm')]: {
          padding: defaultTheme.spacing(2),
        },
      },

      '&.ButtonsBoxPaper': {
        padding: '12px 0',
        marginTop: -12,
        marginBottom: '32px',
        background: 'transparent',
        border: 0,
        borderRadius: 0,
        borderTop: '1px solid #ccc',
        boxShadow: 'none',
      },

      '&.ContentPaper-Loading': {
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  MuiButton: {
    iconSizeMedium: {
      '&> *:first-child': {
        fontSize: 16,
      },
    },
  },
  MuiInput: {
    input: {
      fontSize: '0.875rem',
      '&.LargeTitleInput': {
        fontSize: 24,
      },
    },
    underline: {
      '&:before': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
      },
    },
  },
  MuiTextField: {

  },
  MuiSkeleton: {
    root: {
      '&.LoadingSkeleton': {

      },
    },
  },
};

const props = {
  // Name of the component
  MuiTextField: {
    // The default props to change
    /* InputLabelProps: {
      shrink: true,
    }, */
  },
};

const theme = createMuiTheme({ palette, typography, overrides, themeName, props });
export default theme;
