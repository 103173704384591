import { all, takeLatest } from 'redux-saga/effects';
import {
  MENUS_ADD_REQUEST,
  MENUS_COPY_MENU,
  MENUS_DELETE_REQUEST,
  MENUS_FETCH_MENU_START,
  MENUS_FETCH_REQUEST,
  MENUS_SAVE_REQUEST,
  MENUS_UPDATE_DATA,
} from '../features/menus/actions';
import {
  addMenuSaga,
  copyMenuSaga,
  deleteMenuSaga,
  fetchMenuByIdSaga,
  fetchMenusSaga,
  saveMenuSaga,
  updateMenuSaga,
} from '../features/menus/sagas';

import { BEVERAGES_FETCH_REQUEST } from '../features/beverage/actions';
import { fetchBeveragesSaga } from '../features/beverage/sagas';
import { AREAS_FETCH_REQUEST } from '../features/area/actions';
import { fetchAreasSaga } from '../features/area/sagas';
import { VENDORS_FETCH_REQUEST } from '../features/vendor/actions';
import { fetchVendorsSaga } from '../features/vendor/sagas';
import { fetchIngredientsSaga } from '../features/ingredient/sagas';
import { INGREDIENTS_FETCH_REQUEST } from '../features/ingredient/actions';
import { fetchVenuesSaga } from '../features/venue/sagas';
import { VENUES_FETCH_REQUEST } from '../features/venue/actions';
import { USERS_FETCH_REQUEST, USERS_FETCH_USER_REQUEST } from '../features/user/actions';
import { fetchUserByIdSaga, fetchUsersSaga } from '../features/user/sagas';
import { PAYMENTS_FETCH_REQUEST } from '../features/payments/actions';
import { fetchPaymentsSaga } from '../features/payments/sagas';
import { RECIPES_FETCH_RECIPE_START, RECIPES_FETCH_REQUEST } from '../features/recipe/actions';
import { fetchRecipeByIdSaga, fetchRecipesSaga } from '../features/recipe/sagas';
import { MODIFIERS_FETCH_MODIFIER_START, MODIFIERS_FETCH_REQUEST } from '../features/modifier/actions';
import { fetchModifierByIdSaga, fetchModifiersSaga } from '../features/modifier/sagas';
import {
  PRODUCTS_FETCH_PRINTER_AREAS_START,
  PRODUCTS_FETCH_PRODUCT_START,
  PRODUCTS_FETCH_REQUEST,
} from '../features/product/actions';
import { fetchPrinterAreasSaga, fetchProductByIdSaga, fetchProductsSaga } from '../features/product/sagas';
import { CATEGORIES_FETCH_CATEGORY_START, CATEGORIES_FETCH_REQUEST } from '../features/category/actions';
import { fetchCategoriesSaga, fetchCategoryByIdSaga } from '../features/category/sagas';
import { DISCOUNTS_FETCH_DISCOUNT_START, DISCOUNTS_FETCH_REQUEST } from '../features/discount/actions';
import { fetchDiscountByIdSaga, fetchDiscountsSaga } from '../features/discount/sagas';
import { PRINTERS_FETCH_PRINTER_START, PRINTERS_FETCH_REQUEST } from '../features/printer/actions';
import { fetchPrinterByIdSaga, fetchPrintersSaga } from '../features/printer/sagas';
import { AUDIT_DATABASE_FETCH_REQUEST, AUDIT_EVENT_FETCH_REQUEST } from '../features/audit/actions';
import { fetchAuditDatabaseSaga, fetchAuditEventsSaga } from '../features/audit/sagas';

export default function* rootSaga() {
  yield all([
    // Menus
    takeLatest(MENUS_FETCH_REQUEST, fetchMenusSaga),
    takeLatest(MENUS_ADD_REQUEST, addMenuSaga),
    takeLatest(MENUS_COPY_MENU, copyMenuSaga),
    takeLatest(MENUS_SAVE_REQUEST, saveMenuSaga),
    takeLatest(MENUS_DELETE_REQUEST, deleteMenuSaga),
    takeLatest(MENUS_FETCH_MENU_START, fetchMenuByIdSaga),
    takeLatest(MENUS_UPDATE_DATA, updateMenuSaga),

    // Beverages
    takeLatest(BEVERAGES_FETCH_REQUEST, fetchBeveragesSaga),

    // Areas
    takeLatest(AREAS_FETCH_REQUEST, fetchAreasSaga),

    // Vendors
    takeLatest(VENDORS_FETCH_REQUEST, fetchVendorsSaga),

    // Ingredients
    takeLatest(INGREDIENTS_FETCH_REQUEST, fetchIngredientsSaga),

    // Venues
    takeLatest(VENUES_FETCH_REQUEST, fetchVenuesSaga),

    // Payments
    takeLatest(PAYMENTS_FETCH_REQUEST, fetchPaymentsSaga),

    // Users
    takeLatest(USERS_FETCH_REQUEST, fetchUsersSaga),
    takeLatest(USERS_FETCH_USER_REQUEST, fetchUserByIdSaga),

    // Recipes
    takeLatest(RECIPES_FETCH_REQUEST, fetchRecipesSaga),
    takeLatest(RECIPES_FETCH_RECIPE_START, fetchRecipeByIdSaga),

    // Modifiers
    takeLatest(MODIFIERS_FETCH_REQUEST, fetchModifiersSaga),
    takeLatest(MODIFIERS_FETCH_MODIFIER_START, fetchModifierByIdSaga),

    // Products
    takeLatest(PRODUCTS_FETCH_REQUEST, fetchProductsSaga),
    takeLatest(PRODUCTS_FETCH_PRODUCT_START, fetchProductByIdSaga),
    takeLatest(PRODUCTS_FETCH_PRINTER_AREAS_START, fetchPrinterAreasSaga),

    // Product categories
    takeLatest(CATEGORIES_FETCH_REQUEST, fetchCategoriesSaga),
    takeLatest(CATEGORIES_FETCH_CATEGORY_START, fetchCategoryByIdSaga),

    // Discounts
    takeLatest(DISCOUNTS_FETCH_REQUEST, fetchDiscountsSaga),
    takeLatest(DISCOUNTS_FETCH_DISCOUNT_START, fetchDiscountByIdSaga),

    // Printer groups
    takeLatest(PRINTERS_FETCH_REQUEST, fetchPrintersSaga),
    takeLatest(PRINTERS_FETCH_PRINTER_START, fetchPrinterByIdSaga),

    // Audit
    takeLatest(AUDIT_EVENT_FETCH_REQUEST, fetchAuditEventsSaga),
    takeLatest(AUDIT_DATABASE_FETCH_REQUEST, fetchAuditDatabaseSaga),
  ]);
}
