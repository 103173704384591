import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

const LoadingContainer = ({ loading, children }) => {
  if (loading) {
    return <CircularProgress variant="indeterminate" size={24} color="secondary" />;
  }
  return children;
};

export default LoadingContainer;
