import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Checkbox, TableHead, TableRow, TableCell, Tooltip, TableSortLabel } from '@material-ui/core';

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, pageRowCount, noCheckbox, columns } = this.props;

    return (
      <TableHead>
        <TableRow>
          {noCheckbox ? null : (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < pageRowCount}
                checked={!!(numSelected && numSelected === pageRowCount)}
                onChange={onSelectAllClick}
              />
            </TableCell>
          )}
          {columns &&
            columns.map(
              row => (
                <TableCell
                  key={row.id}
                  align={row.align ? row.align : row.numeric ? 'right' : 'left'}
                  padding={row.padding ? row.padding : 'default'}
                  sortDirection={orderBy === row.id ? order : false}>
                  {row.sortable ? (
                    <Tooltip title="Sort" placement={row.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
                      <TableSortLabel
                        active={orderBy === row.id}
                        direction={order}
                        onClick={this.createSortHandler(row.id)}>
                        {row.label}
                      </TableSortLabel>
                    </Tooltip>
                  ) : (
                    <span>{row.label}</span>
                  )}
                </TableCell>
              ),
              this,
            )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
};

export default EnhancedTableHead;
