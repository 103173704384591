import { connect } from 'react-redux';
import AreaAddDialog from '../components/area-add-dialog';
import { showAreaAddDialog, hideAreaAddDialog } from '../actions';

function mapStateToProps(state) {
  return {
    open: state.areas.showAddDialog,
  };
}

const mapDispatchToProps = {
  showAddDialog: showAreaAddDialog,
  hideAddDialog: hideAreaAddDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(AreaAddDialog);
