import React from 'react';

import { withRouter } from 'react-router-dom';
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  withStyles,
} from '@material-ui/core';
import { translate } from '../../../i18n';
import { vendorApi } from '../../../api/vendor';

const styles = theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(0, 3),
  },
  error: {
    color: theme.palette.error.dark,
    marginTop: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 99,
  },
});

@withStyles(styles)
@withRouter
class AddVendorDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      error: '',
    };
  }

  handleSubmit = async () => {
    const { name } = this.state;
    try {
      const response = await vendorApi.add({
        name,
        producer: '',
      });

      const { data } = response.data;
      const id = data && data.id;

      if (id) {
        const { hideAddDialog, history } = this.props;
        hideAddDialog();

        history.push(`/vendor/edit/${id}`);
      }
    } catch (e) {
      console.log(e)
      this.setState({
        error: translate('An error occurred while saving the data. Please try again.'),
      });
    }
  };

  render() {
    const { error, name } = this.state;
    const { classes, open, hideAddDialog } = this.props;

    return (
      <div className={classes.root}>
        <Dialog open={open} onClose={hideAddDialog} aria-labelledby="form-dialog-title" fullWidth>
          <DialogContent>
            <DialogContentText>{translate('Enter the new vendor name')}</DialogContentText>
            {!error ? null : (
              <Typography component="span" className={classes.error}>
                {error}
              </Typography>
            )}
            <TextField
              error={!!error}
              value={name}
              onChange={event => this.setState({ name: event.target.value })}
              autoFocus
              margin="dense"
              id="name"
              label=""
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={hideAddDialog} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AddVendorDialog;
