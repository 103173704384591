import React from 'react';
import { connect } from 'react-redux';
import Categories from '../components/categories';
import { fetchCategories, hideCategoryAddDialog, setTableFilter, showCategoryAddDialog } from '../actions';
import { setPageTitle } from '../../ui/actions';
import { getFilteredCategories } from '../reducers';
import { enqueueSnackbar } from '../../snackbar/actions';
import { fetchIngredients } from '../../ingredient/actions';

function mapStateToProps(state) {
  const categories = getFilteredCategories(state);

  return {
    dataById: categories || {},
    data: state.categories.entitiesIds || [],
    filters: state.categories.filters,
    selected: state.categories.selected,
    loading: state.categories.loading,
    initialLoaded: state.categories.initialLoaded,
    fetchError: state.categories.fetchError,
    loadingIds: state.categories.loadingIds,
  };
}

const mapDispatchToProps = {
  fetchData: fetchCategories,
  fetchIngredients,
  setPageTitle,
  setTableFilter,
  showAddDialog: showCategoryAddDialog,
  hideAddDialog: hideCategoryAddDialog,
  enqueueSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
