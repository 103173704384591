import React from 'react';
import { TextField } from '@material-ui/core';
import { translate } from '../../i18n';
import NumericInputFormat from './numeric';

const CurrencyField = ({ isNumericString, inputProps = {}, InputProps = {}, ...rest}) => {
  return (
    <TextField
      {...rest}
      InputProps={{
        ...InputProps,
        inputProps: {
          fixedDecimalScale: true,
          prefix: `${translate('number||currency||format||unit')} `,
          ...inputProps,
        },
        inputComponent: NumericInputFormat,
      }}
    />
  );
};

export default CurrencyField;
