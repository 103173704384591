const lodash = require('lodash');
const { EventEmitter } = require('events');

const emitter = new EventEmitter();

let config = {
  CurrentPageTitle: 'Dashboard',
  AppBarSearch: false,
  CurrentDisplayId: null,
};

module.exports = {
  getConfig() {
    if (arguments[0]) {
      return config[arguments[0]];
    }
    return config;
  },

  setConfig(name, value) {
    if (typeof name === 'string') {
      config[name] = value;
    } else if (typeof name === 'object') {
      // Merge config
      config = lodash.merge(config, name);
    }

    emitter.emit('update');
  },

  subscribe(callback, event) {
    emitter.on(event || 'update', callback);
  },

  unsubscribe(callback, event) {
    emitter.removeListener(event || 'update', callback);
  },
};
