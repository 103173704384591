import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import snackbar from '../features/snackbar/reducers';
import ui from '../features/ui/reducers';
import orders from '../features/order/reducers';
import menus from '../features/menus/reducers';
import beverage from '../features/beverage/reducers';
import areas from '../features/area/reducers';
import vendors from '../features/vendor/reducers';
import ingredients from '../features/ingredient/reducers';
import venues from '../features/venue/reducers';
import users from '../features/user/reducers';
import payments from '../features/payments/reducers';
import recipes from '../features/recipe/reducers';
import modifiers from '../features/modifier/reducers';
import products from '../features/product/reducers';
import categories from '../features/category/reducers';
import discounts from '../features/discount/reducers';
import printers from '../features/printer/reducers';
import audit from '../features/audit/reducers';
import reports from '../features/reports/store/reducers';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    ui,
    snackbar,
    orders,
    menus,
    beverage,
    areas,
    vendors,
    ingredients,
    venues,
    users,
    payments,
    recipes,
    modifiers,
    products,
    categories,
    discounts,
    printers,
    audit,
    reports,
  });

export default createRootReducer;
