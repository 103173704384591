import { call, put } from 'redux-saga/effects';

import { fetchBeveragesError, fetchBeveragesSuccess } from '../actions';
import { beverageApi } from '../../../api/beverage';
import { parseApiError } from '../../../api/helpers';
import { hideProgressBar, showProgressBar } from '../../ui/actions';

export function* fetchBeveragesSaga() {
  try {
    yield put(showProgressBar());
    const response = yield call(beverageApi.fetch);
    yield put(hideProgressBar());

    if (!response.ok) {
      yield put(fetchBeveragesError(parseApiError(response).message));
      return;
    }

    yield put(fetchBeveragesSuccess(response.data.beverage.results || []));
  } catch (err) {
    yield put(fetchBeveragesError(parseApiError(err).message));
  }
}
