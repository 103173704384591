import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import { List, withStyles } from '@material-ui/core';

import _ from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import AppDrawerNavItem from './AppDrawerNavItem';

import { pageToTitleI18n } from '../util/helpers';
import UserStore from '../lib/stores/UserStore';
import pages, { findActivePage } from '../pages';
import { translate } from '../i18n';

function renderNavItems(options) {
  const { pages, ...params } = options;

  return (
    <List>
      {pages.reduce(
        // eslint-disable-next-line no-use-before-define
        (items, page) => reduceChildRoutes({ items, page, ...params }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({ props, activePage, items, page, depth, t, drawerOpen, isMobile }) {
  if (page.displayNav === false) {
    return items;
  }

  const userData = UserStore.getUser();

  // Check role
  if (page.requireRole && (!userData || !userData.role)) {
    return items;
  }

  if (page.requireRole) {
    const role = page.requireRole;

    if (typeof role === 'string' && role !== userData.role) {
      return items;
    }

    if (role.indexOf(userData.role) === -1) {
      return items;
    }
  }

  let currentPage = activePage.pathname === page.pathname;

  if (page.children && page.children.length > 1) {
    const title = pageToTitleI18n(page, t);
    const topLevel = activePage.pathname.indexOf(`${page.pathname}/`) === 0;

    // Check if any of the children is active; if yes, we make this active too
    if (!currentPage) {
      currentPage =
        _.findIndex(page.children, c => {
          return c.pathname === activePage.pathname;
        }) !== -1;
    }

    items.push(
      <AppDrawerNavItem
        drawerOpen={drawerOpen}
        linkProps={page.linkProps}
        pageObject={page}
        depth={depth}
        key={title}
        topLevel={topLevel && !page.subheader}
        openImmediately={topLevel || Boolean(page.subheader) || page.startOpen || (drawerOpen && currentPage)}
        title={title}
        icon={page.icon ? page.icon : null}
        showToggle={drawerOpen}
        isMobile={isMobile}
        active={currentPage}>
        {renderNavItems({ props, pages: page.children, activePage, depth: depth + 1, t, drawerOpen })}
      </AppDrawerNavItem>,
    );
  } else {
    const title = pageToTitleI18n(page, t);
    page = page.children && page.children.length === 1 ? page.children[0] : page;

    items.push(
      <AppDrawerNavItem
        drawerOpen={drawerOpen}
        linkProps={page.linkProps}
        pageObject={page}
        depth={depth}
        key={title}
        title={title}
        icon={page.icon ? page.icon : null}
        isMobile={isMobile}
        active={currentPage}
        href={page.pathname}
        onClick={props.onClose}
      />,
    );
  }

  return items;
}

class NavbarOwn extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      selectedIndex: 0,
    };
  }

  handleClickButton(event) {
    event.preventDefault();
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  render() {
    const { props } = this;
    const { classes, drawerOpen, history, isMobile } = this.props;
    const t = translate;
    const { anchorEl, selectedIndex } = this.state;

    // Determine active page
    let { pathname } = window.location;

    // Add support for leading / in development mode.
    if (pathname !== '/') {
      // The leading / is only added to support static hosting (resolve /index.html).
      // We remove it to normalize the pathname.
      // See `_rewriteUrlForNextExport` on Next.js side.
      pathname = pathname.replace(/\/$/, '');
    }

    let activePage = findActivePage(pages, pathname);
    if (!activePage) {
      activePage = {
        pathname: '',
      };
    }

    const user = UserStore.getUser();
    const venues = user ? user.venues : [];

    const currentVenueId = UserStore.getCurrentVenue();
    const venue = _.find(venues, o => {
      return o.id.toString() === currentVenueId.toString();
    });

    if (!venue || !venues) {
      return null;
    }

    return (
      <div id="sidebar-wrapper" className={classes.sidebar}>
        <Box className={classes.venueChanger}>
          <a href="#" className={classes.changerAction} onClick={this.handleClickButton.bind(this)}>
            <i className={[classes.venueIcon, 'fal fa-map-marker-alt'].join(' ')} />

            <span className={classes.profileInfo}>
              {venue.name}
              {venue.city && (
                <small className={classes.small}>{`${venue.city}, ${venue.country ? venue.country : '-'}`}</small>
              )}
            </span>

            {/* <MoreVertIcon className={classes.venueIcon} /> */}
          </a>

          <Menu
            id="venue-selection-menu"
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            onClose={() =>
              this.setState({
                anchorEl: null,
              })
            }>
            {venues.map((option, index) => (
              <MenuItem
                key={option.id}
                selected={index === selectedIndex}
                onClick={() => {
                  const venueId = option.id;
                  UserStore.setCurrentVenue(venueId);

                  this.setState(
                    {
                      anchorEl: null,
                      selectedIndex: index,
                    },
                    () => {
                      // Redirect to dashboard
                      history.push('/dashboard');
                    },
                  );
                }}>
                <span className={classes.profileInfo}>
                  {option.name}
                  <small className={classes.small}>{`${option.city}, ${option.country}`}</small>
                </span>
              </MenuItem>
            ))}
          </Menu>
        </Box>

        <Divider />

        {renderNavItems({ props, pages, activePage, depth: 0, t, drawerOpen, isMobile })}
      </div>
    );
  }
}

export default withRouter(NavbarOwn);
