import React from 'react';
import { Route } from 'react-router-dom';
import NoMatch from './page-404';
import CustomStaticSwitch from '../components/CustomStaticSwitch';

import { routes } from '../routes';

const RouteSwitcher = ({ appRef }) => {
  return (
    <CustomStaticSwitch>
      {routes.map(route => {
        const { RouteComponent, ...props } = route;
        return <RouteComponent appRef={appRef} {...props} />;
      })}

      <Route component={NoMatch} />
    </CustomStaticSwitch>
  );
};

export default RouteSwitcher;
