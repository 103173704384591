import { connect } from 'react-redux';
import ProductSmallFormComponent from '../components/product-small-form';
import { setPageTitle } from '../../ui/actions';
import { enqueueSnackbar as enqueueSnackbarAction } from '../../snackbar/actions';
import { getProductByIdSelector } from '../reducers';
import { fetchPrinterAreas, fetchProductById, fetchProducts } from '../actions';
import { fetchIngredients as fetchIngredientsAction } from '../../ingredient/actions';
import { fetchCategories } from '../../category/actions';
import { fetchModifiers } from '../../modifier/actions';

const mapStateToProps = (state, ownProps) => {
  const data = getProductByIdSelector(state, ownProps.id);

  let { ingredients } = state.ingredients.entities;
  ingredients = ingredients ? Object.values(ingredients).map(o => ({ id: o.id, name: o.name })) : null;

  let { recipes } = state.recipes.entities;
  recipes = recipes ? Object.values(recipes).map(o => ({ id: o.id, name: o.name })) : null;

  let { categories } = state.categories.entities;
  categories = categories ? Object.values(categories).map(o => ({ id: o.id, name: o.name })) : null;

  let { printerAreas } = state.products;
  // printerAreas = printerAreas ? Object.values(printerAreas).map(o => o.printer_area) : [];
  printerAreas = printerAreas ? Object.values(printerAreas) : [];

  return {
    data,
    recipes,
    ingredients,
    categories,
    modifiers: Object.values(state.modifiers.entities.modifiers || {}),
    printerAreas,
  };
};

const mapDispatchToProps = {
  setPageTitle,
  fetchData: fetchProducts,
  fetchProductById,
  fetchIngredients: fetchIngredientsAction,
  fetchCategories,
  fetchModifiers,
  fetchPrinterAreas,
  enqueueSnackbar: enqueueSnackbarAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSmallFormComponent);
