import React from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core';
import capitalize from 'lodash/capitalize';
import Button from '@material-ui/core/Button';
import styles from '../styles';
import EnhancedTable from '../../../components/enhanced-table/EnhancedTable';
import ContentPaper from '../../../components/content-paper';
import ActionMenu from './row-actions';
import BeverageAddDialog from '../containers/beverage-add-dialog';
import { beverageApi } from '../../../api/beverage';
import { translate } from '../../../i18n';
import { store } from '../../../store';
import { fetchBeveragesSuccess } from '../actions';
import { hideProgressBar, showProgressBar } from '../../ui/actions';

@withStyles(styles)
class Beverage extends React.Component {
  static prefetch = async navigate => {
    const response = await beverageApi.fetch();
    if (response.ok) {
      store.dispatch(showProgressBar());
      store.dispatch(fetchBeveragesSuccess(response.data.beverage.results || []));
    }
    store.dispatch(hideProgressBar());
    navigate();
  };

  componentDidMount() {
    const { fetchData, setPageTitle } = this.props;
    fetchData();

    setPageTitle(translate('Beverage'));
  }

  handleAdd = () => {
    const { showAddDialog } = this.props;
    showAddDialog();
  };

  handleMassDelete = ids => ids.map(id => this.handleDelete(id));

  handleDelete = async id => {
    const { enqueueSnackbar, fetchData } = this.props;

    // Delete AJAX
    try {
      await beverageApi.deleteById(id);

      // All good
      setTimeout(() => fetchData(), 100);
    } catch (err) {
      enqueueSnackbar(translate('Could not delete the beverage'), { options: { variant: 'error' } });
    }
  };

  handleCopy = async (id, data) => {
    const { enqueueSnackbar, fetchData } = this.props;

    if (!data) {
      enqueueSnackbar(translate('Beverage not copied'), { options: { variant: 'error' } });
      return;
    }

    const cloned = { ...data, id: null, name: translate('{{name}} - Copy', { name: data.name }) };

    try {
      const response = await beverageApi.add(cloned);
      if (!response.ok) {
        enqueueSnackbar(translate('Beverage not copied'), { options: { variant: 'error' } });
        return;
      }

      enqueueSnackbar(translate('Beverage copied'));
      fetchData();
    } catch (e) {
      enqueueSnackbar(translate('Beverage not copied'), { options: { variant: 'error' } });
    }
  };

  handleRowClick = ({ id }) => {
    const { history } = this.props;
    history.push(`/beverage/edit/${id}`);
  };

  getTableColumns = () => {
    const { loadingIds } = this.props;

    return [
      {
        id: 'name',
        accessor: 'name',
        sortable: true,
        component: 'td',

        label: translate('Name'),
      },
      {
        id: 'beverage_type',
        accessor: 'beverage_type',
        label: translate('Type'),
        renderValue: row => capitalize(row.beverage_type),
      },
      {
        id: 'producer_name',
        accessor: 'producer_name',
        label: translate('Producer'),
      },
      {
        id: 'id',
        accessor: 'id',
        numeric: false,
        label: '',
        align: 'right',
        padding: 'none',
        renderValue: row => (
          <ActionMenu
            isLoading={loadingIds.indexOf(parseInt(row.id, 10)) !== -1}
            handleDelete={this.handleDelete}
            handleCopy={this.handleCopy}
            id={row.id}
            data={row}
          />
        ),
      },
    ];
  };

  getTableFilters = () => {
    const { setTableFilter, filters, producers } = this.props;

    let producersFilter = _.map(producers, producer => ({ value: producer.id, label: producer.name }));
    producersFilter = _.orderBy(producersFilter, 'label');

    return [
      {
        label: translate('Type'),
        type: 'select',
        value: filters.beverage_type,
        onChange: value => setTableFilter('beverage_type', value),
        options: [
          {
            value: '',
            label: translate('All'),
          },
          {
            value: 'beer',
            label: translate('Beer'),
          },
          {
            value: 'cider',
            label: translate('Cider'),
          },
          {
            value: 'other',
            label: translate('Other'),
          },
          {
            value: 'wine',
            label: translate('Wine'),
          },
        ],
      },
      {
        label: translate('Producer'),
        type: 'select',
        value: filters.producer,
        onChange: value => setTableFilter('producer', value),
        options: [
          {
            value: '',
            label: translate('All'),
          },
          ...producersFilter,
        ],
      },
      {
        label: translate('Search'),
        type: 'search',
        value: filters.search,
        onChange: value => setTableFilter('search', value),
      },
    ];
  };

  shouldComponentRender() {
    const { dataById } = this.props;
    return dataById != null;
  }

  render() {
    const { classes, dataById } = this.props;

    if (!this.shouldComponentRender()) {
      return <div />;
    }

    const data = Object.values(dataById);

    return (
      <div className={classes.root}>
        <BeverageAddDialog />
        <ContentPaper table className={classes.paper}>
          <EnhancedTable
            toolbarActions={
              <>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleAdd}
                  startIcon={<i className="fal fa-plus" />}>
                  {translate('Add beverage')}
                </Button>
              </>
            }
            columns={this.getTableColumns()}
            filters={this.getTableFilters()}
            data={data}
            rowCount={data.length}
            className={classes.table}
            onMassDelete={this.handleMassDelete}
            onRowClick={this.handleRowClick}
            filter={null}
            order="asc"
            orderBy="name"
          />
        </ContentPaper>
      </div>
    );
  }
}

export default Beverage;
