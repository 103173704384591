import { createClient } from './helpers';

const apiClient = createClient();

function fetch() {
  return apiClient.get('menu');
}

function add(data) {
  return apiClient.post('menu', data);
}

function update(id, data) {
  return apiClient.patch(`menu/${id}`, data);
}

function getById(id) {
  return apiClient.get(`menu/${id}`);
}

function deleteById(id) {
  return apiClient.delete(`menu/${id}`);
}

export const menusApi = {
  fetch,
  add,
  update,
  getById,
  deleteById,
};
