import { createClient } from './helpers';

const apiClient = createClient();

function getFilters() {
  return apiClient.get('reports/sales/filters');
}

function getSalesSummary(filters) {
  return apiClient.post('reports/sales', filters);
}

function getItemsSales(filters) {
  return apiClient.post('reports/sales/items', filters);
}

function getReceiptsReport(filters) {
  return apiClient.post('reports/sales/receipts', filters);
}

function getDiscountsReport(filters) {
  return apiClient.post('reports/sales/discounts', filters);
}

function getCategoriesSales(filters) {
  return apiClient.post('reports/sales/categories', filters);
}

export const reportsApi = {
  getFilters,
  getSalesSummary,
  getItemsSales,
  getReceiptsReport,
  getDiscountsReport,
  getCategoriesSales,
};
