import produce from 'immer';
import { normalize, schema } from 'normalizr';
import _ from 'lodash';

import {
  PAYMENTS_FETCH_REQUEST,
  PAYMENTS_FETCH_SUCCESS,
  PAYMENTS_FETCH_ERROR,
  PAYMENTS_HIDE_ADD_DIALOG,
  PAYMENTS_SHOW_ADD_DIALOG,
  PAYMENTS_SET_TABLE_FILTER,
} from '../actions';

const INITIAL_STATE = {
  entities: {
    payments: {},
  },
  entitiesIds: [],
  selected: null,

  // Filters
  filters: {
    search: null,
    status: 'new',
  },

  // Other state
  showAddDialog: false,
  loading: false,
  initialLoaded: false,
  loadingId: null,
  loadingIds: [],

  fetchError: null,
  saveError: null,
};

// Define the order schema so we can normalize the data and index it properly
const paymentschema = new schema.Entity('payments');
const paymentListSchema = new schema.Array(paymentschema);

export default function payments(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case PAYMENTS_FETCH_SUCCESS:
        // Normalize the data for better access
        const { data } = action.payload;
        const normalized = normalize(data, paymentListSchema);
        draft.entities = normalized.entities;
        draft.entitiesIds = normalized.result;
        draft.loading = false;
        draft.initialLoaded = true;
        draft.fetchError = null;

        break;

      case PAYMENTS_FETCH_REQUEST:
        draft.loading = true;
        draft.fetchError = null;
        break;

      case PAYMENTS_FETCH_ERROR:
        draft.fetchError = action.payload.error;
        break;

      case PAYMENTS_HIDE_ADD_DIALOG:
        draft.showAddDialog = false;
        break;

      case PAYMENTS_SHOW_ADD_DIALOG:
        draft.showAddDialog = true;
        break;

      case PAYMENTS_SET_TABLE_FILTER:
        draft.filters[action.payload.name] = action.payload.value;
        break;

      default:
    }
  });
}

// Selectors
export function getFilteredPayments(state) {
  const { entities, filters } = state.payments;

  return _.filter(entities.payments, o => {
    if (filters.search && o.name.toLowerCase().indexOf(filters.search.toLowerCase()) === -1) {
      return false;
    }

    if (filters.status && filters.status === 'new' && o.approved !== false) {
      return false;
    }

    return true;
  });
}

export const getPaymentByIdSelector = (state, id) => {
  return state.payments && state.payments.entities && state.payments.entities.payments
    ? state.payments.entities.payments[id]
    : {};
};
