import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import _ from 'lodash';
import { withSnackbar } from 'notistack';

import styles from '../styles';
import EnhancedTable from '../../../components/enhanced-table/EnhancedTable';
import ContentPaper from '../../../components/content-paper';
import ActionMenu from './row-actions';
import PageLoadingText from '../../../components/PageLoadingText';
import { translate } from '../../../i18n';
import { recipeApi } from '../../../api/recipe';
import { parseApiError } from '../../../api/helpers';

@withStyles(styles)
class Recipes extends React.Component {
  constructor(props) {
    super(props);
    const { fetchData, fetchIngredients, setPageTitle } = this.props;

    this.typeName = 'recipe';

    setPageTitle(translate('Recipes'));
    fetchData();
    fetchIngredients();
  }

  handleMassDelete = ids => ids.map(id => this.handleDelete(id));

  handleDelete = async id => {
    const { enqueueSnackbar, fetchData } = this.props;

    // Delete AJAX
    try {
      const response = await recipeApi.deleteById(id);

      if (!response.ok) {
        enqueueSnackbar(parseApiError(response).message, { options: { variant: 'error' } });
      }

      // All good
      setTimeout(() => fetchData(), 100);
    } catch (err) {
      enqueueSnackbar(translate(`Could not delete the ${this.typeName}`), { options: { variant: 'error' } });
    }
  };

  handleRowClick = ({ id }) => {
    console.log('handleRowClick', id)
    const { history } = this.props;
    history.push(`/recipe/edit/${id}`);
  };

  handleAdd = () => {
    const { history } = this.props;
    history.push('/recipe/add');
  };

  handleClone = async (data, name) => {
    const { enqueueSnackbar, fetchData } = this.props;

    try {
      const response = await recipeApi.cloneById(data.id, { name });

      if (!response.ok) {
        enqueueSnackbar(parseApiError(response).message);
        return;
      }

      enqueueSnackbar('Recipe cloned');
      fetchData();
    } catch (e) {
      enqueueSnackbar('Could not clone the recipe!', { options: { variant: 'error' } });
    }
  };

  getTableColumns = () => {
    const { loadingIds } = this.props;

    return [
      {
        id: 'name',
        accessor: 'name',
        sortable: true,
        component: 'td',

        label: translate('Name'),
      },
      {
        id: 'ingredients',
        accessor: 'ingredients',
        label: translate('Ingredients'),
        renderValue: row =>
          translate('{{count}} ingredients', { count: (row.recipe_ingredients && row.recipe_ingredients.length) || 0 }),
      },
      /* {
        id: 'description',
        accessor: 'description',
        label: translate('Description'),
      }, */
      {
        id: 'id',
        accessor: 'id',
        numeric: false,
        label: '',
        align: 'right',
        padding: 'none',
        renderValue: row => (
          <ActionMenu
            isLoading={loadingIds.indexOf(parseInt(row.id, 10)) !== -1}
            handleDelete={this.handleDelete}
            handleStatusChange={this.handleStatusChange}
            handleClone={this.handleClone}
            id={row.id}
            data={row}
          />
        ),
      },
    ];
  };

  getTableFilters = () => {
    const { ingredients, setTableFilter, filters } = this.props;
    let ingredientsFilter = _.map(ingredients, vendor => ({ value: vendor.id, label: vendor.name }));
    ingredientsFilter = _.orderBy(ingredientsFilter, 'label');

    return [
      {
        label: translate('Ingredient'),
        type: 'select',
        value: filters.ingredient_id,
        onChange: value => setTableFilter('ingredient_id', value),
        options: [
          {
            value: '',
            label: translate('All'),
          },
          ...ingredientsFilter,
        ],
      },
      {
        label: translate('Search'),
        type: 'search',
        value: filters.search,
        onChange: value => setTableFilter('search', value),
      },
    ];
  };

  shouldComponentRender = () => {
    const { loading, initialLoaded } = this.props;

    return initialLoaded;
  };

  getLoadingSkeleton = () => {
    return <PageLoadingText />;
  };

  render() {
    const { classes, dataById } = this.props;
    const data = Object.values(dataById);

    if (!this.shouldComponentRender()) {
      return <div className={classes.root}>{this.getLoadingSkeleton()}</div>;
    }

    return (
      <div className={classes.root}>
        <ContentPaper className={classes.paper}>
          <EnhancedTable
            autoWidth
            toolbarActions={
              <>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleAdd}
                  startIcon={<i className="fal fa-plus" />}>
                  {translate('Add recipe')}
                </Button>
              </>
            }
            filters={this.getTableFilters()}
            columns={this.getTableColumns()}
            onRowClick={this.handleRowClick}
            onMassDelete={this.handleMassDelete}
            data={data}
            rowCount={data.length}
            order="asc"
            orderBy="name"
          />
        </ContentPaper>
      </div>
    );
  }
}

export default Recipes;
