export const ENQUEUE_SNACKBAR = '@snackbar / ENQUEUE SNACKBAR';
export const CLOSE_SNACKBAR = '@snackbar / CLOSE SNACKBAR';
export const REMOVE_SNACKBAR = '@snackbar / REMOVE SNACKBAR';

export const enqueueSnackbar = (notification, options) => {
  const key = options && options.key;
  if (options && !options.options && options.variant) {
    options = { options };
  }

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      message: notification,
      ...options,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const closeSnackbar = key => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key,
  key,
});

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key,
});
