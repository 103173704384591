import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

const styles = theme => ({
  root: {},
  bold: {
    fontWeight: 'bold',
  },
  paper: {
    width: '100%',
    overflowX: 'auto',
    position: 'relative',
  },

  colHead: {
    width: 200,
    fontWeight: 500,
    backgroundColor: '#f2f2f2',
  },

  confirmedTime: {
    color: '#3d3d3d',
    fontWeight: 400,
    display: 'block',
    marginTop: 5,
    fontSize: 15,
  },

  danger: {
    color: theme.palette.error.dark,
  },

  extras: {
    display: 'block',
    color: '#5c5c5c',
  },

  filterBox: {
    marginRight: theme.spacing(4),
  },

  newOrder: {
    color: theme.palette.primary.main,
  },

  processingOrder: {
    color: amber[800],
  },

  readyOrder: {
    color: green[500],
  },

  orderStatus: {
    textTransform: 'capitalize',
    textAlign: 'center',
    fontWeight: 500,
  },

  productsCard: {
    marginTop: theme.spacing(4),
  },

  productsTable: {
    fontSize: 14,
  },

  table: {
    minWidth: 700,
  },

  tableButton: {
    marginRight: theme.spacing(2),
  },

  totalRow: {
    fontSize: 16,
    textAlign: 'right',
    lineHeight: 1.5,
    color: '#333',
    fontWeight: 500,
  },

  totalLabel: {
    display: 'inline-block',
  },

  totalValue: {
    width: '90px',
    display: 'inline-block',
  },

  qtyField: {
    fontWeight: 600,
    fontSize: 16,
  },

  textDanger: {
    color: 'red',
  },
});

export default styles;
