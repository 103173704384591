import theme from './theme/theme';

export const styles = () => ({
  root: {
    display: 'flex',
    background: '#f5f5f5',
  },

  fadeTransitionEnter: {
    opacity: 0,
    zIndex: '1',
    display: 'block',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(8, 3, 4),
    minHeight: '100vh',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8, 2, 4),
    },
    transition: theme.transitions.create(['padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fadeTransitionEnterActive: {
    opacity: 1,
    transition: 'opacity 250ms ease-in',
  },
  fadeTransitionExit: {
    opacity: 0,
    display: 'none',
  },
  /* fadeTransitionExitActive: {

  }, */
});
