import React from 'react';
import { Box, Button } from '@material-ui/core';
import { ButtonWhite } from './white-button';
import { translate } from '../i18n';
import ContentPaper from './content-paper';
import ConfirmationDialog from './ConfirmationDialog';

class FormActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteConfirmation: false,
    };
  }

  cancelDelete = () => {
    this.setState({ deleteConfirmation: false });
  };

  confirmDelete = () => {
    this.setState({ deleteConfirmation: true });
  };

  handleDelete = () => {
    const { onDelete } = this.props;

    onDelete();
  };

  render() {
    const { deleteConfirmation } = this.state;
    const { disableSubmit = false, onCancel, onSubmit, short } = this.props;

    return (
      <ContentPaper short={!!short} className="ButtonsBoxPaper" elevation={0}>
        <Box display="flex" flexDirection="row">
          <Box flexGrow={1}>
            <ButtonWhite variant="contained" color="default" onClick={this.confirmDelete}>
              {translate('Delete')}
            </ButtonWhite>
          </Box>

          <Box>
            <ButtonWhite variant="contained" color="default" onClick={onCancel} style={{ marginRight: 16 }}>
              {translate('Cancel')}
            </ButtonWhite>

            <Button type="submit" variant="contained" color="primary" onClick={onSubmit} disabled={disableSubmit}>
              {translate('Save')}
            </Button>
          </Box>
        </Box>

        {!deleteConfirmation ? null : (
          <ConfirmationDialog
            title={translate('Delete confirmation')}
            message={translate('Are you sure you want to delete this item?')}
            confirm={this.handleDelete}
            cancel={this.cancelDelete}
            show
          />
        )}
      </ContentPaper>
    );
  }
}

export default FormActions;
