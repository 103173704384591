import ApiClient from "../components/ApiClient";
import AppState from "../lib/stores/AppState";

const InventoryService = {
    add(data, callback) {
        if ( callback ) {
            ApiClient.post({
                endpoint: '/api/inventory',
                data: data
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.post({
            endpoint: '/api/inventory',
            data: data
        });
    },

    fetch(id, callback) {

        if ( callback ) {
            ApiClient.get({
                endpoint: '/api/inventory/' + id
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            });

            return;
        }

        return ApiClient.get({
            endpoint: '/api/inventory/' + id
        });
    },

    fetchAll(callback) {

        if ( callback ) {
            ApiClient.get({
                endpoint: '/api/inventory/'
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.get({
            endpoint: '/api/inventory/'
        });
    },

    update(id, data, callback) {
        if ( callback ) {
            ApiClient.patch({
                endpoint: '/api/inventory/' + id,
                data: data
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.patch({
            endpoint: '/api/inventory/' + id,
            data: data
        });
    },

    delete(id) {
        const uri = '/api/inventory/' + id;

        return ApiClient.delete({
            endpoint: uri,
        });
    },

    getDates() {
        return ApiClient.get({
            endpoint: '/api/inventory/dates'
        });
    },

    getOrderReport(date, query) {
        const uri = '/api/inventory/' + date + '/report';

        return ApiClient.get({
            endpoint: uri,
            query
        });
    },

    getInventoryData(date, callback) {
        const uri = '/api/inventory/' + date + '/data';

        if ( callback ) {
            ApiClient.get({
                endpoint: uri,
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.get({
            endpoint: uri
        });
    },

};

export default InventoryService;
