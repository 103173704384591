import { call, put } from 'redux-saga/effects';

import { fetchVendorsSuccess, fetchVendorsError } from '../actions';
import { parseApiError } from '../../../api/helpers';
import { hideProgressBar, showProgressBar } from '../../ui/actions';
import { vendorApi } from '../../../api/vendor';

export function* fetchVendorsSaga() {
  try {
    yield put(showProgressBar());
    const response = yield call(vendorApi.fetch);
    yield put(hideProgressBar());

    if (!response.ok) {
      yield put(fetchVendorsError(parseApiError(response).message));
      return;
    }

    yield put(fetchVendorsSuccess(response.data.data.results || []));
  } catch (err) {
    yield put(fetchVendorsError(parseApiError(err).message));
  }
}
