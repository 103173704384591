import React, { Component } from 'react';

import { withStyles } from '@material-ui/styles';

import {
  Typography,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Paper,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Box,
} from '@material-ui/core';
import DragHandle from '@material-ui/icons/DragIndicator';
import { DragSource, DropTarget } from 'react-dnd';
import update from 'react-addons-update';
import { Prompt, withRouter } from 'react-router';
import DraggableTypes from '../../components/DraggableTypes';
import VendorService from '../../services/VendorService';
import IngredientService from '../../services/IngredientService';
import ContentPaper from '../../components/content-paper';
import { vendorApi } from '../../api/vendor';
import FormActions from '../../components/form-actions';
import { translate } from '../../i18n';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginRight: theme.spacing(2),
  },
  buttonBlock: {
    display: 'block',
    width: '140px',
    margin: '0 auto',
    marginBottom: theme.spacing(1),
  },
  buttonDelete: {
    margin: theme.spacing(1),
    color: theme.palette.error.dark,
  },
  caption: {
    marginBottom: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    display: 'flex',
  },
  descriptionField: {
    width: '100%',
  },
  formControl: {
    /* marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit, */
    minWidth: 120,
    display: 'flex',
  },
  helperText: {
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  imagePreview: {
    width: '100px',
    height: '100px',
    backgroundColor: '#fafafa',
    margin: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  img: {
    width: 'auto',
    maxHeight: '90%',
    maxWidth: '90%',
    marginTop: '50%',
    transform: 'translateY(-50%)',
  },
  listContainer: {
    maxHeight: '50vh',
    overflow: 'auto',
    padding: theme.spacing(0, 2),
  },
});

/**
 * Implements the drag source contract.
 */
const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },

  endDrag(props) {
    if (props.handleDragEnd) {
      props.handleDragEnd();
    }
  },
};

const cardTarget = {
  hover(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveCard(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

/**
 * Specifies the props to inject into your component.
 */
function collect(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

function collect2(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}
let SortableListItem = props => {
  const { data, connectDropTarget, connectDragSource, connectDragPreview, isLast } = props;

  return (
    <ListItem disableGutters divider={!isLast}>
      {connectDropTarget(
        connectDragPreview(
          <div style={{ display: 'flex', width: '100%' }}>
            <ListItemText primary={data.name} />
            {connectDragSource(
              <div
                style={{
                  cursor: 'pointer',
                }}>
                <ListItemIcon>
                  <DragHandle />
                </ListItemIcon>
              </div>,
            )}
          </div>,
        ),
      )}
    </ListItem>
  );
};

SortableListItem = DropTarget(
  DraggableTypes.INGREDIENT_ITEM,
  cardTarget,
  collect,
)(DragSource(DraggableTypes.INGREDIENT_ITEM, cardSource, collect2)(SortableListItem));

@withStyles(styles)
class SortableIngredientList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ data: nextProps.data });
    }
  }

  endSort = () => {
    const { data } = this.state;
    const { sortEnd } = this.props;
    if (sortEnd) {
      sortEnd(data);
    }
  };

  handleSort = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragCard = data[dragIndex];

    this.setState(
      update(this.state, {
        data: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        },
      }),
      function () {},
    );
  };

  render() {
    const { data } = this.state;

    return (
      <List>
        {data &&
          data.map((ingredient, i) => {
            return (
              <SortableListItem
                key={`sortable-list-${ingredient.id}`}
                handleDragEnd={this.endSort}
                moveCard={this.handleSort}
                index={i}
                id={i}
                data={ingredient}
                isLast={i + 1 === data.length}
              />
            );
          })}
      </List>
    );
  }
}

@withStyles(styles)
@withRouter
class VendorForm extends Component {
  constructor(props) {
    super(props);

    let INITIAL_DATA = {
      name: '',
    };

    if (props.data) {
      INITIAL_DATA = props.data;
    }

    this.state = {
      id: props.id ? props.id : null,
      data: INITIAL_DATA,
    };
  }

  componentDidMount() {
    const { id } = this.state;
    // Load ingredient list
    IngredientService.find({
      vendor: id,
    }).then(res => {
      if (res && res.body && res.body.data) {
        this.setState({
          ingredients: res.body.data.results,
        });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    const { id } = this.state;
    if (!id && nextProps.id) {
      this.setState({
        id: nextProps.id,
        data: nextProps.data,
      });
    }
  }

  handleSubmit = async ev => {
    if (ev) {
      ev.preventDefault();
    }

    const { data, id } = this.state;
    const { onNotification, onSave } = this.props;

    try {
      const response = !id ? await vendorApi.add(data) : await vendorApi.update(id, data);

      this.setState({ hasUnsavedChanges: false });
      onNotification('Vendor saved');

      if (onSave) {
        onSave(response);
      }
    } catch (e) {
      onNotification('Could not save the vendor!', { variant: 'error' });
    }

    return false;
  };

  handleChange = name => event => {
    const { data } = this.state;

    this.setState({ data: { ...data, [name]: event.target.value }, hasUnsavedChanges: true });
  };

  handleIngredientsReorder = async ingredients => {
    // Update ingredients order

    const order = [];
    ingredients.forEach(function (ingredient, index) {
      order.push({
        id: ingredient.id,
        vendor_order: index,
      });
    });

    const { id } = this.state;
    await VendorService.updateIngredientOrder(id, order);
    this.setState({
      ingredients,
    });
  };

  handleDelete = async () => {
    const { id } = this.state;
    const { onNotification, history } = this.props;

    // Reset form
    this.setState({ hasUnsavedChanges: false });

    // Delete AJAX
    try {
      await vendorApi.deleteById(id);

      onNotification('Vendor deleted');
      history.push('/vendor');
    } catch (e) {
      onNotification('Could not delete the vendor', { options: { variant: 'error' } });
    }
  };

  render() {
    const { classes, onCancel } = this.props;

    const { data, hasUnsavedChanges, ingredients } = this.state;

    const name = data && data.name ? data.name : '';

    return (
      <>
        <Prompt
          when={!!hasUnsavedChanges}
          message={translate('You have unsaved changes. Are you sure you want to leave?')}
        />
        <ContentPaper className="FormBoxPaper">
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <TextField
                required
                autoComplete="off"
                name="name"
                label="Vendor name"
                className={classes.textField}
                value={name || ''}
                onChange={this.handleChange('name')}
                InputProps={{
                  classes: {
                    input: 'LargeTitleInput',
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-label-placeholder">Country</InputLabel>
                <Select
                  value={data.country || ''}
                  onChange={this.handleChange('country')}
                  inputProps={{
                    name: 'country',
                    id: 'country-label-placeholder',
                  }}
                  displayEmpty
                  name="age">
                  <MenuItem value="Mexico">Mexico</MenuItem>
                  <MenuItem value="USA">United States</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="off"
                name="region"
                label="Region"
                className={classes.textField}
                value={data.region || ''}
                onChange={this.handleChange('region')}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={12}>
                <TextField
                  name="description"
                  label="Description"
                  className={classes.descriptionField}
                  value={data.description || ''}
                  onChange={this.handleChange('description')}
                />
              </Grid>
            </Grid>

            {/* <Grid item xs={12}>
              <Grid item xs={12} md={12} lg={8}>
                <TextField
                  required
                  name="url"
                  label="URL"
                  className={classes.descriptionField}
                  value={data.url || ''}
                  onChange={this.handleChange('url')}
                />
              </Grid>
            </Grid> */}
          </Grid>
        </ContentPaper>

        <ContentPaper className="FormBoxPaper">
          <Box>
            <Typography component="div" variant="body1" className={classes.caption}>
              Drag ingredients by handler to re-order them.
            </Typography>

            <Paper elevation={0} className={classes.listContainer}>
              <SortableIngredientList data={ingredients} sortEnd={this.handleIngredientsReorder} />
            </Paper>
          </Box>
        </ContentPaper>

        <FormActions onDelete={this.handleDelete} onCancel={onCancel} onSubmit={this.handleSubmit} />
      </>
    );
  }
}

export default VendorForm;
