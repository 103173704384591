import ApiClient from "../components/ApiClient";
import AppState from "../lib/stores/AppState";

const UserService = {


    fetch(id, callback) {

        if ( callback ) {
            ApiClient.get({
                endpoint: '/api/user/' + id
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.get({
            endpoint: '/api/user/' + id
        });
    },

    fetchAll(callback) {

        if ( callback ) {
            ApiClient.get({
                endpoint: '/api/user/'
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.get({
            endpoint: '/api/user/'
        });
    },

    add(data, callback) {
        if ( callback ) {
            ApiClient.post({
                endpoint: '/api/user',
                data: data
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.post({
            endpoint: '/api/user',
            data: data
        });
    },

    update(id, data, callback) {
        if ( callback ) {
            ApiClient.patch({
                endpoint: '/api/user/' + id,
                data: data
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.patch({
            endpoint: '/api/user/' + id,
            data: data
        });
    },

    delete(id, callback) {
        if ( callback ) {
            ApiClient.delete({
                endpoint: '/api/user/' + id,
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.delete({
            endpoint: '/api/user/' + id,
        });
    },

    addUserToVenue(user_id, venue_id, callback) {
        if ( callback ) {
            ApiClient.post({
                endpoint: '/api/user/' + user_id + '/venue/' + venue_id,
                data: {
                    user_id,
                    venue_id,
                }
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.post({
            endpoint: '/api/user/' + user_id + '/venue/' + venue_id,
            data: {
                user_id,
                venue_id,
            }
        });
    },

    removeUserFromVenue(user_id, venue_id, callback) {
        if ( callback ) {
            ApiClient.delete({
                endpoint: '/api/user/' + user_id + '/venue/' + venue_id,
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.delete({
            endpoint: '/api/user/' + user_id + '/venue/' + venue_id,
            data: {
                user_id,
                venue_id,
            }
        });
    },
};

export default UserService;
