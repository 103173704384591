import React from 'react';

export const FormElement = ({ children, childrenCount, ...rest }) => {
  let classes = ['d-block'];
  if (rest && rest.className) {
    if (typeof rest.className === 'string') {
      classes = [...classes, rest.className];
    } else {
      classes = [...classes, ...rest.className];
    }
  }

  // Determine width class
  if (childrenCount) {
    switch (childrenCount) {
      case 2:
        classes.push('width-50');
        break;
      case 3:
        classes.push('width-33');
        break;
      case 4:
        classes.push('width-25');
        break;
      default:
        classes.push('width-100');
        break;
    }
  }

  return (
    <div {...rest} className={classes.join(' ')}>
      {children}
    </div>
  );
};
