import React from 'react';
import { connect } from 'react-redux';
import PageLoadingText from '../../components/PageLoadingText';
import IngredientForm from './IngredientForm';
import { translate } from '../../i18n';
import { setPageTitle as setPageTitleAction } from '../ui/actions';
import { enqueueSnackbar as enqueueSnackbarAction } from '../snackbar/actions';
import { getIngredientByIdSelector } from './reducers';
import { fetchIngredients } from './actions';
import { fetchVendors } from '../vendor/actions';

class IngredientAddEdit extends React.Component {
  constructor(props) {
    super(props);

    const { id, setPageTitle } = this.props;

    let pageTitle = translate('Add ingredient');
    if (id) {
      pageTitle = translate('Edit ingredient');
    }

    setPageTitle(pageTitle);
  }

  componentDidMount() {
    const { fetchData, fetchVendors } = this.props;
    fetchData();
    fetchVendors();
  }

  render() {
    const { id, data, enqueueSnackbar, history, vendors } = this.props;

    if (id && !data) {
      return <PageLoadingText />;
    }

    return (
      <IngredientForm
        id={data.id}
        data={data}
        vendors={vendors}
        onCancel={() => history.push('/ingredient')}
        onSave={() => history.push('/ingredient')}
        onNotification={enqueueSnackbar}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: getIngredientByIdSelector(state, ownProps.id),
    vendors: state.vendors.entities.vendors || {},
  };
};

const mapDispatchToProps = {
  setPageTitle: setPageTitleAction,
  fetchData: fetchIngredients,
  fetchVendors,
  enqueueSnackbar: enqueueSnackbarAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(IngredientAddEdit);
