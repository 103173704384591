export const foodImages = {
  'TAZON DE CHILI CON CARNE': {
    image_name: 'chili-con-carne.png',
  },
  'PIZZA BOMBS': {
    image_name: 'pizza-bombs.png',
  },

  'TOSTADA ENSALADA': {
    image_name: 'tostada-ensalada.png',
  },

  BUFFALO: {
    image_name: 'buffalo.png',
  },

  'CHILI DOG': {
    image_name: 'chili-dog.png',
  },

  CAMPBURGERS: {
    image_name: 'campburgers.png',
  },

  'CAMP BURGERS': {
    image_name: 'campburgers.png',
  },

  'BUFFALO CHICKEN WRAP': {
    image_name: 'buffalo-chicken-wrap.png',
  },

  'BAJA PICANTE': {
    image_name: 'baja-picante.png',
    style: {
      maxHeight: '120px',
    },
  },

  'LONG ISLAND ICED TEA': {
    image_name: 'long-island-iced-tea.png',
  },

  'ROOTBEER O COKE FLOAT': {
    image_name: 'rootbeer.png',
  },
};
