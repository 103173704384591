import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import { withStyles } from '@material-ui/styles';

import UAParser from 'ua-parser-js';

import { Portal, Grid, Typography, Box } from '@material-ui/core';
import MediaOverlay from './MediaOverlay';
import AppState from '../../lib/stores/AppState';
import ApiClient from '../../components/ApiClient';
import srmList from '../../components/SrmValuesList';

const styles = theme => ({
  root: {
    zIndex: -99,
  },

  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 9999,
    backgroundImage: 'url(/menu-display/menu-bg-1.jpg)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },

  header: {
    height: '22vh',
    width: '100%',
    backgroundColor: '#0e0e0e',
    position: 'relative',
    marginBottom: '2vh',
  },

  slogan: {
    position: 'absolute',
    right: '15vw',
  },

  headerImage: {
    height: '20vh',
    marginTop: '2.1vh',
    maxWidth: '100%',
  },

  logoImage: {
    height: '100%',
    maxWidth: '100%',
  },

  logo: {
    position: 'absolute',
    left: '1vw',
    height: '18vh',
    top: '3vh',
  },

  menuItem: {
    height: '19vh',
    overflow: 'hidden',
    borderRight: '1px solid #494949',
    borderBottom: '1px solid #494949',
    position: 'relative',
    '&:nth-child(3n)': {
      borderRight: '0',
    },
    '&:nth-child(10),&:nth-child(11),&:nth-child(12)': {
      borderBottom: '0',
    },
  },

  itemMedia: {
    flex: '0 0 25%',
    maxWidth: '25%',
    paddingTop: '1vh',
    paddingRight: '0',
  },

  glass: {
    maxWidth: '100%',
    /* maxHeight: '15vh', */
    height: '18vh',
    /* width: 'auto' */
  },

  bevLogoContainer: {
    position: 'absolute',
    left: '0.5vw',
    bottom: '1vh',
    width: '5vw',
    textAlign: 'center',
  },

  bevLogo: {
    maxWidth: '100%',
    maxHeight: '8vh',
    /* width: 'auto' */
  },

  bevName: {
    fontFamily: 'Cabin Sketch',
    color: '#ffffff',
    fontSize: '2.2vw',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    webkitTextSizeAdjust: 'none',
    lineHeight: '1',
  },

  bevMeta: {
    fontFamily: 'EB Garamond',
    fontWeight: 'bold',
    fontSize: '1.2vw',
    color: '#ffffff',
    lineHeight: '1.1',
  },

  itemBody: {
    flex: 1,
    color: '#ffffff',
  },

  pricing: {
    bottom: '1.0vh',
    position: 'absolute',
    fontSize: '1.5vw',
    fontFamily: 'EB Garamond',
    fontWeight: 'bold',
    lineHeight: '1.2',
    color: '#d0b38b',
  },
  abvIbu: {
    bottom: '1.5vh',
    right: '1vw',
    position: 'absolute',
    fontSize: '1.2vw',
    fontFamily: 'EB Garamond',
    fontWeight: 'bold',
    lineHeight: '1.2',
    color: '#d0b38b',
  },
  aiValue: {
    fontWeight: 'normal',
  },

  itemContainer: {},
  placeholder: {
    background: 'transparent',

    '& $itemContainer': {
      opacity: 0,
    },
  },
});

function getDPI() {
  const div = document.createElement('div');
  div.style.height = '1in';
  div.style.width = '1in';
  div.style.top = '-100%';
  div.style.left = '-100%';
  div.style.position = 'absolute';

  document.body.appendChild(div);

  const result = div.offsetHeight;

  document.body.removeChild(div);

  return result;
}

class OnTap extends React.Component {
  timer = null;

  state = {
    data: null,
    open: true,
    venueData: null,
  };

  loadMenu() {
    const displayId = AppState.getConfig('CurrentDisplayId');

    const parser = new UAParser();

    const e = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const t = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const n = window.screen;
    const device = parser.getDevice();
    const r = {
      headers: {
        'Campfire-Browser': parser.getBrowser().name,
        'Campfire-Device': device.vendor || parser.getBrowser().name,
        'Campfire-OS': parser.getOS().name,
        'Campfire-Screen-Width': n.width,
        'Campfire-Screen-Height': n.height,
        'Campfire-Color-Depth': n.colorDepth,
        'Campfire-Pixel-Depth': n.pixelDepth,
        'Campfire-Window-Width': e,
        'Campfire-Window-Height': t,
        'Campfire-Screen-DPI': getDPI(),
      },
    };

    ApiClient.get({
      endpoint: `/api/display/${displayId}/content/ontap`,
      headers: r.headers,
    }).then(
      res => {
        // Do we have any commands we need to handle?
        if (res && res.body && res.body.commands) {
          const { commands } = res.body;
          this.onDisplayCommands(commands);
        }

        this.setState(
          {
            data: res.body ? res.body : '',
          },
          () => {
            this.timer = setTimeout(this.loadMenu.bind(this), 120000);
          },
        );
      },
      err => {
        this.setState({
          loading: false,
          error: true,
          errorMsg:
            err.response && err.response.body && err.response.body.error
              ? err.response.body.error
              : 'API communication error',
        });
      },
    );
  }

  onDisplayCommands(commands) {
    // We do it with if's because we can set priority to the commands
    // based on the required outcome; for example, command reload-screen
    // always has priority because other commands are not relevant anymore;
    commands = _.uniq(commands);
    if (commands.includes('reload-screen')) {
      window.location = window.location;
    }
    if (commands.includes('refresh-display-data')) {
      this.loadMenu();
    }
  }

  wsHeartbeat = () => {
    clearInterval(this.heartbeatInterval);

    this.heartbeatInterval = setInterval(() => {
      if (!this.wsConnected) {
        this.initWsClient();
      }
    }, 10000);
  };

  initWsClient = () => {
    const displayId = AppState.getConfig('CurrentDisplayId');
    const { hostname, port, protocol } = window.location;
    const URL = `wss://${hostname}:${parseInt(port) !== 3000 ? port : 5005}/ws/display/commands/${displayId}`;

    this.wsConnected = false;
    this.ws = null;

    // Init websocket
    this.ws = new WebSocket(URL);

    this.ws.onopen = () => {
      // Start heartbeat check
      this.wsHeartbeat();

      // Send display data
      const message = { displayId };
      this.ws.send(JSON.stringify(message));

      this.wsConnected = true;
    };

    this.ws.onmessage = evt => {
      try {
        const message = JSON.parse(evt.data);

        // Parse remote commands?
        if (message && message.commands) {
          this.onDisplayCommands(message.commands);
        }
      } catch (e) {}
    };

    this.ws.onerror = error => {
      this.wsConnected = false;
      this.ws.close();
    };

    this.ws.onclose = () => {
      this.wsConnected = false;
    };
  };

  componentDidMount() {
    AppState.setConfig({
      CurrentPageTitle: 'Beverage Menu',
      AppBarSearch: false,
    });

    this.loadMenu();

    this.initWsClient();
  }

  componentWillUnmount() {
    clearInterval(this.heartbeatInterval);
  }

  render() {
    const { classes } = this.props;
    const { data } = this.state;

    return (
      <div className={classes.root}>
        <Portal container={document.body}>
          <div className={classes.container}>
            <div className={classes.header}>
              <div className={classes.logo}>
                <img className={classes.logoImage} src="/menu-display/campfire-logo.png" />
              </div>
              <div className={classes.slogan}>
                <img className={classes.headerImage} src="/menu-display/header-on-tap.png" />
              </div>
            </div>

            <Grid className={classes.items} direction="row" justify="center" alignItems="center" spacing={0} container>
              {!data || !data.on_tap
                ? null
                : data.on_tap.map((d, i) => {
                    const beverage = d.beverage ? d.beverage : {};
                    const isEmpty = !!beverage.id;

                    // Extract the color from the image URL
                    let color = '';
                    if (beverage.srm) {
                      const srmVal = _.find(srmList, o => o.value.srmNumber === Math.round(beverage.srm));
                      if (srmVal) {
                        color = srmVal.value.color.replace('#', '');
                      }
                    }

                    let subtitle = [];
                    if (beverage.producer_name) {
                      subtitle.push(beverage.producer_name);
                    } else if (beverage.producer && beverage.producer.name) {
                      subtitle.push(beverage.producer.name);
                    }

                    if (beverage.style && beverage.style.style) {
                      subtitle.push(beverage.style.style);
                    }

                    if (beverage.style_name) {
                      subtitle.push(beverage.style_name);
                    }
                    subtitle = subtitle.join(' | ');

                    // Prepare sizes
                    const { sizes } = beverage;

                    return (
                      <Grid
                        item
                        key={`gbi${i}`}
                        xs={12}
                        md={4}
                        className={classNames(classes.menuItem, !isEmpty && classes.placeholder)}>
                        {!beverage.image || !beverage.image.secure_url ? null : (
                          <div className={classes.bevLogoContainer}>
                            <img className={classes.bevLogo} src={beverage.image.secure_url} />
                          </div>
                        )}

                        <Grid container spacing={0} className={classes.itemContainer}>
                          <Grid item className={classes.itemMedia}>
                            <img className={classes.glass} src={`/icon/beer-glass.svg?color=${color}`} />
                          </Grid>

                          <Box className={classes.itemBody}>
                            <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                              <Box display="flex" className={classes.itemTitle} pt={1}>
                                <Typography component="div" className={classes.bevName}>
                                  {beverage.name}
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <Typography component="div" className={classes.bevMeta}>
                                  {subtitle}
                                </Typography>
                              </Box>

                              <Box className={classes.pricing}>
                                {sizes &&
                                  sizes.map((d, i) => {
                                    if (!d.price) {
                                      return null;
                                    }
                                    return (
                                      <Box key={`scrs${i}`}>
                                        {d.label ? d.label : `${d.size} ${d.unit}`} ........... $ {d.price}
                                      </Box>
                                    );
                                  })}
                              </Box>

                              <Box className={classes.abvIbu}>
                                {!beverage.abv_percent ? null : (
                                  <Box>
                                    ABV: &nbsp;<span className={classes.aiValue}>{beverage.abv_percent}%</span>
                                  </Box>
                                )}
                                {!beverage.ibu ? null : (
                                  <Box>
                                    IBU: &nbsp;&nbsp;<span className={classes.aiValue}>{beverage.ibu}</span>
                                  </Box>
                                )}
                              </Box>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    );
                  })}
            </Grid>
          </div>
          <MediaOverlay />
        </Portal>
      </div>
    );
  }
}

export default withStyles(styles)(OnTap);
