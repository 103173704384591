import { makeActionCreator } from '../../../store/actions';

export const CATEGORIES_FETCH_REQUEST = '@categories / FETCH REQUEST';
export const CATEGORIES_FETCH_SUCCESS = '@categories / FETCH SUCCESS';
export const CATEGORIES_FETCH_ERROR = '@categories / FETCH ERROR';
export const CATEGORIES_SHOW_ADD_DIALOG = '@categories / SHOW ADD DIALOG';
export const CATEGORIES_HIDE_ADD_DIALOG = '@categories / HIDE ADD DIALOG';
export const CATEGORIES_SET_TABLE_FILTER = '@categories / SET TABLE FILTER';
export const CATEGORIES_FETCH_CATEGORY_START = '@categories / FETCH CATEGORY START';
export const CATEGORIES_FETCH_CATEGORY_SUCCESS = '@categories / FETCH CATEGORY SUCCESS';

export const fetchCategories = makeActionCreator(CATEGORIES_FETCH_REQUEST);
export const fetchCategoriesError = makeActionCreator(CATEGORIES_FETCH_ERROR, 'error');
export const fetchCategoriesSuccess = makeActionCreator(CATEGORIES_FETCH_SUCCESS, 'data');
export const showCategoryAddDialog = makeActionCreator(CATEGORIES_SHOW_ADD_DIALOG);
export const hideCategoryAddDialog = makeActionCreator(CATEGORIES_HIDE_ADD_DIALOG);

export const fetchCategoryById = makeActionCreator(CATEGORIES_FETCH_CATEGORY_START, 'id');
export const fetchCategoryByIdSuccess = makeActionCreator(CATEGORIES_FETCH_CATEGORY_SUCCESS, 'id', 'data');

// Table filters
export const setTableFilter = makeActionCreator(CATEGORIES_SET_TABLE_FILTER, 'name', 'value');
