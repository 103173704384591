import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import {
  Checkbox,
  FormControlLabel,
  withStyles,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  Select,
  ListSubheader,
  Button,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { green, grey } from '@material-ui/core/colors';
import { connect } from 'react-redux';
import ApiClient from '../../components/ApiClient';
import DisplayService from '../../services/DisplayService';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';

import ContentPaper from '../../components/content-paper';
import { translate } from '../../i18n';
import {
  hideProgressBar as hideProgressBarAction,
  setPageTitle as setPageTitleAction,
  showProgressBar as showProgressBarAction,
} from '../ui/actions';

import { ONLINE_MENU_DISPLAY_ID } from '../../config';

const styles = theme => ({
  root: {},
  paper: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto',
  },
  bold: {
    fontWeight: '600',
  },

  contentSelect: {
    display: 'flex',
    maxWidth: 300,
  },

  maxWidthCol: {
    maxWidth: 300,
  },

  danger: {
    color: theme.palette.error.dark,
  },
  table: {
    minWidth: 700,
  },

  tableButton: {
    marginRight: theme.spacing(2),
  },
});

@withStyles(styles)
class DisplaysTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      onlyOnline: false,
      menus: [],
    };
  }

  load = () => {
    const { showProgressBar, hideProgressBar } = this.props;
    showProgressBar();

    ApiClient.get({
      endpoint: '/api/display',
    }).then(
      res => {
        hideProgressBar();

        this.setState({
          data: res.body.data ? res.body.data : [],
        });
      },
      () => {
        hideProgressBar();
      },
    );
  };

  componentDidMount() {
    const { setPageTitle } = this.props;
    setPageTitle(translate('Displays'));

    this.load();
    this.loadMenus();
  }

  loadMenus = async () => {
    try {
      const response = await ApiClient.get({
        endpoint: '/api/menu',
      });

      this.setState({
        menus: response.body.data ? response.body.data : [],
      });
    } catch (err) {}
  };

  handleContentTypeChange = async (id, value, row) => {
    await ApiClient.patch({
      endpoint: `/api/display/${id}`,
      data: {
        display_content: value,
      },
    });

    DisplayService.sendCommand(row.display_id, 'reload-screen');

    this.load();
  };

  triggerMassDelete = ids => {
    this.setState({ deleting: ids });
  };

  cancelMassDelete = () => {
    this.setState({ deleting: null });
  };

  handleDelete = () => {
    const { deleting } = this.state;
    if (deleting) {
      this.massDelete(deleting);
      this.setState({
        deleting: null,
      });
    }
  };

  deleteDisplay = id => {
    ApiClient.delete({
      endpoint: `/api/display/${id}`,
    }).then(this.load);
  };

  massDelete = ids => {
    ids.map(
      id => {
        const { data } = this.state;
        this.setState({
          data: _.filter(data, o => o.id !== id),
        });

        return id;

        /* this.setState({
                    data: _.filter(data, (o) => o.id !== id)
                }) */
      },
      /* ApiClient.delete({
                    endpoint: '/api/display/' + id
                }).then(() => {
                    // Remove from table
                    const {data} = this.state;

                    this.setState({
                        data: _.remove(data, {id: id})
                    })
                }) */
    );
  };

  getTableColumns = () => {
    const { classes } = this.props;
    const { menus } = this.state;
    const menusSelectOptions =
      menus &&
      menus.map(m => (
        <MenuItem key={`key/${m.id}`} value={`menu/${m.id}`}>
          {m.menu_name}
        </MenuItem>
      ));

    return [
      {
        id: 'name',
        accessor: 'name',
        sortable: true,
        component: 'th',

        padding: 'none',
        label: 'Device',
        renderValue: row => {
          let name = row.name ? row.name : row.display_data.device;
          let details = row.display_data && row.display_data.os;

          const isOnlineFoodMenu = row.display_id === ONLINE_MENU_DISPLAY_ID;
          if (isOnlineFoodMenu) {
            name = 'QR online menu';
            details = '';
          }

          return (
            <>
              <div className={classes.bold}>{name}</div>
              <div>{details}</div>
            </>
          );
        },
      },
      {
        id: 'last_seen',
        accessor: 'last_seen',
        sortable: true,
        numeric: false,
        align: 'center',
        label: 'Last seen',
        renderValue: row => {
          const lastIp = (
            <div>
              {row.last_ip && row.last_ip.indexOf(':') !== -1
                ? row.last_ip.substr(row.last_ip.lastIndexOf(':') + 1)
                : row.last_ip}
            </div>
          );

          const lastSeen = <div>{row.last_seen ? `${moment(row.last_seen * 1000).toNow(true)} ago` : '-'}</div>;
          return (
            <div>
              {lastSeen}
              {lastIp}
            </div>
          );
        },
      },
      {
        id: 'display_data',
        accessor: 'display_data',
        sortable: false,
        numeric: false,
        align: 'center',
        padding: 'none',
        label: 'Screen details',
        renderValue: row => {
          let windowSize = '';
          let dpi = '';

          if (row.display_data && row.display_data.window_width) {
            windowSize = <div>{`${row.display_data.window_width} x ${row.display_data.window_height}`}</div>;
          }

          if (row.display_data && row.display_data.screen_dpi) {
            dpi = <div>{`${row.display_data.screen_dpi} DPI`}</div>;
          }

          return (
            <div>
              {windowSize} {dpi}
            </div>
          );
        },
      },
      {
        id: 'display_content',
        accessor: 'display_content',
        numeric: false,
        label: 'Content',
        className: classes.maxWidthCol,
        renderValue: row => {
          const content = row.display_content || '';

          const handleChange = event => this.handleContentTypeChange(row.id, event.target.value, row);

          return (
            <>
              <FormControl className={classes.contentSelect}>
                <Select value={content} onChange={handleChange}>
                  <ListSubheader>{translate('Beer')}</ListSubheader>
                  <MenuItem value="ontap">{translate('Beer on Tap')}</MenuItem>
                  <ListSubheader>{translate('Menus')}</ListSubheader>
                  <MenuItem value="menu/image">{translate('Printed Menu')}</MenuItem>
                  {menusSelectOptions}
                </Select>
              </FormControl>
            </>
          );
        },
      },
      {
        id: 'status',
        accessor: 'status',
        numeric: false,
        sortable: false,
        align: 'right',
        label: 'Status',
        renderValue: row =>
          row.status === 'online' ? (
            <span style={{ color: green[600] }}>Online</span>
          ) : (
            <span style={{ color: grey[400] }}>Offline</span>
          ),
      },
      {
        id: 'id',
        accessor: 'id',
        numeric: false,
        label: '',
        align: 'right',
        padding: 'none',
        renderValue: row => <ActionMenu handleDelete={this.deleteDisplay} id={row.id} data={row} />,
      },
    ];
  };

  render() {
    const { classes } = this.props;
    const { data, deleting, onlyOnline } = this.state;

    return (
      <div className={classes.root}>
        <ContentPaper table className="FiltersPaper">
          <FormControlLabel
            className={classes.tableButton}
            control={
              <Checkbox
                checked={!!onlyOnline}
                onChange={() => this.setState({ onlyOnline: !onlyOnline })}
                value="onlyOnline"
              />
            }
            label="Show only Online"
          />
        </ContentPaper>

        <ContentPaper table className={classes.paper}>
          <EnhancedTable
            toolbarActions={
              <>
                <Button
                  variant="text"
                  color="primary"
                  className={classes.tableButton}
                  onClick={this.load}
                  startIcon={<i className="fal fa-sync" />}>
                  Refresh
                </Button>
              </>
            }
            columns={this.getTableColumns()}
            data={!onlyOnline ? data : _.filter(data, { status: 'online' })}
            rowCount={data.length}
            className={classes.table}
            onDelete={this.triggerMassDelete}
            filter={null}
            order="desc"
            orderBy="last_seen"
          />

          {!deleting ? null : (
            <ConfirmationDialog
              message={`You are going to delete ${deleting.length} selected ${
                deleting.length === 1 ? 'display' : 'displays'
              }.`}
              confirm={this.handleDelete}
              cancel={this.cancelMassDelete}
              show
            />
          )}
        </ContentPaper>
      </div>
    );
  }
}

@withStyles(styles)
class ActionMenu extends React.Component {
  static propTypes = {
    handleDelete: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
      anchorEl: null,
    };
  }

  handleClick = event => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = event => {
    event.stopPropagation();
    this.setState({ anchorEl: null });
  };

  handleReloadDisplay = displayId => {
    DisplayService.sendCommand(displayId, 'reload-screen');
  };

  handleRefreshDisplay = displayId => {
    DisplayService.sendCommand(displayId, 'refresh-display-data');
  };

  triggerDelete = () => {
    this.setState({ deleting: true });
  };

  cancelDelete = () => {
    this.setState({ deleting: false });
  };

  handleDelete = () => {
    const { id, handleDelete } = this.props;
    handleDelete(id);
  };

  render() {
    const { anchorEl, deleting } = this.state;
    const { key, classes, data } = this.props;

    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={anchorEl ? key : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          style={{ padding: '5px' }}>
          <MoreVertIcon />
        </IconButton>
        <Menu id={key} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          {/* <MenuItem onClick={() => { this.handleClose(); }} component="a" href="/display" target="_blank">Preview</MenuItem> */}
          <MenuItem
            onClick={event => {
              this.handleClose(event);
              this.handleRefreshDisplay(data.display_id);
            }}>
            Refresh menu
          </MenuItem>
          <MenuItem
            onClick={event => {
              this.handleClose(event);
              this.handleReloadDisplay(data.display_id);
            }}>
            Reload screen
          </MenuItem>

          {/* <MenuItem onClick={() => { this.handleClose(); this.props.onBlockDisplay(); }} className={classes.danger}>
                        Block
                    </MenuItem> */}
          <MenuItem
            onClick={event => {
              this.handleClose(event);
              this.triggerDelete(data.id);
            }}
            className={classes.danger}>
            Remove
          </MenuItem>
        </Menu>

        {!deleting ? null : (
          <ConfirmationDialog
            message={`Are you sure you want to delete the display ${
              data.name || data.display_data.device ? `'${data.name || data.display_data.device}'` : ''
            }?`}
            confirm={this.handleDelete}
            cancel={this.cancelDelete}
            show
          />
        )}
      </div>
    );
  }
}

export default connect(null, {
  setPageTitle: setPageTitleAction,
  showProgressBar: showProgressBarAction,
  hideProgressBar: hideProgressBarAction,
})(DisplaysTable);
