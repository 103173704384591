import { connect } from 'react-redux';
import PrinterFormComponent from '../components/printer-form';
import { setPageTitle } from '../../ui/actions';
import { enqueueSnackbar as enqueueSnackbarAction } from '../../snackbar/actions';
import { getPrinterByIdSelector } from '../reducers';
import { fetchPrinterById, fetchPrinters } from '../actions';
import { fetchCategories } from '../../category/actions';

const mapStateToProps = (state, ownProps) => {
  const data = getPrinterByIdSelector(state, ownProps.id);

  return {
    data,
    categories: Object.values(state.categories.entities ? state.categories.entities.categories : []),
  };
};

const mapDispatchToProps = {
  setPageTitle,
  fetchData: fetchPrinters,
  fetchPrinterById,
  fetchCategories,
  enqueueSnackbar: enqueueSnackbarAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrinterFormComponent);
