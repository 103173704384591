import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import { ClickAwayListener, Grow, Paper, Popper, Tooltip } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import theme from '../theme/theme';

// import Link from "@material-ui/core/Link";
// import Link from 'docs/src/modules/components/Link';

const useStyles = makeStyles(() => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    letterSpacing: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '100%',
    borderRadius: 0,
  },
  buttonLeaf: {
    letterSpacing: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '100%',
    borderRadius: 0,
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    background: '#f7f7f7',

    '&:before': {
      display: 'block',
      backgroundColor: theme.palette.primary.main,
      width: 4,
      content: '" "',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
    },

    '.menuIcon': {
      color: theme.palette.primary.main,
    },
  },
  activeSubmenu: {
    color: theme.palette.primary.main,
  },
  menuIcon: {
    // color: theme.palette.secondary.main,
    fontSize: 22,
    width: 72,
    minWidth: 72,
    textAlign: 'center',
  },
  menuIconFull: {
    textAlign: 'center',
  },
  menuTitle: {
    padding: 0,
    opacity: 1,
    flexGrow: 1,
    textAlign: 'left',

    transition: theme.transitions.create(['opacity', 'max-width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    overflow: 'hidden',
  },
  menuPopover: {
    borderRadius: 0, // theme.spacing(0, 0.5, 0.5, 0),
    boxShadow: '2px 2px 1px -1px rgba(0,0,0,0.1), 1px 1px 1px 0px rgba(0,0,0,0.1), 1px 1px 3px 0px rgba(0,0,0,0.1)',
    minWidth: 180,
  },
  menuPopper: {
    /* background: '#fff', */
    boxShadow: '2px 2px 1px -1px rgba(0,0,0,0.1), 1px 1px 1px 0px rgba(0,0,0,0.1), 1px 1px 3px 0px rgba(0,0,0,0.1)',
    minWidth: 180,
    zIndex: 140,
  },
  toggleIcon: {
    fontSize: 14,
    width: 40,
    textAlign: 'center',
    marginRight: 0,

    '& > .fal': {
      fontSize: 16,
    },
  },
  rotate: {
    transform: 'rotate( 0deg )',
    transition: 'transform 150ms ease',
  },
  open: {
    transform: 'rotate( -180deg )',
  },
}));

function AppDrawerNavItem(props) {
  const {
    active,
    children,
    depth,
    href,
    icon,
    isMobile,
    onClick,
    openImmediately = false,
    topLevel = false,
    title,
    linkProps,
    drawerOpen,
    showToggle,
    pageObject,
    ...other
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(openImmediately);
  const [openMenu, setOpenMenu] = React.useState(openImmediately);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = () => {
    setOpen(oldOpen => !oldOpen);
  };

  const openSideMenu = event => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const closeSideMenu = () => {
    setOpenMenu(false);
    setAnchorEl(null);
  };

  const handleButtonClick = event => {
    if (props.onClick) props.onClick(event);
    closeSideMenu(event);
  };

  const style = {
    padding: theme.spacing(1, depth ? 2 : 0),
  };

  if (drawerOpen && depth) {
    style.padding = '8px 16px 8px 72px';
  }

  // console.log(drawerOpen, openMenu, anchorEl)

  let menuIcon = icon && <span className={clsx(classes.menuIcon, !drawerOpen && classes.menuIconFull)}>{icon}</span>;
  if (menuIcon && !drawerOpen) {
    menuIcon = (
      <Tooltip title={title} placement="top">
        {menuIcon}
      </Tooltip>
    );
  }

  // Is divider instead?
  if (pageObject && pageObject.divider === true) {
    return (
      <ListItem className={classes.itemLeaf} disableGutters {...other}>
        <Box flexGrow={1}>
          <Divider />
        </Box>
      </ListItem>
    );
  }

  if (href) {
    return (
      <ListItem className={classes.itemLeaf} disableGutters {...other}>
        <Button
          size="large"
          component={Link}
          to={href}
          /* activeClassName={`drawer-active ${classes.active}`} */
          href={href}
          className={clsx(
            classes.buttonLeaf,
            `depth-${depth}`,
            active && 'drawer-active',
            active && depth === 0 && classes.active,
            active && depth >= 0 && classes.activeSubmenu,
          )}
          disableTouchRipple
          onClick={handleButtonClick}
          style={style}
          {...linkProps}>
          {menuIcon}
          <span className={[classes.menuTitle].join(' ')}>{`${title}`}</span>
        </Button>
      </ListItem>
    );
  }

  return (
    <ListItem className={classes.item} disableGutters {...other}>
      <Button
        className={clsx(active && `drawer-active ${classes.active}`)}
        size="large"
        classes={{
          root: classes.button,
          label: topLevel ? 'algolia-lvl0' : '',
          endIcon: classes.toggleIcon,
        }}
        onClick={event => {
          if (!drawerOpen) {
            openSideMenu(event);
          } else {
            handleClick();
          }
          if (onClick) onClick(event);
        }}
        endIcon={
          //! showToggle ? null : <i className={['fal', open && 'fa-chevron-up', open || 'fa-chevron-down'].join(' ')} />
          !showToggle ? null : (
            <i className={['fal', 'fa-chevron-down', classes.rotate, open && classes.open].join(' ')} />
          )
        }
        style={style}>
        {menuIcon}
        <span className={[classes.menuTitle].join(' ')}>{`${title}`}</span>
      </Button>
      {drawerOpen || isMobile ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      ) : (
        anchorEl && (
          <Popper
            className={classes.menuPopper}
            placement="right-start"
            modifiers={{
              flip: {
                enabled: false,
              },
              arrow: {
                enabled: false,
              },
            }}
            transition
            anchorEl={anchorEl}
            open={openMenu}>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left top' }}>
                <Paper elevation={0}>
                  <ClickAwayListener onClickAway={closeSideMenu}>
                    <div role="menuitem" onClick={closeSideMenu}>
                      {children}
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        )
      )}
    </ListItem>
  );
}

AppDrawerNavItem.propTypes = {
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  linkProps: PropTypes.object,
  onClick: PropTypes.func,
  openImmediately: PropTypes.bool,
  title: PropTypes.string.isRequired,
  topLevel: PropTypes.bool,
};

export default AppDrawerNavItem;
