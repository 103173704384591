import ApiClient from "../components/ApiClient";
import AppState from "../lib/stores/AppState";

const IngredientService = {
    add(data, callback) {
        if ( callback ) {
            ApiClient.post({
                endpoint: '/api/ingredient',
                data: data
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.post({
            endpoint: '/api/ingredient',
            data: data
        });
    },

    find(filters) {
        return ApiClient.get({
            endpoint: '/api/ingredient/',
            query: filters
        });
    },

    fetchAll(callback) {

        if ( callback ) {
            ApiClient.get({
                endpoint: '/api/ingredient/'
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.get({
            endpoint: '/api/ingredient/'
        });
    },

    fetch(id, callback) {

        if ( callback ) {
            ApiClient.get({
                endpoint: '/api/ingredient/' + id
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.get({
            endpoint: '/api/ingredient/' + id
        });
    },
};

export default IngredientService;
