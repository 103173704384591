import React, { Component } from 'react';

import { withStyles } from '@material-ui/styles';
import { withSnackbar } from 'notistack';

import { InputLabel, FormControl, FormHelperText, MenuItem, Select, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Prompt, withRouter } from 'react-router';
import InputAdornment from '@material-ui/core/InputAdornment';
import ContentPaper from '../../components/content-paper';
import { convertFromBaseUnit, convertToBaseUnit, translate } from '../../i18n';
import { ingredientApi } from '../../api/ingredient';
import FormActions from '../../components/form-actions';
import { FormContainer } from '../../components/forms/container';
import { FormRow } from '../../components/forms/row';
import MeasureUnitSelect from '../../components/forms/measure-unit-select';
import CurrencyField from '../../components/forms/currency-field';
import NumericInputFormat from '../../components/forms/numeric';
import StockField from '../../components/forms/stock-input';
import PackageSizeSelect from '../../components/forms/package-size-select';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginRight: theme.spacing(2),
  },
  buttonBlock: {
    display: 'block',
    width: '140px',
    margin: '0 auto',
    marginBottom: theme.spacing(1),
  },
  buttonDelete: {
    margin: theme.spacing(1),
    color: theme.palette.error.dark,
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    display: 'flex',
  },
  descriptionField: {
    width: '100%',
  },
  formControl: {
    minWidth: 120,
    display: 'flex',
  },
  helperText: {
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  imagePreview: {
    width: '100px',
    height: '100px',
    backgroundColor: '#fafafa',
    margin: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  img: {
    width: 'auto',
    maxHeight: '90%',
    maxWidth: '90%',
    marginTop: '50%',
    transform: 'translateY(-50%)',
  },
});

@withSnackbar
@withStyles(styles)
@withRouter
class IngredientForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : null,
      data: props.data
        ? props.data
        : {
            name: '',
            image: null,
          },
      newData: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    const { id } = this.state;
    if (!id && nextProps.id) {
      this.setState({
        id: nextProps.id,
        data: nextProps.data,
      });
    }
  }

  handleSubmit = async ev => {
    if (ev) {
      ev.preventDefault();
    }

    const { newData, id } = this.state;
    const { onNotification, onSave } = this.props;

    try {
      const response = !id ? await ingredientApi.add(newData) : await ingredientApi.update(id, newData);

      this.setState({ hasUnsavedChanges: false });
      onNotification('Ingredient saved');

      if (onSave) {
        onSave(response);
      }
    } catch (e) {
      onNotification('Could not save the ingredient!', { variant: 'error' });
    }

    return false;
  };

  handleChange = (name, value) => event => {
    const { data, newData } = this.state;

    value = typeof value !== 'undefined' ? value : event.target.value;
    this.setState({
      hasUnsavedChanges: true,
      data: { ...data, [name]: value },
      newData: { ...newData, [name]: value },
    });
  };

  handleDelete = async () => {
    const { id } = this.state;
    const { onNotification, history } = this.props;

    // Reset form
    this.setState({ hasUnsavedChanges: false });

    // Delete AJAX
    try {
      await ingredientApi.deleteById(id);

      onNotification('Ingredient deleted');
      history.push('/ingredient');
    } catch (e) {
      onNotification('Could not delete the ingredient', { options: { variant: 'error' } });
    }
  };

  render() {
    const { classes, onCancel, vendors } = this.props;

    const { data, hasUnsavedChanges } = this.state;

    const name = data && data.name ? data.name : '';

    return (
      <>
        <Prompt
          when={!!hasUnsavedChanges}
          message={translate('You have unsaved changes. Are you sure you want to leave?')}
        />
        <ContentPaper className="FormBoxPaper">
          <FormContainer>
            <FormRow>
              <TextField
                required
                autoComplete="off"
                name="name"
                label={translate('Ingredient name')}
                className={classes.textField}
                value={name || ''}
                onChange={this.handleChange('name')}
                InputProps={{
                  classes: {
                    input: 'LargeTitleInput',
                  },
                }}
              />
            </FormRow>

            <FormRow>
              <TextField
                multiline
                name="description"
                label={translate('Description')}
                className={classes.descriptionField}
                value={data.description || ''}
                onChange={this.handleChange('description')}
              />
            </FormRow>

            <FormRow>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="vendor_id">{translate('Vendor')}</InputLabel>
                <Select
                  value={data.vendor_id || ''}
                  onChange={this.handleChange('vendor_id')}
                  inputProps={{
                    name: 'vendor_id',
                    id: 'vendor_id',
                  }}
                  displayEmpty
                  name="vendor_id">
                  <MenuItem value="" />
                  {vendors &&
                    Object.values(vendors)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(vendor => {
                        return (
                          <MenuItem key={data.id + vendor.id} value={vendor.id}>
                            {vendor.name}
                          </MenuItem>
                        );
                      })}
                </Select>
              </FormControl>

              <TextField
                name="barcode"
                label={translate('Barcode')}
                className={classes.textField}
                value={data.barcode ? data.barcode : ''}
                onChange={this.handleChange('barcode')}
              />
            </FormRow>
          </FormContainer>
        </ContentPaper>

        <ContentPaper className="FormBoxPaper">
          <Typography variant="h5" className="mb-1">
            {translate('Inventory')}
          </Typography>
          <FormContainer>
            <FormRow cols={2}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="ingredient-type" shrink>
                  {translate('Inventory unit')}
                </InputLabel>
                <MeasureUnitSelect
                  value={data.unit || ''}
                  onChange={this.handleChange('unit')}
                  name="unit"
                  id="unit-type"
                />
                <FormHelperText>{translate('Measure unit user for inventory and reports')}</FormHelperText>
              </FormControl>

              <StockField
                name="package_size"
                label={translate('Package size')}
                className={classes.textField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <PackageSizeSelect
                        disableUnderline
                        style={{ minWidth: '80px' }}
                        value={data.package_unit || ''}
                        onChange={this.handleChange('package_unit')}
                      />
                    </InputAdornment>
                  ),
                }}
                defaultValue={data.package_size || ''}
                onChange={this.handleChange('package_size')}
                /* inputProps={{
                  className: 'text-right mr-2',
                }} */
              />
            </FormRow>

            {/* <FormRow>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="portion_unit" shrink>
                  {translate('Portion unit')}
                </InputLabel>
                <MeasureUnitSelect
                  value={data.portion_unit || ''}
                  onChange={this.handleChange('portion_unit')}
                  name="portion_unit"
                  id="portion_unit"
                />
                <FormHelperText>{translate('Used by recipes to calculate costs')}</FormHelperText>
              </FormControl>

              <TextField
                type="number"
                name="portion_count"
                label={translate('Number of portions')}
                className={classes.textField}
                value={data.portion_count ? data.portion_count : ''}
                onChange={this.handleChange('portion_count')}
                InputProps={{ inputProps: { min: 0 } }}
              />
            </FormRow> */}

            <FormRow>
              <StockField
                name="stock_quantity"
                label={translate('Stock')}
                className={classes.textField}
                value={data.stock_quantity ? convertFromBaseUnit(data.stock_quantity, data.unit, 'stock') : ''}
                onChange={ev => {
                  const newStock = convertToBaseUnit(ev.target.value, data.unit, 'stock');
                  this.handleChange('stock_quantity', newStock)();
                }}
                helperText={translate('Current in stock quantity')}
              />

              <StockField
                name="par_number"
                label={translate('Par number')}
                className={classes.textField}
                value={data.par_number ? convertFromBaseUnit(data.par_number, data.unit, 'stock') : ''}
                onChange={ev =>
                  this.handleChange('par_number', convertToBaseUnit(ev.target.value, data.unit, 'stock'))()
                }
                InputProps={{ inputProps: { min: 0 } }}
                helperText={translate('Overrides automatic par amount')}
              />
            </FormRow>

            <FormRow cols={2}>
              <CurrencyField
                fullWidth
                label={translate('Cost')}
                required
                name="cost"
                value={data.cost || ''}
                onChange={this.handleChange('cost')}
                helperText={translate('Cost per 1 {{unit}}', {
                  unit: data.unit && !data.unit.startsWith('case') ? data.unit : (data.unit || '').replace('-', ' of '),
                })}
              />
            </FormRow>
          </FormContainer>
        </ContentPaper>

        <FormActions onDelete={this.handleDelete} onCancel={onCancel} onSubmit={this.handleSubmit} />
      </>
    );
  }
}

export default IngredientForm;
