import React, { Component } from 'react';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import TextField from '@material-ui/core/TextField';
import { Switch, Typography } from '@material-ui/core';
import { Prompt, withRouter } from 'react-router';
import ContentPaper from '../../../components/content-paper';
import { translate } from '../../../i18n';
import { printerGroupApi as printerApi } from '../../../api/printer';
import FormActions from '../../../components/form-actions';
import { FormContainer } from '../../../components/forms/container';
import { FormRow } from '../../../components/forms/row';
import PageLoadingText from '../../../components/PageLoadingText';

import '../styles/printers.scss';
import { parseApiError } from '../../../api/helpers';

@withSnackbar
@withRouter
class PrinterForm extends Component {
  constructor(props) {
    super(props);

    // Set page title
    const { id, setPageTitle } = this.props;
    let pageTitle = translate('Add printer group');
    if (id) {
      pageTitle = translate('Edit printer group');
    }
    setPageTitle(pageTitle);

    this.state = {
      id: props.id ? props.id : null,
      data: !_.isEmpty(props.data) ? props.data : { printer_type: '' },
      newData: {},
    };
  }

  componentDidMount() {
    const { id, fetchCategories, fetchPrinterById } = this.props;

    fetchCategories();

    if (id) {
      fetchPrinterById(id);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { id, data } = this.state;

    // Has the data been loaded and passed over via props?
    if (id && !data.id && nextProps.data) {
      this.setState({
        id: nextProps.id,
        data: nextProps.data,
      });
    }
  }

  handleSubmit = async ev => {
    if (ev) {
      ev.preventDefault();
    }

    const { id, newData } = this.state;
    const { enqueueSnackbar } = this.props;

    try {
      const response = !id ? await printerApi.add(newData) : await printerApi.update(id, newData);

      if (!response.ok) {
        enqueueSnackbar(parseApiError(response).message);
        return;
      }

      this.setState({ hasUnsavedChanges: false });

      enqueueSnackbar('Printer group saved');
      this.handleAfterSave(response);
    } catch (e) {
      enqueueSnackbar('Could not save the printer group!', { variant: 'error' });
    }
  };

  handleChange = (name, value) => event => {
    const { data, newData } = this.state;

    value = typeof value !== 'undefined' ? value : event.target.value;
    this.setState({
      hasUnsavedChanges: true,
      data: { ...data, [name]: value },
      newData: { ...newData, [name]: value },
    });
  };

  handleOptionsChange = nextOptions => {
    const { data, newData } = this.state;

    this.setState({
      data: { ...data, options: nextOptions },
      newData: { ...newData, options: nextOptions },
    });
  };

  handleDelete = async () => {
    const { id } = this.state;
    const { enqueueSnackbar, history } = this.props;

    // Reset state
    this.setState({ hasUnsavedChanges: false });

    // Delete AJAX
    try {
      await printerApi.deleteById(id);

      enqueueSnackbar('Printer group deleted');
      history.push('/printer-group');
    } catch (e) {
      enqueueSnackbar('Could not delete the printer group', { options: { variant: 'error' } });
    }
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push('/printer-group');
  };

  handleAfterSave = () => {
    const { history } = this.props;
    history.push('/printer-group');
  };

  handleChangeCategory = (category, value) => {
    const { data, newData } = this.state;
    const { categories: categoriesState } = data;
    let categories = [];
    if (categoriesState && categoriesState.length) {
      categories = [...categoriesState];
    }

    const eIndex = categories.findIndex(c => c.id === category.id);
    if (eIndex === -1 && value) {
      // Add
      categories = [...categories, { id: category.id, name: category.name }];
    } else if (eIndex !== -1 && !value) {
      // Remove
      categories.splice(eIndex, 1);
    }

    this.setState({
      hasUnsavedChanges: true,
      data: { ...data, categories },
      newData: { ...newData, categories },
    });
  };

  canSubmitForm = () => {
    const { data } = this.state;
    const { name } = data;

    return !!name;
  };

  render() {
    const { id, categories } = this.props;
    const { data, hasUnsavedChanges } = this.state;

    if (id && (!data || !data.id)) {
      return <PageLoadingText />;
    }

    const name = data && data.name ? data.name : '';

    return (
      <form onSubmit={this.handleSubmit} method="POST">
        <Prompt
          when={!!hasUnsavedChanges}
          message={translate('You have unsaved changes. Are you sure you want to leave?')}
        />
        <ContentPaper short className="FormBoxPaper">
          <FormContainer>
            <FormRow>
              <TextField
                required
                fullWidth
                autoComplete="off"
                name="name"
                label={translate('Printer group name')}
                value={name || ''}
                error={hasUnsavedChanges && !name}
                helperText={hasUnsavedChanges && !name ? translate('This field is required') : ''}
                onChange={this.handleChange('name')}
                InputProps={{
                  classes: {
                    input: 'LargeTitleInput',
                  },
                }}
              />
            </FormRow>
          </FormContainer>
        </ContentPaper>

        <ContentPaper short className="FormBoxPaper">
          <Typography variant="h5" className="mb-1">
            {translate('Product categories')}
          </Typography>
          <Typography className="text-muted mb-3">
            {translate('Categories already assigned to other printers are disabled.')}
          </Typography>
          <div>
            {categories.map(category => {
              const isDisabled = category.printer_group_id && category.printer_group_id !== parseInt(id, 10);
              return (
                <div
                  key={`product-modifier-${category.id}`}
                  className={['d-table', isDisabled ? 'text-muted' : ''].join(' ')}>
                  <div className="d-table-cell align-middle width-75">{category.name}</div>
                  <div className="d-table-cell align-middle text-right width-25">
                    <Switch
                      disabled={isDisabled}
                      color="primary"
                      checked={!!(data.categories && data.categories.findIndex(c => c.id === category.id) > -1)}
                      onChange={(event, value) => this.handleChangeCategory(category, value)}
                      name="modifiers"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </ContentPaper>

        <FormActions
          short
          onDelete={this.handleDelete}
          onCancel={this.handleCancel}
          onSubmit={() => {}}
          disableSubmit={!this.canSubmitForm()}
        />
      </form>
    );
  }
}

export default PrinterForm;
