import produce from 'immer';
import { denormalize, normalize, schema } from 'normalizr';
import _ from 'lodash';

import {
  PRODUCTS_FETCH_REQUEST,
  PRODUCTS_FETCH_SUCCESS,
  PRODUCTS_FETCH_ERROR,
  PRODUCTS_HIDE_ADD_DIALOG,
  PRODUCTS_SHOW_ADD_DIALOG,
  PRODUCTS_SET_TABLE_FILTER,
  PRODUCTS_FETCH_PRODUCT_START,
  PRODUCTS_FETCH_PRODUCT_SUCCESS, PRODUCTS_FETCH_PRINTER_AREAS_SUCCESS,
} from '../actions';

const INITIAL_STATE = {
  entities: {
    products: {},
  },
  entitiesIds: [],
  selected: null,
  printerAreas: [],

  // Filters
  filters: {
    search: null,
    product_type: null,
    is_enabled: 1,
    category_id: null,
  },

  // Other state
  showAddDialog: false,
  loading: false,
  initialLoaded: false,
  loadingId: null,
  loadingIds: [],

  fetchError: null,
  saveError: null,
};

// Define the order schema so we can normalize the data and index it properly
const productsSchema = new schema.Entity('products');
const productListSchema = new schema.Array(productsSchema);

export default function products(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case PRODUCTS_FETCH_SUCCESS:
        // Normalize the data for better access
        const { data } = action.payload;
        const normalized = normalize(data, productListSchema);
        draft.entities = normalized.entities;
        draft.entitiesIds = normalized.result;
        draft.loading = false;
        draft.initialLoaded = true;
        draft.fetchError = null;
        break;

      case PRODUCTS_FETCH_REQUEST:
        draft.loading = true;
        draft.fetchError = null;
        break;

      case PRODUCTS_FETCH_ERROR:
        draft.fetchError = action.payload.error;
        break;

      case PRODUCTS_HIDE_ADD_DIALOG:
        draft.showAddDialog = false;
        break;

      case PRODUCTS_SHOW_ADD_DIALOG:
        draft.showAddDialog = true;
        break;

      case PRODUCTS_SET_TABLE_FILTER:
        draft.filters[action.payload.name] = action.payload.value;
        break;

      case PRODUCTS_FETCH_PRODUCT_START:
        draft.loadingId = action.payload.id;
        break;

      case PRODUCTS_FETCH_PRODUCT_SUCCESS:
        // eslint-disable-next-line no-use-before-define
        draft = addOrUpdateProductInStore(state, action, draft);
        break;

      case PRODUCTS_FETCH_PRINTER_AREAS_SUCCESS:
        // eslint-disable-next-line no-use-before-define
        draft.printerAreas = action.payload.data;
        break;

      default:
    }
  });
}

// Selectors
export function getFilteredProducts(state) {
  const { entities, filters } = state.products;

  return _.filter(entities.products, o => {
    if (filters.search && o.name.toLowerCase().indexOf(filters.search.toLowerCase()) === -1) {
      return false;
    }

    if (filters.is_enabled !== null && filters.is_enabled !== '') {
      let { is_enabled: isEnabled } = filters;
      isEnabled = !!parseInt(isEnabled, 10);
      if (!isEnabled && o.is_enabled) {
        return false;
      }

      if (isEnabled && !o.is_enabled) {
        return false;
      }
    }

    if (filters.category_id && !o.category_id) {
      return false;
    }

    if (filters.category_id) {
      if (!o.category_id) {
        return false;
      }

      if (o.category_id.toString() !== filters.category_id.toString()) {
        return false;
      }
    }

    return true;
  });
}

export const getProductByIdSelector = (state, id) => {
  return state.products && state.products.entities && state.products.entities.products
    ? state.products.entities.products[id]
    : {};
};

// Data management functions
const addOrUpdateProductInStore = (state, action, draft) => {
  let productsArr = denormalize(state.entitiesIds, productListSchema, state.entities);
  const rowIndex = state.entitiesIds.indexOf(parseInt(action.payload.id, 10));

  if (rowIndex === -1) {
    productsArr = [...productsArr, action.payload.data];
  } else {
    productsArr = [...productsArr.slice(0, rowIndex), action.payload.data, ...productsArr.slice(rowIndex + 1)];
  }

  const singleProduct = normalize(productsArr, productListSchema);
  draft.entities = singleProduct.entities;
  draft.entitiesIds = singleProduct.result;

  // Reset loading
  if (state.loadingId === action.payload.id) {
    draft.loadingId = null;
  }

  return draft;
};
