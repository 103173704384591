import { createClient } from './helpers';

const apiClient = createClient();

function fetch() {
  return apiClient.get('venue');
}

function add(data) {
  return apiClient.post('venue', data);
}

function update(id, data) {
  return apiClient.patch(`venue/${id}`, data);
}

function getById(id) {
  return apiClient.get(`venue/${id}`);
}

function deleteById(id) {
  return apiClient.delete(`venue/${id}`);
}

export const venueApi = {
  fetch,
  add,
  update,
  getById,
  deleteById,
};
