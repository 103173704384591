import React from 'react';
import { connect } from 'react-redux';
import Payments from '../components/payments';
import { fetchPayments, hidePaymentAddDialog, setTableFilter, showPaymentAddDialog } from '../actions';
import { setPageTitle } from '../../ui/actions';
import { getFilteredPayments } from '../reducers';
import { enqueueSnackbar } from '../../snackbar/actions';

function mapStateToProps(state) {
  const payments = getFilteredPayments(state);

  return {
    dataById: payments || {},
    data: state.payments.entitiesIds || [],
    filters: state.payments.filters,
    selected: state.payments.selected,
    loading: state.payments.loading,
    initialLoaded: state.payments.initialLoaded,
    fetchError: state.payments.fetchError,
    loadingIds: state.payments.loadingIds,
  };
}

const mapDispatchToProps = {
  fetchData: fetchPayments,
  setPageTitle,
  setTableFilter,
  showAddDialog: showPaymentAddDialog,
  hideAddDialog: hidePaymentAddDialog,
  enqueueSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
