import { makeActionCreator } from '../../../store/actions';

export const DISCOUNTS_FETCH_REQUEST = '@discounts / FETCH REQUEST';
export const DISCOUNTS_FETCH_SUCCESS = '@discounts / FETCH SUCCESS';
export const DISCOUNTS_FETCH_ERROR = '@discounts / FETCH ERROR';
export const DISCOUNTS_SHOW_ADD_DIALOG = '@discounts / SHOW ADD DIALOG';
export const DISCOUNTS_HIDE_ADD_DIALOG = '@discounts / HIDE ADD DIALOG';
export const DISCOUNTS_SET_TABLE_FILTER = '@discounts / SET TABLE FILTER';
export const DISCOUNTS_FETCH_DISCOUNT_START = '@discounts / FETCH DISCOUNT START';
export const DISCOUNTS_FETCH_DISCOUNT_SUCCESS = '@discounts / FETCH DISCOUNT SUCCESS';

export const fetchDiscounts = makeActionCreator(DISCOUNTS_FETCH_REQUEST);
export const fetchDiscountsError = makeActionCreator(DISCOUNTS_FETCH_ERROR, 'error');
export const fetchDiscountsSuccess = makeActionCreator(DISCOUNTS_FETCH_SUCCESS, 'data');
export const showDiscountAddDialog = makeActionCreator(DISCOUNTS_SHOW_ADD_DIALOG);
export const hideDiscountAddDialog = makeActionCreator(DISCOUNTS_HIDE_ADD_DIALOG);

export const fetchDiscountById = makeActionCreator(DISCOUNTS_FETCH_DISCOUNT_START, 'id');
export const fetchDiscountByIdSuccess = makeActionCreator(DISCOUNTS_FETCH_DISCOUNT_SUCCESS, 'id', 'data');

// Table filters
export const setTableFilter = makeActionCreator(DISCOUNTS_SET_TABLE_FILTER, 'name', 'value');
