import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { IconButton, Menu, MenuItem, withStyles } from '@material-ui/core';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { translate } from '../../../i18n';

const styles = theme => ({
  root: {},
  paper: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto',
    position: 'relative',
  },

  danger: {
    color: theme.palette.error.dark,
  },
  table: {
    minWidth: 700,
  },

  tableButton: {
    marginRight: theme.spacing(2),
  },
});

@withStyles(styles)
class ActionMenu extends React.Component {
  static propTypes = {
    handleDelete: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
      anchorEl: null,
      redirectTo: null,
    };
  }

  handleCopy = event => {
    this.handleClose(event);

    const { id, handleCopy } = this.props;
    handleCopy(id);
  };

  handleClick = event => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = event => {
    event.stopPropagation();
    this.setState({ anchorEl: null });
  };

  triggerDelete = () => {
    this.setState({ deleting: true });
  };

  cancelDelete = () => {
    this.setState({ deleting: false });
  };

  handleDelete = () => {
    const { id, handleDelete } = this.props;
    handleDelete(id);
  };

  handleStatusChange = (id, enabled) => {
    const { handleStatusChange } = this.props;
    handleStatusChange(id, enabled);
  };

  render() {
    const { anchorEl, deleting, redirectTo } = this.state;
    const { key, classes, data, isLoading } = this.props;

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={anchorEl ? key : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}>
          {isLoading ? <i className="fal fa-fw fa-spin fa-spinner" /> : <i className="fal fa-fw fa-ellipsis-v" />}
        </IconButton>
        <Menu id={key} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>


          <MenuItem
            onClick={event => {
              this.handleClose(event);
              this.triggerDelete(data.id);
            }}
            className={classes.danger}>
            {translate('Delete')}
          </MenuItem>
        </Menu>

        {!deleting ? null : (
          <ConfirmationDialog
            message={translate(`Are you sure you want to delete '{{name}}'?`, { name: data.name })}
            confirm={this.handleDelete}
            cancel={this.cancelDelete}
            show
          />
        )}
      </div>
    );
  }
}

export default ActionMenu;
