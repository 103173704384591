import produce from 'immer';
import { denormalize, normalize, schema } from 'normalizr';
import _ from 'lodash';

import {
  CATEGORIES_FETCH_REQUEST,
  CATEGORIES_FETCH_SUCCESS,
  CATEGORIES_FETCH_ERROR,
  CATEGORIES_HIDE_ADD_DIALOG,
  CATEGORIES_SHOW_ADD_DIALOG,
  CATEGORIES_SET_TABLE_FILTER,
  CATEGORIES_FETCH_CATEGORY_START,
  CATEGORIES_FETCH_CATEGORY_SUCCESS,
} from '../actions';

const INITIAL_STATE = {
  entities: {
    categories: {},
  },
  entitiesIds: [],
  selected: null,

  // Filters
  filters: {
    search: null,
    category_type: null,
  },

  // Other state
  showAddDialog: false,
  loading: false,
  initialLoaded: false,
  loadingId: null,
  loadingIds: [],

  fetchError: null,
  saveError: null,
};

// Define the order schema so we can normalize the data and index it properly
const categoryOptionsSchema = new schema.Entity('options');
const categoriesSchema = new schema.Entity('categories', /*{
  options: [categoryOptionsSchema],
}*/);
const categoryListSchema = new schema.Array(categoriesSchema);

export default function categories(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case CATEGORIES_FETCH_SUCCESS:
        // Normalize the data for better access
        const { data } = action.payload;
        const normalized = normalize(data, categoryListSchema);
        draft.entities = normalized.entities;
        draft.entitiesIds = normalized.result;
        draft.loading = false;
        draft.initialLoaded = true;
        draft.fetchError = null;
        break;

      case CATEGORIES_FETCH_REQUEST:
        draft.loading = true;
        draft.fetchError = null;
        break;

      case CATEGORIES_FETCH_ERROR:
        draft.fetchError = action.payload.error;
        break;

      case CATEGORIES_HIDE_ADD_DIALOG:
        draft.showAddDialog = false;
        break;

      case CATEGORIES_SHOW_ADD_DIALOG:
        draft.showAddDialog = true;
        break;

      case CATEGORIES_SET_TABLE_FILTER:
        draft.filters[action.payload.name] = action.payload.value;
        break;

      case CATEGORIES_FETCH_CATEGORY_START:
        draft.loadingId = action.payload.id;
        break;

      case CATEGORIES_FETCH_CATEGORY_SUCCESS:
        // eslint-disable-next-line no-use-before-define
        draft = addOrUpdateCategoryInStore(state, action, draft);

        break;

      default:
    }
  });
}

// Selectors
export function getFilteredCategories(state) {
  const { entities, filters } = state.categories;

  return _.filter(entities.categories, o => {
    if (filters.search && o.name.toLowerCase().indexOf(filters.search.toLowerCase()) === -1) {
      return false;
    }

    if (filters.category_type && o.category_type !== filters.category_type) {
      return false;
    }

    return true;
  });
}

export const getCategoryByIdSelector = (state, id) => {
  return state.categories && state.categories.entities && state.categories.entities.categories
    ? state.categories.entities.categories[id]
    : {};
};

// Data management functions
const addOrUpdateCategoryInStore = (state, action, draft) => {
  let categoriesArr = denormalize(state.entitiesIds, categoryListSchema, state.entities);
  const rowIndex = state.entitiesIds.indexOf(parseInt(action.payload.id, 10));

  if (rowIndex === -1) {
    categoriesArr = [...categoriesArr, action.payload.data];
  } else {
    categoriesArr = [...categoriesArr.slice(0, rowIndex), action.payload.data, ...categoriesArr.slice(rowIndex + 1)];
  }

  const singleCategory = normalize(categoriesArr, categoryListSchema);
  draft.entities = singleCategory.entities;
  draft.entitiesIds = singleCategory.result;

  // Reset loading
  if (state.loadingId === action.payload.id) {
    draft.loadingId = null;
  }

  return draft;
};
