import produce from 'immer';
import { denormalize, normalize, schema } from 'normalizr';
import _ from 'lodash';

import {
  RECIPES_FETCH_REQUEST,
  RECIPES_FETCH_SUCCESS,
  RECIPES_FETCH_ERROR,
  RECIPES_HIDE_ADD_DIALOG,
  RECIPES_SHOW_ADD_DIALOG,
  RECIPES_SET_TABLE_FILTER,
  RECIPES_FETCH_RECIPE_START,
  RECIPES_FETCH_RECIPE_SUCCESS,
} from '../actions';

const INITIAL_STATE = {
  entities: {
    recipes: {},
  },
  entitiesIds: [],
  selected: null,

  // Filters
  filters: {
    search: null,
    ingredient_id: null,
  },

  // Other state
  showAddDialog: false,
  loading: false,
  initialLoaded: false,
  loadingId: null,
  loadingIds: [],

  fetchError: null,
  saveError: null,
};

// Define the order schema so we can normalize the data and index it properly
const ingredientSchema = new schema.Entity('ingredients');
const recipeIngredientSchema = new schema.Entity('recipe_ingredients', {
  ingredient: ingredientSchema,
});
const recipesSchema = new schema.Entity('recipes', {
  recipe_ingredients: [recipeIngredientSchema],
});
const recipeListSchema = new schema.Array(recipesSchema);

export default function recipes(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case RECIPES_FETCH_SUCCESS:
        // Normalize the data for better access
        const { data } = action.payload;
        const normalized = normalize(data, recipeListSchema);
        draft.entities = normalized.entities;
        draft.entitiesIds = normalized.result;
        draft.loading = false;
        draft.initialLoaded = true;
        draft.fetchError = null;
        break;

      case RECIPES_FETCH_REQUEST:
        draft.loading = true;
        draft.fetchError = null;
        break;

      case RECIPES_FETCH_ERROR:
        draft.fetchError = action.payload.error;
        break;

      case RECIPES_HIDE_ADD_DIALOG:
        draft.showAddDialog = false;
        break;

      case RECIPES_SHOW_ADD_DIALOG:
        draft.showAddDialog = true;
        break;

      case RECIPES_SET_TABLE_FILTER:
        draft.filters[action.payload.name] = action.payload.value;
        break;

      case RECIPES_FETCH_RECIPE_START:
        draft.loadingId = action.payload.id;
        break;

      case RECIPES_FETCH_RECIPE_SUCCESS:
        // eslint-disable-next-line no-use-before-define
        draft = addOrUpdateRecipeInStore(state, action, draft);

        break;

      default:
    }
  });
}

// Selectors
export function getFilteredRecipes(state) {
  const { entities, filters } = state.recipes;

  return _.filter(entities.recipes, o => {
    if (filters.search && o.name.toLowerCase().indexOf(filters.search.toLowerCase()) === -1) {
      return false;
    }
    if (filters.ingredient_id) {
      if (!o.recipe_ingredients || !o.recipe_ingredients.length) {
        return false;
      }

      const recipeIngredient = _.find(
        state.recipes.entities.recipe_ingredients,
        ri => ri.ingredient_id.toString() === filters.ingredient_id.toString(),
      );

      // No ingredient found matching the filter
      if (!recipeIngredient) {
        return false;
      }

      // Ingredient not existing in this recipe?
      if (recipeIngredient && recipeIngredient.id && o.recipe_ingredients.indexOf(recipeIngredient.id) === -1) {
        return false;
      }
    }

    return true;
  });
}

export const getRecipeByIdSelector = (state, id) => {
  return state.recipes && state.recipes.entities && state.recipes.entities.recipes
    ? state.recipes.entities.recipes[id]
    : {};
};

// Data management functions
const addOrUpdateRecipeInStore = (state, action, draft) => {
  let recipesArr = denormalize(state.entitiesIds, recipeListSchema, state.entities);
  const rowIndex = state.entitiesIds.indexOf(parseInt(action.payload.id, 10));

  if (rowIndex === -1) {
    recipesArr = [...recipesArr, action.payload.data];
  } else {
    recipesArr = [...recipesArr.slice(0, rowIndex), action.payload.data, ...recipesArr.slice(rowIndex + 1)];
  }

  const singleRecipe = normalize(recipesArr, recipeListSchema);
  draft.entities = singleRecipe.entities;
  draft.entitiesIds = singleRecipe.result;

  // Reset loading
  if (state.loadingId === action.payload.id) {
    draft.loadingId = null;
  }

  return draft;
};
