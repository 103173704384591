import { Route } from 'react-router';
import React from 'react';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';
import SignIn from './features/auth/SignIn';
import SignOut from './features/auth/SignOut';
import Dashboard from './features/dashboard/Dashboard';
import DisplayList from './features/display/List';
import OnTap from './features/on-tap/OnTap';
import IngredientAddEdit from './features/ingredient/IngredientAddEdit';
import Ingredients from './features/ingredient/containers/ingredients';
import VendorAddEdit from './features/vendor/VendorAddEdit';
import Vendors from './features/vendor/containers/vendors';
import Venues from './features/venue/containers/venues';
import VenueAddEdit from './features/venue/VenueAddEdit';
import InvoiceAddEdit from './features/invoice/InvoiceAddEdit';
import InvoicesList from './features/invoice/Invoices';
import OrdersList from './features/order/orders';
import OrderSystemConfiguration from './features/order/settings';
import AreaAddEdit from './features/area/AreaAddEdit';
import Area from './features/area/containers/areas';
import UserForm from './features/user/containers/user-form';
import Users from './features/user/containers/users';
import Beverage from './features/beverage/containers/beverage';
import BeverageAddEdit from './features/beverage/BeverageAddEdit';
import MediaManager from './features/media/MediaManager';
import MenuEdit from './features/menus/containers/menu-edit';
import Menus from './features/menus/containers/menus';
import InventoryEdit from './features/inventory/InventoryEdit';
import InventoryView from './features/inventory/InventoryView';
import InventoryReport from './features/inventory/InventoryReport';
import Inventory from './features/inventory/Inventory';
import ContentTypeRouter from './features/display/ContentTypeRouter';
import Payments from './features/payments/containers/payments';
import Recipes from './features/recipe/containers/recipes';
import RecipeForm from './features/recipe/containers/recipe-form';
import ModifierForm from './features/modifier/containers/modifier-form';
import Modifiers from './features/modifier/containers/modifiers';
import ProductForm from './features/product/containers/product-form';
import Products from './features/product/containers/products';
import CategoryForm from './features/category/containers/category-form';
import Categories from './features/category/containers/categories';
import InventoryInvoiceInput from './features/inventory/containers/incoming-invoices';
import DiscountForm from './features/discount/containers/discount-form';
import Discounts from './features/discount/containers/discounts';
import Printers from './features/printer/containers/printers';
import PrinterForm from './features/printer/containers/printer-form';
import AuditEvent from './features/audit/containers/audit-event';
import AuditDatabase from './features/audit/containers/audit-database';
import SalesSummary from './features/reports/containers/sales-summary';
import SalesItems from './features/reports/containers/sales-items';
import SalesReceipts from './features/reports/containers/sales-receipts';
import SalesDiscounts from './features/reports/containers/sales-discounts';
import SalesCategories from './features/reports/containers/sales-categories';

export const routes = [
  {
    path: '/login',
    key: 'login-route',
    component: SignIn,
    RouteComponent: Route,
  },
  {
    path: '/logout',
    key: 'logout-route',
    component: SignOut,
    RouteComponent: Route,
  },

  {
    path: '/dashboard',
    key: 'dashboard-route',
    exact: true,
    component: Dashboard,
    RouteComponent: PrivateRoute,
  },

  {
    path: '/display/manage',
    key: 'display-manage-route',
    exact: true,
    component: DisplayList,
    RouteComponent: AdminRoute,
  },
  {
    path: '/on-tap',
    key: 'on-tap-route',
    exact: true,
    component: OnTap,
    RouteComponent: AdminRoute,
  },

  {
    path: '/ingredient/edit/:id',
    key: 'ingredient-edit-route',
    exact: true,
    component: props => <IngredientAddEdit id={props.match.params.id} {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/ingredient',
    key: 'ingredient-list-route',
    exact: true,
    component: Ingredients,
    RouteComponent: AdminRoute,
  },

  // Recipes
  {
    path: '/recipe/add',
    key: 'recipe-add-route',
    exact: true,
    component: props => <RecipeForm {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/recipe/edit/:id',
    key: 'recipe-edit-route',
    exact: true,
    component: props => <RecipeForm id={props.match.params.id} {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/recipe',
    key: 'recipe-list-route',
    exact: true,
    component: Recipes,
    RouteComponent: AdminRoute,
  },

  // Modifiers
  {
    path: '/modifier/add',
    key: 'modifier-add-route',
    exact: true,
    component: props => <ModifierForm {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/modifier/edit/:id',
    key: 'modifier-edit-route',
    exact: true,
    component: props => <ModifierForm id={props.match.params.id} {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/modifier',
    key: 'modifier-list-route',
    exact: true,
    component: Modifiers,
    RouteComponent: AdminRoute,
  },

  // Discounts
  {
    path: '/discount/add',
    key: 'discount-add-route',
    exact: true,
    component: props => <DiscountForm {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/discount/edit/:id',
    key: 'discount-edit-route',
    exact: true,
    component: props => <DiscountForm id={props.match.params.id} {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/discount',
    key: 'discount-list-route',
    exact: true,
    component: Discounts,
    RouteComponent: AdminRoute,
  },

  // Products
  {
    path: '/product/add',
    key: 'product-add-route',
    exact: true,
    component: props => <ProductForm {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/product/edit/:id',
    key: 'product-edit-route',
    exact: true,
    component: props => <ProductForm id={props.match.params.id} {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/product',
    key: 'product-list-route',
    exact: true,
    component: Products,
    RouteComponent: AdminRoute,
  },

  // Product categories
  {
    path: '/category/add',
    key: 'category-add-route',
    exact: true,
    component: props => <CategoryForm {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/category/edit/:id',
    key: 'category-edit-route',
    exact: true,
    component: props => <CategoryForm id={props.match.params.id} {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/category',
    key: 'category-list-route',
    exact: true,
    component: Categories,
    RouteComponent: AdminRoute,
  },

  {
    path: '/vendor/edit/:id',
    key: 'vendor-edit-route',
    exact: true,
    component: props => <VendorAddEdit id={props.match.params.id} {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/vendor',
    key: 'vendor-list-route',
    exact: true,
    component: Vendors,
    RouteComponent: AdminRoute,
  },

  {
    path: '/venue/edit/:id',
    key: 'venue-edit-route',
    exact: true,
    component: props => <VenueAddEdit id={props.match.params.id} {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/venue',
    key: 'venue-list-route',
    exact: true,
    component: Venues,
    RouteComponent: AdminRoute,
  },

  {
    path: '/invoice/edit/:id',
    key: 'invoice-edit-route',
    exact: true,
    component: props => <InvoiceAddEdit id={props.match.params.id} {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/invoice',
    key: 'invoice-list-route',
    exact: true,
    component: InvoicesList,
    RouteComponent: AdminRoute,
  },

  {
    path: '/order/edit/:id',
    key: 'order-edit-route',
    exact: true,
    component: props => <InvoiceAddEdit id={props.match.params.id} {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/order/settings',
    key: 'order-settings-route',
    exact: true,
    component: OrderSystemConfiguration,
    RouteComponent: AdminRoute,
  },
  {
    path: '/order',
    key: 'order-list-route',
    exact: true,
    component: OrdersList,
    RouteComponent: PrivateRoute,
  },
  {
    path: '/payment',
    key: 'payments-list-route',
    exact: true,
    component: Payments,
    RouteComponent: PrivateRoute,
  },

  {
    path: '/area/edit/:id',
    key: 'area-edit-route',
    exact: true,
    component: props => <AreaAddEdit id={props.match.params.id} {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/area',
    key: 'area-route',
    exact: true,
    component: Area,
    RouteComponent: AdminRoute,
  },

  {
    path: '/user/add',
    key: 'user-add-route',
    exact: true,
    component: props => <UserForm {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/user/edit/:id',
    key: 'user-edit-route',
    exact: true,
    component: props => <UserForm id={props.match.params.id} {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/user',
    key: 'user-list-route',
    exact: true,
    component: Users,
    RouteComponent: AdminRoute,
  },

  {
    path: '/beverage/edit/:id',
    key: 'beverage-edit-route',
    exact: true,
    component: props => <BeverageAddEdit id={props.match.params.id} {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/beverage',
    key: 'beverage-route',
    exact: true,
    component: Beverage,
    RouteComponent: AdminRoute,
  },

  {
    path: '/media',
    key: 'media-route',
    exact: true,
    component: MediaManager,
    RouteComponent: AdminRoute,
  },

  {
    path: '/menu/edit/:id',
    key: 'menu-edit-route',
    exact: true,
    component: props => <MenuEdit id={props.match.params.id} {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/menu/add',
    key: 'menu-add-route',
    exact: true,
    component: MenuEdit,
    RouteComponent: AdminRoute,
  },
  {
    path: '/menu',
    key: 'menu-list-route',
    exact: true,
    component: Menus,
    RouteComponent: AdminRoute,
  },

  // Inventory related
  {
    path: '/inventory/new/area/:areaId',
    key: 'new-inventory',
    exact: true,
    component: InventoryEdit,
    RouteComponent: PrivateRoute,
  },
  {
    path: '/inventory/edit/:inventoryId/:areaId',
    key: 'edit-inventory',
    exact: true,
    editing: true,
    component: InventoryEdit,
    RouteComponent: PrivateRoute,
  },
  {
    path: '/inventory/report/:inventoryId',
    key: 'view-inventory',
    exact: true,
    component: InventoryView,
    RouteComponent: PrivateRoute,
  },
  {
    path: '/inventory/report',
    key: 'inventory-report',
    exact: true,
    component: InventoryReport,
    RouteComponent: PrivateRoute,
  },
  {
    path: '/inventory/incoming-invoice',
    key: 'incoming-invoice',
    exact: true,
    component: InventoryInvoiceInput,
    RouteComponent: PrivateRoute,
  },
  {
    path: '/inventory',
    key: 'manage-inventory',
    exact: true,
    component: Inventory,
    RouteComponent: PrivateRoute,
  },

  // Digital displays
  {
    path: '/display/embedded',
    key: 'display-embedded-route',
    exact: true,
    component: ContentTypeRouter,
    RouteComponent: Route,
  },
  {
    path: '/display',
    key: 'display-list-route',
    exact: true,
    component: ContentTypeRouter,
    RouteComponent: Route,
  },

  // Printer groups
  {
    path: '/printer-group/add',
    key: 'printer-group-add-route',
    exact: true,
    component: props => <PrinterForm {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/printer-group/edit/:id',
    key: 'printer-group-edit-route',
    exact: true,
    component: props => <PrinterForm id={props.match.params.id} {...props} />,
    RouteComponent: AdminRoute,
  },
  {
    path: '/printer-group',
    key: 'printer-group-list-route',
    exact: true,
    component: Printers,
    RouteComponent: AdminRoute,
  },

  // Audit
  {
    path: '/audit/event',
    key: 'audit-event-list-route',
    exact: true,
    component: props => <AuditEvent {...props} />,
    RouteComponent: AdminRoute,
  },

  // Audit
  {
    path: '/audit/database',
    key: 'audit-database-list-route',
    exact: true,
    component: props => <AuditDatabase {...props} />,
    RouteComponent: AdminRoute,
  },

  // Sales summary report
  {
    path: '/report/sales',
    key: 'report-sales-summary',
    exact: true,
    component: props => <SalesSummary {...props} />,
    RouteComponent: AdminRoute,
  },

  // Sales items report
  {
    path: '/report/sales/items',
    key: 'report-sales-items',
    exact: true,
    component: props => <SalesItems {...props} />,
    RouteComponent: AdminRoute,
  },

  // Sales categories report
  {
    path: '/report/sales/categories',
    key: 'report-sales-categories',
    exact: true,
    component: props => <SalesCategories {...props} />,
    RouteComponent: AdminRoute,
  },

  // Sales receipts
  {
    path: '/report/sales/receipts',
    key: 'report-sales-receipts',
    exact: true,
    component: props => <SalesReceipts {...props} />,
    RouteComponent: AdminRoute,
  },

  // Sales discounts
  {
    path: '/report/sales/discounts',
    key: 'report-sales-discounts',
    exact: true,
    component: props => <SalesDiscounts {...props} />,
    RouteComponent: AdminRoute,
  },
];
