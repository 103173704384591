import React, { Component } from 'react';
import { Redirect, Route, Router } from 'react-router-dom';
import { Provider as ReduxProvider, ReactReduxContext } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Detector as OfflineDetector } from 'react-detect-offline';
import MuiThemeProvider from '@material-ui/styles/ThemeProvider';
import { SnackbarProvider } from 'notistack';
import { CssBaseline } from '@material-ui/core';
import { ConnectedRouter } from 'connected-react-router';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import { store, history } from './store';
import { setI18nConfig } from './i18n';
import AppBar from './features/ui/containers/appbar';
import SnackbarNotifier from './features/snackbar';
import NoInternetAlert from './components/NoInternetAlert';
import PrivateComponent from './components/PrivateComponent';
import theme from './theme/theme';
import { styles } from './App-styles';
import HandleProgressBar from './features/ui/containers/progress-bar';
import RouteSwitcher from './navigation/route-switcher';
import AppDrawer from './features/ui/containers/drawer';
import ScrollToTop from './navigation/scroll-to-top';

import './theme/scss/base.scss';

@withStyles(styles)
class App extends Component {
  constructor(props) {
    super(props);
    this.appRef = React.createRef();

    // Init localization - will render only
    let localeConfig = '{ "language": "en" }';
    if (localStorage.getItem('localeConfig')) {
      localeConfig = localStorage.getItem('localeConfig');
    }
    const locale = JSON.parse(localeConfig);
    setI18nConfig(locale)
      .then(appLanguage => {
        localStorage.setItem('appLanguage', appLanguage || 'en');
      })
      .catch(() => {
        localStorage.setItem('appLanguage', 'en');
      });
  }

  componentWillMount() {
    // AppState.subscribe(this.postConfigUpdate, 'update');
  }

  componentDidMount() {
    // UserStore.subscribe(this.onUserStoreLogout, 'logout');
  }

  componentWillUnmount() {
    // AppState.unsubscribe(this.postConfigUpdate, 'update');
  }

  render() {
    const { classes } = this.props;

    const isTouchCapable =
      'ontouchstart' in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch) ||
      navigator.maxTouchPoints > 0 ||
      window.navigator.msMaxTouchPoints > 0;

    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ReduxProvider store={store} context={ReactReduxContext}>
          <ConnectedRouter history={history} context={ReactReduxContext}>
            <Router history={history}>
              <div className={classes.root}>
                <OfflineDetector
                  polling={{
                    enabled: false,
                    url: '/',
                    interval: 10000,
                    timeout: 5000,
                  }}
                  render={({ online }) => {
                    if (!online) {
                      return <NoInternetAlert />;
                    }

                    return null;
                  }}
                />

                {/* Scroll to top upon navigation */}
                <ScrollToTop />

                {/* Takes care of displaying a top loading progress bar */}
                <HandleProgressBar />

                <Route exact path="/" render={() => <Redirect to="/dashboard" />} />

                <CssBaseline />

                <PrivateComponent>
                  <AppBar history={history} />

                  <AppDrawer />
                </PrivateComponent>

                <SnackbarProvider
                  maxSnack={3}
                  dense
                  disableWindowBlurListener
                  autoHideDuration={3000}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}>
                  <SnackbarNotifier />

                  <main id="App-content" className={classes.content} ref={this.appRef}>
                    <DragDropContextProvider backend={isTouchCapable ? TouchBackend : HTML5Backend}>
                      <RouteSwitcher appRef={this.appRef} />
                    </DragDropContextProvider>
                  </main>
                </SnackbarProvider>
              </div>
            </Router>
          </ConnectedRouter>
        </ReduxProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
