import React from 'react';
import Sticky from 'react-stickynode';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/styles';
import { Box, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import TodayIcon from '@material-ui/icons/TodayOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse/Collapse';
import moment from 'moment';
import Button from '@material-ui/core/Button/Button';
import ContentPaper from '../../../components/content-paper';
import { convertToBaseUnit, translate } from '../../../i18n';
import InvoiceVendorTable from './invoice-vendor-table';
import { inventoryApi } from '../../../api/inventory';
import { parseApiError } from '../../../api/helpers';
import Icon from '../../../components/Icon';

import '../styles/inventory.scss';
import IngredientAddDialog from '../../ingredient/containers/ingredient-add-dialog';

const styles = theme => ({
  actionMenu: {
    marginLeft: theme.spacing(3),
  },

  backButton: {
    // background: '#fffff',
    padding: '8px 18px 8px 24px',
  },
  button: {
    margin: theme.spacing(0),
    marginRight: theme.spacing(2),
  },

  buttonInline: {
    margin: theme.spacing(0, 2),
  },

  buttonOption: {
    margin: theme.spacing(2, 0),
  },

  buttonIcon: {
    marginRight: theme.spacing(1),
  },

  formControl: {
    width: '100%',
  },

  buttonBox: {
    marginBottom: theme.spacing(2),
  },

  paper: {
    padding: theme.spacing(2, 2),
    marginBottom: theme.spacing(1),
    position: 'relative',
  },

  table: {
    minWidth: 600,
  },

  shortCol: {
    width: 165,
  },
  optionalCol: {
    width: 100,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  nameCol: {
    fontWeight: 500,
  },
  areaCol: {
    width: 200,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  oddRow: {
    backgroundColor: '#f5f5f5',
  },
  row: {
    border: 0,
    '&>th,>td': {
      border: 0,
    },
  },
});

@withStyles(styles)
@withWidth()
class InventoryInvoiceInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invoice: {},
      openPapers: [],
      vendorFilter: '',
    };
  }

  componentDidMount() {
    const { fetchIngredients, fetchVendors, setPageTitle, enqueueSnackbar } = this.props;
    setPageTitle(translate('Incoming invoices'));

    fetchIngredients();
    fetchVendors();

    inventoryApi.getIncomingInvoiceDates().then(response => {
      if (!response.ok) {
        enqueueSnackbar(translate('Could not fetch past invoices'));
        return true;
      }

      let dates = [moment().format('YYYY-MM-DD')];
      if (response.data && response.data.data && response.data.data.length) {
        dates = response.data.data;
      }

      this.setState(
        {
          dates,
          selectedDate: dates[0],
        },
        async () => {
          await this.loadInvoice();
        },
      );

      return true;
    });
  }

  handleSubmit = async ev => {
    if (ev) ev.preventDefault();

    const { enqueueSnackbar, history } = this.props;
    const { invoice } = this.state;

    try {
      const response = await inventoryApi.submitInvoices(Object.values(invoice));
      if (!response.ok) {
        enqueueSnackbar(parseApiError(response).message, { variant: 'error' });
        return;
      }

      enqueueSnackbar(translate('Incoming invoice data saved'));
      history.replace('/inventory/incoming-invoice');
    } catch (err) {
      enqueueSnackbar(parseApiError(err).message, { variant: 'error' });
    }
  };

  handleRowChange = (vendorId, ingredient, data) => {
    const { invoice } = this.state;

    const newData = invoice[ingredient.id] || {
      ingredient_id: ingredient.id,
      vendor_id: vendorId,
      quantity: '',
      cost_per_unit: '',
      unit_measure: ingredient.unit,
    };

    if (data && data.unit_measure) {
      newData.unit_measure = data.unit_measure;
    }

    // Convert required values
    if (data && data.quantity) {
      data.quantity = convertToBaseUnit(data.quantity, newData.unit_measure, 'stock');
    }

    this.setState({
      remoteData: null,
      invoice: {
        ...invoice,
        [ingredient.id]: {
          ...newData,
          ...data,
        },
      },
    });
  };

  togglePaperDropdown = id => event => {
    let { openPapers } = this.state;
    if (openPapers.indexOf(id) !== -1) {
      openPapers.splice(openPapers.indexOf(id), 1);
    } else {
      openPapers = [...openPapers, id];
    }

    this.setState({ openPapers: [...openPapers] });
  };

  loadInvoice = async () => {
    const { dates } = this.state;
    let { selectedDate } = this.state;

    // Load selected inventory data

    if (!selectedDate && dates && dates.length) {
      // eslint-disable-next-line prefer-destructuring
      selectedDate = dates[0];
    }

    const response = await inventoryApi.getInvoiceByDate(selectedDate);

    const invoiceRows = {};
    for (const invoiceRow of response.data.data) {
      invoiceRows[invoiceRow.ingredient_id] = { ...invoiceRow };
      // Update pricing with the default one
    }

    if (response.ok) {
      this.setState({
        remoteData: response.data.data,
        // invoice: invoiceRows,
      });
    }
  };

  shouldComponentRender = () => {
    const { ingredients } = this.props;

    return ingredients !== null;
  };

  handleDateChange = date => {
    this.setState(
      {
        selectedDate: date && date.format('YYYY-MM-DD'),
      },
      () => this.loadInvoice(),
    );
  };

  handleAddIngredient = () => {
    const { showAddDialog } = this.props;
    showAddDialog();
  };

  render() {
    const { classes, fetchIngredients, groupedByVendor, width } = this.props;

    const { dates, invoice, vendorFilter, openPapers, openDateSelector, remoteData } = this.state;
    let { selectedDate } = this.state;
    if (!selectedDate && dates && dates.length) {
      // eslint-disable-next-line prefer-destructuring
      selectedDate = dates[0];
    }

    if (!this.shouldComponentRender()) {
      return (
        <Box px={3}>
          <Typography>{translate('Loading, please wait')}</Typography>
        </Box>
      );
    }

    let editable = true;
    if (dates && dates.length > 1 && dates.findIndex(d => d === selectedDate) > 0) {
      editable = false;
    }

    return (
      <>
        <Sticky top=".MuiAppBar-positionFixed" innerZ={3} enabled={!isWidthDown('sm', width)}>
          <Box mt={1} mb={2} className="d-flex align-items-center flex-wrap">
            <Box mr={3} mb={0}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  disableFuture
                  open={!!openDateSelector}
                  margin="dense"
                  variant="dialog"
                  labelFunc={date => {
                    return date.format('ddd, D MMM YYYY');
                  }}
                  inputVariant="outlined"
                  InputProps={{
                    'aria-label': 'naked',
                    /* disableUnderline: true, */
                    style: {
                      width: 220,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <TodayIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={selectedDate}
                  onChange={this.handleDateChange}
                  onOpen={() => this.setState({ openDateSelector: true })}
                  onClose={() => this.setState({ openDateSelector: false })}
                  shouldDisableDate={day => {
                    const index = dates.findIndex(o => {
                      return day.isSame(o, 'day');
                    });

                    return index === -1;
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box mr={3} mb={0}>
              <Autocomplete
                options={groupedByVendor.map(v => ({ id: v.vendor_id, name: v.vendor }))}
                getOptionLabel={option => (option.name ? option.name : '')}
                openOnFocus
                fullWidth
                value={vendorFilter}
                onChange={(event, value) => {
                  this.setState({
                    vendorFilter: value || '',
                  });
                }}
                renderInput={params => (
                  <TextField
                    margin="dense"
                    variant="outlined"
                    {...params}
                    style={{ minWidth: 220 }}
                    placeholder={translate('Vendor')}
                  />
                )}
              />
            </Box>

            <Box mr={2} mb={0}>
              <Button
                disabled={!editable}
                color="primary"
                aria-label="save"
                className={classes.button}
                variant="contained"
                onClick={this.handleSubmit}>
                {translate('Save invoice')}
              </Button>
              {/* <Button
                color="secondary"
                aria-label="refresh"
                className={classes.margin}
                variant="text"
                onClick={fetchIngredients}>
                {translate('Refresh ingredients')}
              </Button> */}
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                onClick={this.props.showIngredientAddDialog}
                startIcon={<i className="fal fa-plus" />}>
                {translate('Add ingredient')}
              </Button>
              <IngredientAddDialog onSave={fetchIngredients} />
            </Box>
          </Box>
        </Sticky>

        {groupedByVendor.map(vendor => {
          // Skip this vendor?
          if (vendorFilter && vendorFilter.id !== vendor.id) {
            return null;
          }

          const vendorOpen = openPapers && openPapers.indexOf(vendor.id) !== -1;

          // Prepare invoice data by merging from 3 sources
          const preparedInvoices = {};
          Object.values(invoice)
            .filter(i => i.vendor_id === vendor.id)
            .map(i => {
              preparedInvoices[i.ingredient_id] = i;
              return i;
            });

          let ingredientsTable = null;
          if (vendorOpen) {
            ingredientsTable = (
              <InvoiceVendorTable
                editable={editable}
                ingredients={vendor.ingredients}
                invoice={preparedInvoices}
                invoiceData={remoteData}
                vendor={vendor}
                classes={classes}
                onChange={this.handleRowChange}
              />
            );
          }

          return (
            <ContentPaper table className={classes.paper} key={`paper-${vendor.vendor_id || vendor.vendor}`}>
              <Box style={{ display: 'flex', cursor: 'pointer' }} onClick={this.togglePaperDropdown(vendor.id)}>
                <IconButton size="small" color="secondary" className="mr-2">
                  <Icon name={!vendorOpen ? 'chevron-down' : 'chevron-up'} />
                </IconButton>
                <Typography variant="h6" color="secondary" className={clsx(classes.caption, 'flex-grow-1')}>
                  {vendor.vendor}
                </Typography>
              </Box>

              <Collapse in={vendorOpen}>{ingredientsTable}</Collapse>
            </ContentPaper>
          );
        })}
      </>
    );
  }
}

export default InventoryInvoiceInput;
