import React from 'react';
import { connect } from 'react-redux';
import Users from '../components/users';
import { fetchUsers, hideUserAddDialog, setTableFilter, showUserAddDialog } from '../actions';
import { setPageTitle } from '../../ui/actions';
import { getFilteredUsers } from '../reducers';
import { enqueueSnackbar } from '../../snackbar/actions';

function mapStateToProps(state) {
  const users = getFilteredUsers(state);

  return {
    dataById: users || {},
    data: state.users.entitiesIds || [],
    filters: state.users.filters,
    selected: state.users.selected,
    loading: state.users.loading,
    initialLoaded: state.users.initialLoaded,
    fetchError: state.users.fetchError,
    loadingIds: state.users.loadingIds,
  };
}

const mapDispatchToProps = {
  fetchData: fetchUsers,
  setPageTitle,
  setTableFilter,
  showAddDialog: showUserAddDialog,
  hideAddDialog: hideUserAddDialog,
  enqueueSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
