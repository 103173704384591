import React from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import green from '@material-ui/core/colors/amber';
import { translate } from '../../../i18n';
import Icon from '../../../components/Icon';

const ReadyButton = withStyles(theme => ({
  root: {
    color: '#fff',
    backgroundColor: green[800],
    '&:hover': {
      backgroundColor: green[900],
    },
  },
}))(Button);

class ActionButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleReady = () => {
    const { handleReady, row } = this.props;
    handleReady(row.id_string);
  };

  handleComplete = () => {
    const { handleComplete, row } = this.props;
    handleComplete(row.id_string);
  };

  setDuration = min => {
    const { handleConfirmation, row } = this.props;

    this.handleClose();

    handleConfirmation(row.id_string, min);
  };

  render() {
    const { anchorEl } = this.state;
    const { row } = this.props;

    // Time options
    if (row.order_status === 'new') {
      const times = [15, 30, 45, 60, 75, 90];

      return (
        <>
          <Button variant="contained" color="primary" onClick={this.handleClick}>
            {translate('Confirm')}
          </Button>

          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={this.handleClose}>
            {times.map(o => (
              <MenuItem key={`time-${o}`} onClick={() => this.setDuration(o)}>{`${o} min`}</MenuItem>
            ))}
          </Menu>
        </>
      );
    }

    if (row.order_status === 'processing') {
      return (
        <ReadyButton variant="contained" color="primary" onClick={this.handleReady} startIcon={<Icon name="check" />}>
          {translate('Ready')}
        </ReadyButton>
      );
    }

    if (row.order_status === 'ready') {
      return (
        <Button variant="outlined" color="secondary" onClick={this.handleComplete}>
          {translate('Complete')}
        </Button>
      );
    }

    return null;
  }
}

export default ActionButton;
