import React, { Component } from 'react';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import TextField from '@material-ui/core/TextField';
import { FormControl, FormLabel, Radio, RadioGroup, FormControlLabel, Switch } from '@material-ui/core';
import { Prompt, withRouter } from 'react-router';
import ContentPaper from '../../../components/content-paper';
import { translate } from '../../../i18n';
import { discountApi } from '../../../api/discount';
import FormActions from '../../../components/form-actions';
import { FormContainer } from '../../../components/forms/container';
import { FormRow } from '../../../components/forms/row';
import PageLoadingText from '../../../components/PageLoadingText';

import '../styles/discounts.scss';
import { parseApiError } from '../../../api/helpers';
import NumericInputFormat from '../../../components/forms/numeric';
import CurrencyField from '../../../components/forms/currency-field';

@withSnackbar
@withRouter
class DiscountForm extends Component {
  constructor(props) {
    super(props);

    // Set page title
    const { id, setPageTitle } = this.props;
    let pageTitle = translate('Add discount');
    if (id) {
      pageTitle = translate('Edit discount');
    }
    setPageTitle(pageTitle);

    this.state = {
      id: props.id ? props.id : null,
      data: !_.isEmpty(props.data) ? props.data : { type: 'FIXED' },
      newData: {},
    };
  }

  componentDidMount() {
    const { id, fetchDiscountById } = this.props;
    if (id) {
      fetchDiscountById(id);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { id, data } = this.state;

    // Has the data been loaded and passed over via props?
    if (id && !data.id && nextProps.data) {
      this.setState({
        id: nextProps.id,
        data: nextProps.data,
      });
    }
  }

  handleSubmit = async ev => {
    if (ev) {
      ev.preventDefault();
    }

    const { id, newData } = this.state;
    const { enqueueSnackbar } = this.props;

    try {
      const response = !id ? await discountApi.add(newData) : await discountApi.update(id, newData);

      if (!response.ok) {
        enqueueSnackbar(parseApiError(response).message);
        return;
      }

      this.setState({ hasUnsavedChanges: false });

      enqueueSnackbar('Discount saved');
      this.handleAfterSave(response);
    } catch (e) {
      enqueueSnackbar('Could not save the discount!', { variant: 'error' });
    }

    return false;
  };

  handleChange = (name, value) => event => {
    const { data, newData } = this.state;

    value = typeof value !== 'undefined' ? value : event.target.value;
    this.setState({
      hasUnsavedChanges: true,
      data: { ...data, [name]: value },
      newData: { ...newData, [name]: value },
    });
  };

  handleDelete = async () => {
    const { id } = this.state;
    const { enqueueSnackbar, history } = this.props;

    // Reset state
    this.setState({ hasUnsavedChanges: false });

    // Delete AJAX
    try {
      await discountApi.deleteById(id);

      enqueueSnackbar('Discount deleted');
      history.push('/discount');
    } catch (e) {
      enqueueSnackbar('Could not delete the discount', { options: { variant: 'error' } });
    }
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push('/discount');
  };

  handleAfterSave = () => {
    const { history } = this.props;
    history.push('/discount');
  };

  canSubmitForm = () => {
    const { data } = this.state;
    const { name, type } = data;

    return name && type;
  };

  render() {
    const { id } = this.props;
    const { data, hasUnsavedChanges } = this.state;

    if (id && (!data || !data.id)) {
      return <PageLoadingText />;
    }

    const name = data && data.name ? data.name : '';

    return (
      <form onSubmit={this.handleSubmit} method="POST">
        <Prompt
          when={!!hasUnsavedChanges}
          message={translate('You have unsaved changes. Are you sure you want to leave?')}
        />
        <ContentPaper short className="FormBoxPaper">
          <FormContainer>
            <FormRow>
              <TextField
                required
                fullWidth
                autoComplete="off"
                name="name"
                label={translate('Name')}
                value={name || ''}
                error={hasUnsavedChanges && !name}
                helperText={hasUnsavedChanges && !name ? translate('This field is required') : ''}
                onChange={this.handleChange('name')}
                InputProps={{
                  classes: {
                    input: 'LargeTitleInput',
                  },
                }}
              />
            </FormRow>

            <FormRow>
              <FormControl component="fieldset">
                <FormLabel component="legend" required>
                  {translate('Type')}
                </FormLabel>
                <RadioGroup
                  row
                  required
                  aria-label="discount type"
                  name="type"
                  value={data.type || ''}
                  onChange={this.handleChange('type')}>
                  <FormControlLabel value="FIXED" control={<Radio required />} label={translate('Fixed amount')} />
                  <FormControlLabel value="PERCENT" control={<Radio required />} label={translate('Percent')} />
                </RadioGroup>
              </FormControl>
            </FormRow>

            <FormRow>
              {data.type === 'PERCENT' ? (
                <TextField
                  fullWidth
                  required
                  label={translate('Amount')}
                  InputProps={{
                    inputComponent: NumericInputFormat,
                  }}
                  name="value"
                  value={data.value || ''}
                  onChange={this.handleChange('value')}
                />
              ) : (
                <CurrencyField
                  fullWidth
                  required
                  label={translate('Amount')}
                  name="value"
                  value={data.value || ''}
                  onChange={this.handleChange('value')}
                />
              )}
            </FormRow>

            <FormRow>
              <div className="d-table">
                <div className="d-table-cell align-middle width-75">{translate('Restricted access')}</div>
                <div className="d-table-cell align-middle text-right width-25">
                  <Switch
                    color="primary"
                    checked={data.restricted_access}
                    onChange={(event, value) => this.handleChange('restricted_access', value)()}
                    name="restricted_access"
                  />
                </div>
              </div>
            </FormRow>
          </FormContainer>
        </ContentPaper>

        <FormActions
          short
          onDelete={this.handleDelete}
          onCancel={this.handleCancel}
          onSubmit={() => {}}
          disableSubmit={!this.canSubmitForm()}
        />
      </form>
    );
  }
}

export default DiscountForm;
