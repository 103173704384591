import { makeActionCreator } from '../../../store/actions';

export const UI_PROGRESS_BAR_OPEN = '@ui / PROGRESS BAR OPEN';
export const UI_PROGRESS_BAR_CLOSE = '@ui / PROGRESS BAR CLOSE';
export const UI_SET_PAGE_TITLE = '@ui / SET PAGE TITLE';
export const UI_OPEN_DRAWER = '@ui / DRAWER OPEN';
export const UI_CLOSE_DRAWER = '@ui / DRAWER CLOSE';
export const UI_TOGGLE_DRAWER = '@ui / DRAWER TOGGLE';

export const showProgressBar = makeActionCreator(UI_PROGRESS_BAR_OPEN, 'progressBarStatus');
export const hideProgressBar = makeActionCreator(UI_PROGRESS_BAR_CLOSE, 'progressBarStatus');
export const setPageTitle = makeActionCreator(UI_SET_PAGE_TITLE, 'title');

export const openDrawer = makeActionCreator(UI_OPEN_DRAWER);
export const closeDrawer = makeActionCreator(UI_CLOSE_DRAWER);
export const toggleDrawer = makeActionCreator(UI_TOGGLE_DRAWER);
