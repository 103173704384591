import React from 'react';
import { withSnackbar } from 'notistack';
import { Link, withStyles } from '@material-ui/core';
import { size, startCase } from 'lodash';
import moment from 'moment';
import ActionMenu from './row-actions';
import PageLoadingText from '../../../components/PageLoadingText';
import { translate } from '../../../i18n';
import { auditApi } from '../../../api/audit';

import '../styles/audit.scss';
import EnhancedTable from '../../../components/enhanced-table/EnhancedTable';
import ContentPaper from '../../../components/content-paper';
import styles from '../styles';
import { date, time } from '../../../i18n/time';

@withSnackbar
@withStyles(styles)
class AuditEvent extends React.Component {
  constructor(props) {
    super(props);
    const { fetchData, setPageTitle } = this.props;

    this.typeName = 'audit';

    setPageTitle(translate('Audit'));
    fetchData();
  }

  handleMassDelete = ids => ids.map(id => this.handleDelete(id));

  handleDelete = async id => {
    const { enqueueSnackbar, fetchData } = this.props;

    // Delete AJAX
    try {
      await auditApi.deleteById(id);

      // All good
      setTimeout(() => fetchData(), 100);
    } catch (err) {
      enqueueSnackbar(translate(`Could not delete the ${this.typeName}`), { variant: 'error' });
    }
  };

  getTableColumns = () => {
    const { classes } = this.props;

    return [
      {
        id: 'event_name',
        accessor: 'event_name',
        sortable: true,
        component: 'td',
        label: translate('Event name'),
        renderValue: row => <strong>{row.event_name}</strong>,
      },
      {
        id: 'event_type',
        accessor: 'event_type',
        sortable: true,
        component: 'td',
        label: translate('Event type'),
      },
      {
        id: 'object_type',
        sortable: true,
        component: 'td',
        label: translate('Object'),
        renderValue: row => (row.object_type || '').toUpperCase(),
      },
      {
        id: 'object_identifier',
        sortable: true,
        component: 'td',
        label: translate('Object identifier'),
        renderValue: row => {
          if (!row.object_pk) {
            return row.object_identifier;
          }

          return (
            <Link href={`/${row.object_type}/edit/${row.object_pk}`} target="_blank">
              {row.object_identifier}
            </Link>
          );
        },
      },
      {
        id: 'category',
        sortable: true,
        component: 'td',
        label: translate('Category'),
      },
      {
        id: 'user_name',
        sortable: true,
        component: 'td',
        label: translate('User / Employee'),
      },
      {
        id: 'created_at',
        sortable: true,
        component: 'td',
        label: translate('Event date'),
        renderValue: row => (
          <div style={{}}>
            {time(row.created_at)}
            <br />
            {date(row.created_at)}
          </div>
        ),
      },
      /* {
        id: 'event_data',
        accessor: 'event_data',
        label: '',
        renderValue: row => {
          if (!row || !row.event_data) {
            return null;
          }

          const hasData = size(row.event_data);
          const eventDataArr = [];

          if (hasData) {
            for (const field in row.event_data) {
              if (row.event_data.hasOwnProperty(field)) {
                eventDataArr.push({
                  column: field,
                  value: row.event_data[field],
                });
              }
            }
          }

          return (
            <>
              {eventDataArr.map(data => {
                // Prepare column
                const column = data && data.column;
                const columnNice = startCase(column);

                // Format the data, if needed
                let value = '';

                if (data && data.value) {
                  value = JSON.stringify(data.value)

                  // Unix timestamp?
                  if (value.match(/^[0-9]{10}$/)) {
                    value = moment.unix(value).format('HH:mm:ss MMM D, YYYY');
                  }
                }

                return (
                  <div key={`${row.id}-cmdae-${column}`}>
                    <span className="marginR half text-weight-500 d-inline-block">{columnNice}:</span>
                    <span>{value}</span>
                  </div>
                );
              })}
            </>
          );
        },
      }, */
    ];
  };

  getTableFilters = () => {
    const { categoriesFilter, eventsFilter, usersFilter, objectsFilter, setTableFilter, filters } = this.props;

    return [
      {
        label: translate('Event type'),
        type: 'select',
        value: filters.event_name,
        onChange: value => setTableFilter('event_name', value),
        options: [
          {
            value: '',
            label: translate('All'),
          },
          ...eventsFilter,
        ],
      },
      {
        label: translate('Object'),
        type: 'select',
        value: filters.object_type,
        onChange: value => setTableFilter('object_type', value),
        options: [
          {
            value: '',
            label: translate('All'),
          },
          ...objectsFilter,
        ],
      },
      {
        label: translate('User'),
        type: 'select',
        value: filters.user_name,
        onChange: value => setTableFilter('user_name', value),
        options: [
          {
            value: '',
            label: translate('All'),
          },
          ...usersFilter,
        ],
      },
      {
        label: translate('Category'),
        type: 'select',
        value: filters.category,
        onChange: value => setTableFilter('category', value),
        options: [
          {
            value: '',
            label: translate('All'),
          },
          ...categoriesFilter,
        ],
      },
    ];
  };

  shouldComponentRender = () => {
    const { initialLoaded } = this.props;

    return initialLoaded;
  };

  getLoadingSkeleton = () => {
    return <PageLoadingText />;
  };

  render() {
    const { classes, dataById } = this.props;
    const data = Object.values(dataById);

    if (!this.shouldComponentRender()) {
      return <div className={classes.root}>{this.getLoadingSkeleton()}</div>;
    }

    return (
      <div className={classes.root}>
        <ContentPaper table className={classes.paper}>
          <EnhancedTable
            noCheckbox
            size="small"
            filters={this.getTableFilters()}
            columns={this.getTableColumns()}
            data={data}
            rowCount={data.length}
            order="desc"
            orderBy="created_at"
          />
        </ContentPaper>
      </div>
    );
  }
}

export default AuditEvent;
