import { makeActionCreator } from '../../../store/actions';

export const RECIPES_FETCH_REQUEST = '@recipes / FETCH REQUEST';
export const RECIPES_FETCH_SUCCESS = '@recipes / FETCH SUCCESS';
export const RECIPES_FETCH_ERROR = '@recipes / FETCH ERROR';
export const RECIPES_SHOW_ADD_DIALOG = '@recipes / SHOW ADD DIALOG';
export const RECIPES_HIDE_ADD_DIALOG = '@recipes / HIDE ADD DIALOG';
export const RECIPES_SET_TABLE_FILTER = '@recipes / SET TABLE FILTER';
export const RECIPES_FETCH_RECIPE_START = '@recipes / FETCH RECIPE START';
export const RECIPES_FETCH_RECIPE_SUCCESS = '@recipes / FETCH RECIPE SUCCESS';
export const RECIPES_CLONE_RECIPE = '@recipes / CLONE';

export const fetchRecipes = makeActionCreator(RECIPES_FETCH_REQUEST);
export const fetchRecipesError = makeActionCreator(RECIPES_FETCH_ERROR, 'error');
export const fetchRecipesSuccess = makeActionCreator(RECIPES_FETCH_SUCCESS, 'data');
export const showRecipeAddDialog = makeActionCreator(RECIPES_SHOW_ADD_DIALOG);
export const hideRecipeAddDialog = makeActionCreator(RECIPES_HIDE_ADD_DIALOG);

export const fetchRecipeById = makeActionCreator(RECIPES_FETCH_RECIPE_START, 'id');
export const fetchRecipeByIdSuccess = makeActionCreator(RECIPES_FETCH_RECIPE_SUCCESS, 'id', 'data');
export const cloneRecipeAction = makeActionCreator(RECIPES_CLONE_RECIPE, 'id', 'data');

// Table filters
export const setTableFilter = makeActionCreator(RECIPES_SET_TABLE_FILTER, 'name', 'value');
