

import UserStore from "../lib/stores/UserStore";

export default function PrivateComponent ({component: Component, children, ...rest}) {

    return UserStore.isLoggedIn()
        ? children
        : null;

}
