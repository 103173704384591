import React from 'react';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import AreaService from '../../services/AreaService';
import LocationService from '../../services/LocationService';

const styles = theme => ({
  root: {
    /* margin: 'auto', */
    margin: theme.spacing(0, 0, 5, 0),
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    background: '#f0f0f0',
  },
  list: {
    /* width: 100%, */
    height: '50vh',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
});

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

@withStyles(styles)
class LocationIngredientsSelection extends React.Component {
  state = {
    checked: this.props.location.ingredients ? this.props.location.ingredients : [],
  };

  numberOfChecked = items => intersection(this.state.checked, items).length;

  handleToggle = value => async () => {
    /* const {
            checked,
        } = this.state;

        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({checked: newChecked}) */

    const { location } = this.props;
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);

      try {
        await LocationService.addIngredient(value, location.id);
      } catch (e) {
        this.props.enqueueSnackbar('An error occurred. The ingredient was not added to the area.', {
          variant: 'danger',
        });
      }
    } else {
      newChecked.splice(currentIndex, 1);

      try {
        await LocationService.removeIngredient(value, location.id);
      } catch (e) {
        this.props.enqueueSnackbar('An error occurred. The ingredient was not removed from the area.', {
          variant: 'danger',
        });
      }
    }

    this.setState(
      {
        checked: newChecked,
      },
      () => {},
    );
  };

  render() {
    const { location, classes, ingredients } = this.props;

    const { checked } = this.state;

    return (
      <Card elevation={1} className={classes.root}>
        <CardHeader
          className={classes.cardHeader}
          title={location.name}
          titleTypographyProps={{
            variant: 'subtitle1',
          }}
          subheader={`${this.numberOfChecked(ingredients)}/${ingredients.length} selected`}
          subheaderTypographyProps={{
            variant: 'body2',
          }}
        />
        <Divider />
        <List className={classes.list} dense component="div" role="list">
          {ingredients.map(value => {
            const labelId = `transfer-list-all-item-${value}-label`;

            return (
              <ListItem key={value} role="listitem" button onClick={this.handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`List item ${value}`} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );
  }
}

export default LocationIngredientsSelection;
