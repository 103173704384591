import { call, put } from 'redux-saga/effects';

import { fetchDiscountsSuccess, fetchDiscountsError, fetchDiscountByIdSuccess } from '../actions';
import { parseApiError } from '../../../api/helpers';
import { hideProgressBar, showProgressBar } from '../../ui/actions';
import { discountApi } from '../../../api/discount';
import { enqueueSnackbar } from '../../snackbar/actions';

export function* fetchDiscountsSaga() {
  try {
    yield put(showProgressBar());
    const response = yield call(discountApi.fetch);
    yield put(hideProgressBar());

    if (!response.ok) {
      yield put(fetchDiscountsError(parseApiError(response).message));
      return;
    }

    yield put(fetchDiscountsSuccess(response.data.data.results || []));
  } catch (err) {
    yield put(fetchDiscountsError(parseApiError(err).message));
  }
}

export function* fetchDiscountByIdSaga(action) {
  try {
    const response = yield call(discountApi.getById, action.payload.id);
    if (!response.ok) {
      return;
    }

    yield put(fetchDiscountByIdSuccess(action.payload.id, response.data.data || null));
  } catch (err) {
    yield put(enqueueSnackbar(parseApiError(err).message, { options: { variant: 'error' } }));
  }
}
