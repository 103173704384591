import React from 'react';
import InvoiceService from '../../services/InvoiceService';

import PageLoadingText from '../../components/PageLoadingText';
import InvoiceForm from './InvoiceForm';
import { setPageTitle as setPageTitleAction } from '../ui/actions';
import { enqueueSnackbar as enqueueSnackbarAction } from '../snackbar/actions';
import { connect } from 'react-redux';
import { translate } from '../../i18n';

class InvoiceAddEdit extends React.Component {
  constructor(props) {
    super(props);

    const { id, setPageTitle } = props;
    let pageTitle = translate('Add invoice');
    if (id) {
      pageTitle = translate('Edit invoice');
    }

    setPageTitle(pageTitle);

    this.state = {
      id,
      data: {},
    };
  }

  load = () => {
    const { id } = this.state;

    if (!id) {
      return null;
    }

    InvoiceService.fetch(id).then(res => {
      if (res && res.body && res.body.data) {

        this.setState({
          data: res.body.data,
        });
      }
    });
  };

  componentDidMount() {
    this.load();
  }

  render() {
    const { id, data } = this.state;
    const { enqueueSnackbar, history } = this.props;

    if (id && !data) {
      return <PageLoadingText />;
    }

    return (
      <InvoiceForm
        data={data}
        id={data.id}
        onCancel={() => history.push('/invoice')}
        onSave={() => history.push('/invoice')}
        onNotification={enqueueSnackbar}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {

  };
};

const mapDispatchToProps = {
  setPageTitle: setPageTitleAction,
  enqueueSnackbar: enqueueSnackbarAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceAddEdit);

