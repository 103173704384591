import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import {
  Grid,
  InputAdornment,
  TextField,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Button,
  Box,
  Typography,
} from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { withSnackbar } from 'notistack';
import InventoryService from '../../services/InventoryService';
import AreaService from '../../services/AreaService';
import ContentPaper from '../../components/content-paper';
import { convertFromBaseUnit } from '../../i18n';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(15),
    overflowX: 'auto',
  },
  actionButton: {},
  backButton: {
    // background: '#ffffff',
    padding: '8px 18px 8px 24px',
  },
  currentRow: {
    background: '#ffffff',
  },
  currentRowName: {
    fontSize: '1rem',
    fontWeight: 700,
  },
  fadedRow: {
    background: '#f7f7f7',
  },
  input: {
    textAlign: 'right',
  },
  headingContainer: {
    padding: theme.spacing(0, 3),
  },
  table: {
    minWidth: 320,
  },

  shortCol: {
    width: 100,
  },
  stockCol: {
    width: 130,
  },
  optionalCol: {
    width: 100,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  vendorCol: {
    width: 150,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  buttonBox: {
    /* textAlign: 'right' */
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 3),
  },
});

@withStyles(styles)
@withSnackbar
class InventoryView extends React.Component {
  constructor(props) {
    super(props);

    this.inputRefs = [];

    this.state = {
      area: null,
      areaId: null,
      editing: this.props.editing ? this.props.editing : false,
      currentRow: null,
      inventory: {
        ingredients: null,
      },
      inventoryId: null,
    };
  }

  componentDidMount() {
    let { match, inventoryId } = this.props;

    const areaId = match && match.params ? match.params.areaId : null;
    inventoryId = match && match.params ? match.params.inventoryId : inventoryId || null;
    //

    // Update state accordingly

    if (inventoryId) {
      // Pull inventory data and populate area and ingredients
      // Fetch area ingredients to prepare new inventory

      this.getInventory(inventoryId);
    } else {
      // Fetch area ingredients to prepare new inventory
      this.setState(
        {
          areaId,
          editing: !!areaId,
          inventory: {
            area_id: null,
            date: null,
            ingredients: null,
          },
        },
        () => this.getAreaIngredients(areaId),
      );
    }
  }

  getInventory(inventoryId) {
    InventoryService.fetch(inventoryId).then(res => {
      if (res && res.body && res.body.data) {
        const inventory = res.body.data;

        this.setState({
          inventoryId: inventory.id,
          /* editing: false, */
          area: inventory.area,
          inventory,
        });
      }
    });
  }

  getAreaIngredients(areaId) {
    AreaService.fetchIngredients(areaId).then(res => {
      if (res && res.body && res.body.area) {
        const ingredients = [];
        res.body.data.map((ingredient, i) => {
          ingredients.push({
            id: ingredient.id,
            name: ingredient.name,
            vendor: ingredient.vendor ? ingredient.vendor : {},
            unit: ingredient.unit,
            par_number: ingredient.par_number,
            stock_amount: '',
            order_amount: '',
          });
        });

        const { inventory } = this.state;
        inventory.ingredients = ingredients;

        inventory.area = res.body.area;
        inventory.area_id = inventory.area.id;

        this.setState({
          area: inventory.area,
          inventory,
        });
      }
    });
  }

  handleSave(ev) {
    ev && ev.preventDefault();

    const { inventoryId, inventory } = this.state;

    if (!inventoryId) {
      InventoryService.add(inventory).then(
        res => {
          const { data } = res.body;
          const id = data && data.id;

          if (id) {
            this.props.enqueueSnackbar('The inventory was saved', { variant: 'success', autoHideDuration: 5000 });

            const { history } = this.props;
            // history.push('/inventory/report/' + id)
            history.push('/inventory');
          }
        },
        err => {
          let message = 'An error occurred while saving the inventory. Please try again.';
          if (err.response && err.response.body && err.response.body.errors) {
            message = err.response.body.errors.join(' ');
          }

          this.props.enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });

          this.setState({
            error: message,
          });
        },
      );
    } else {
      InventoryService.update(inventoryId, inventory).then(
        res => {
          const { data } = res.body;
          const id = data && data.id;

          if (id) {
            this.props.enqueueSnackbar('The inventory was saved', { variant: 'success', autoHideDuration: 5000 });

            const { history } = this.props;
            // history.push('/inventory/report/' + id)
            history.push('/inventory');
          }
        },
        err => {
          let message = 'An error occurred while saving the inventory. Please try again.';
          if (err.response && err.response.body && err.response.body.errors) {
            message = err.response.body.errors.join(' ');
          }

          this.props.enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });

          this.setState({
            error: message,
          });
        },
      );
    }

    return false;
  }

  handleStockChange(rowIndex, event) {
    const { inventory } = this.state;
    inventory.ingredients[rowIndex].stock_amount = event.target.value;

    // Also calculate the required order amount based on par number
    inventory.ingredients[rowIndex].order_amount = null;
    if (inventory.ingredients[rowIndex].par_number) {
      let order = inventory.ingredients[rowIndex].par_number - inventory.ingredients[rowIndex].stock_amount;
      if (!order || order <= 0) {
        order = '';
      } else {
        order = _.round(order, 2);
      }

      // For some measurement units, we round up to integer
      if (inventory.ingredients[rowIndex].unit == 'pz') {
        //order = _.ceil(order);
      }

      inventory.ingredients[rowIndex].order_amount = order;
    }

    this.setState({ inventory });
  }

  nextIngredient(row, preventFocus) {
    this.handleFocus(row);
    this.inputRefs[row] && this.inputRefs[row].current && this.inputRefs[row].current.focus({ preventScroll: true });
  }

  handleFocus(row) {
    const { top } = this.inputRefs[row].current.getBoundingClientRect();
    // this.props.appRef.current.scrollTo(0, top - 121);
    // this.props.appRef.current.scrollTo(0, 120 + (row * 61));
    window.scrollTo(0, 120 + row * 61);

    this.setState({ currentRow: row });
  }

  render() {
    const { currentRow, editing, inventory, inventoryId } = this.state;
    const { classes } = this.props;

    if (!inventory) {
      return null;
    }

    const { area } = inventory;
    let inventoryDate = inventory.date;
    inventoryDate = new Date(inventoryDate);
    inventoryDate = inventoryDate.toLocaleDateString('es-MX');

    return (
      <>
        <Box p={0} className={classes.buttonBox}>
          <Grid container>
            <Grid item xs={3}>
              <Button
                size="large"
                to="/inventory"
                component={Link}
                variant="text"
                color="default"
                className={classes.backButton}>
                <ArrowBackIosIcon />
              </Button>
            </Grid>
            {!editing ? null : (
              <Grid item xs={9} style={{ textAlign: 'right' }}>
                <Button
                  size="large"
                  onClick={this.handleSave.bind(this)}
                  variant="contained"
                  color="primary"
                  className={classes.actionButton}>
                  Guardar inventario
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>

        <div className={classes.headingContainer}>
          {inventoryId ? (
            <>
              <Typography variant="h6">
                {editing ? 'Editar inventario' : 'Informe de pedido'}
                <Typography component="span" variant="subtitle1" style={{ marginLeft: '24px' }}>
                  {inventoryDate}
                </Typography>
              </Typography>
              {!area ? null : <Typography variant="subtitle1">{area.name}</Typography>}
            </>
          ) : (
            <>
              <Typography variant="h6">Inventario nuevo</Typography>
              {!area ? null : <Typography variant="subtitle1">{area.name}</Typography>}
            </>
          )}
        </div>

        <ContentPaper className={classes.root} elevation={0}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Ingredient name</TableCell>
                <TableCell className={classes.vendorCol}>Vendor</TableCell>
                <TableCell align="center" className={classes.optionalCol}>
                  Unit
                </TableCell>
                <TableCell align="center" className={classes.optionalCol}>
                  Par
                </TableCell>
                <TableCell align="right" className={classes.stockCol}>
                  Stock
                </TableCell>
                {editing ? null : (
                  <TableCell align="right" className={classes.shortCol}>
                    Order
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {inventory.ingredients &&
                inventory.ingredients.map((row, idx) => {
                  const isCurrentRow = currentRow === idx;

                  this.inputRefs[idx] = React.createRef();

                  return (
                    <TableRow
                      key={row.id}
                      className={classNames(
                        isCurrentRow && classes.currentRow,
                        currentRow !== null && !isCurrentRow && classes.fadedRow,
                      )}>
                      <TableCell component="th" scope="row" className={isCurrentRow ? classes.currentRowName : null}>
                        {row.name}
                      </TableCell>

                      <TableCell className={classes.vendorCol}>
                        {row.vendor && row.vendor.name ? row.vendor.name : '-'}
                      </TableCell>

                      <TableCell align="center" className={classes.optionalCol}>
                        {row.unit}
                      </TableCell>
                      <TableCell align="center" className={classes.optionalCol}>
                        {row.par_number ? convertFromBaseUnit(row.par_number, row.unit, 'numberInput') : 0}
                      </TableCell>
                      <TableCell align="right" className={classes.stockCol}>
                        {!editing ? (
                          <>{row.stock_amount}</>
                        ) : (
                          <>
                            <TextField
                              type="number"
                              value={row.stock_amount}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">{row.unit ? row.unit : '-'}</InputAdornment>
                                ),
                              }}
                              inputProps={{
                                className: classes.input,
                                tabIndex: idx + 1,
                              }}
                              inputRef={this.inputRefs[idx]}
                              autoFocus={isCurrentRow}
                              onChange={ev => this.handleStockChange(idx, ev)}
                              onFocus={ev => {
                                this.handleFocus(idx);
                              }}
                            />

                            {!isCurrentRow ? null : idx < inventory.ingredients.length - 1 ? (
                              <Button
                                variant="outlined"
                                size="small"
                                style={{ marginTop: '8px' }}
                                color="primary"
                                onClick={() => this.nextIngredient(idx + 1)}>
                                Continuar
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                style={{ marginTop: '8px' }}
                                color="primary"
                                onClick={this.handleSave.bind(this)}>
                                Guardar
                              </Button>
                            )}
                          </>
                        )}
                      </TableCell>

                      {editing ? null : (
                        <TableCell align="right" className={classes.shortCol}>
                          {row.order_amount}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </ContentPaper>
      </>
    );
  }
}

export default InventoryView;
