import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { withSnackbar } from 'notistack';
import classnames from 'classnames';
import _ from 'lodash';

import {
  Box,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InventoryService from '../../services/InventoryService';
import ContentPaper from '../../components/content-paper';
import { convertFromBaseUnit } from '../../i18n';

const styles = theme => ({
  root: {
    width: '100%',
  },

  actionMenu: {
    marginLeft: theme.spacing(3),
  },

  areaHeading: {
    border: 0,
    padding: theme.spacing(1, 1, 1),
  },

  areaName: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    fontSize: '1.25rem',
  },

  button: {
    marginRight: theme.spacing(1),
  },

  caption: {
    marginBottom: theme.spacing(2),
    fontWeight: 400,
  },

  locationName: {
    border: 0,
    fontWeight: 500,
    padding: theme.spacing(1, 1, 2),
  },

  paper: {
    padding: theme.spacing(2, 3),
    marginBottom: theme.spacing(4),
    position: 'relative',
  },

  nameCol: {
    fontWeight: 500,
    paddingLeft: theme.spacing(4),
  },

  oddRow: {
    backgroundColor: '#f5f5f5',
  },
  row: {
    border: 0,
    '&>th,>td': {
      border: 0,
    },
  },

  shortCol: {
    width: 130,
  },
  stockCol: {
    width: 130,
  },
  optionalCol: {
    width: 100,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  vendorCol: {
    width: 150,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
});

@withStyles(styles)
class ActionMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.actionMenu}>
        <IconButton aria-label="More" aria-haspopup="true" onClick={this.handleClick} style={{ padding: '5px' }}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          <MenuItem
            onClick={() => {
              this.handleClose();
              this.props.onEdit();
            }}>
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.handleClose();
              this.props.onRemove();
            }}>
            Remove
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

@withSnackbar
@withStyles(styles)
class InventoryTable extends Component {
  state = {
    search: '',

    data: [],
  };

  componentDidMount() {}

  componentWillReceiveProps(nextProps, nextContext) {}

  render() {
    const { classes, history, date } = this.props;

    let { ingredients } = this.props;

    let prevArea = null;
    const prevLocation = null;

    // We will group the ingredients in the report by area ID
    ingredients = _.reduce(
      _.cloneDeep(ingredients),
      (result, value, key) => {
        if (!result[value.ingredient_id]) {
          result[value.ingredient_id] = value;

          result[value.ingredient_id].stock_amount = value.stock_amount;
        } else {
          result[value.ingredient_id].stock_amount += value.stock_amount;
        }

        return result;
      },
      {},
    );

    ingredients = _.toArray(ingredients);

    // Sort ingredients by area name
    ingredients = _.sortBy(ingredients, 'area.name');

    return (
      <>
        <ContentPaper table className={classes.paper} elevation={2}>
          <Table size="small" className={classes.table}>
            {/* <TableHead>
                                <TableRow>
                                    <TableCell style={{border: 0,}}>{''}</TableCell>
                                    <TableCell className={classes.vendorCol}>Vendor</TableCell>
                                    <TableCell align="center" className={classes.optionalCol}>Unit</TableCell>
                                    <TableCell align="center" className={classes.optionalCol}>Par</TableCell>
                                    <TableCell align="right" className={classes.stockCol}>Stock</TableCell>
                                </TableRow>
                            </TableHead> */}
            <TableBody>
              {ingredients &&
                ingredients.map((row, idx) => {
                  // Is it a different Area?
                  let headingArea = null;
                  if (prevArea !== row.area.id) {
                    prevArea = row.area.id;

                    headingArea = (
                      <>
                        <TableRow key={`area-${row.area.id}`}>
                          <TableCell colSpan={5} className={classes.areaHeading}>
                            <Box style={{ display: 'flex' }} className={[idx !== 0 && 'mt-3'].join(' ')}>
                              <Typography component="div" className={classes.areaName}>
                                {row.area.name}
                              </Typography>
                              <ActionMenu
                                onEdit={() => {
                                  history && history.push(`/inventory/edit/${date}/${row.area.id}`);
                                }}
                                onRemove={() => {
                                  InventoryService.delete(`${date}/area/${row.area.id}`).then(res => {
                                    this.props.loadInventory();
                                  });
                                }}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Ingredient name</TableCell>
                          <TableCell variant="head" className={classes.vendorCol}>
                            Vendor
                          </TableCell>
                          <TableCell variant="head" align="center" className={classes.optionalCol}>
                            Unit
                          </TableCell>
                          <TableCell variant="head" align="center" className={classes.optionalCol}>
                            Par
                          </TableCell>
                          <TableCell variant="head" align="right" className={classes.stockCol}>
                            Stock
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  }

                  const headingRow = null;
                  const ingredient = row.ingredient ? row.ingredient : {};
                  let amount = '-';
                  if (row.stock_amount !== null && ingredient.unit) {
                    amount = convertFromBaseUnit(row.stock_amount, ingredient.unit, 'stock');
                  }

                  return (
                    <React.Fragment key={`inventory-${row.id}`}>
                      {headingArea}
                      {headingRow}

                      <TableRow className={classnames(classes.row, idx % 2 && classes.oddRow)}>
                        <TableCell component="th" scope="row" className={classes.nameCol}>
                          {ingredient.name}
                        </TableCell>

                        <TableCell className={classes.vendorCol}>
                          {ingredient.vendor && ingredient.vendor.name ? ingredient.vendor.name : '-'}
                        </TableCell>

                        <TableCell align="center" className={classes.optionalCol}>
                          {ingredient.unit}
                        </TableCell>
                        <TableCell align="center" className={classes.optionalCol}>
                          {ingredient.par_number ? convertFromBaseUnit(ingredient.par_number, ingredient.unit, 'stock') : 0}
                        </TableCell>
                        <TableCell align="right" className={classes.stockCol}>
                          {amount}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </ContentPaper>
      </>
    );
  }
}

export default InventoryTable;
