import ApiClient from "../components/ApiClient";
import AppState from "../lib/stores/AppState";

const BeverageService = {
    add(data, callback) {
        if ( callback ) {
            ApiClient.post({
                endpoint: '/api/beverage',
                data: data
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.post({
            endpoint: '/api/beverage',
            data: data
        });
    },

    fetch(id, callback) {

        if ( callback ) {
            ApiClient.get({
                endpoint: '/api/beverage/' + id
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.get({
            endpoint: '/api/beverage/' + id
        });
    },

    saveSizes(id, data, callback) {
        ApiClient.patch({
            endpoint: '/api/beverage/' + id,
            data: {
                sizes: data
            }
        }).then((res) => {
            callback && callback(res);
        }, (err) => {
            callback && callback(null, err);
        })
    }
};

export default BeverageService;
