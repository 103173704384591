import React from 'react';
import { connect } from 'react-redux';
import Areas from '../components/areas';
import { fetchAreas, hideAreaAddDialog, setTableFilter, showAreaAddDialog } from '../actions';
import { setPageTitle } from '../../ui/actions';
import { getFilteredAreas } from '../reducers';
import { enqueueSnackbar } from '../../snackbar/actions';

function mapStateToProps(state) {
  const areas = getFilteredAreas(state);

  return {
    dataById: areas || {},
    data: state.areas.entitiesIds || [],
    filters: state.areas.filters,
    selected: state.areas.selected,
    loading: state.areas.loading,
    initialLoaded: state.areas.initialLoaded,
    fetchError: state.areas.fetchError,
    loadingIds: state.areas.loadingIds,
  };
}

const mapDispatchToProps = {
  fetchData: fetchAreas,
  setPageTitle,
  setTableFilter,
  showAddDialog: showAreaAddDialog,
  hideAddDialog: hideAreaAddDialog,
  enqueueSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Areas);
