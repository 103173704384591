const PAYMENT_METHODS = {
    "01": "01 - Efectivo",
    "99": "99 - Por definir",
    "26": "26 - Prescripción o caducidad",
    "04": "04 - Tarjeta de crédito",
    "28": "28 - Tarjeta de débito",
};

const CFDI_USES = {
    "G01": "G01 - Adquisición de mercancias",
    "D06": "D06 - Aportaciones voluntarias al SAR.",
    "I07": "I07 - Comunicaciones satelitales",
    "I06": "I06 - Comunicaciones telefónicas",
    "I01": "I01 - Construcciones",
    "I05": "I05 - Dados, troqueles, moldes, matrices y herramental",
    "D09": "D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
    "G02": "G02 - Devoluciones, descuentos o bonificaciones",
    "D04": "D04 - Donativos.",
    "I04": "I04 - Equipo de computo y accesorios",
    "I03": "I03 - Equipo de transporte",
    "D08": "D08 - Gastos de transportación escolar obligatoria.",
    "G03": "G03 - Gastos en general",
    "D03": "D03 - Gastos funerales.",
    "D02": "D02 - Gastos médicos por incapacidad o discapacidad",
    "D01": "D01 - Honorarios médicos, dentales y gastos hospitalarios.",
    "D05": "D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
    "I02": "I02 - Mobilario y equipo de oficina por inversiones",
    "I08": "I08 - Otra maquinaria y equipo",
    "D10": "D10 - Pagos por servicios educativos (colegiaturas)",
    "P01": "P01 - Por definir",
    "D07": "D07 - Primas por seguros de gastos médicos.",
};

export {
    PAYMENT_METHODS,
    CFDI_USES
}