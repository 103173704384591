import { createClient } from './helpers';

const apiClient = createClient();

function fetch() {
  return apiClient.get('inventory');
}

function add(data) {
  return apiClient.post('inventory', data);
}

function update(id, data) {
  return apiClient.patch(`inventory/${id}`, data);
}

function getById(id) {
  return apiClient.get(`inventory/${id}`);
}

function deleteById(id) {
  return apiClient.delete(`inventory/${id}`);
}

function submitInvoices(data) {
  return apiClient.post(`inventory/incoming-invoice`, {
    invoice: data,
  });
}

function getInvoiceByDate(date) {
  return apiClient.get(`inventory/incoming-invoice/date/${date}`);
}

function getIncomingInvoiceDates() {
  return apiClient.get('inventory/incoming-invoice/dates');
}

export const inventoryApi = {
  fetch,
  add,
  update,
  getById,
  deleteById,
  getInvoiceByDate,
  getIncomingInvoiceDates,
  submitInvoices,
};
