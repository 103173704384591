import React from "react";
import {FormControlLabel, withStyles} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import ApiClient from "../../components/ApiClient";
import Typography from "@material-ui/core/Typography";

const styles  = theme => ({
    container: {
        display: 'inline-flex',
        alignItems: 'center',
    },

    labelOn: {
        /*color: 'green',*/
        width: 'auto',
    },

    labelOff: {
        color: 'red',
        width: 'auto',
    },

    outsideLabel: {
        marginRight: theme.spacing(2),
        fontWeight: 500,
    }
})

@withStyles(styles)
class SystemStatus extends React.Component {

    state = {
        loading: false,
        status: false,
    }

    componentDidMount() {
        this.setState({loading: true});

        ApiClient.get({endpoint: '/api/order/system-status'})
            .then(res => {
                let status = false;
                if (res && res.body && res.body.system_status && res.body.system_status === 'on') {
                    status = true;
                }

                this.setState({
                    loading: false,
                    error: false,
                    status
                })

            }, (err) => {

                this.setState({
                    loading: false,
                    error: true,
                })

                ApiClient.onError(err)
            })
    }

    handleChange = (event) => {
        const newStatus = event.target && event.target.checked

        this.setState({loading: true});

        ApiClient.post({
            endpoint: '/api/order/system-status',
            data: {
                status: newStatus ? "on" : "off",
            }
        })
            .then(res => {

                this.setState({
                    loading: false,
                    error: false,
                    status: newStatus
                })

            }, (err) => {

                this.setState({
                    loading: false,
                    error: true,
                })

                ApiClient.onError(err)
            })
    }

    render() {
        const { classes } = this.props;
        const { loading, status } = this.state;

        return (
            <div className={classes.container}>
                <Typography className={classes.outsideLabel} variant="body1">Accepting orders</Typography>
                <FormControlLabel
                    classes={{
                        label: status ? classes.labelOn : classes.labelOff,
                    }}
                    control={<Switch color="primary" checked={status} onChange={this.handleChange} name="status" />}
                    label={status ? "Yes" : "No"}
                />
            </div>
        )
    }
}


export default SystemStatus;
