import React from 'react';
import { FormControlLabel, withStyles } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import * as PushServiceWorker from '../../lib/push/pushSetup';

const styles = theme => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
  },

  labelOn: {
    /* color: 'green', */
    width: 60,
  },

  labelOff: {
    color: 'red',
    width: 60,
  },

  outsideLabel: {
    marginRight: theme.spacing(2),
    fontWeight: 500,
  },
});

@withStyles(styles)
class ReceiveNotifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: false,
    };
  }


  componentDidMount() {
    this.isStillMounted = true;

    // Set initial status
    (async () => {
      const state = await PushServiceWorker.getPushSubscriptionState();

      this.isStillMounted &&
        this.setState({
          status: !!state,
        });
    })();
  }

  componentWillUnmount() {
    this.isStillMounted = false;
  }

  handleChange = event => {
    const newStatus = event.target && event.target.checked;

    // Register push notifications for logged in users
    if (newStatus) {
      PushServiceWorker.initPush();
    } else {
      PushServiceWorker.unsubscribeUserFromPush();
    }

    this.setState({ status: newStatus });
  };

  render() {
    const { classes } = this.props;
    const { status } = this.state;

    return (
      <div className={classes.container}>
        <Typography className={classes.outsideLabel} variant="body1">
          New Order Alerts
        </Typography>
        <FormControlLabel
          classes={{
            label: status ? classes.labelOn : classes.labelOff,
          }}
          control={<Switch color="primary" checked={status} onChange={this.handleChange} name="order_alerts" />}
          label={status ? 'On' : 'Off'}
        />
      </div>
    );
  }
}

export default ReceiveNotifications;
