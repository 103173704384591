import { connect } from 'react-redux';
import UserAddDialog from '../components/user-add-dialog';
import { showUserAddDialog, hideUserAddDialog } from '../actions';

function mapStateToProps(state) {
  return {
    open: state.users.showAddDialog,
  };
}

const mapDispatchToProps = {
  showAddDialog: showUserAddDialog,
  hideAddDialog: hideUserAddDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAddDialog);
