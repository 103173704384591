import React from 'react';
import find from 'lodash/find';
import roles from './roles';

export function findActivePage(currentPages, pathname) {
  const activePage = find(currentPages, page => {
    if (page.children) {
      // if (pathname.indexOf(`${page.pathname}/`) === 0) {
      // Check if one of the children matches (for /components)
      return findActivePage(page.children, pathname);
      // }
    }

    // Should be an exact match if no children
    return pathname === page.pathname;
  });

  if (!activePage) {
    return null;
  }

  // We need to drill down
  if (activePage.pathname !== pathname) {
    return findActivePage(activePage.children, pathname);
  }

  return activePage;
}

const pages = [
  /* {
        pathname: '/dashboard',
        title: 'Dashboard',
        //icon: <DashboardIcon/>
    }, */

  {
    pathname: '/order',
    title: 'Orders',
    startOpen: false,
    icon: <i className="fal fa-utensils" />,
    requireRole: [roles.USER],
  },
  {
    pathname: '/payment',
    title: 'Payments',
    startOpen: false,
    icon: <i className="fal fa-credit-card" />,
    requireRole: [roles.USER],
  },
  {
    pathname: '/order',
    title: 'Sales',
    icon: <i className="fal fa-utensils" />,
    startOpen: false,
    requireRole: [roles.OWNER, roles.ADMIN, roles.MANAGER],
    children: [
      { pathname: '/order', title: 'Online orders' },
      { pathname: '/payment', title: 'Payments' },
      { pathname: '/invoice', title: 'Invoices' },
      /* { pathname: null, divider: true }, */
      { pathname: '/order/settings', title: 'Configuration' },
    ],
  },
  /* {
    pathname: '/invoice',
    title: 'Invoices',
    icon: <i className="fal fa-file-invoice-dollar" />,
    startOpen: false,
    requireRole: [roles.OWNER, roles.ADMIN, roles.MANAGER],
  }, */
  {
    pathname: '/beverage',
    title: 'Beers',
    icon: <i className="fal fa-glass" />,
    startOpen: false,
    requireRole: [roles.OWNER, roles.ADMIN, roles.MANAGER],
    children: [
      { pathname: '/beverage', title: 'Beers' },
      { pathname: '/on-tap', title: 'Beer on Tap' },
    ],
  },
  {
    pathname: '/product',
    title: 'Products',
    icon: <i className="fal fa-list-ul" />,
    startOpen: false,
    requireRole: [roles.OWNER, roles.ADMIN, roles.MANAGER],
    children: [
      { pathname: '/product', title: 'Products' },
      { pathname: '/category', title: 'Categories' },
      { pathname: '/modifier', title: 'Modifiers' },
      { pathname: '/discount', title: 'Discounts' },
    ],
  },
  {
    pathname: '/inventory',
    title: 'Inventory',
    icon: <i className="fal fa-inventory" />,
    startOpen: false,
    requireRole: [roles.USER, roles.OWNER, roles.ADMIN, roles.MANAGER],
    children: [
      { pathname: '/inventory', title: 'Inventory' },
      { pathname: '/inventory/incoming-invoice', title: 'Incoming invoices' },

      { pathname: '/recipe', title: 'Recipes' },
      { pathname: '/ingredient', title: 'Ingredients' },

      { pathname: '/area', title: 'Areas', requireRole: [roles.OWNER, roles.ADMIN, roles.MANAGER] },
      { pathname: '/vendor', title: 'Vendors', requireRole: [roles.OWNER, roles.ADMIN, roles.MANAGER] },
    ],
  },
  {
    pathname: '/report',
    title: 'Reports',
    icon: <i className="fal fa-analytics" />,
    startOpen: false,
    requireRole: [roles.USER, roles.OWNER, roles.ADMIN, roles.MANAGER],
    children: [
      { pathname: '/inventory/report', title: 'Order report', requireRole: [roles.OWNER, roles.ADMIN, roles.MANAGER] },
      { pathname: '/report/sales', title: 'Sales summary', requireRole: [roles.OWNER, roles.ADMIN, roles.MANAGER] },
      {
        pathname: '/report/sales/items',
        title: 'Sales by item',
        requireRole: [roles.OWNER, roles.ADMIN, roles.MANAGER],
      },
      {
        pathname: '/report/sales/categories',
        title: 'Sales by category',
        requireRole: [roles.OWNER, roles.ADMIN, roles.MANAGER],
      },
      { pathname: '/report/sales/receipts', title: 'Receipts', requireRole: [roles.OWNER, roles.ADMIN, roles.MANAGER] },
      {
        pathname: '/report/sales/discounts',
        title: 'Discounts',
        requireRole: [roles.OWNER, roles.ADMIN, roles.MANAGER],
      },
    ],
  },
  {
    pathname: '/media',
    title: 'Media',
    icon: <i className="fal fa-tv-alt" />,
    startOpen: false,
    requireRole: [roles.OWNER, roles.ADMIN, roles.MANAGER],
    children: [
      { pathname: '/menu', title: 'Menus' },
      { pathname: '/media', title: 'Media items' },
      { pathname: '/display/manage', title: 'Displays' },
    ],
  },
  {
    pathname: '/venue',
    icon: <i className="fal fa-map-marker-alt" />,
    requireRole: [roles.OWNER, roles.ADMIN],
    title: 'Venues',
  },
  {
    pathname: '/audit',
    requireRole: [roles.OWNER, roles.ADMIN],
    title: 'Audit',
    icon: <i className="fal fa-history" />,
    children: [
      { pathname: '/audit/event', title: 'Events', requireRole: [roles.OWNER, roles.ADMIN] },
      { pathname: '/audit/database', title: 'Database', requireRole: [roles.OWNER, roles.ADMIN] },
    ],
  },
  /* {
    pathname: '/user',
    requireRole: [roles.OWNER, roles.ADMIN, roles.MANAGER],
    title: 'Employees',
    icon: <i className="fal fa-users" />,
    children: [
      { pathname: '/user', title: 'Employee list', requireRole: [roles.OWNER, roles.ADMIN] },
      { pathname: '/permissions', title: 'Roles & permissions', requireRole: [roles.OWNER, roles.ADMIN, roles.MANAGER] },
    ],
  }, */
  {
    pathname: '/system',
    requireRole: [roles.OWNER, roles.ADMIN, roles.MANAGER],
    title: 'Configuration',
    icon: <i className="fal fa-cogs" />,
    children: [
      { pathname: '/user', title: 'Employee list', requireRole: [roles.OWNER, roles.ADMIN] },
      {
        pathname: '/permissions',
        title: 'Roles & permissions',
        requireRole: [roles.OWNER, roles.ADMIN, roles.MANAGER],
      },
      { pathname: '/printer-group', title: 'Printer groups', requireRole: [roles.OWNER, roles.ADMIN, roles.MANAGER] },
      { pathname: '/system', title: 'Settings', requireRole: [roles.OWNER, roles.ADMIN] },
    ],
  },
];

export default pages;
