import React from 'react';
import { connect } from 'react-redux';
import Venues from '../components/venues';
import { fetchVenues, hideVenueAddDialog, setTableFilter, showVenueAddDialog } from '../actions';
import { setPageTitle } from '../../ui/actions';
import { getFilteredVenues } from '../reducers';
import { enqueueSnackbar } from '../../snackbar/actions';

function mapStateToProps(state) {
  const venues = getFilteredVenues(state);

  return {
    dataById: venues || {},
    data: state.venues.entitiesIds || [],
    filters: state.venues.filters,
    selected: state.venues.selected,
    loading: state.venues.loading,
    initialLoaded: state.venues.initialLoaded,
    fetchError: state.venues.fetchError,
    loadingIds: state.venues.loadingIds,
  };
}

const mapDispatchToProps = {
  fetchData: fetchVenues,
  setPageTitle,
  setTableFilter,
  showAddDialog: showVenueAddDialog,
  hideAddDialog: hideVenueAddDialog,
  enqueueSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Venues);
