import React from 'react'


import ApiClient from "../../components/ApiClient";

import Media from './Media';


class MediaOverlay extends React.Component {

    sliderTimeout = null
    reloadAdsTimeout = null
    reloadAdsInterval = 30 // Seconds

    constructor(props) {
        super(props)

        this.showNextAd = this.showNextAd.bind(this)

        this.state = {
            waitTime: props.waitTime ? props.waitTime : 600,
            data: [],
            initialLoaded: false,
            active: this.props.active,
            adWillHide: false
        }
    }

    /**
     * Read the list of styles from the server
     */
    loadAds() {
        ApiClient.get({
            endpoint: '/api/media'
        }).then((res) => {

            const config = res.body.config ? res.body.config : {};
            let waitTime = this.state.waitTime;

            if (config.adsSliderWaitTime) {
                waitTime = config.adsSliderWaitTime;
            }

            const media = [];
            if ( res.body.media ) {
                for ( let j in res.body.media ) {
                    if ( res.body.media[j].is_active ) {
                        media.push ( res.body.media[j] );
                    }
                }
            }

            const {initialLoaded} = this.state;

            this.setState ({
                data: media,
                config: config,
                initialLoaded: true,
                waitTime
            }, () => {
                if ( !initialLoaded ) {
                    this.startTimer()
                }

                this.reloadAdsTimeout = setTimeout( this.loadAds.bind(this), this.reloadAdsInterval * 1000 );
            } );

        }, (err) => {
            this.setState({
                loading: false,
                error: true,
                errorMsg: err.response && err.response.body && err.response.body.error ? err.response.body.error : 'API communication error'
            })
        } );
    }

    startTimer() {
        clearTimeout(this.sliderTimeout);
        this.sliderTimeout = setTimeout( this.showNextAd, this.state.waitTime * 1000 );
    }

    showNextAd() {
        // Do we have any ads?
        if ( !this.state.data.length ) {
            return;
        }

        var nextAdId = 0;
        if ( this.state.active == null ) {
            // Show the first one
            nextAdId = 0;

        } else {
            // Let's figure out which one to show
            if ( this.state.active + 1 > this.state.data.length - 1 ) {
                nextAdId = 0;

            } else {
                nextAdId = this.state.active + 1;
            }
        }

        this.setState({
            active: nextAdId,
            adWillHide: false,
            open: true
        }, function() {

            // Set a timer to hide the ads
            var adDuration = this.state.data[this.state.active] ? this.state.data[this.state.active].duration : 0;

            if ( !adDuration || adDuration < 5 ) {
                adDuration = 5; // default to 5
            }

            setTimeout( () => {
                this.hideAds();
                this.startTimer();

            }, adDuration * 1000 );

            // Also set a timeout 1 second before hiding the ads to fade out the creative
            setTimeout( () => {
                this.setState({
                    adWillHide: true
                })
            }, (adDuration - 1) * 1000)

        }.bind(this) );
    }

    hideAds() {
        this.setState({
            adWillHide: false,
            open: false
        });
    }

    componentDidMount() {
        this.loadAds();
    }

    render() {

        var ads = this.state.data.map(function(ad,key) {
            if ( !ad.is_active ) {
                return null;
            }

            return (
                <Media key={'boardad' + key}
                    active={key === this.state.active && this.state.open}
                    adWillHide={this.state.adWillHide}
                    data={ad}
                />
                );

        }.bind(this));

        return(
            <div id="board-ads">{ads}</div>
        );
    }
}

MediaOverlay.defaultProps = {
    waitTime: 600,
    layout: null,
    open: false,
    active: null
};

export default MediaOverlay;
