import produce from 'immer';
import { denormalize, normalize, schema } from 'normalizr';
import _ from 'lodash';
import update from 'react-addons-update';

import {
  MENUS_FETCH_SUCCESS,
  MENUS_FETCH_ERROR,
  MENUS_FETCH_REQUEST,
  MENUS_DELETE_REQUEST,
  MENUS_SELECTED,
  MENUS_CATEGORIES_SORTED,
  MENUS_PRODUCTS_SORTED,
  MENUS_ADD_CATEGORY,
  MENUS_SAVE_REQUEST,
  MENUS_SAVE_SUCCESS,
  MENUS_UPDATE_CATEGORY,
  MENUS_FETCH_MENU_START,
  MENUS_FETCH_MENU_SUCCESS,
  MENUS_DELETE_SUCCESS,
  MENUS_UPDATE_DATA,
  MENUS_DELETE_ERROR,
  MENUS_ADD_REQUEST,
  MENUS_ADD_SUCCESS,
  MENUS_LOADING_START,
  MENUS_LOADING_STOP,
  MENUS_SET_TABLE_FILTER,
} from '../actions';

const INITIAL_STATE = {
  entities: {
    menus: null,
  },
  entitiesIds: null,
  selected: null,

  // Filters
  filters: {
    search: null,
    status: null,
  },

  // Other state
  loading: false,
  initialLoaded: false,
  loadingId: null,
  loadingIds: [],

  fetchError: null,
  saveError: null,
};

// Define the order schema so we can normalize the data and index it properly
const menuSchema = new schema.Entity('menus');
const menuListSchema = new schema.Array(menuSchema);

export default function menus(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    let categories;
    let dragCard;

    switch (action.type) {
      case MENUS_FETCH_SUCCESS:
        // Normalize the data for better access
        const { data } = action.payload;
        const normalized = normalize(data, menuListSchema);
        draft.entities = normalized.entities;
        draft.entitiesIds = normalized.result;
        draft.loading = false;
        draft.initialLoaded = true;
        draft.fetchError = null;

        break;

      case MENUS_FETCH_REQUEST:
        draft.loading = true;
        draft.fetchError = null;
        break;

      case MENUS_FETCH_ERROR:
        draft.fetchError = action.payload.error;
        break;

      case MENUS_SAVE_REQUEST:
        if (state.loadingIds.indexOf(parseInt(action.payload.id, 10)) === -1) {
          draft.loadingIds = [...state.loadingIds, parseInt(action.payload.id, 10)];
        }
        draft.loadingId = action.payload.id;
        draft.saveError = null;
        break;

      case MENUS_SAVE_SUCCESS: {
        draft.loadingIds = state.loadingIds.filter(el => el !== action.payload.id);
        draft.loadingId = false;
        draft.saveError = null;
        break;
      }

      case MENUS_ADD_REQUEST: {
        if (state.loadingIds.indexOf(parseInt(action.payload.id, 10)) === -1) {
          draft.loadingIds = [...state.loadingIds, parseInt(action.payload.id, 10)];
        }
        break;
      }

      case MENUS_ADD_SUCCESS: {
        draft.loadingIds = state.loadingIds.filter(el => el !== action.payload.id);

        const { data } = action.payload;
        draft.entitiesIds.push(data.id);
        draft.entities.menus[data.id] = data;
        break;
      }

      case MENUS_UPDATE_DATA: {
        const menusArr = denormalize(state.entitiesIds, menuListSchema, state.entities);
        const rowIndex = state.entitiesIds.indexOf(parseInt(action.payload.id, 10));

        if (rowIndex !== -1) {
          menusArr[rowIndex] = { ...menusArr[rowIndex], ...action.payload.data };
        }

        const singleMenu = normalize(menusArr, menuListSchema);
        draft.entities = singleMenu.entities;
        draft.entitiesIds = singleMenu.result;
        break;
      }

      case MENUS_DELETE_REQUEST:
        if (state.loadingIds.indexOf(parseInt(action.payload.id, 10)) === -1) {
          draft.loadingIds = [...state.loadingIds, parseInt(action.payload.id, 10)];
        }

        // Delete from state
        draft.entitiesIds = state.entitiesIds.filter(el => el !== action.payload.id);
        draft.entities.menus = _.pickBy(state.entities.menus, el => el.id !== action.payload.id);

        break;

      case MENUS_DELETE_SUCCESS:
        draft.loadingIds = state.loadingIds.filter(el => el !== action.payload.id);
        draft.loadingId = false;
        break;

      case MENUS_DELETE_ERROR:
        draft.loadingIds = state.loadingIds.filter(el => el !== action.payload.id);
        break;

      case MENUS_SELECTED:
        draft.selected = action.payload.id;
        break;

      case MENUS_CATEGORIES_SORTED:
        categories = state.entities.menus ? [...state.entities.menus[action.payload.menuId].categories] : [];
        dragCard = categories[action.payload.dragIndex];

        draft.entities.menus[action.payload.menuId].categories = update(categories, {
          $splice: [
            [action.payload.dragIndex, 1],
            [action.payload.hoverIndex, 0, dragCard],
          ],
        });
        break;

      case MENUS_PRODUCTS_SORTED:
        const { payload } = action;
        categories = state.entities.menus ? [...state.entities.menus[payload.menuId].categories] : [];
        dragCard = categories[payload.categoryIndex].products[payload.dragIndex];

        draft.entities.menus[payload.menuId].categories[payload.categoryIndex] = update(
          categories[payload.categoryIndex],
          {
            products: {
              $splice: [
                [payload.dragIndex, 1],
                [payload.hoverIndex, 0, dragCard],
              ],
            },
          },
        );
        break;

      case MENUS_ADD_CATEGORY: {
        const { menuId } = action.payload;
        const menus = { ...state.entities.menus };

        categories = [];
        if (menus && menus[menuId]) {
          categories = menus[menuId].categories || [];
        }

        categories = [...categories, action.payload.category];
        menus[menuId] = { ...menus[menuId], categories };

        draft.entities = { menus };
        break;
      }

      case MENUS_UPDATE_CATEGORY:
        categories = state.entities.menus ? [...state.entities.menus[action.payload.menuId].categories] : [];
        categories = [
          ...categories.slice(0, action.payload.rowIndex),
          action.payload.category,
          ...categories.slice(action.payload.rowIndex + 1),
        ];

        draft.entities.menus[action.payload.menuId].categories = categories;

        break;

      case MENUS_FETCH_MENU_START:
        draft.loadingId = action.payload.id;
        break;

      case MENUS_FETCH_MENU_SUCCESS:
        if (state.entities && state.entities.menus) {
          let menusArr = denormalize(state.entitiesIds, menuListSchema, state.entities);
          const rowIndex = state.entitiesIds.indexOf(parseInt(action.payload.id, 10));

          if (rowIndex === -1) {
            menusArr = [...menusArr, action.payload.data];
          } else {
            menusArr = [...menusArr.slice(0, rowIndex), action.payload.data, ...menusArr.slice(rowIndex + 1)];
          }

          const singleMenu = normalize(menusArr, menuListSchema);
          draft.entities = singleMenu.entities;
          draft.entitiesIds = singleMenu.result;
          // Reset loading
          if (state.loadingId === action.payload.id) {
            draft.loadingId = null;
          }
        }

        break;

      case MENUS_LOADING_START:
        if (state.loadingIds.indexOf(parseInt(action.payload.id, 10)) === -1) {
          draft.loadingIds = [...state.loadingIds, parseInt(action.payload.id, 10)];
        }
        break;

      case MENUS_LOADING_STOP:
        draft.loadingIds = state.loadingIds.filter(el => el !== parseInt(action.payload.id, 10));
        break;

      case MENUS_SET_TABLE_FILTER:
        draft.filters[action.payload.name] = action.payload.value;
        break;

      default:
    }
  });
}

// Selectors
export function getFilteredMenus(state) {
  const { entities, filters } = state.menus;
  const { menus } = entities;

  if (menus === null) {
    return null;
  }

  return _.filter(menus, o => {
    if (filters.search && o.menu_name.toLowerCase().indexOf(filters.search.toLowerCase()) === -1) {
      return false;
    }

    if (filters.status && filters.status !== 'all' && o.is_active !== (filters.status === 'active')) {
      return false;
    }

    return true;
  });
}

export function getMenuCategoriesSelector(state, id) {
  const { entities } = state.menus;
  const { menus } = entities;

  let categories = [];
  if (menus && id && menus[id]) {
    categories = menus[id].categories || [];
  }

  return categories;
}

export const getMenuByIdSelector = (state, id) => {
  return state.menus && state.menus.entities && state.menus.entities.menus ? state.menus.entities.menus[id] : {};
};
