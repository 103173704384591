import { createClient } from './helpers';

const apiClient = createClient();

function fetch() {
  return apiClient.get('product');
}

function add(data) {
  return apiClient.post('product', data);
}

function update(id, data) {
  return apiClient.patch(`product/${id}`, data);
}

function getById(id) {
  return apiClient.get(`product/${id}`);
}

function deleteById(id) {
  return apiClient.delete(`product/${id}`);
}

function fetchPrinterAreas() {
  return apiClient.get('product/printer-areas');
}

function cloneById(id, data) {
  return apiClient.post(`product/${id}/clone`, data);
}

export const productApi = {
  fetch,
  add,
  update,
  getById,
  deleteById,
  fetchPrinterAreas,
  cloneById,
};
