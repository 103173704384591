import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import {
  InputAdornment,
  TextField,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Button,
  Box,
  Typography,
} from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import { withSnackbar } from 'notistack';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';

import Tabs from '@material-ui/core/Tabs';
import InventoryService from '../../services/InventoryService';
import AreaService from '../../services/AreaService';
import ContentPaper from '../../components/content-paper';
import { convertFromBaseUnit, convertToBaseUnit } from '../../i18n';
import StockField from '../../components/forms/stock-input';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(15),
    overflowX: 'auto',
  },
  actionButton: {
    margin: theme.spacing(2, 0),
  },
  backButton: {
    // background: '#ffffff',
    padding: '8px 18px 8px 24px',
  },
  currentRow: {
    background: '#ffffff',
  },
  currentRowName: {
    fontSize: '1rem',
    fontWeight: 700,
  },
  colorPrimary: {
    // color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
  },
  fadedRow: {
    background: '#f7f7f7',
  },
  input: {
    textAlign: 'right',
  },
  headingContainer: {
    padding: theme.spacing(2, 2, 2),
    /* display: 'flex',
        alignItems: 'center', */
  },
  table: {
    minWidth: 320,
  },

  shortCol: {
    width: 130,
  },
  stockCol: {
    width: 130,
  },
  optionalCol: {
    width: 100,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  vendorCol: {
    width: 150,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  buttonBox: {
    /* textAlign: 'right' */
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 3),
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`inventory-edit-tabpanel-${index}`}
      aria-labelledby={`inventory-edit-tab-${index}`}
      {...other}>
      <Box px={1} py={1}>
        {children}
      </Box>
    </Typography>
  );
}

@withStyles(styles)
@withSnackbar
class InventoryEdit extends React.Component {
  constructor(props) {
    super(props);

    this.inputRefs = [];

    this.state = {
      area: null,
      areaId: null,
      editing: this.props.editing ? this.props.editing : false,
      currentRow: null,
      inventory: {
        ingredients: null,
      },
      inventoryId: null,
      currentTab: 0,
      savingForm: false,
    };
  }

  componentDidMount() {
    let { match, inventoryId, areaId } = this.props;

    areaId = match && match.params ? match.params.areaId : areaId || null;
    inventoryId = match && match.params ? match.params.inventoryId : inventoryId || null;
    //

    // Update state accordingly

    if (inventoryId) {
      // Pull inventory data and populate area and ingredients
      // Fetch area ingredients to prepare new inventory

      this.setState(
        {
          areaId,
        },
        () => this.getInventory(inventoryId),
      );
    } else {
      // Fetch area ingredients to prepare new inventory
      this.setState(
        {
          areaId,
          inventory: {
            area_id: null,
            date: null,
            inventory_date: null,
            ingredients: null,
            locations: null,
          },
        },
        () => this.getAreaIngredients(areaId),
      );
    }
  }

  getInventory(inventoryId) {
    /* this.setState({
            areaId: areaId,
            editing: !!areaId,
            inventory: {
                area_id: areaId,
                date: null,
                ingredients: null
            },
        }); */

    InventoryService.fetch(`${inventoryId}/area/${this.state.areaId}`).then(res => {
      if (res && res.body && res.body.data) {
        const ingredients = [];
        res.body.data.map((row, i) => {
          ingredients.push({
            id: row.ingredient_id,
            location_id: row.location_id,
            name: row.ingredient && row.ingredient.name ? row.ingredient.name : '',
            vendor: row.ingredient && row.ingredient.vendor ? row.ingredient.vendor : {},
            unit: row.ingredient ? row.ingredient.unit : '',
            par_number: row.ingredient ? row.ingredient.par_number : '',
            stock_amount: row.stock_amount,
            order_amount: '',
          });
        });

        const { inventory } = this.state;
        inventory.area = res.body.area;
        inventory.area_id = inventory.area.id;
        inventory.locations = res.body.locations;

        inventory.ingredients = ingredients;

        // Populate the locations data
        let locations = [];
        for (const location of inventory.locations) {
          locations.push({
            name: location.name,
            id: location.id,
            ingredients: _.filter(_.cloneDeep(ingredients), function (o) {
              return _.findIndex(location.ingredients, { id: o.id }) > -1 && o.location_id === location.id;
            }),
          });
        }

        // Sort locations by name
        locations = _.sortBy(locations, 'name');
        inventory.locations = locations;

        this.setState({
          area: inventory.area,
          inventoryId,
          inventory,
        });
      }
    });
  }

  getAreaIngredients(areaId) {
    AreaService.fetchIngredients(areaId).then(res => {
      if (res && res.body && res.body.area) {
        const ingredients = [];
        res.body.data.map((ingredient, i) => {
          ingredients.push({
            id: ingredient.id,
            name: ingredient.name,
            vendor: ingredient.vendor ? ingredient.vendor : {},
            unit: ingredient.unit,
            par_number: ingredient.par_number,
            stock_amount: '',
            order_amount: '',
          });
        });

        const { inventory } = this.state;
        inventory.area = res.body.area;
        inventory.area_id = inventory.area.id;
        inventory.locations = res.body.locations;

        inventory.ingredients = ingredients;

        // Populate the locations data
        let locations = [];
        for (const location of inventory.locations) {
          locations.push({
            name: location.name,
            id: location.id,
            ingredients: _.filter(_.cloneDeep(ingredients), function (o) {
              return _.findIndex(location.ingredients, { id: o.id }) > -1;
            }),
          });
        }

        // Sort locations by name
        locations = _.sortBy(locations, 'name');
        inventory.locations = locations;

        this.setState({
          area: inventory.area,
          inventory,
        });
      }
    });
  }

  handleSave(ev) {
    ev && ev.preventDefault();

    const { inventoryId, inventory } = this.state;

    this.setState({
      savingForm: true,
    });

    if (!inventoryId) {
      InventoryService.add(inventory).then(
        res => {
          const { data } = res.body;
          const date = data && data.date;

          if (date) {
            this.props.enqueueSnackbar('The inventory was saved', { variant: 'success', autoHideDuration: 5000 });

            const { history } = this.props;
            // history.push('/inventory/report/' + id)
            history.push('/inventory');
            return;
          }

          this.setState({
            savingForm: false,
          });
        },
        err => {
          let message = 'An error occurred while saving the inventory. Please try again.';
          if (err.response && err.response.body && err.response.body.errors) {
            message = err.response.body.errors.join('. ');
          }

          this.props.enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });

          this.setState({
            error: message,
            savingForm: false,
          });
        },
      );
    } else {
      InventoryService.update(inventoryId, inventory).then(
        res => {
          const { data } = res.body;

          this.props.enqueueSnackbar('The inventory was saved', { variant: 'success', autoHideDuration: 5000 });

          const { history } = this.props;
          // history.push('/inventory/report/' + id)
          history.push('/inventory');
          return;
        },
        err => {
          let message = 'An error occurred while saving the inventory. Please try again.';
          if (err.response && err.response.body && err.response.body.errors) {
            message = err.response.body.errors.join(' ');
          }

          this.props.enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });

          this.setState({
            error: message,
            savingForm: false,
          });
        },
      );
    }

    return false;
  }

  handleStockChange(rowIndex, event) {
    const { inventory } = this.state;
    inventory.ingredients[rowIndex].stock_amount = event.target.value;

    // Also calculate the required order amount based on par number
    inventory.ingredients[rowIndex].order_amount = null;
    if (inventory.ingredients[rowIndex].par_number) {
      let order = inventory.ingredients[rowIndex].par_number - inventory.ingredients[rowIndex].stock_amount;
      if (!order || order <= 0) {
        order = '';
      } else {
        order = _.round(order, 2);
      }

      // For some measurement units, we round up to integer
      if (inventory.ingredients[rowIndex].unit === 'pz') {
        // order = _.ceil(order);
      }

      inventory.ingredients[rowIndex].order_amount = order;
    }

    this.setState({ inventory });
  }

  handleLocationStockChange(locationIndex, rowIndex, event) {
    const { inventory } = this.state;
    const ingredient = {
      ...inventory.locations[locationIndex].ingredients[rowIndex],
    };

    // Convert stock amount to base unit
    ingredient.stock_amount = convertToBaseUnit(event.target.value, ingredient.unit, 'stock');
    inventory.locations[locationIndex].ingredients[rowIndex] = ingredient;

    // Recalculate ingredient totals
    const prodId = inventory.locations[locationIndex].ingredients[rowIndex].id;

    // Calculate total
    // Find location subtotals
    const subtotals = _.filter(inventory.locations, function (o) {
      return _.find(o.ingredients, { id: prodId });
    });

    const subtotalsClean = _.reduce(
      subtotals,
      function (result, value) {
        const sIngredient = _.find(value.ingredients, { id: prodId });

        (result[prodId] || (result[prodId] = [])).push(
          sIngredient.stock_amount ? parseFloat(sIngredient.stock_amount) : 0,
        );

        return result;
      },
      {},
    );

    // Prepare & format stock
    const stockQuantity = _.sum(subtotalsClean[prodId]);

    // Update
    const areaRowIndex = _.findIndex(inventory.ingredients, { id: prodId });
    this.handleStockChange(areaRowIndex, { target: { value: stockQuantity } });

    this.setState({ inventory });
  }

  handleFocus(row) {
    // const top = this.inputRefs[row].current.getBoundingClientRect().top;
    // this.props.appRef.current.scrollTo(0, top - 121);
    // this.props.appRef.current.scrollTo(0, 120 + (row * 61));
    // window.scrollTo(0, 120 + (row * 61));

    this.setState({ currentRow: row });
  }

  render() {
    const { currentRow, inventory, inventoryId, currentTab, savingForm } = this.state;

    const { classes } = this.props;

    if (!inventory) {
      return null;
    }

    const inventoryDate = inventoryId;

    const { locations } = inventory;

    return (
      <div>
        <AppBar
          position="static"
          color="transparent"
          style={{ position: 'fixed', top: 48, marginLeft: -24, zIndex: 999, backgroundColor: '#ffffff' }}
          elevation={2}>
          <Tabs
            value={currentTab}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, currentTab) => this.setState({ currentTab })}>
            {locations &&
              locations.map(l => {
                return <Tab key={l.id} label={l.name} />;
              })}

            <Tab label="Total" />
          </Tabs>
        </AppBar>

        <ContentPaper table style={{ marginTop: 50 }}>
          <div className={classes.headingContainer}>
            {inventoryId ? (
              <Typography variant="h6" style={{ flexGrow: 1 }}>
                Editar inventario
                <Typography component="span" variant="subtitle1" style={{ marginLeft: '24px' }}>
                  {inventoryDate}
                </Typography>
              </Typography>
            ) : (
              <Typography variant="h6" style={{ flexGrow: 1 }}>
                Inventario nuevo
              </Typography>
            )}

            <Box>
              <Button
                size="medium"
                disabled={savingForm}
                onClick={this.handleSave.bind(this)}
                variant="contained"
                color="primary"
                className={classes.actionButton}>
                Guardar inventario
              </Button>
            </Box>
          </div>

          {locations &&
            locations.map((location, locationIdx) => {
              return (
                <TabPanel value={currentTab} index={locationIdx} key={`${location.id}-${inventoryId}`} elevation={0}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Ingredient name</TableCell>
                        <TableCell className={classes.vendorCol}>Vendor</TableCell>
                        {/* <TableCell align="center" className={classes.optionalCol}>Unit</TableCell> */}
                        {/* <TableCell align="center" className={classes.optionalCol}>Par</TableCell> */}
                        <TableCell align="right" className={classes.stockCol}>
                          Stock
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {location.ingredients &&
                        location.ingredients.map((row, idx) => {
                          const isCurrentRow = currentRow === idx;

                          this.inputRefs[idx] = React.createRef();

                          return (
                            <TableRow
                              key={`${location.id}-${inventoryId}-${idx}`}
                              className={classNames(
                                isCurrentRow && classes.currentRow,
                                currentRow !== null && !isCurrentRow && classes.fadedRow,
                              )}>
                              <TableCell component="th" className={isCurrentRow ? classes.currentRowName : null}>
                                {row.name}
                              </TableCell>

                              <TableCell className={classes.vendorCol}>
                                {row.vendor && row.vendor.name ? row.vendor.name : '-'}
                              </TableCell>

                              {/* <TableCell align="center" className={classes.optionalCol}>
                                        {row.unit}
                                    </TableCell> */}
                              {/* <TableCell align="center" className={classes.optionalCol}>
                                        {row.par_number ? row.par_number : 0}
                                    </TableCell> */}
                              <TableCell align="right" className={classes.stockCol}>
                                <>
                                  <StockField
                                    name={`stock-${row.id}`}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">{row.unit ? row.unit : '-'}</InputAdornment>
                                      ),
                                      size: 'large',
                                    }}
                                    inputProps={{
                                      className: classes.input,
                                      tabIndex: idx + 1,
                                    }}
                                    inputRef={this.inputRefs[idx]}
                                    autoFocus={isCurrentRow}
                                    value={convertFromBaseUnit(row.stock_amount, row.unit, 'stock')}
                                    onChange={ev => this.handleLocationStockChange(locationIdx, idx, ev)}
                                    onFocus={ev => {
                                      ev.target.select();
                                      this.handleFocus(idx);
                                    }}
                                  />

                                  {/* {!isCurrentRow ? null :
                                                    idx < inventory.ingredients.length-1 ?
                                                        <Button variant="outlined"
                                                                size="small"
                                                                style={{marginTop: '8px'}}
                                                                color="primary"
                                                                onClick={() => this.nextIngredient(idx + 1)}
                                                        >
                                                            Continuar
                                                        </Button>
                                                        :
                                                        <Button variant="contained"
                                                                style={{marginTop: '8px'}}
                                                                color="primary"
                                                                onClick={this.handleSave.bind(this)}
                                                        >
                                                            Guardar
                                                        </Button>

                                                } */}
                                </>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TabPanel>
              );
            })}

          <TabPanel value={currentTab} index={locations ? locations.length : 0}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Ingredient name</TableCell>
                  <TableCell className={classes.vendorCol}>Vendor</TableCell>
                  <TableCell align="center" className={classes.optionalCol}>
                    Unit
                  </TableCell>
                  <TableCell align="center" className={classes.optionalCol}>
                    Par
                  </TableCell>
                  <TableCell align="right" className={classes.stockCol}>
                    Stock
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inventory.ingredients &&
                  inventory.ingredients.map((row, idx) => {
                    const isCurrentRow = currentRow === idx;

                    return (
                      <TableRow key={`totals-${idx}`} className={classNames()}>
                        <TableCell component="td" className={isCurrentRow ? classes.currentRowName : null}>
                          {row.name}
                        </TableCell>

                        <TableCell className={classes.vendorCol}>
                          {row.vendor && row.vendor.name ? row.vendor.name : '-'}
                        </TableCell>

                        <TableCell align="center" className={classes.optionalCol}>
                          {row.unit}
                        </TableCell>
                        <TableCell align="center" className={classes.optionalCol}>
                          {row.par_number ? convertFromBaseUnit(row.par_number, row.unit, 'numberInput') : 0}
                        </TableCell>
                        <TableCell align="right" className={classes.stockCol}>
                          <>
                            <TextField
                              type="number"
                              value={convertFromBaseUnit(row.stock_amount, row.unit, 'stock')}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">{row.unit ? row.unit : '-'}</InputAdornment>
                                ),
                              }}
                              inputProps={{
                                className: classes.input,
                                tabIndex: -1,
                              }}
                              disabled
                            />
                          </>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TabPanel>
        </ContentPaper>
      </div>
    );
  }
}

export default InventoryEdit;
