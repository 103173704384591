import { makeActionCreator } from '../../../store/actions';

export const PAYMENTS_FETCH_REQUEST = '@payments / FETCH REQUEST';
export const PAYMENTS_FETCH_SUCCESS = '@payments / FETCH SUCCESS';
export const PAYMENTS_FETCH_ERROR = '@payments / FETCH ERROR';
export const PAYMENTS_SHOW_ADD_DIALOG = '@payments / SHOW ADD DIALOG';
export const PAYMENTS_HIDE_ADD_DIALOG = '@payments / HIDE ADD DIALOG';
export const PAYMENTS_SET_TABLE_FILTER = '@payments / SET TABLE FILTER';

export const fetchPayments = makeActionCreator(PAYMENTS_FETCH_REQUEST);
export const fetchPaymentsError = makeActionCreator(PAYMENTS_FETCH_ERROR, 'error');
export const fetchPaymentsSuccess = makeActionCreator(PAYMENTS_FETCH_SUCCESS, 'data');
export const showPaymentAddDialog = makeActionCreator(PAYMENTS_SHOW_ADD_DIALOG);
export const hidePaymentAddDialog = makeActionCreator(PAYMENTS_HIDE_ADD_DIALOG);

// Table filters
export const setTableFilter = makeActionCreator(PAYMENTS_SET_TABLE_FILTER, 'name', 'value');
