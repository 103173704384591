import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import { withStyles } from '@material-ui/styles';

import { Portal, Grid, Typography, Box } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Masonry from 'react-masonry-css';
import MediaOverlay from './MediaOverlay';
import AppState from '../../lib/stores/AppState';
import ApiClient from '../../components/ApiClient';
import srmList from '../../components/SrmValuesList';

const styles = theme => ({
  root: {
    zIndex: -99,
  },

  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 9999,
    // backgroundColor: '#f5f5f5',
    backgroundColor: '#fff',
    padding: 0,
    overflow: 'hidden',
  },

  header: {
    height: '54px',
    width: '100%',
    backgroundColor: '#f5f5f5',
    position: 'relative',
    marginBottom: '1vh',
  },

  imageMenu: {
    width: 'auto',
    height: '100%',
  },

  imageMenuContainer: {
    height: '100%',
    textAlign: 'center',
    background: '#000',
  },
});

class FoodMenuImage extends React.Component {
  timer = null;

  constructor(props) {
    super(props);

    this.state = {
      data: {},
    };
  }

  componentWillUnmount() {
    clearInterval(this.heartbeatInterval);
  }

  componentDidMount() {
    // Set scaling
    document.body.style.zoom = 1 / window.devicePixelRatio;

    AppState.setConfig({
      CurrentPageTitle: 'Food Menu',
      AppBarSearch: false,
    });

    this.initWsClient();
  }

  onDisplayCommands(commands) {
    // We do it with if's because we can set priority to the commands
    // based on the required outcome; for example, command reload-screen
    // always has priority because other commands are not relevant anymore;
    commands = _.uniq(commands);
    if (commands.includes('reload-screen')) {
      window.location = window.location;
    }
    if (commands.includes('refresh-display-data')) {
      this.loadMenu();
    }
  }

  initWsClient = () => {
    const displayId = AppState.getConfig('CurrentDisplayId');
    const { hostname, port } = window.location;
    const URL = `wss://${hostname}:${parseInt(port, 10) !== 3000 ? port : 5005}/ws/display/commands/${displayId}`;

    this.wsConnected = false;
    this.ws = null;

    // Init websocket
    this.ws = new WebSocket(URL);

    this.ws.onopen = () => {
      // Start heartbeat check
      this.wsHeartbeat();

      // Send display data
      const message = { displayId };
      this.ws.send(JSON.stringify(message));

      this.wsConnected = true;
    };

    this.ws.onmessage = evt => {
      try {
        const message = JSON.parse(evt.data);

        // Parse remote commands?
        if (message && message.commands) {
          this.onDisplayCommands(message.commands);
        }
      } catch (e) {}
    };

    this.ws.onerror = error => {
      this.wsConnected = false;
      this.ws.close();
    };

    this.ws.onclose = () => {
      this.wsConnected = false;
    };
  };

  wsHeartbeat = () => {
    clearInterval(this.heartbeatInterval);

    this.heartbeatInterval = setInterval(() => {
      if (!this.wsConnected) {
        this.initWsClient();
      }
    }, 10000);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Portal container={document.body}>
          <div className={classes.container}>
            {/* <div className={classes.header}>
              <div className={classes.logo}>
                <img className={classes.logoImage} src="/menu-display/campfire-logo.png" />
              </div>
              <div className={classes.slogan}>Food menu</div>
            </div> */}

            <div className={classes.imageMenuContainer}>
              <img alt="Campfire Food Menu" src="/images/campfire-menu.jpg" className={classes.imageMenu} />
            </div>
          </div>
        </Portal>
      </div>
    );
  }
}

export default withStyles(styles)(FoodMenuImage);
