import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import { withSnackbar } from 'notistack';

import styles from '../styles';
import EnhancedTable from '../../../components/enhanced-table/EnhancedTable';
import ContentPaper from '../../../components/content-paper';
import PageLoadingText from '../../../components/PageLoadingText';
import { localize, translate } from '../../../i18n';
import { paymentApi } from '../../../api/payment';

@withSnackbar
@withStyles(styles)
class Payments extends React.Component {
  constructor(props) {
    super(props);
    const { fetchData, setPageTitle } = this.props;

    this.typeName = 'payment';

    setPageTitle(translate('Payments'));
    fetchData();
  }

  handleMassDelete = ids => ids.map(id => this.handleDelete(id));

  handleDelete = async id => {
    const { enqueueSnackbar, fetchData } = this.props;

    // Delete AJAX
    try {
      await paymentApi.deleteById(id);

      // All good
      setTimeout(() => fetchData(), 100);
    } catch (err) {
      enqueueSnackbar(translate(`Could not delete the ${this.typeName}`), { options: { variant: 'error' } });
    }
  };

  handleConfirm = async id => {
    try {
      const { fetchData } = this.props;
      await paymentApi.update(id, {
        approved: true,
        approved_at: new Date(),
      });

      fetchData();
    } catch (e) {}
  };

  getTableColumns = () => {
    return [
      {
        id: 'approved',
        accessor: 'approved',
        align: 'center',
        style: { width: 120 },
        label: translate('Status'),
        renderValue: row => {
          if (row.approved) {
            return translate('Confirmed');
          }

          return (
            <Button variant="outlined" color="primary" onClick={() => this.handleConfirm(row.id)}>
              {translate('Confirm')}
            </Button>
          );
        },
      },
      {
        id: 'amount',
        accessor: 'amount',
        label: translate('Total amount'),
        style: { width: 120 },
        renderValue: row => localize('currency', row.amount),
      },
      {
        id: 'tip_amount',
        accessor: 'tip_amount',
        label: translate('Tip amount'),
        style: { width: 120 },
        renderValue: row => localize('currency', row.tip_amount),
      },
      {
        id: 'transaction_id',
        accessor: 'transaction_id',
        label: translate('Transaction'),
        style: { width: 120 },
        renderValue: row => {
          if (!row.transaction_id) {
            return <div style={{ color: 'red' }}>{translate('ERROR')}</div>;
          }

          return <div style={{ color: 'green' }}>{translate('OK')}</div>;
        },
      },
      {
        id: 'server_name',
        accessor: 'server_name',
        sortable: true,
        component: 'td',
        //
        label: translate('Server name'),
      },

      {
        id: 'note',
        accessor: 'note',
        label: translate('Note'),
      },
      /* {
        id: 'id',
        accessor: 'id',
        numeric: false,
        label: '',
        align: 'right',
        padding: 'none',
        renderValue: row => (
          <ActionMenu
            isLoading={loadingIds.indexOf(parseInt(row.id, 10)) !== -1}
            handleDelete={this.handleDelete}
            id={row.id}
            data={row}
          />
        ),
      }, */
    ];
  };

  getTableFilters = () => {
    const { setTableFilter, filters } = this.props;

    return [
      {
        label: translate('Status'),
        type: 'select',
        value: filters.status,
        onChange: value => setTableFilter('status', value),
        options: [
          {
            value: '',
            label: translate('All'),
          },
          {
            value: 'new',
            label: translate('New'),
          },
        ],
      },
      {
        label: translate('Search'),
        type: 'search',
        value: filters.search,
        onChange: value => setTableFilter('search', value),
      },
    ];
  };

  shouldComponentRender = () => {
    const { initialLoaded } = this.props;

    return initialLoaded;
  };

  getLoadingSkeleton = () => {
    return <PageLoadingText />;
  };

  render() {
    const { classes, dataById, fetchData } = this.props;
    const data = Object.values(dataById);

    if (!this.shouldComponentRender()) {
      return <div className={classes.root}>{this.getLoadingSkeleton()}</div>;
    }

    return (
      <div className={classes.root}>
        <ContentPaper table className={classes.paper}>
          <EnhancedTable
            noCheckbox
            toolbarActions={
              <>
                <Button
                  variant="text"
                  color="primary"
                  className={classes.tableButton}
                  onClick={fetchData}
                  startIcon={<i className="fal fa-sync" />}>
                  Refresh
                </Button>
              </>
            }
            filters={this.getTableFilters()}
            columns={this.getTableColumns()}
            data={data}
            rowCount={data.length}
            order="desc"
            orderBy="id"
          />
        </ContentPaper>
      </div>
    );
  }
}

export default Payments;
