import { call, put } from 'redux-saga/effects';

import { fetchCategoriesSuccess, fetchCategoriesError, fetchCategoryByIdSuccess } from '../actions';
import { parseApiError } from '../../../api/helpers';
import { hideProgressBar, showProgressBar } from '../../ui/actions';
import { categoryApi } from '../../../api/category';
import { enqueueSnackbar } from '../../snackbar/actions';

export function* fetchCategoriesSaga() {
  try {
    yield put(showProgressBar());
    const response = yield call(categoryApi.fetch);
    yield put(hideProgressBar());

    if (!response.ok) {
      yield put(fetchCategoriesError(parseApiError(response).message));
      return;
    }

    yield put(fetchCategoriesSuccess(response.data.data.results || []));
  } catch (err) {
    yield put(fetchCategoriesError(parseApiError(err).message));
  }
}

export function* fetchCategoryByIdSaga(action) {
  try {
    const response = yield call(categoryApi.getById, action.payload.id);
    if (!response.ok) {
      return;
    }

    yield put(fetchCategoryByIdSuccess(action.payload.id, response.data.data || null));
  } catch (err) {
    yield put(enqueueSnackbar(parseApiError(err).message, { options: { variant: 'error' } }));
  }
}
