import { createClient } from './helpers';

const apiClient = createClient();

function fetch() {
  return apiClient.get('discount');
}

function add(data) {
  return apiClient.post('discount', data);
}

function update(id, data) {
  return apiClient.patch(`discount/${id}`, data);
}

function getById(id) {
  return apiClient.get(`discount/${id}`);
}

function deleteById(id) {
  return apiClient.delete(`discount/${id}`);
}

export const discountApi = {
  fetch,
  add,
  update,
  getById,
  deleteById,
};
