import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import { Grid, TextField, InputLabel, FormControl, MenuItem, Select } from '@material-ui/core';
import { Prompt, withRouter } from 'react-router';
import { PAYMENT_METHODS, CFDI_USES } from '../../lib/constants';
import ContentPaper from '../../components/content-paper';
import { invoiceApi } from '../../api/invoice';
import FormActions from '../../components/form-actions';
import { translate } from '../../i18n';

const styles = theme => ({
  root: {
    padding: theme.spacing(0, 2),
  },
  button: {
    marginRight: theme.spacing(2),
  },
  buttonBlock: {
    display: 'block',
    width: '140px',
    margin: '0 auto',
    marginBottom: theme.spacing(1),
  },
  buttonDelete: {
    margin: theme.spacing(1),
    color: theme.palette.error.dark,
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  descriptionField: {
    width: '100%',
  },
  formControl: {
    /* marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit, */
    minWidth: 120,
    width: '100%',
  },
  helperText: {
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  imagePreview: {
    width: '100px',
    height: '100px',
    backgroundColor: '#fafafa',
    margin: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  img: {
    width: 'auto',
    maxHeight: '90%',
    maxWidth: '90%',
    marginTop: '50%',
    transform: 'translateY(-50%)',
  },
});

@withSnackbar
@withStyles(styles)
@withRouter
class InvoiceForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ? props.id : null,
      data: props.data
        ? props.data
        : {
            name: '',
          },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { id } = this.state;
    if (!id && nextProps.id) {
      this.setState({
        id: nextProps.id,
        data: nextProps.data,
      });
    }
  }

  handleChange = (name, value) => event => {
    const { data } = this.state;

    value = typeof value !== 'undefined' ? value : event.target.value;
    this.setState({ data: { ...data, [name]: value }, hasUnsavedChanges: true });
  };

  handleDelete = async () => {
    const { id } = this.state;
    const { onNotification, history } = this.props;

    // Reset form
    this.setState({ hasUnsavedChanges: false });

    // Delete AJAX
    try {
      await invoiceApi.deleteById(id);

      onNotification('Invoice deleted');
      history.push('/invoice');
    } catch (e) {
      onNotification('Could not delete the invoice', { options: { variant: 'error' } });
    }
  };

  handleSubmit = async ev => {
    if (ev) {
      ev.preventDefault();
    }

    const { data, id } = this.state;
    const { onNotification, onSave } = this.props;

    try {
      const response = !id ? await invoiceApi.add(data) : await invoiceApi.update(id, data);

      this.setState({ hasUnsavedChanges: false });
      onNotification('Invoice saved');

      if (onSave) {
        onSave(response);
      }
    } catch (e) {
      onNotification('Could not save the invoice!', { variant: 'error' });
    }

    return false;
  };

  render() {
    const { classes, onCancel } = this.props;

    const { data, hasUnsavedChanges } = this.state;

    const name = data && data.name ? data.name : '';

    return (
      <div className={classes.root}>
        <Prompt
          when={!!hasUnsavedChanges}
          message={translate('You have unsaved changes. Are you sure you want to leave?')}
        />
        <ContentPaper className="FormBoxPaper">
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                autoComplete="off"
                name="name"
                label="Full name"
                className={classes.textField}
                value={name || ''}
                onChange={this.handleChange('name')}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                autoComplete="off"
                name="email"
                label="Email address"
                className={classes.textField}
                value={data.email || ''}
                onChange={this.handleChange('email')}
              />
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                autoComplete="off"
                name="rfc"
                label="RFC"
                className={classes.textField}
                value={data.rfc || ''}
                onChange={this.handleChange('rfc')}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="invoice-type" shrink>
                  CFDI
                </InputLabel>
                <Select
                  value={data.uso_cfdi ? data.uso_cfdi : ''}
                  onChange={this.handleChange('uso_cfdi')}
                  inputProps={{
                    name: 'uso_cfdi',

                    fullwidth: 'false',
                  }}>
                  {_.flatMap(CFDI_USES, (d, i) => {
                    return (
                      <MenuItem key={`usecfdi-${i}`} value={i}>
                        {d}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                autoComplete="off"
                name="amount"
                label="Valor"
                className={classes.textField}
                value={data.amount || ''}
                onChange={this.handleChange('amount')}
                inputProps={{
                  type: 'number',
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="invoice-type" shrink>
                  Payment method
                </InputLabel>
                <Select
                  value={data.forma_pago ? data.forma_pago : ''}
                  onChange={this.handleChange('forma_pago')}
                  inputProps={{
                    name: 'forma_pago',
                    fullwidth: 'false',
                  }}>
                  {_.flatMap(PAYMENT_METHODS, (d, i) => {
                    return (
                      <MenuItem key={`forma_pago-${i}`} value={i}>
                        {d}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </ContentPaper>

        <FormActions onDelete={this.handleDelete} onCancel={onCancel} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default InvoiceForm;
