import React from 'react';
import { Button, Chip, withStyles } from '@material-ui/core';
import { withSnackbar } from 'notistack';

import styles from '../styles';
import EnhancedTable from '../../../components/enhanced-table/EnhancedTable';
import ContentPaper from '../../../components/content-paper';
import ActionMenu from './row-actions';
import PageLoadingText from '../../../components/PageLoadingText';
import { localize, translate } from '../../../i18n';
import { discountApi } from '../../../api/discount';

@withSnackbar
@withStyles(styles)
class Discounts extends React.Component {
  constructor(props) {
    super(props);
    const { fetchData, setPageTitle } = this.props;

    this.typeName = 'discount';

    setPageTitle(translate('Discounts'));
    fetchData();
  }

  handleMassDelete = ids => ids.map(id => this.handleDelete(id));

  handleDelete = async id => {
    const { enqueueSnackbar, fetchData } = this.props;

    // Delete AJAX
    try {
      await discountApi.deleteById(id);

      // All good
      setTimeout(() => fetchData(), 100);
    } catch (err) {
      enqueueSnackbar(translate(`Could not delete the ${this.typeName}`), { options: { variant: 'error' } });
    }
  };

  handleRowClick = ({ id }) => {
    const { history } = this.props;
    history.push(`/discount/edit/${id}`);
  };

  handleAdd = () => {
    const { history } = this.props;
    history.push('/discount/add');
  };

  getTableColumns = () => {
    const { loadingIds } = this.props;

    return [
      {
        id: 'name',
        accessor: 'name',
        sortable: true,
        component: 'td',
        label: translate('Discount name'),
      },
      {
        id: 'value',
        accessor: 'value',
        label: translate('Amount'),
        align: 'right',
        style: {
          width: 130,
        },
        renderValue: row => {
          if (row.type === 'FIXED') {
            return localize('number', row.value, { strip_insignificant_zeros: false });
          }

          if (row.type === 'PERCENT') {
            return `${row.value}${row.type === 'PERCENT' ? '%' : ''}`;
          }

          return row.value;
        },
      },
      {
        id: 'restricted_access',
        accessor: 'restricted_access',
        sortable: true,
        component: 'td',
        label: '',
        style: {
          width: 130,
        },
        renderValue: row =>
          row.restricted_access ? (
            <Chip
              color="primary"
              variant="outlined"
              size="small"
              label={translate('Restricted')}
              style={{ marginLeft: 24 }}
            />
          ) : (
            ''
          ),
      },
      {
        id: 'id',
        accessor: 'id',
        numeric: false,
        label: '',
        align: 'right',
        padding: 'none',
        renderValue: row => (
          <ActionMenu
            isLoading={loadingIds.indexOf(parseInt(row.id, 10)) !== -1}
            handleDelete={this.handleDelete}
            id={row.id}
            data={row}
          />
        ),
      },
    ];
  };

  getTableFilters = () => {
    const { setTableFilter, filters } = this.props;

    return [];
  };

  shouldComponentRender = () => {
    const { initialLoaded } = this.props;

    return initialLoaded;
  };

  getLoadingSkeleton = () => {
    return <PageLoadingText />;
  };

  render() {
    const { classes, dataById } = this.props;
    const data = Object.values(dataById);

    if (!this.shouldComponentRender()) {
      return <div className={classes.root}>{this.getLoadingSkeleton()}</div>;
    }

    return (
      <div className={classes.root}>
        <ContentPaper className={classes.paper}>
          <EnhancedTable
            autoWidth
            toolbarActions={
              <>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleAdd}
                  startIcon={<i className="fal fa-plus" />}>
                  {translate('Add discount')}
                </Button>
              </>
            }
            filters={this.getTableFilters()}
            columns={this.getTableColumns()}
            onRowClick={this.handleRowClick}
            onMassDelete={this.handleMassDelete}
            data={data}
            rowCount={data.length}
            order="asc"
            orderBy="name"
          />
        </ContentPaper>
      </div>
    );
  }
}

export default Discounts;
