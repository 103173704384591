import { makeActionCreator } from '../../../store/actions';

export const MODIFIERS_FETCH_REQUEST = '@modifiers / FETCH REQUEST';
export const MODIFIERS_FETCH_SUCCESS = '@modifiers / FETCH SUCCESS';
export const MODIFIERS_FETCH_ERROR = '@modifiers / FETCH ERROR';
export const MODIFIERS_SHOW_ADD_DIALOG = '@modifiers / SHOW ADD DIALOG';
export const MODIFIERS_HIDE_ADD_DIALOG = '@modifiers / HIDE ADD DIALOG';
export const MODIFIERS_SET_TABLE_FILTER = '@modifiers / SET TABLE FILTER';
export const MODIFIERS_FETCH_MODIFIER_START = '@modifiers / FETCH MODIFIER START';
export const MODIFIERS_FETCH_MODIFIER_SUCCESS = '@modifiers / FETCH MODIFIER SUCCESS';

export const fetchModifiers = makeActionCreator(MODIFIERS_FETCH_REQUEST);
export const fetchModifiersError = makeActionCreator(MODIFIERS_FETCH_ERROR, 'error');
export const fetchModifiersSuccess = makeActionCreator(MODIFIERS_FETCH_SUCCESS, 'data');
export const showModifierAddDialog = makeActionCreator(MODIFIERS_SHOW_ADD_DIALOG);
export const hideModifierAddDialog = makeActionCreator(MODIFIERS_HIDE_ADD_DIALOG);

export const fetchModifierById = makeActionCreator(MODIFIERS_FETCH_MODIFIER_START, 'id');
export const fetchModifierByIdSuccess = makeActionCreator(MODIFIERS_FETCH_MODIFIER_SUCCESS, 'id', 'data');

// Table filters
export const setTableFilter = makeActionCreator(MODIFIERS_SET_TABLE_FILTER, 'name', 'value');
