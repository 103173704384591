import React from 'react';
import { withSnackbar } from 'notistack';
import { Chip, Button, Dialog, withStyles, Table, TableBody, TableCell, TableRow, TableHead } from '@material-ui/core';
import { startCase } from 'lodash';

import PageLoadingText from '../../../components/PageLoadingText';
import { translate } from '../../../i18n';
import { auditApi } from '../../../api/audit';

import '../styles/audit.scss';
import EnhancedTable from '../../../components/enhanced-table/EnhancedTable';
import ContentPaper from '../../../components/content-paper';
import styles from '../styles';

@withSnackbar
@withStyles(styles)
class AuditDatabase extends React.Component {
  constructor(props) {
    super(props);
    const { fetchData, setPageTitle } = this.props;

    this.typeName = 'audit';

    setPageTitle(translate('Audit'));
    fetchData();

    this.state = {
      changesModalData: null,
    };
  }

  handleMassDelete = ids => ids.map(id => this.handleDelete(id));

  handleDelete = async id => {
    const { enqueueSnackbar, fetchData } = this.props;

    // Delete AJAX
    try {
      await auditApi.deleteById(id);

      // All good
      setTimeout(() => fetchData(), 100);
    } catch (err) {
      enqueueSnackbar(translate(`Could not delete the ${this.typeName}`), { variant: 'error' });
    }
  };

  getTableColumns = () => {
    const { classes } = this.props;

    return [
      {
        id: 'action_type',
        accessor: 'action_type',
        sortable: true,
        component: 'td',
        label: translate('Action type'),
        style: { width: '150px' },
        renderValue: row => {
          let label = row.action_type;

          let variant = 'default';
          if (label === 'INSERT') {
            variant = 'default';
            label = 'CREATE';
          } else if (label === 'UPDATE') {
            variant = 'secondary';
          } else if (label === 'DELETE') {
            variant = 'primary';
          }

          return <Chip color={variant} label={label} />;
        },
      },
      {
        id: 'object_type',
        sortable: true,
        component: 'td',
        label: translate('Object'),
        style: { width: '170px' },
        renderValue: row => {
          const c = row.object_type;
          return c.charAt(0).toUpperCase() + c.replace('_', ' ').slice(1);
        },
      },
      {
        id: 'object_name',
        accessor: 'object_name',
        sortable: true,
        component: 'td',
        label: translate('Object name'),
        renderValue: r => (!r.object_name ? '' : `${r.object_name} ${r.object_pk ? `(ID: ${r.object_pk})` : ''}`),
      },

      {
        id: 'created_at',
        sortable: true,
        component: 'td',
        label: translate('Event date'),
        style: { width: '170px' },
        renderValue: row => (
          <div style={{}}>
            {new Date(row.created_at).toLocaleTimeString()}
            <br />
            {new Date(row.created_at).toLocaleDateString()}
          </div>
        ),
      },
      {
        id: 'changed_data',
        accessor: 'changed_data',
        label: '',
        style: { width: '160px', textAlign: 'right' },
        renderValue: row =>
          !Object.values(row.changed_data || []).length ? null : (
            <Button
              variant="text"
              color="primary"
              size="small"
              onClick={() => this.setState({ changesModalData: row.changed_data })}>
              Changed data
            </Button>
          ),
      },
    ];
  };

  getTableFilters = () => {
    const { typesFilter, objectsFilter, setTableFilter, filters } = this.props;

    return [
      {
        label: translate('Action'),
        type: 'select',
        value: filters.action_type,
        onChange: value => setTableFilter('action_type', value),
        options: [
          {
            value: '',
            label: translate('All'),
          },
          ...typesFilter,
        ],
      },
      {
        label: translate('Object'),
        type: 'select',
        value: filters.object_type,
        onChange: value => setTableFilter('object_type', value),
        options: [
          {
            value: '',
            label: translate('All'),
          },
          ...objectsFilter,
        ],
      },
    ];
  };

  shouldComponentRender = () => {
    const { initialLoaded } = this.props;

    return initialLoaded;
  };

  getLoadingSkeleton = () => {
    return <PageLoadingText />;
  };

  render() {
    const { classes, dataById } = this.props;
    const { changesModalData } = this.state;

    const data = Object.values(dataById);

    if (!this.shouldComponentRender()) {
      return <div className={classes.root}>{this.getLoadingSkeleton()}</div>;
    }

    const changesModalDataArr = [];
    if (changesModalData) {
      for (const field in changesModalData) {
        changesModalDataArr.push({ ...changesModalData[field], column: field });
      }
    }

    return (
      <div className={classes.root}>
        <ContentPaper table className={classes.paper}>
          <EnhancedTable
            noCheckbox
            size="small"
            filters={this.getTableFilters()}
            columns={this.getTableColumns()}
            data={data}
            rowCount={data.length}
            order="desc"
            orderBy="created_at"
          />
        </ContentPaper>

        {!changesModalData ? null : (
          <Dialog
            fullWidth
            maxWidth="md"
            open={!!changesModalData}
            onClose={() => this.setState({ changesModalData: null })}>
            <div style={{ padding: 15 }}>
              <p>The following data has changed:</p>
              <Table size="small" striped className="size13">
                <TableHead>
                  <TableRow>
                    <TableCell>Field</TableCell>
                    <TableCell>Old Value</TableCell>
                    <TableCell>New Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {changesModalDataArr.map(changedData => {
                    // Prepare column
                    const column = changedData && changedData.column;
                    const columnNice = startCase(column);

                    // Format the data, if needed
                    let oldValue = '-';
                    let newValue = '-';

                    if (changedData && changedData.old) {
                      oldValue = changedData.old.toString();

                      // Unix timestamp?
                      if (oldValue.match(/^[0-9]{10}$/)) {
                        oldValue = new Date(oldValue).toLocaleString();
                      }
                    }

                    if (changedData && changedData.new) {
                      if (typeof changedData.new !== 'string') {
                        newValue = JSON.stringify(changedData.new);
                      } else {
                        newValue = changedData.new.toString();
                      }

                      // Unix timestamp?
                      if (newValue.match(/^[0-9]{10}$/)) {
                        newValue = new Date(newValue).toLocaleString();
                      }
                    }

                    return (
                      <TableRow key={`cmda-${column}`}>
                        <TableCell>{columnNice}</TableCell>
                        <TableCell>{oldValue}</TableCell>
                        <TableCell>{newValue}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </Dialog>
        )}
      </div>
    );
  }
}

export default AuditDatabase;
