import React from 'react';
import UAParser from 'ua-parser-js';
import AppState from '../../lib/stores/AppState';
import PageLoadingText from '../../components/PageLoadingText';
import OnTap from './OnTap';
import FoodMenu from './FoodMenu';
import ApiClient from '../../components/ApiClient';
import FoodMenuImage from './FoodMenuImage';

import { ONLINE_MENU_DISPLAY_ID } from '../../config';

function getDPI() {
  const div = document.createElement('div');
  div.style.height = '1in';
  div.style.width = '1in';
  div.style.top = '-100%';
  div.style.left = '-100%';
  div.style.position = 'absolute';

  document.body.appendChild(div);

  const result = div.offsetHeight;

  document.body.removeChild(div);

  return result;
}

class ContentTypeRouter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      display: null,
    };
  }

  componentDidMount() {
    AppState.setConfig({
      CurrentPageTitle: '',
      AppBarSearch: false,
    });

    this.loadDisplayConfig();
  }

  loadDisplayConfig = async () => {
    try {
      const displayId = AppState.getConfig('CurrentDisplayId');
      const isEmbedded = AppState.getConfig('CurrentDisplayIsEmbedded');
      const parser = new UAParser();

      const e = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const t = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      const n = window.screen;
      const device = parser.getDevice();
      const headers = {
        'Campfire-Browser': parser.getBrowser().name,
        'Campfire-Device': device.vendor || parser.getBrowser().name,
        'Campfire-OS': parser.getOS().name,
        'Campfire-Screen-Width': n.width,
        'Campfire-Screen-Height': n.height,
        'Campfire-Color-Depth': n.colorDepth,
        'Campfire-Pixel-Depth': n.pixelDepth,
        'Campfire-Window-Width': e,
        'Campfire-Window-Height': t,
        'Campfire-Screen-DPI': getDPI(),
      };

      // Send request
      const response = await ApiClient.get({
          endpoint: `/api/display/${displayId}`, headers, query: { embedded: isEmbedded ? 1 : 0}
        });

      if (response && response.body && response.body.data) {
        this.setState({ display: response.body.data }, () => {
          this.timer = setTimeout(this.loadDisplayConfig.bind(this), 120000);
        });
      }
    } catch (err) {}
  };

  render() {
    const { display } = this.state;

    if (!display || !display.id) {
      return (
        <div style={{ textAlign: 'center' }}>
          <PageLoadingText size={50} />
        </div>
      );
    }

    const isOnlineMenuDisplay = AppState.getConfig('CurrentDisplayId') === ONLINE_MENU_DISPLAY_ID

    if (!display.display_content || display.display_content === 'ontap') {
      return <OnTap responsive={isOnlineMenuDisplay} {...this.props} />;
    }
    if (display.display_content.indexOf('menu/image') === 0) {
      return <FoodMenuImage responsive={isOnlineMenuDisplay} {...this.props} />;
    }
    if (display.display_content.indexOf('menu/') === 0) {
      return <FoodMenu responsive={isOnlineMenuDisplay} {...this.props} />;
    }

    return null;
  }
}

export default ContentTypeRouter;
