import ApiClient from "../components/ApiClient";

const apiEndpointBase = '/api/location';

const LocationService = {
    add(data, callback) {
        if ( callback ) {
            ApiClient.post({
                endpoint: apiEndpointBase,
                data: data
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.post({
            endpoint: apiEndpointBase,
            data: data
        });
    },

    delete(id) {
        const uri = apiEndpointBase + '/' + id;

        return ApiClient.delete({
            endpoint: uri,
        });
    },

    fetch(id, callback) {

        if ( callback ) {
            ApiClient.get({
                endpoint: apiEndpointBase + '/' + id
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            });

            return;
        }

        return ApiClient.get({
            endpoint: apiEndpointBase + '/' + id
        });
    },

    find(filters) {
        return ApiClient.get({
            endpoint: apiEndpointBase,
            query: filters
        });
    },

    fetchAll(callback) {

        if ( callback ) {
            ApiClient.get({
                endpoint: apiEndpointBase + '/'
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.get({
            endpoint: apiEndpointBase + '/'
        });
    },

    update(id, data, callback) {
        if ( callback ) {
            ApiClient.patch({
                endpoint: apiEndpointBase + '/' + id,
                data: data
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.patch({
            endpoint: apiEndpointBase + '/' + id,
            data: data
        });
    },

    addIngredient(ingredient_id, location_id) {

        return ApiClient.post({
            endpoint: apiEndpointBase + '/' + location_id + '/ingredient/' + ingredient_id,
            data: {
                ingredient_id
            }
        });
    },

    removeIngredient(ingredient_id, location_id) {

        return ApiClient.delete({
            endpoint: apiEndpointBase + '/' + location_id + '/ingredient/' + ingredient_id,
            data: {
                ingredient_id
            }
        });
    },
};

export default LocationService;
