import produce from 'immer';
import { denormalize, normalize, schema } from 'normalizr';
import _ from 'lodash';

import {
  MODIFIERS_FETCH_REQUEST,
  MODIFIERS_FETCH_SUCCESS,
  MODIFIERS_FETCH_ERROR,
  MODIFIERS_HIDE_ADD_DIALOG,
  MODIFIERS_SHOW_ADD_DIALOG,
  MODIFIERS_SET_TABLE_FILTER,
  MODIFIERS_FETCH_MODIFIER_START,
  MODIFIERS_FETCH_MODIFIER_SUCCESS,
} from '../actions';

const INITIAL_STATE = {
  entities: {
    modifiers: {},
  },
  entitiesIds: [],
  selected: null,

  // Filters
  filters: {
    search: null,
    modifier_type: null,
  },

  // Other state
  showAddDialog: false,
  loading: false,
  initialLoaded: false,
  loadingId: null,
  loadingIds: [],

  fetchError: null,
  saveError: null,
};

// Define the order schema so we can normalize the data and index it properly
const modifierOptionsSchema = new schema.Entity('options');
const modifiersSchema = new schema.Entity('modifiers', /*{
  options: [modifierOptionsSchema],
}*/);
const modifierListSchema = new schema.Array(modifiersSchema);

export default function modifiers(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case MODIFIERS_FETCH_SUCCESS:
        // Normalize the data for better access
        const { data } = action.payload;
        const normalized = normalize(data, modifierListSchema);
        draft.entities = normalized.entities;
        draft.entitiesIds = normalized.result;
        draft.loading = false;
        draft.initialLoaded = true;
        draft.fetchError = null;
        break;

      case MODIFIERS_FETCH_REQUEST:
        draft.loading = true;
        draft.fetchError = null;
        break;

      case MODIFIERS_FETCH_ERROR:
        draft.fetchError = action.payload.error;
        break;

      case MODIFIERS_HIDE_ADD_DIALOG:
        draft.showAddDialog = false;
        break;

      case MODIFIERS_SHOW_ADD_DIALOG:
        draft.showAddDialog = true;
        break;

      case MODIFIERS_SET_TABLE_FILTER:
        draft.filters[action.payload.name] = action.payload.value;
        break;

      case MODIFIERS_FETCH_MODIFIER_START:
        draft.loadingId = action.payload.id;
        break;

      case MODIFIERS_FETCH_MODIFIER_SUCCESS:
        // eslint-disable-next-line no-use-before-define
        draft = addOrUpdateModifierInStore(state, action, draft);

        break;

      default:
    }
  });
}

// Selectors
export function getFilteredModifiers(state) {
  const { entities, filters } = state.modifiers;

  return _.filter(entities.modifiers, o => {
    if (filters.search && o.name.toLowerCase().indexOf(filters.search.toLowerCase()) === -1) {
      return false;
    }

    if (filters.modifier_type && o.modifier_type !== filters.modifier_type) {
      return false;
    }

    return true;
  });
}

export const getModifierByIdSelector = (state, id) => {
  return state.modifiers && state.modifiers.entities && state.modifiers.entities.modifiers
    ? state.modifiers.entities.modifiers[id]
    : {};
};

// Data management functions
const addOrUpdateModifierInStore = (state, action, draft) => {
  let modifiersArr = denormalize(state.entitiesIds, modifierListSchema, state.entities);
  const rowIndex = state.entitiesIds.indexOf(parseInt(action.payload.id, 10));

  if (rowIndex === -1) {
    modifiersArr = [...modifiersArr, action.payload.data];
  } else {
    modifiersArr = [...modifiersArr.slice(0, rowIndex), action.payload.data, ...modifiersArr.slice(rowIndex + 1)];
  }

  const singleModifier = normalize(modifiersArr, modifierListSchema);
  draft.entities = singleModifier.entities;
  draft.entitiesIds = singleModifier.result;

  // Reset loading
  if (state.loadingId === action.payload.id) {
    draft.loadingId = null;
  }

  return draft;
};
