import { makeActionCreator } from '../../../store/actions';

export const PRINTERS_FETCH_REQUEST = '@printers / FETCH REQUEST';
export const PRINTERS_FETCH_SUCCESS = '@printers / FETCH SUCCESS';
export const PRINTERS_FETCH_ERROR = '@printers / FETCH ERROR';
export const PRINTERS_SHOW_ADD_DIALOG = '@printers / SHOW ADD DIALOG';
export const PRINTERS_HIDE_ADD_DIALOG = '@printers / HIDE ADD DIALOG';
export const PRINTERS_SET_TABLE_FILTER = '@printers / SET TABLE FILTER';
export const PRINTERS_FETCH_PRINTER_START = '@printers / FETCH PRINTER START';
export const PRINTERS_FETCH_PRINTER_SUCCESS = '@printers / FETCH PRINTER SUCCESS';

export const fetchPrinters = makeActionCreator(PRINTERS_FETCH_REQUEST);
export const fetchPrintersError = makeActionCreator(PRINTERS_FETCH_ERROR, 'error');
export const fetchPrintersSuccess = makeActionCreator(PRINTERS_FETCH_SUCCESS, 'data');
export const showPrinterAddDialog = makeActionCreator(PRINTERS_SHOW_ADD_DIALOG);
export const hidePrinterAddDialog = makeActionCreator(PRINTERS_HIDE_ADD_DIALOG);

export const fetchPrinterById = makeActionCreator(PRINTERS_FETCH_PRINTER_START, 'id');
export const fetchPrinterByIdSuccess = makeActionCreator(PRINTERS_FETCH_PRINTER_SUCCESS, 'id', 'data');

// Table filters
export const setTableFilter = makeActionCreator(PRINTERS_SET_TABLE_FILTER, 'name', 'value');
