import { connect } from 'react-redux';
import MenuEdit from '../components/menu-edit';
import {
  addMenu,
  addMenuCategory,
  fetchMenus,
  getMenuById,
  saveMenu,
  sortCategories,
  sortProducts,
  updateMenuCategory,
  updateMenuData,
} from '../actions';
import { getMenuByIdSelector, getMenuCategoriesSelector } from '../reducers';
import { setPageTitle } from '../../ui/actions';
import { fetchProducts } from '../../product/actions';

function mapStateToProps(state, props) {
  return {
    categories: props.id ? getMenuCategoriesSelector(state, props.id) : [],
    data: props.id ? getMenuByIdSelector(state, props.id) : {},
    loadingId: state.menus.loadingId,
  };
}

const mapDispatchToProps = {
  setPageTitle,
  fetchMenus,
  fetchProducts,
  getMenuById,
  addMenu,
  saveMenu,
  addMenuCategory,
  updateMenuCategory,
  updateMenuData,
  handleSortCategories: sortCategories,
  handleSortProducts: sortProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuEdit);
