import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TableCell, TableContainer } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import { makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import { localize } from '../../../i18n';
import { datetime } from '../../../i18n/time';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const DiscountModal = ({ data, handleClose, ...props }) => {
  const classes = useStyles();

  return (
    <Dialog open onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{data.name}</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Receipt no.</TableCell>
                <TableCell>Table name</TableCell>
                <TableCell>Employee</TableCell>
                <TableCell>Date</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data.checks || []).map(row => (
                <TableRow key={row.c}>
                  <TableCell component="th" scope="row">
                    {row.c}
                  </TableCell>
                  <TableCell>{row.t}</TableCell>
                  <TableCell>{row.n}</TableCell>
                  <TableCell>{datetime(row.d)}</TableCell>
                  <TableCell align="right">{localize('number', row.v, { strip_insignificant_zeros: false })}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscountModal;
