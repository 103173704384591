import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, Redirect, withRouter } from 'react-router-dom';
import _ from 'lodash';

import { Checkbox, FormControlLabel, withStyles, IconButton, Menu, MenuItem, Link } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import ApiClient from '../../components/ApiClient';
import InvoiceService from '../../services/InvoiceService';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';
import { PAYMENT_METHODS } from '../../lib/constants';
import TableLoadingOverlay from '../../components/TableLoadingOverlay';
import ContentPaper from '../../components/content-paper';
import { translate } from '../../i18n';
import {
  setPageTitle,
  hideProgressBar as hideProgressBarAction,
  showProgressBar as showProgressBarAction,
} from '../ui/actions';

const styles = theme => ({
  root: {},
  paper: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto',
    position: 'relative',
  },

  danger: {
    color: theme.palette.error.dark,
  },
  table: {
    minWidth: 700,
  },

  tableButton: {
    marginRight: theme.spacing(2),
  },
});

@withSnackbar
@withStyles(styles)
class InvoicesTable extends React.Component {
  constructor(props) {
    super(props);

    props.setPageTitle(translate('Invoices'));

    this.state = {
      data: [],
      includeGenerated: false,
    };
  }

  load = () => {
    const { includeGenerated } = this.state;
    const { showProgressBar, hideProgressBar } = this.props;
    showProgressBar();

    ApiClient.get({
      endpoint: '/api/invoice',
      query: {
        all: includeGenerated ? 1 : 0,
      },
    }).then(
      res => {
        hideProgressBar();
        this.setState({
          data: res.body.data && res.body.data.results ? res.body.data.results : [],
        });
      },
      () => hideProgressBar(),
    );
  };

  handleMassDelete = ids => {
    if (ids) {
      this.massDelete(ids);
    }
  };

  deleteInvoice = (id, callback) => {
    // Delete AJAX
    InvoiceService.delete(id).then(res => {
      if (callback) {
        callback(res);
      } else {
        setTimeout(() => this.load(), 100);
      }
    });
  };

  generateInvoice = (id, index, callback) => {
    this.setState({
      loadingRow: index,
    });

    // Delete AJAX
    InvoiceService.generate(id).then(
      res => {
        if (callback) {
          callback(res);
        } else {
          this.props.enqueueSnackbar(translate('The invoice has been generated'), { variant: 'success' });
          this.setState({
            loadingRow: false,
          });
          setTimeout(() => this.load(), 100);
        }
      },
      err => {
        const errorMsg =
          err.response && err.response.body && err.response.body.error
            ? err.response.body.error
            : 'API communication error';

        this.props.enqueueSnackbar(errorMsg, { variant: 'error' });

        this.setState({
          loadingRow: false,
        });
      },
    );
  };

  massDelete = ids => {
    ids.map(id => {
      this.deleteInvoice(id, () => {
        const { data } = this.state;
        this.setState({
          data: _.filter(data, o => o.id !== id),
        });
      });
    });
  };

  getColumns = () => [
    {
      id: 'name',
      accessor: 'name',
      sortable: true,
      component: 'th',

      padding: 'none',
      label: translate('Name'),
      renderValue: row => row.name,
    },
    {
      id: 'email',
      accessor: 'email',
      numeric: false,
      label: 'Email address',
      renderValue: row => row.email,
    },
    {
      id: 'rfc',
      accessor: 'rfc',
      numeric: false,
      label: 'RFC',
      renderValue: row => row.rfc,
    },
    /* {
            id: 'uso_cfdi',
            accessor: 'uso_cfdi',
            numeric: false,
            label: 'CFDI',
            renderValue: (row) => row.uso_cfdi ? CFDI_USES[row.uso_cfdi] : '-',
        }, */
    {
      id: 'forma_pago',
      accessor: 'forma_pago',
      numeric: false,
      label: 'Payment',
      renderValue: row => (row.forma_pago ? PAYMENT_METHODS[row.forma_pago] : '-'),
    },
    {
      id: 'amount',
      accessor: 'amount',
      numeric: false,
      label: 'Amount',
      renderValue: row => `$ ${row.amount}`,
    },
    {
      id: 'receipt',
      accessor: 'receipt',
      numeric: false,
      label: 'Receipt',
      renderValue: row => {
        if (!row.receipt_image) {
          return null;
        }

        return (
          <Link
            variant="body2"
            href={row.receipt_image.secure_url}
            target="_blank"
            onClick={event => event.stopPropagation()}>
            View receipt
          </Link>
        );
      },
    },
    {
      id: 'id',
      accessor: 'id',
      numeric: false,
      label: '',
      align: 'center',
      padding: 'none',
      renderValue: (row, idx) => (
        <ActionMenu
          handleGenerate={this.generateInvoice}
          handleDelete={this.deleteInvoice}
          id={row.id}
          index={idx}
          data={row}
        />
      ),
    },
  ];

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.searchQuery !== prevProps.searchQuery) {
      this.setState({
        search: this.props.searchQuery,
      });
    }
  }

  render() {
    const { classes } = this.props;
    let { data, search, loadingRow } = this.state;

    data = !search
      ? data
      : _.filter(
          data,
          d =>
            d.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            d.email.toLowerCase().indexOf(search.toLowerCase()) > -1,
          /* || d.rfc.toLowerCase().indexOf(search.toLowerCase()) > -1 */
        );

    return (
      <div className={classes.root}>
        <ContentPaper table className="FiltersPaper">
          <FormControlLabel
            className={classes.tableButton}
            control={
              <Checkbox
                checked={!!this.state.includeGenerated}
                onChange={() => this.setState({ includeGenerated: !this.state.includeGenerated }, this.load)}
                value="includeGenerated"
              />
            }
            label="Include generated invoices"
          />

          {/* <AddInvoiceDialog /> */}
        </ContentPaper>

        <ContentPaper table className={classes.paper}>
          {!loadingRow ? null : <TableLoadingOverlay />}

          <EnhancedTable
            toolbarActions={
              <>
                <Button
                  variant="text"
                  color="primary"
                  className={classes.tableButton}
                  onClick={this.load}
                  startIcon={<i className="fal fa-sync" />}>
                  Refresh
                </Button>
              </>
            }
            columns={this.getColumns()}
            data={data}
            rowCount={data.length}
            className={classes.table}
            onMassDelete={this.handleMassDelete}
            filter={null}
            loading={loadingRow}
            order="desc"
            orderBy="created_at"
          />
        </ContentPaper>
      </div>
    );
  }
}

@withStyles(styles)
@withRouter
class ActionMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: null,
      anchorEl: null,
      loadingGenerate: false,
    };
  }

  handleClick = event => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = event => {
    event.stopPropagation();
    this.setState({ anchorEl: null });
  };

  handleDelete = () => {
    this.props.handleDelete(this.props.id);
  };

  triggerDelete = id => {
    this.setState({ deleting: true });
  };

  cancelDelete = () => {
    this.setState({ deleting: false });
  };

  handleGenerate = () => {
    this.setState({
      loadingGenerate: true,
    });
    this.props.handleGenerate(this.props.id, this.props.index);
  };

  render() {
    const { anchorEl, deleting } = this.state;
    const { key, classes, data, history } = this.props;

    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={anchorEl ? key : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          style={{ padding: '5px' }}>
          <MoreVertIcon />
        </IconButton>
        <Menu id={key} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          <MenuItem
            onClick={event => {
              this.handleClose(event);
              history.push(`/invoice/edit/${data.id}`);
            }}>
            Edit
          </MenuItem>

          <MenuItem
            onClick={event => {
              this.handleClose(event);
              this.handleGenerate(data.id);
            }}>
            Generate invoice
          </MenuItem>

          <MenuItem
            onClick={event => {
              this.handleClose(event);
              this.triggerDelete(data.id);
            }}
            className={classes.danger}>
            Remove
          </MenuItem>
        </Menu>

        {!deleting ? null : (
          <ConfirmationDialog
            message={`Are you sure you want to delete the invoice for '${data.name}'?`}
            confirm={this.handleDelete}
            cancel={this.cancelDelete}
            show
          />
        )}
      </div>
    );
  }
}

export default connect(null, {
  setPageTitle,
  hideProgressBar: hideProgressBarAction,
  showProgressBar: showProgressBarAction,
})(InvoicesTable);
