import React from 'react';
import { CircularProgress, Paper, Button, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import ApiClient from '../ApiClient';
import { translate } from '../../i18n';
import { enqueueSnackbar as enqueueSnackbarAction } from '../../features/snackbar/actions';

import '../../theme/scss/modules/_m-image-element.scss';
import Icon from '../Icon';

class Image extends React.Component {
  constructor(props) {
    super(props);

    this.fileInput = document.createElement('input');
    this.fileInput.setAttribute('type', 'file');
    this.fileInput.addEventListener('change', event => {
      this.handleFileSelected(event);
    });

    this.state = {
      image: props.image || null,
      loading: false,
    };
  }

  handleFileSelected = event => {
    const { enqueueSnackbar, onChange } = this.props;

    if (!event.target.files || !event.target.files[0]) {
      return false;
    }

    this.setState({ loading: true });
    const req = ApiClient.getPostRequestObject('/api/media/image');
    req.attach('image', event.target.files[0]);

    req.end((err, res) => {
      if (err || !res.ok) {
        enqueueSnackbar(
          translate('The media file could not be uploaded: {{error}}', {
            error: res.body && res.body.error ? res.body.error : 'Server error',
          }),
          { variant: 'error' },
        );

        return true;
      }

      // All good
      const { image } = res.body;

      this.setState(
        {
          image,
          loading: false,
        },
        () => onChange && onChange(image),
      );

      return true;
    });
  };

  handleChange = (name, value) => event => {
    const { onChange } = this.props;

    value = typeof value !== 'undefined' ? value : event.target.value;
    this.setState({ [name]: value }, () => onChange && onChange(value));
  };

  handleRemoveImage = () => {
    this.handleChange('image', null)();
    if( this.fileInput) {
      this.fileInput.value = '';
    }
  };

  render() {
    const { image, loading } = this.state;

    return (
      <div className="image-element text-center">
        <Paper elevation={0} square className="image-preview">
          {!image || !image.secure_url ? null : <img alt="" src={image.secure_url} className="" />}
        </Paper>

        <div className="image-buttons">
          {(!image || !image.secure_url) && !loading ? (
            <IconButton
              disableRipple
              disableFocusRipple
              disableTouchRipple
              style={{ minWidth: 'auto' }}
              onClick={() => this.fileInput.click()}
              disabled={loading}
              title={translate('Change image')}>
              <Icon name="camera" solid />
            </IconButton>
          ) : null}

          {!loading ? null :
            <div className="loading-icon">
              <CircularProgress
                variant="indeterminate"
                color="secondary" />
            </div>
          }
        </div>

        {!image || !image.secure_url ? null : (
          <Button onClick={this.handleRemoveImage} title={translate('Remove')} disabled={loading}>
            {translate('Remove')}
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = null;
const mapDispatchToProps = {
  enqueueSnackbar: enqueueSnackbarAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Image);
