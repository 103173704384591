import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Box, IconButton, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';

import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import ApiClient from '../../../components/ApiClient';

const styles = (theme) => ({
  root: {},

  center: {
    textAlign: 'center',
  },

  wrapper: {
    position: 'relative',
    display: 'inline-block',
  },

  noMarginTop: {
    marginTop: 0,
  },

  predefined: {
    textTransform: 'none',
    fontWeight: 'normal',
    margin: theme.spacing(0, 1, 1, 0),
  },

  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

const predefinedAnswers = [
  '¡Tu pedido está listo! Estamos esperando que lo recojas.',
  'Su pedido está listo y en camino hacia usted.',
];

@withSnackbar
@withWidth()
@withStyles(styles)
class OrderContact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      subject: 'Actualización del pedido',

      useEmail: true,
      useWhatsapp: false,
      useSms: false,
      isLoading: false,
    };
  }

  handleClose = () => {
    const { handleClose } = this.props;
    handleClose();
  };

  handleChange = (event) => {
    const { state } = this;
    this.setState({ ...state, [event.target.name]: event.target.checked });
  };

  handleChangeData = (event) => {
    const { state } = this;
    this.setState({ ...state, [event.target.name]: event.target.value });
  };

  handleSubmit = () => {
    const { data, enqueueSnackbar } = this.props;
    const { useEmail, message, subject } = this.state;

    this.setState({ isLoading: true });

    ApiClient.post({
      endpoint: `/api/order/${data.id_string}/contact`,
      data: {
        useEmail,
        message,
        subject,
      },
    }).then(
      () => {
        this.setState({ isLoading: false });
        enqueueSnackbar('The client has been contacted', { variant: 'success' });
        setTimeout(() => this.handleClose(), 100);
      },
      () => {
        this.setState({ isLoading: false });
        enqueueSnackbar('An error occurred while contacting the client', { variant: 'error' });
      },
    );
  };

  render() {
    const { isLoading, message, subject, useEmail, useWhatsapp, useSms } = this.state;

    const { classes, width } = this.props;

    const sendDisabled = (!useEmail && !useWhatsapp && !useSms) || !message || isLoading;

    return (
      <Dialog fullScreen={!!isWidthDown('sm', width)} fullWidth maxWidth="sm" onClose={this.handleClose} open>
        <DialogContent>
          <div style={{ float: 'right' }}>
            <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>

          <Box pb={2}>
            <Box pb={2}>
              <Typography variant="subtitle1">Contact via</Typography>
              <FormHelperText className={classes.noMarginTop}>Some options are disabled for now.</FormHelperText>

              <FormControlLabel
                control={<Checkbox checked={useEmail} onChange={this.handleChange} name="useEmail" />}
                label="Email"
              />
              <FormControlLabel
                disabled
                control={<Checkbox checked={useWhatsapp} onChange={this.handleChange} name="useWhatsapp" />}
                label="WhatsApp"
              />
              <FormControlLabel
                disabled
                control={<Checkbox checked={useSms} onChange={this.handleChange} name="useSms" />}
                label="SMS"
              />
            </Box>

            <Box pb={2}>
              <TextField
                fullWidth
                id="contact-client-subject"
                label="Email subject"
                value={subject}
                onChange={this.handleChangeData}
                variant="outlined"
                name="subject"
              />
            </Box>

            <Box pb={2}>
              <TextField
                fullWidth
                id="contact-client-message"
                label="Enter a message for the client"
                multiline
                rows="4"
                value={message}
                onChange={this.handleChangeData}
                variant="outlined"
                name="message"
              />
            </Box>

            <Box pb={2}>
              <Typography variant="subtitle1">Predefined messages</Typography>
              {predefinedAnswers.map((text, idx) => {
                return (
                  <Button
                    key={`pmb-${idx}`}
                    className={classes.predefined}
                    onClick={() => this.handleChangeData({ target: { name: 'message', value: text } })}
                    variant="outlined"
                    color="secondary"
                    size="small">
                    {text}
                  </Button>
                );
              })}
            </Box>

            <Box pb={2} className={classes.center}>
              <div className={classes.wrapper}>
                {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                <Button color="primary" onClick={this.handleSubmit} variant="contained" disabled={sendDisabled}>
                  Send message
                </Button>
              </div>
              &nbsp;&nbsp;&nbsp;
              <Button color="secondary" onClick={this.handleClose} variant="outlined">
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}

export default OrderContact;
