import React from 'react'

import { withStyles } from '@material-ui/styles';

import classnames from 'classnames'

const styles = theme => ({
    animate: {

    },

    '@keyframes fadeInOpacity': {
        from: {
            opacity: 0,
        },
        to: {
            opacity: 1,
        }
    },

    '@keyframes fadeOutOpacity': {
        from: {
            opacity: 1,
        },
        to: {
            opacity: 0,

        }
    },

    boardAd: {
        zIndex: -99,
    },

    boardAdVisible: {
        bottom: 0,
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: 9999,
    },

    boardAdElement: {
        display: 'none',
        height: '100%',
        width: '100%',
        backgroundColor: '#000000',

    },

    boardAdElementWillHide: {
        display: 'block',
        opacity: 0,

        animationName: '$fadeOutOpacity',
        animationTimingFunction: 'ease-in',
        animationDuration: '0.5s',
    },

    boardAdElementVisible: {
        display: 'block',
        opacity: 1,

        animationName: '$fadeInOpacity',
        animationTimingFunction: 'ease-in',
        animationDuration: '0.7s'
    },

    boardAdImageContent: {
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        height: '100%',
        width: '100%'
    },

    embedResponsiveItem: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        height: '100%',
        width: '100%',
        border: 0
    },

    embedResponsive: {
        position: 'relative',
        display: 'block',
        height: 0,
        padding: 0,
        overflow: 'hidden',
        paddingBottom: '56.25%'
    },


});

class Media extends React.Component {


    getAdTag() {
        const isVisible = this.props.active;
        const {
            data,
            classes,
            adWillHide
        } = this.props;

        const imgCx = classnames(
            classes.boardAdElement,
            isVisible && !adWillHide && classes.boardAdElementVisible,
            adWillHide && classes.boardAdElementWillHide
        )

        if ( 'image' === data.media_type ) {

            const imgStyle = {
                backgroundImage : 'url(' + data.media_url + ')'
            };

            return (
                <div className={imgCx}>
                    <div style={imgStyle} className={classes.boardAdImageContent}>
                        <img src={data.media_url} style={{display: 'none'}} alt="" />
                    </div>
                </div>

            )
        }

        if ( 'youtube' === data.media_type ) {

            const stepIsVisible = isVisible ? 1 : 0;

            let videoUrl = data.media_url.replace('autoplay=0', 'autoplay=' + stepIsVisible);
            videoUrl = videoUrl.replace('autoplay=1', 'autoplay=' + stepIsVisible);

            return (
                <div className={imgCx}>
                    <div className={classnames(classes.embedResponsive)}>
                        <iframe title="Youtube Video" allow="autoplay; encrypted-media" className={classes.embedResponsiveItem} src={videoUrl}></iframe>
                    </div>
                </div>

            )
        }

        return null;
    }


    render() {
        var isVisible = this.props.active;
        const {classes} = this.props;

        var cx = classnames(
            classes.boardAd,
            isVisible && classes.boardAdVisible
            );

        return(
            <div className={cx}>
                {this.getAdTag()}
            </div>
        );
    }
}

export default withStyles(styles)(Media);
