import React from 'react';
import { connect } from 'react-redux';
import Printers from '../components/printers';
import { fetchPrinters, hidePrinterAddDialog, setTableFilter, showPrinterAddDialog } from '../actions';
import { setPageTitle } from '../../ui/actions';
import { getFilteredPrinters } from '../reducers';
import { enqueueSnackbar } from '../../snackbar/actions';
import { fetchIngredients } from '../../ingredient/actions';

function mapStateToProps(state) {
  const printers = getFilteredPrinters(state);

  return {
    dataById: printers || {},
    data: state.printers.entitiesIds || [],
    filters: state.printers.filters,
    selected: state.printers.selected,
    loading: state.printers.loading,
    initialLoaded: state.printers.initialLoaded,
    fetchError: state.printers.fetchError,
    loadingIds: state.printers.loadingIds,
  };
}

const mapDispatchToProps = {
  fetchData: fetchPrinters,
  fetchIngredients,
  setPageTitle,
  setTableFilter,
  showAddDialog: showPrinterAddDialog,
  hideAddDialog: hidePrinterAddDialog,
  enqueueSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Printers);
