import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import _ from 'lodash';
import { withSnackbar } from 'notistack';

import styles from '../styles';
import EnhancedTable from '../../../components/enhanced-table/EnhancedTable';
import ContentPaper from '../../../components/content-paper';
import ActionMenu from './row-actions';
import PageLoadingText from '../../../components/PageLoadingText';
import { translate } from '../../../i18n';
import { venueApi } from '../../../api/venue';
import VenueAddDialog from '../containers/venue-add-dialog';

@withSnackbar
@withStyles(styles)
class Venues extends React.Component {
  constructor(props) {
    super(props);
    const { fetchData, setPageTitle } = this.props;

    this.typeName = 'venue';

    setPageTitle(translate('Venues'));
    fetchData();
  }

  handleMassDelete = ids => ids.map(id => this.handleDelete(id));

  handleDelete = async id => {
    const { enqueueSnackbar, fetchData } = this.props;

    // Delete AJAX
    try {
      await venueApi.deleteById(id);

      // All good
      setTimeout(() => fetchData(), 100);
    } catch (err) {
      enqueueSnackbar(translate(`Could not delete the ${this.typeName}`), { options: { variant: 'error' } });
    }
  };

  handleRowClick = ({ id }) => {
    const { history } = this.props;
    history.push(`/venue/edit/${id}`);
  };

  handleAdd = () => {
    const { showAddDialog } = this.props;
    showAddDialog();
  };

  getTableColumns = () => {
    const { loadingIds } = this.props;

    return [
      {
        id: 'name',
        accessor: 'name',
        sortable: true,
        component: 'td',

        label: translate('Name'),
      },
      {
        id: 'phone',
        accessor: 'phone',
        label: translate('Phone'),
      },
      {
        id: 'country',
        accessor: 'country',
        label: translate('Country'),
      },
      {
        id: 'unit_system',
        accessor: 'unit_system',
        label: translate('Measure system'),
        renderValue: (row) => _.capitalize(row.unit_system),
      },
      {
        id: 'id',
        accessor: 'id',
        numeric: false,
        label: '',
        align: 'right',
        padding: 'none',
        renderValue: row => (
          <ActionMenu
            isLoading={loadingIds.indexOf(parseInt(row.id, 10)) !== -1}
            handleDelete={this.handleDelete}
            id={row.id}
            data={row}
          />
        ),
      },
    ];
  };

  getTableFilters = () => {
    const { setTableFilter, filters } = this.props;

    return [
      {
        label: translate('Country'),
        type: 'select',
        value: filters.country,
        onChange: value => setTableFilter('country', value),
        options: [
          {
            value: '',
            label: translate('All'),
          },
          {
            value: 'USA',
            label: translate('USA'),
          },
          {
            value: 'Mexico',
            label: translate('Mexico'),
          },
        ],
      },
      {
        label: translate('Search'),
        type: 'search',
        value: filters.search,
        onChange: value => setTableFilter('search', value),
      },
    ];
  };

  shouldComponentRender = () => {
    const { initialLoaded } = this.props;

    return initialLoaded;
  };

  getLoadingSkeleton = () => {
    return <PageLoadingText />;
  };

  render() {
    const { classes, dataById } = this.props;
    const data = Object.values(dataById);

    if (!this.shouldComponentRender()) {
      return <div className={classes.root}>{this.getLoadingSkeleton()}</div>;
    }

    return (
      <div className={classes.root}>
        <VenueAddDialog />
        <ContentPaper table className={classes.paper}>
          <EnhancedTable
            toolbarActions={
              <>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleAdd}
                  startIcon={<i className="fal fa-plus" />}>
                  {translate('Add venue')}
                </Button>
              </>
            }
            filters={this.getTableFilters()}
            columns={this.getTableColumns()}
            onRowClick={this.handleRowClick}
            onMassDelete={this.handleMassDelete}
            data={data}
            rowCount={data.length}
            order="asc"
            orderBy="name"
          />
        </ContentPaper>
      </div>
    );
  }
}

export default Venues;
