import React from 'react';
import _ from 'lodash';

import {withStyles} from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ApiClient from "../../components/ApiClient";
import UserService from "../../services/UserService";
import LocationService from "../../services/LocationService";
import {withSnackbar} from "notistack";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import {Delete, DeleteOutlineOutlined} from "@material-ui/icons";

const styles = theme => ({
    root: {
        /*margin: 'auto',*/
        margin: theme.spacing(0, 0, 2, 0),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 250,
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
    margin: {
        margin: theme.spacing(1),
    },
});

@withSnackbar
@withStyles(styles)
class LocationManageItem extends React.Component {

    state = {
        name: this.props.location && this.props.location.name ? this.props.location.name : '',
        editing: false,
        nameError: '',
    }

    constructor(props) {
        super(props)

        // Debounce submit function
        this.handleSubmitDebounced = _.debounce(this.handleSubmit.bind(this), 500)
    }

    handleSubmit(ev, showAlerts = true) {
        ev && ev.preventDefault();

        const {
            location
        } = this.props;

        const data = {};
        data.name    = this.state.name;
        data.area_id = location.area_id;

        if ( !location.id ) {
            LocationService.add(data)
                .then((res) => {
                    if ( showAlerts ) {
                        this.props.enqueueSnackbar('Location saved', {variant: "success"});
                    }
                }, (err) => {
                    this.props.enqueueSnackbar('Could not save the area!', { variant: "error" });
                })

        } else {
            LocationService.update(location.id, data)
                .then((res) => {

                    if ( showAlerts ) {
                        this.props.enqueueSnackbar('Location updated', {variant: "success"});
                    }
                }, (err) => {
                    this.props.enqueueSnackbar('Could not update the location!', { variant: "error" });
                })
        }

        return false;
    }

    handleChange = () => async (event) => {
        const {value} = event.target;
        let isError = '';
        if (!value) {
            //this.props.enqueueSnackbar('The location name is required and can not be empty', { variant: "error" });
            isError = 'The location name is required';
        }

        this.setState({ name: event.target.value, nameError: isError }, async () => {
            if (value) {
                await this.handleSubmitDebounced(null, false)
            }
        });
    }


    render() {
        const {
            location,
            classes,
            locationsCount,
        } = this.props;

        const {
            name,
            nameError,
            editing,
        } = this.state;


        return (
            <Grid container spacing={2} className={classes.root}>
                <TextField
                    className={classes.textField}
                    placeholder={"Enter location name"}
                    value={name || ''}
                    error={!!nameError}
                    helperText={nameError}
                    margin="dense"
                    label={`Assigned to ${location.ingredients ? location.ingredients.length : '0'} ingredients`}
                    onChange={this.handleChange('name')}
                />

                <IconButton aria-label="delete" className={classes.margin}
                            onClick={() => {
                                if (locationsCount === 1) {
                                    this.props.enqueueSnackbar('The location was not deleted, at least one is required', { variant: "error" });
                                }
                                else if (location.ingredients && location.ingredients.length) {
                                    this.props.enqueueSnackbar('Unassign all ingredients from location before deleting it', { variant: "error" });
                                } else {
                                    this.props.handleDelete(location.id);
                                }
                            }}>
                    <DeleteOutlineOutlined  />
                </IconButton>

            </Grid>
        )
    }
}


export default LocationManageItem;
