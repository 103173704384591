import React from 'react';
import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { translate } from '../i18n';
import theme from '../theme/theme';
import { MuiThemeProvider } from '@material-ui/core/styles';

export default function UserConfirmation(message, callback) {
  const container = document.createElement('div');
  container.setAttribute('custom-confirmation-navigation', '');
  document.body.appendChild(container);

  const handleCancel = () => {
    ReactDOM.unmountComponentAtNode(container);
    callback(false);
  };

  const handleOk = () => {
    ReactDOM.unmountComponentAtNode(container);
    callback(true);
  };

  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <Dialog open onClose={handleCancel} disableBackdropClick disableEscapeKeyDown>
        <DialogTitle>{translate('Unsaved changes')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message || translate('Are you sure you want to leave the page without saving your changes?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary">
            {translate('No')}
          </Button>
          <Button onClick={handleOk} color="primary">
            {translate('Yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>,
    container,
  );
}
