import React from 'react';
import { connect } from 'react-redux';
import Discounts from '../components/discounts';
import { fetchDiscounts, hideDiscountAddDialog, setTableFilter, showDiscountAddDialog } from '../actions';
import { setPageTitle } from '../../ui/actions';
import { getFilteredDiscounts } from '../reducers';
import { enqueueSnackbar } from '../../snackbar/actions';
import { fetchIngredients } from '../../ingredient/actions';

function mapStateToProps(state) {
  const discounts = getFilteredDiscounts(state);

  return {
    dataById: discounts || {},
    data: state.discounts.entitiesIds || [],
    filters: state.discounts.filters,
    selected: state.discounts.selected,
    loading: state.discounts.loading,
    initialLoaded: state.discounts.initialLoaded,
    fetchError: state.discounts.fetchError,
    loadingIds: state.discounts.loadingIds,
  };
}

const mapDispatchToProps = {
  fetchData: fetchDiscounts,
  fetchIngredients,
  setPageTitle,
  setTableFilter,
  showAddDialog: showDiscountAddDialog,
  hideAddDialog: hideDiscountAddDialog,
  enqueueSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Discounts);
