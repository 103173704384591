import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import DoneAllIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import { withSnackbar } from 'notistack';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Button, Checkbox, FormControlLabel, withStyles, Box } from '@material-ui/core';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBox from '@material-ui/icons/CheckBox';
import clsx from 'clsx';
import SystemStatus from '../SystemStatus';
import ReceiveNotifications from '../ReceiveNotifications';
import TableLoadingOverlay from '../../../components/TableLoadingOverlay';
import EnhancedTable from '../../../components/enhanced-table/EnhancedTable';
import OrderContact from './contact';

import OrderService from '../../../services/OrderService';
import ApiClient from '../../../components/ApiClient';

import styles from './styles';
import DetailsDialog from './details-dialog';
import ActionsMenu from './actions-menu';
import ContentPaper from '../../../components/content-paper';
import { translate } from '../../../i18n';
import { hideProgressBar, setPageTitle, showProgressBar } from '../../ui/actions';
import Icon from '../../../components/Icon';
import ActionButton from './action-button';
import { date, time } from '../../../i18n/time';

const ALERT_NEW_ORDER_SOUND = new Audio('/sounds/new_order3.mp3');
const ALERT_QUEUED_ORDERS_SOUND = new Audio('/sounds/new_order2.mp3');

const playNewOrderAlert = () => {
  ALERT_NEW_ORDER_SOUND.play();
};

const playUnconfirmedOrdersAlert = () => {
  ALERT_QUEUED_ORDERS_SOUND.play();
};

@withSnackbar
@withStyles(styles)
class OrdersTable extends React.Component {
  alertInterval = null;

  refreshInterval = null;

  constructor(props) {
    super(props);

    if (props.setPageTitle) {
      props.setPageTitle(translate('Orders'));
    }

    this.state = {
      data: [],
      includeCompleted: false,
      lastOrderDate: null,

      contactModal: null,
    };
  }

  componentWillMount() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }

    if (this.alertInterval) {
      clearInterval(this.alertInterval);
      this.alertInterval = null;
    }
  }

  componentDidMount() {
    this.load();

    this.refreshInterval = setInterval(this.load, 5000);
  }

  componentWillReceiveProps(prevProps) {
    const { searchQuery } = this.props;
    if (searchQuery !== prevProps.searchQuery) {
      this.setState({
        search: searchQuery,
      });
    }
  }

  componentWillUnmount() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }

    if (this.alertInterval) {
      clearInterval(this.alertInterval);
      this.alertInterval = null;
    }
  }

  load = () => {
    const { showProgressBar, hideProgressBar } = this.props;
    const { includeCompleted } = this.state;

    showProgressBar();

    ApiClient.get({
      endpoint: '/api/order',
      query: {
        completed: includeCompleted ? 1 : 0,
      },
    }).then(
      res => {
        const data = res.body.data && res.body.data.results ? res.body.data.results : [];

        // Let's figure out if we have any unconfirmed orders
        let unconfirmed = false;
        let shouldPlayAlert = false;
        let { lastOrderDate } = this.state;

        // eslint-disable-next-line no-restricted-syntax
        for (const order of data) {
          if (order.confirmed_at === null && !order.is_completed && !order.is_canceled) {
            unconfirmed = true;

            // Should we also play new order alert?
            if (!lastOrderDate || order.created_at > lastOrderDate) {
              // Play new order alert
              shouldPlayAlert = true;
              lastOrderDate = order.created_at;
            }

            break;
          }
        }

        // Now orders alert?
        if (shouldPlayAlert) {
          playNewOrderAlert();
        }

        // If any unconfirmed, we play an alert every 3 minutes
        if (unconfirmed) {
          if (!this.alertInterval) {
            this.alertInterval = setInterval(playUnconfirmedOrdersAlert, 180000);
          }
        } else if (this.alertInterval) {
          clearInterval(this.alertInterval);
          this.alertInterval = null;
        }

        this.setState(
          {
            data,
            lastOrderDate,
          },
          () => hideProgressBar(),
        );

        // Play alert
        // this.alertSound.play();
      },
      () => {
        hideProgressBar();
      },
    );
  };

  handleMassDelete = ids => {
    if (ids) {
      this.massDelete(ids);
    }
  };

  cancelOrder = id => {
    // Delete AJAX
    OrderService.cancel(id).then(() => {
      setTimeout(() => this.load(), 100);
    });
  };

  completeOrder = id => {
    // Delete AJAX
    OrderService.complete(id).then(() => {
      setTimeout(() => this.load(), 100);
    });
  };

  deleteOrder = (id, callback) => {
    // Delete AJAX
    OrderService.delete(id).then(res => {
      if (callback) {
        callback(res);
      } else {
        setTimeout(() => this.load(), 100);
      }
    });
  };

  massDelete = ids => {
    ids.forEach(id => {
      this.deleteOrder(id, () => {
        const { data } = this.state;
        this.setState({
          data: _.filter(data, o => o.id !== id),
        });
      });
    });
  };

  handleReady = id => {
    ApiClient.patch({
      endpoint: `/api/order/${id}`,
      data: {
        order_status: 'ready',
      },
    }).then(
      () => {
        setTimeout(() => this.load(), 100);
      },
      () => {
        // eslint-disable-next-line react/destructuring-assignment
        this.props.enqueueSnackbar(translate('An error occurred while saving the order!'), { variant: 'error' });
      },
    );
  };

  handleConfirmation = (id, duration) => {
    ApiClient.post({
      endpoint: `/api/order/${id}/confirm`,
      data: {
        is_confirmed: parseInt(duration, 10),
      },
    }).then(
      () => {
        // eslint-disable-next-line react/destructuring-assignment
        this.props.enqueueSnackbar(translate('Order confirmed!'), { variant: 'success' });
        setTimeout(() => this.load(), 100);
      },
      () => {
        // eslint-disable-next-line react/destructuring-assignment
        this.props.enqueueSnackbar(translate('An error occurred while confirming the order!'), { variant: 'error' });
      },
    );
  };

  showDetailsModal = row => () => {
    this.setState({
      detailsModal: row,
    });
  };

  showContactModal = row => {
    this.setState({
      contactModal: row,
    });
  };

  getColumns = () => {
    const { classes } = this.props;
    return [
      {
        id: 'first_name',
        accessor: 'first_name',

        label: translate('Name'),
        renderValue: row => `${row.first_name} ${row.last_name}`,
      },
      {
        id: 'phone',
        accessor: 'phone',
        numeric: false,
        label: translate('Phone'),
        renderValue: row => (
          <a className="text-primary" href={`tel:${row.phone.replace(/[ .-]/, '')}`}>
            {row.phone}
          </a>
        ),
      },
      {
        id: 'order_type',
        accessor: 'order_type',
        numeric: false,
        label: translate('Delivery'),
        renderValue: row => row.order_type,
      },
      {
        id: 'payment_type',
        accessor: 'payment_type',
        numeric: false,
        label: translate('Payment'),
        renderValue: row => {
          if (row.payment_type === 'PayPal' || row.payment_type === 'Stripe') {
            let successPayment = false;

            if (row.payment_type === 'PayPal') {
              successPayment =
                row.cart_details.payment_details && row.cart_details.payment_details.orderID
                  ? row.cart_details.payment_details.orderID
                  : null;
            }

            if (row.payment_type === 'Stripe') {
              successPayment =
                row.cart_details.payment_details && row.cart_details.payment_details.PaymentIntent
                  ? row.cart_details.payment_details.PaymentIntent.id
                  : null;
            }

            return (
              <>
                {successPayment ? (
                  <DoneAllIcon style={{ fontSize: 18, display: 'block', float: 'left', marginRight: '5px' }} />
                ) : (
                  <CancelIcon
                    style={{ fontSize: 18, display: 'block', float: 'left', marginRight: '5px', color: 'red' }}
                  />
                )}
                <span>{row.payment_type}</span>
              </>
            );
          }

          return row.payment_type;
        },
      },
      {
        id: 'order_status',
        accessor: 'order_status',
        numeric: false,
        label: '',
        style: { width: 130 },
        renderValue: row => {
          const className = clsx(classes.orderStatus, {
            [classes.newOrder]: row.order_status === 'new',
            [classes.processingOrder]: row.order_status === 'processing',
            [classes.readyOrder]: row.order_status === 'ready',
            [classes.completedOrder]: row.order_status === 'completed',
          });

          let statusText = row.order_status;
          if (!statusText && row.is_completed) {
            statusText = 'Completed';
          }
          if (!statusText && row.is_canceled) {
            statusText = 'Canceled';
          }

          return (
            <>
              <div className={className}>
                {statusText}
                {row.order_status === 'processing' && (
                  <span className={classes.confirmedTime}>
                    {row.order_status === 'processing' ? <Icon name="clock" fixedWidth /> : null} {row.is_confirmed}'
                  </span>
                )}
              </div>
            </>
          );
        },
      },
      {
        id: 'order_status_button',
        accessor: 'order_status',
        numeric: false,
        label: '',
        onClick: () => {}, // Disable row click
        renderValue: row => (
          <ActionButton
            handleReady={this.handleReady}
            handleConfirmation={this.handleConfirmation}
            handleComplete={this.completeOrder}
            row={row}
          />
        ),
      },
      /* {
        id: 'id_details',
        accessor: 'id',
        numeric: false,
        label: '',
        renderValue: row => {
          const showDetailsModal = this.showDetailsModal(row);
          return (
            /!* <Button variant="outlined" color="primary" onClick={showDetailsModal}>Details</Button> *!/
            <ButtonGroup color="primary" aria-label="outlined primary button group">
              <Button onClick={showDetailsModal}>{translate('Details')}</Button>
            </ButtonGroup>
          );
        },
      }, */
      {
        id: 'created_at',
        accessor: 'created_at',
        component: 'th',

        label: translate('Order Date'),
        renderValue: row => (
          <div style={{}}>
            {time(row.created_at)}
            <br/>
            {date(row.created_at)}
          </div>
        ),
      },
      {
        id: 'id',
        accessor: 'id',
        numeric: false,
        label: '',
        align: 'center',
        padding: 'none',
        renderValue: (row, idx) => (
          <ActionsMenu
            handleGenerate={null}
            handleDelete={this.deleteOrder}
            handleCancel={this.cancelOrder}
            handleCompleted={this.completeOrder}
            handleContact={this.showContactModal}
            id={row.id_string}
            index={idx}
            data={row}
          />
        ),
      },
    ];
  };

  render() {
    const { classes } = this.props;
    const { contactModal, detailsModal, includeCompleted, search, loadingRow } = this.state;
    let { data } = this.state;

    data = !search
      ? data
      : _.filter(
          data,
          d =>
            d.first_name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            d.last_name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            d.phone.toLowerCase().indexOf(search.toLowerCase()) > -1,
        );

    return (
      <div className={classes.root}>
        <ContentPaper table className="FiltersPaper">
          <Box>
            <FormControlLabel
              size="small"
              className={classes.filterBox}
              control={
                <Checkbox
                  checked={!!includeCompleted}
                  onChange={() => this.setState({ includeCompleted: !includeCompleted }, this.load)}
                  value="includeCompleted"
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                />
              }
              label={translate('Show All')}
            />

            <ReceiveNotifications />

            <SystemStatus />

            {/* <AddInvoiceDialog /> */}
          </Box>
        </ContentPaper>

        <ContentPaper table className={classes.paper}>
          {!loadingRow ? null : <TableLoadingOverlay />}

          <EnhancedTable
            className={classes.table}
            noCheckbox
            toolbarActions={
              <>
                <Button
                  variant="text"
                  color="primary"
                  className={classes.tableButton}
                  onClick={this.load}
                  startIcon={<i className="fal fa-sync" />}>
                  {translate('Check new orders')}
                </Button>
              </>
            }
            columns={this.getColumns()}
            data={data}
            rowCount={data.length}
            onMassDelete={this.handleMassDelete}
            onRowClick={row => this.showDetailsModal(row)()}
            filter={null}
            loading={loadingRow}
            order="desc"
            orderBy="created_at"
          />
        </ContentPaper>

        {!detailsModal ? null : (
          <DetailsDialog data={detailsModal} handleClose={() => this.setState({ detailsModal: null })} />
        )}

        {!contactModal ? null : (
          <OrderContact data={contactModal} handleClose={() => this.setState({ contactModal: null })} />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  showProgressBar,
  hideProgressBar,
  setPageTitle,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersTable);
