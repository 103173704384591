import { createClient } from './helpers';

const apiClient = createClient();

function fetch() {
  return apiClient.get('recipe');
}

function add(data) {
  return apiClient.post('recipe', data);
}

function update(id, data) {
  return apiClient.patch(`recipe/${id}`, data);
}

function getById(id) {
  return apiClient.get(`recipe/${id}`);
}

function deleteById(id) {
  return apiClient.delete(`recipe/${id}`);
}

function cloneById(id, data) {
  return apiClient.post(`recipe/${id}/clone`, data);
}

export const recipeApi = {
  fetch,
  add,
  update,
  getById,
  deleteById,
  cloneById,
};
