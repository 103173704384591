import { connect } from 'react-redux';
import VenueAddDialog from '../components/venue-add-dialog';
import { showVenueAddDialog, hideVenueAddDialog } from '../actions';

function mapStateToProps(state) {
  return {
    open: state.venues.showAddDialog,
  };
}

const mapDispatchToProps = {
  showAddDialog: showVenueAddDialog,
  hideAddDialog: hideVenueAddDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(VenueAddDialog);
