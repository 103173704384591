import ApiClient from '../components/ApiClient';

const OrderService = {
  add(data, callback) {
    if (callback) {
      ApiClient.post({
        endpoint: '/api/order',
        data,
      }).then(
        (res) => {
          callback && callback(res);
        },
        (err) => {
          callback && callback(null, err);
        },
      );

      return;
    }

    return ApiClient.post({
      endpoint: '/api/order',
      data,
    });
  },

  update(id, data, callback) {
    if (callback) {
      ApiClient.patch({
        endpoint: `/api/order/${id}`,
        data,
      }).then(
        (res) => {
          callback && callback(res);
        },
        (err) => {
          callback && callback(null, err);
        },
      );

      return;
    }

    return ApiClient.patch({
      endpoint: `/api/order/${id}`,
      data,
    });
  },

  delete(id, callback) {
    if (callback) {
      ApiClient.delete({
        endpoint: `/api/order/${id}`,
      }).then(
        (res) => {
          callback && callback(res);
        },
        (err) => {
          callback && callback(null, err);
        },
      );

      return;
    }

    return ApiClient.delete({
      endpoint: `/api/order/${id}`,
    });
  },

  cancel(id, callback) {
    if (callback) {
      ApiClient.patch({
        endpoint: `/api/order/${id}`,
        data: {
          is_canceled: true,
        },
      }).then(
        (res) => {
          callback && callback(res);
        },
        (err) => {
          callback && callback(null, err);
        },
      );

      return;
    }

    return ApiClient.patch({
      endpoint: `/api/order/${id}`,
      data: {
        is_canceled: true,
      },
    });
  },

  complete(id, callback) {
    if (callback) {
      ApiClient.patch({
        endpoint: `/api/order/${id}`,
        data: {
          is_completed: true,
        },
      }).then(
        (res) => {
          callback && callback(res);
        },
        (err) => {
          callback && callback(null, err);
        },
      );

      return;
    }

    return ApiClient.patch({
      endpoint: `/api/order/${id}`,
      data: {
        is_completed: true,
      },
    });
  },

  fetchAll(callback) {
    if (callback) {
      ApiClient.get({
        endpoint: '/api/order/',
      }).then(
        (res) => {
          callback && callback(res);
        },
        (err) => {
          callback && callback(null, err);
        },
      );

      return;
    }

    return ApiClient.get({
      endpoint: '/api/order/',
    });
  },

  fetch(id, callback) {
    if (callback) {
      ApiClient.get({
        endpoint: `/api/order/${id}`,
      }).then(
        (res) => {
          callback && callback(res);
        },
        (err) => {
          callback && callback(null, err);
        },
      );

      return;
    }

    return ApiClient.get({
      endpoint: `/api/order/${id}`,
    });
  },

  async getMenuProducts() {
    const response = await ApiClient.get({
      endpoint: '/api/order/menu-products',
    });

    return response && response.body && response.body.data ? response.body.data : null;
  },
};

export default OrderService;
