import produce from 'immer';
import { denormalize, normalize, schema } from 'normalizr';
import _ from 'lodash';

import {
  VENDORS_FETCH_REQUEST,
  VENDORS_FETCH_SUCCESS,
  VENDORS_FETCH_ERROR,
  VENDORS_HIDE_ADD_DIALOG,
  VENDORS_SHOW_ADD_DIALOG,
  VENDORS_SET_TABLE_FILTER,
} from '../actions';

const INITIAL_STATE = {
  entities: {
    vendors: {},
  },
  entitiesIds: [],
  selected: null,

  // Filters
  filters: {
    search: null,
    country: null,
  },

  // Other state
  showAddDialog: false,
  loading: false,
  initialLoaded: false,
  loadingId: null,
  loadingIds: [],

  fetchError: null,
  saveError: null,
};

// Define the order schema so we can normalize the data and index it properly
const vendorschema = new schema.Entity('vendors');
const vendorListSchema = new schema.Array(vendorschema);

export default function vendors(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case VENDORS_FETCH_SUCCESS:
        // Normalize the data for better access
        const { data } = action.payload;
        const normalized = normalize(data, vendorListSchema);
        draft.entities = normalized.entities;
        draft.entitiesIds = normalized.result;
        draft.loading = false;
        draft.initialLoaded = true;
        draft.fetchError = null;

        break;

      case VENDORS_FETCH_REQUEST:
        draft.loading = true;
        draft.fetchError = null;
        break;

      case VENDORS_FETCH_ERROR:
        draft.fetchError = action.payload.error;
        break;

      case VENDORS_HIDE_ADD_DIALOG:
        draft.showAddDialog = false;
        break;

      case VENDORS_SHOW_ADD_DIALOG:
        draft.showAddDialog = true;
        break;

      case VENDORS_SET_TABLE_FILTER:
        draft.filters[action.payload.name] = action.payload.value;
        break;

      default:
    }
  });
}

// Selectors
export function getFilteredVendors(state) {
  const { entities, filters } = state.vendors;

  return _.filter(entities.vendors, o => {
    if (filters.search && o.name.toLowerCase().indexOf(filters.search.toLowerCase()) === -1) {
      return false;
    }

    if (filters.country && o.country !== filters.country) {
      return false;
    }

    return true;
  });
}

export const getVendorByIdSelector = (state, id) => {
  return state.vendors && state.vendors.entities && state.vendors.entities.vendors
    ? state.vendors.entities.vendors[id]
    : {};
};
