import React from 'react';
import { connect } from 'react-redux';
import MenuCategory from '../components/category';

function mapStateToProps(state, props) {
  const posProducts =
    state.products.entities && state.products.entities.products ? Object.values(state.products.entities.products) : [];

  return {
    posProducts: posProducts.sort((a, b) => a.name.localeCompare(b.name)),
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MenuCategory);
