import { makeActionCreator } from '../../../store/actions';

export const VENUES_FETCH_REQUEST = '@venues / FETCH REQUEST';
export const VENUES_FETCH_SUCCESS = '@venues / FETCH SUCCESS';
export const VENUES_FETCH_ERROR = '@venues / FETCH ERROR';
export const VENUES_SHOW_ADD_DIALOG = '@venues / SHOW ADD DIALOG';
export const VENUES_HIDE_ADD_DIALOG = '@venues / HIDE ADD DIALOG';
export const VENUES_SET_TABLE_FILTER = '@venues / SET TABLE FILTER';

export const fetchVenues = makeActionCreator(VENUES_FETCH_REQUEST);
export const fetchVenuesError = makeActionCreator(VENUES_FETCH_ERROR, 'error');
export const fetchVenuesSuccess = makeActionCreator(VENUES_FETCH_SUCCESS, 'data');
export const showVenueAddDialog = makeActionCreator(VENUES_SHOW_ADD_DIALOG);
export const hideVenueAddDialog = makeActionCreator(VENUES_HIDE_ADD_DIALOG);

// Table filters
export const setTableFilter = makeActionCreator(VENUES_SET_TABLE_FILTER, 'name', 'value');
