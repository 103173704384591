import { connect } from 'react-redux';
import AppDrawerComponent from '../components/drawer';
import { closeDrawer, openDrawer } from '../actions';

const mapStateToProps = state => {
  return {
    drawerOpen: state.ui.drawerOpen,
  };
};

const AppDrawer = connect(mapStateToProps, {
  openDrawer,
  closeDrawer,
})(AppDrawerComponent);

export default AppDrawer;
