import { call, put } from 'redux-saga/effects';

import { fetchPrintersSuccess, fetchPrintersError, fetchPrinterByIdSuccess } from '../actions';
import { parseApiError } from '../../../api/helpers';
import { hideProgressBar, showProgressBar } from '../../ui/actions';
import { printerGroupApi as printerApi } from '../../../api/printer';
import { enqueueSnackbar } from '../../snackbar/actions';

export function* fetchPrintersSaga() {
  try {
    yield put(showProgressBar());
    const response = yield call(printerApi.fetch);
    yield put(hideProgressBar());

    if (!response.ok) {
      yield put(fetchPrintersError(parseApiError(response).message));
      return;
    }

    yield put(fetchPrintersSuccess(response.data.data.results || []));
  } catch (err) {
    yield put(fetchPrintersError(parseApiError(err).message));
  }
}

export function* fetchPrinterByIdSaga(action) {
  try {
    const response = yield call(printerApi.getById, action.payload.id);
    if (!response.ok) {
      return;
    }

    yield put(fetchPrinterByIdSuccess(action.payload.id, response.data.data || null));
  } catch (err) {
    yield put(enqueueSnackbar(parseApiError(err).message, { options: { variant: 'error' } }));
  }
}
