import React from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import _ from 'lodash';
import { withStyles } from '@material-ui/core';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Button from '@material-ui/core/Button';

import clsx from 'clsx';
import ProductsList from '../../order/menu/ProductsList';

import styles from '../styles/category';
import ContentPaper from '../../../components/content-paper';
import { translate } from '../../../i18n';

@withStyles(styles)
class MenuCategory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      counter: 1,
      show: false,
    };
  }

  handleChange = event => {
    const { category, index, onChange } = this.props;
    onChange(index, { ...category, name: event.target.value });
  };

  handleFeatured = () => {
    const { category, index, onChange } = this.props;

    onChange(index, { ...category, featured: !category.featured });
  };

  handleProductChange = (pIndex, product) => {
    const { category, index, onChange } = this.props;
    const products = [...category.products];

    if (pIndex === null) {
      products.push(product);
    } else {
      products[pIndex] = { ...product };
    }

    onChange(index, { ...category, products });
  };

  handleProductAdd = pIndex => {
    const { counter } = this.state;
    const { index, onChange } = this.props;
    let { category } = this.props;
    category = { ...category };

    category.products = [...category.products, { name: `Product ${counter}` }];

    this.setState(
      ({ icounter, show }) => ({ counter: icounter + 1, show: !show }),
      () => onChange(index, category),
    );
  };

  handleProductRemove = pIndex => {
    const { category, index, onChange } = this.props;
    const products = [...category.products];

    _.pullAt(products, [pIndex]);

    onChange(index, { ...category, products });
  };

  render() {
    const { connectDropTarget } = this.props;
    const { connectDragSource } = this.props;
    const { connectDragPreview } = this.props;
    const { category, posProducts = [] } = this.props;
    const { classes, index, moveProductCard } = this.props;

    const { show } = this.state;

    // Display logic
    let { products } = category;
    if (!products) {
      products = [];
    }

    return (
      <Box mb={3}>
        {connectDropTarget(
          connectDragPreview(
            <div>
              <ContentPaper className={classes.root}>
                {connectDragSource(
                  <div>
                    <TextField
                      className={clsx(classes.category)}
                      defaultValue={category.name || ''}
                      onBlur={this.handleChange}
                      label={translate('Category')}
                    />
                  </div>,
                )}

                <Box mt={2} className={classes.products}>
                  <Box>
                    <Button
                      variant="text"
                      color="secondary"
                      className={classes.button}
                      size="small"
                      onClick={this.handleFeatured}
                      startIcon={category.featured ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}>
                      {translate('Featured')}
                    </Button>

                    <Button
                      variant="text"
                      color="secondary"
                      className={classes.button}
                      size="small"
                      onClick={() => this.setState({ show: !show })}
                      /* startIcon={<Add />} */
                    >
                      {translate('Products')}
                    </Button>

                    <Button
                      variant="text"
                      color="secondary"
                      className={classes.button}
                      size="small"
                      onClick={this.handleProductAdd}
                      /* startIcon={<Add />} */
                    >
                      {translate('Add product')}
                    </Button>
                  </Box>

                  {!show ? null : (
                    <Box mt={2}>
                      <ProductsList
                        products={products}
                        posProducts={posProducts}
                        category={category}
                        categoryIndex={index}
                        onChange={this.handleProductChange}
                        onRemove={this.handleProductRemove}
                        handleDragEnd={() => {}}
                        moveCard={moveProductCard}
                      />
                    </Box>
                  )}
                </Box>
              </ContentPaper>
            </div>,
          ),
        )}
      </Box>
    );
  }
}

export default MenuCategory;
