const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: '1200px',
  },

  button: {
    marginRight: theme.spacing(3),
  },

  category: {
    maxWidth: 300,
    width: '100%',

    '& .MuiInputBase-input': {
      fontSize: 20,
      /*fontWeight: 500,*/
    },
  },

  products: {},
});

export default styles;
