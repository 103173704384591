import React, { useRef } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TextField, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Clear, DragHandle, PlaylistAdd } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { Autocomplete } from '@material-ui/lab';
import { translate } from '../../../i18n';
import { palette } from '../../../theme/theme';

const styles = theme => ({
  root: {
    maxWidth: 650,
    /* flexGrow: 1, */
  },

  description: {
    '& .MuiInputBase-root:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.22)',
    },
    '& .MuiInputBase-input': {
      fontSize: 14,
      color: '#787878',
    },
  },

  descriptionCnt: {
    paddingTop: '0 !important',
    paddingBottom: '10px !important',
  },

  product: {
    '& .MuiInputBase-input': {
      fontSize: 14,
    },
  },

  price: {
    '& .MuiInputBase-input': {
      fontSize: 14,
    },
  },

  specialPrice: {
    '& .MuiInputLabel-root': {
      color: 'red',
    },
    '& .MuiInputBase-input': {
      fontSize: 14,
    },
  },

  right: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },

  row: {},

  variant: {
    marginRight: theme.spacing(2),

    '& .MuiInputBase-input': {
      fontSize: 12,
    },
  },

  variants: {
    fontSize: 13,
    fontWeight: 500,
    display: 'inline-flex',
    marginBottom: '0',
    marginRight: theme.spacing(2),
  },

  variantPrice: {
    maxWidth: 70,
    '& .MuiInputBase-input': {
      fontSize: 12,
    },
  },
});

@withStyles(styles)
class Product extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      variantCounter: 1,
    };
  }

  handleChange = field => event => {
    const product = { ...this.props.product, [field]: event.target ? event.target.value : event };

    this.props.onChange(this.props.index, product);
  };

  handleChangeStatus = event => {
    const product = { ...this.props.product, enabled: !!event.target.checked };

    this.props.onChange(this.props.index, product);
  };

  handleDelete = event => {
    this.props.onRemove(this.props.index);
  };

  handleVariantChange = (values, vIndex) => {
    const product = { ...this.props.product };
    let variants = [];

    if (product.variants.length) {
      const variant = product.variants[vIndex];
      variants = [
        ...product.variants.slice(0, vIndex),
        { ...variant, ...values },
        ...product.variants.slice(vIndex + 1),
      ];
    }

    product.variants = variants;

    this.props.onChange(this.props.index, product);
  };

  handleVariantDelete = vIndex => {
    const product = { ...this.props.product };
    let variants = [];
    if (product.variants) {
      variants = [...product.variants.slice(0, vIndex), ...product.variants.slice(vIndex + 1)];
    }

    product.variants = variants;
    this.props.onChange(this.props.index, product);
  };

  handleVariantAdd = () => {
    const product = { ...this.props.product };
    let variants = [];
    if (product.variants) {
      variants = [...product.variants];
    }

    variants = [...variants, { name: `Variant ${this.state.variantCounter}` }];
    product.variants = variants;

    this.setState(
      ({ variantCounter }) => ({ variantCounter: variantCounter + 1 }),
      () => this.props.onChange(this.props.index, product),
    );
  };

  handleChangeProduct = (event, posProduct) => {
    const { index, onChange: onChangeProduct, product } = this.props;

    const { id: productId, name, retail_price: retailPrice } = posProduct;
    const newData = {
      ...product,
      id: name,
      name,
    };
    console.log(productId, retailPrice)
    if (productId) {
      newData.product_id = productId;
      newData.price = retailPrice;
    }
    console.log('newData', newData)

    onChangeProduct(index, newData);
  };

  render() {
    const { connectDropTarget, connectDragSource, connectDragPreview, product, posProducts = [] } = this.props;
    const { classes } = this.props;

    const data = { ...product };
    const variants = data.variants ? [...data.variants] : [];

    const handleChangePrice = this.handleChange('price');
    const handleChangeSpecialPrice = this.handleChange('special_price');
    const handleChangeDescription = this.handleChange('description');

    let selectedProduct = {
      id: data.product_id,
      name: data.name,
    };

    if (posProducts && data.product_id) {
      const eIndex = posProducts.findIndex(c => c.id === data.product_id);
      const productData = eIndex !== -1 ? posProducts[eIndex] : null;
      if (productData) {
        selectedProduct = {...productData};
        console.log(selectedProduct)
      }
    }

    return (
      <Box className={classes.root} mx={1} mb={1}>
        {connectDropTarget(
          connectDragPreview(
            <div>
              <div className="d-flex flex-row">
                {connectDragSource(
                  <div style={{ marginTop: 20, marginRight: 12 }}>
                    <IconButton size="small" aria-label="delete">
                      <DragHandle />
                    </IconButton>
                  </div>,
                )}

                <div className="d-flex flex-column">
                  <Grid container spacing={2} alignItems="center" className={classes.row}>
                    <Grid item xs={12} sm={5} md={5}>
                      {/* <TextField
                      fullWidth
                      variant="filled"
                      label="Product name"
                      className={classes.product}
                      margin="dense"
                      defaultValue={data.name}
                      onBlur={handleChangeName}
                    /> */}
                      <Autocomplete
                        options={posProducts || []}
                        getOptionLabel={p => (!p || !p.name ? '' : `${p.name ? p.name : ''}`)}
                        disableClearable
                        openOnFocus
                        fullWidth
                        value={selectedProduct}
                        onChange={this.handleChangeProduct}
                        renderInput={({ inputProps, ...params }) => (
                          <TextField
                            inputProps={{
                              ...inputProps,
                              style: {
                                ...inputProps.style,
                                color: palette.primary.main,
                                fontWeight: '500',
                              },
                            }}
                            {...params}
                            label={translate('Product')}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={5} sm={2} md={2}>
                      <TextField
                        type="number"
                        fullWidth
                        label="Price"
                        className={classes.price}
                        margin="dense"
                        defaultValue={!!data.product_id ? selectedProduct.retail_price : data.price}
                        disabled={!!data.product_id}
                        onBlur={handleChangePrice}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>

                    <Grid item xs={5} sm={2} md={2}>
                      <TextField
                        type="number"
                        fullWidth
                        label="Promo"
                        className={classes.specialPrice}
                        margin="dense"
                        defaultValue={data.special_price || ''}
                        onBlur={handleChangeSpecialPrice}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={3} md={3} className={classes.right}>
                      <Switch
                        checked={data.enabled || false}
                        onChange={this.handleChangeStatus.bind(this)}
                        name="enabled"
                        color="secondary"
                      />
                      {/*<IconButton size="small" title="Add variant" onClick={this.handleVariantAdd}>
                        <PlaylistAdd />
                      </IconButton>*/}

                      <IconButton size="small" title="Remove product" onClick={this.handleDelete}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>

                    <Grid item xs={12} sm={8} md={8} className={classes.descriptionCnt}>
                      <Box ml={0} mb={2}>
                        <TextField
                          fullWidth
                          multiline
                          placeholder="Description"
                          margin="none"
                          size="small"
                          className={classes.description}
                          defaultValue={data.description}
                          onBlur={handleChangeDescription}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Variants
                    variants={variants}
                    product={data}
                    onChange={this.handleVariantChange}
                    onRemove={this.handleVariantDelete}
                  />
                </div>
              </div>
            </div>,
          ),
        )}
      </Box>
    );
  }
}

const useStyles = makeStyles(styles);

/**
 * @return {null}
 */
function Variants({ onChange, onRemove, product, variants }) {
  const classes = useStyles();

  if (!variants || !variants.length) {
    return null;
  }

  const handleRemove = vIndex => {
    onRemove(vIndex);
  };

  const handleChange = (name, vIndex) => {
    onChange({ name }, vIndex);
  };

  const handlePriceChange = (price, vIndex) => {
    onChange({ price }, vIndex);
  };

  return (
    <Box ml={2} mt={0} mb={3}>
      <Typography variant="body1" className={classes.variants}>
        Variants:{' '}
      </Typography>
      <Grid container alignItems="center">
        {variants.map((variant, vIndex) => {
          return (
            <Grid item xs={12} key={`prod-${product.name}-variant-${variant.name}`}>
              <TextField
                margin="dense"
                className={classes.variant}
                defaultValue={variant.name}
                onBlur={event => handleChange(event.target.value, vIndex)}
                placeholder="Variant name"
              />

              <TextField
                margin="dense"
                type="number"
                className={classes.variantPrice}
                defaultValue={variant.price || ''}
                onBlur={event => handlePriceChange(event.target.value, vIndex)}
                placeholder="Price"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />

              <IconButton size="small" title="Remove product" onClick={() => handleRemove(vIndex)}>
                <Clear />
              </IconButton>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default Product;
