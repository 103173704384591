import produce from 'immer';
import { normalize, schema } from 'normalizr';
import _ from 'lodash';
import {
  AUDIT_DATABASE_FETCH_ERROR,
  AUDIT_DATABASE_FETCH_REQUEST,
  AUDIT_DATABASE_FETCH_SUCCESS,
  AUDIT_EVENT_FETCH_ERROR,
  AUDIT_EVENT_FETCH_REQUEST,
  AUDIT_EVENT_FETCH_SUCCESS,
  AUDIT_SET_TABLE_FILTER,
} from '../actions';

const INITIAL_STATE = {
  events: {},
  eventsIds: [],
  initialLoadedEvents: false,

  database: {},
  databaseIds: [],
  initialLoadedDatabase: false,

  // Filters
  filters: {
    category: null,
    event_name: null,
    object_type: null,
    user_name: null,
    action_type: null,
  },

  // Other state
  loading: false,
  initialLoaded: false,
  loadingId: null,
  loadingIds: [],
};

const eventsSchema = new schema.Entity('events');
const eventListSchema = new schema.Array(eventsSchema);
const databaseSchema = new schema.Entity('database');
const databaseListSchema = new schema.Array(databaseSchema);

export default function audit(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case AUDIT_EVENT_FETCH_SUCCESS:
        // Normalize the data for better access
        const { data } = action.payload;
        const normalized = normalize(data, eventListSchema);
        draft.events = normalized.entities.events;
        draft.eventsIds = normalized.result;
        draft.loading = false;
        draft.initialLoadedEvents = true;
        draft.fetchError = null;

        break;

      case AUDIT_EVENT_FETCH_REQUEST:
        draft.loading = true;
        draft.fetchError = null;
        break;

      case AUDIT_EVENT_FETCH_ERROR:
        draft.fetchError = action.payload.error;
        break;

      case AUDIT_DATABASE_FETCH_SUCCESS:
        // Normalize the data for better access
        const databaseNormalized = normalize(action.payload.data, databaseListSchema);
        draft.database = databaseNormalized.entities.database;
        draft.databaseIds = databaseNormalized.result;
        draft.loading = false;
        draft.initialLoadedDatabase = true;
        draft.fetchError = null;

        break;

      case AUDIT_DATABASE_FETCH_REQUEST:
        draft.loading = true;
        draft.fetchError = null;
        break;

      case AUDIT_DATABASE_FETCH_ERROR:
        draft.fetchError = action.payload.error;
        break;

      case AUDIT_SET_TABLE_FILTER:
        draft.filters[action.payload.name] = action.payload.value;
        break;

      default:
        break;
    }
  });
}

// Selectors
export function getFilteredEvents(state) {
  const { events, filters } = state.audit;

  return _.filter(events, o => {
    if (filters.category && (!o.category || o.category !== filters.category)) {
      return false;
    }

    if (filters.event_name && o.event_name !== filters.event_name) {
      return false;
    }

    if (filters.object_type && o.object_type !== filters.object_type) {
      return false;
    }

    if (filters.user_name && o.user_name !== filters.user_name) {
      return false;
    }

    return true;
  });
}

export function getFilteredDatabase(state) {
  const { database, filters } = state.audit;

  return _.filter(database, o => {
    if (filters.object_type && o.object_type !== filters.object_type) {
      return false;
    }

    if (filters.action_type && o.action_type !== filters.action_type) {
      return false;
    }

    return true;
  });
}
