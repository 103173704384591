import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { Box, Button, CssBaseline, FormControl, Grid, Input, InputLabel, Paper, Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import HighlightOff from '@material-ui/icons/HighlightOff';
import clsx from 'clsx';
import UserStore from '../../lib/stores/UserStore';
import { authApi } from '../../api/authApi';
import { parseApiError } from '../../api/helpers';
import { translate } from '../../i18n';

const styles = theme => ({
  number: {
    width: '100%',
    textAlign: 'center',
    margin: '0 auto',
    display: 'flex',
    fontSize: '22px',
    background: 'transparent',
    boxShadow: 'none',
    color: '#cc0022',
    border: '1px solid #ececec',
    maxWidth: 'unset',
    borderRadius: 0,
    borderWidth: '1px 1px 0 0',
    minHeight: 80,
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3, 0),
    minHeight: 220,
  },
  paperLoading: {},
  pinControl: {
    margin: '24px auto',
    width: '170px',
    display: 'block',
  },
  pinField: {
    textAlign: 'center',
    fontSize: 24,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formLogin: {
    padding: theme.spacing(0, 3),
  },
  submit: {
    marginTop: theme.spacing(6),
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  error: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(3),
  },
});

@withStyles(styles)
@withRouter
class SignIn extends Component {
  constructor() {
    super();

    this.state = {
      error: false,
      errorMsg: false,
      loading: false,
      username: '',
      password: '',
      pinCode: '',
      pinOk: false,
      redirectToDashboard: false,
      redirectToInventory: false,
      refreshingPage: false,

      pinLogin: true,
    };
  }

  async componentWillMount() {
    // When we mount, we check to see if maybe we are logged in, but
    // got still landed on the login page
    // If that's the case, we clear the session and refresh the page
    if (UserStore.isLoggedIn()) {
      await authApi.logout();
      UserStore.logoutUser();

      // Refresh the page
      this.setState({ refreshingPage: true }, () => setTimeout(() => (window.location = '/login'), 500));
    }
  }

  login = async ev => {
    if (ev) {
      ev.preventDefault();
    }

    this.setState({
      loading: true,
      error: false,
      errorMsg: false,
    });

    const { location } = this.props;
    const { username, password } = this.state;
    try {
      const response = await authApi.login(username, password);
      if (!response.ok || !response.data.sessionid) {
        this.setState({
          error: true,
          errorMsg: parseApiError(
            response,
            translate('Could not verify the credentials. Please make sure they are correct.'),
          ).message,
          loading: false,
        });

        return;
      }

      const { user } = response.data;
      if (!user.venues || !user.venues.length) {
        this.setState({
          error: true,
          errorMsg: parseApiError(response, translate('Your account is not assigned to any venue.')).message,
          loading: false,
        });

        return;
      }

      UserStore.loginUser(response.data.sessionid, response.data.user);

      // Do we have a referrer?
      const redirectToUrl = (location && location.state && location.state.from && location.state.from.pathname) || '/';
      window.location = redirectToUrl;

      /* setTimeout(
        () =>
          this.setState({
            error: false,
            errorMsg: false,
            loading: false,
            username: '',
            password: '',
            redirectToDashboard: !redirectToUrl,
            redirectToUrl,
          }),
        700,
      ); */
    } catch (err) {
      this.setState({
        loading: false,
        error: true,
        errorMsg: parseApiError(err).message,
      });
    }
  };

  loginPin = async ev => {
    if (ev) {
      ev.preventDefault();
    }

    this.setState({
      error: false,
      errorMsg: false,
    });

    const { location } = this.props;
    const { pinCode } = this.state;

    try {
      const response = await authApi.loginPin(pinCode);
      if (!response.ok || !response.data.sessionid) {
        return;
      }

      UserStore.loginUser(response.data.sessionid, response.data.user);

      this.setState({
        pinOk: true,
      });

      // Do we have a referrer?
      const redirectToUrl = (location && location.state && location.state.from && location.state.from.pathname) || null;
      window.location = redirectToUrl || '/inventory';

      /* setTimeout(
        () =>
          this.setState({
            error: false,
            errorMsg: false,
            loading: false,
            username: '',
            password: '',
            pinCode: '',
            redirectToInventory: !redirectToUrl,
            redirectToUrl,
          }),
        700,
      ); */
    } catch (err) {
      this.setState({
        loading: false,
      });
    }
  };

  handleDialpad = value => {
    const { pinCode } = this.state;
    this.setState({ pinCode: pinCode + value }, () => {
      if (this.state.pinCode.length > 3) {
        this.loginPin();
      }
    });
  };

  render() {
    const { classes, location } = this.props;
    const {
      loading,
      error,
      errorMsg,
      redirectToDashboard,
      redirectToInventory,
      redirectToUrl,
      refreshingPage,
      pinCode,
      pinLogin,
      pinOk,
    } = this.state;

    if (refreshingPage) {
      return null;
    }

    if (redirectToUrl) {
      // Make sure the URL is not the same as the current page
      if (redirectToUrl.pathname !== '/login') {
        return <Redirect to={redirectToUrl} />;
      }
    }

    if (redirectToDashboard) {
      const { from } = (location && location.state) || { from: { pathname: '/' } };
      return <Redirect to={from} />;
    }

    if (redirectToInventory) {
      return <Redirect to="/inventory" />;
    }

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            Bar Manager
          </Typography>
          {!error ? null : (
            <Typography component="p" className={classes.error}>
              {errorMsg}
            </Typography>
          )}
          {pinLogin ? (
            <div className={classes.form}>
              <FormControl margin="normal" required className={classes.pinControl}>
                {/* <InputLabel htmlFor="password">PIN Code</InputLabel> */}
                <Input
                  name="pinCode"
                  type="password"
                  id="pinCode"
                  autoComplete="off"
                  placeholder={translate('Enter PIN')}
                  inputProps={{
                    className: classes.pinField,
                  }}
                  disabled={!!pinOk}
                  value={pinCode}
                  onChange={ev => {
                    this.setState({ pinCode: ev.target.value }, () => this.loginPin());
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => this.setState({ pinCode: '' })}
                        onMouseDown={ev => ev.preventDefault()}>
                        <HighlightOff />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <Grid container spacing={0}>
                <Grid item xs={4}>
                  <Button
                    className={classes.number}
                    color="primary"
                    variant="text"
                    onClick={() => this.handleDialpad('1')}>
                    1
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    className={classes.number}
                    color="primary"
                    variant="text"
                    onClick={() => this.handleDialpad('2')}>
                    2
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    className={classes.number}
                    style={{ borderRight: 0 }}
                    color="primary"
                    variant="text"
                    onClick={() => this.handleDialpad('3')}>
                    3
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={4}>
                  <Button
                    className={classes.number}
                    color="primary"
                    variant="text"
                    onClick={() => this.handleDialpad('4')}>
                    4
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    className={classes.number}
                    color="primary"
                    variant="text"
                    onClick={() => this.handleDialpad('5')}>
                    5
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    className={classes.number}
                    style={{ borderRight: 0 }}
                    color="primary"
                    variant="text"
                    onClick={() => this.handleDialpad('6')}>
                    6
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={0} style={{ borderBottom: '1px solid #ececec' }}>
                <Grid item xs={4}>
                  <Button
                    className={classes.number}
                    color="primary"
                    variant="text"
                    onClick={() => this.handleDialpad('7')}>
                    7
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    className={classes.number}
                    color="primary"
                    variant="text"
                    onClick={() => this.handleDialpad('8')}>
                    8
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    className={classes.number}
                    style={{ borderRight: 0 }}
                    color="primary"
                    variant="text"
                    onClick={() => this.handleDialpad('9')}>
                    9
                  </Button>
                </Grid>
              </Grid>

              <Button
                type="button"
                variant="text"
                color="primary"
                disabled={loading}
                className={classes.submit}
                onClick={() => this.setState({ pinLogin: false })}>
                {translate('Admin login')}
              </Button>
            </div>
          ) : (
            <form className={clsx(classes.form, classes.formLogin)} onSubmit={this.login}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">{translate('Email Address')}</InputLabel>
                <Input
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={ev => this.setState({ username: ev.target.value })}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">{translate('Password')}</InputLabel>
                <Input
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={ev => this.setState({ password: ev.target.value })}
                />
              </FormControl>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
                className={classes.submit}>
                {loading ? translate('Please wait') : translate('Sign in')}
              </Button>

              <Box pt={3}>
                <Button
                  type="button"
                  variant="text"
                  color="primary"
                  disabled={loading}
                  className={classes.submit}
                  onClick={() => this.setState({ pinLogin: true })}>
                  {translate('Enter PIN')}
                </Button>
              </Box>
            </form>
          )}
        </Paper>
      </main>
    );
  }
}

export default SignIn;
