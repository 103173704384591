import React from 'react';
import { connect } from 'react-redux';
import PageLoadingText from '../../components/PageLoadingText';
import { translate } from '../../i18n';
import { setPageTitle as setPageTitleAction } from '../ui/actions';
import { enqueueSnackbar as enqueueSnackbarAction } from '../snackbar/actions';
import BeverageForm from './BeverageForm';
import { fetchBeverages } from './actions';
import { getBeverageByIdSelector } from './reducers';

class BeverageAddEdit extends React.Component {
  constructor(props) {
    super(props);

    const { id, setPageTitle } = this.props;

    let pageTitle = translate('Add beverage');
    if (id) {
      pageTitle = translate('Edit beverage');
    }

    setPageTitle(pageTitle);
  }

  componentDidMount() {
    const { fetchData } = this.props;
    fetchData();
  }

  render() {
    const { id, data, enqueueSnackbar, history } = this.props;

    if (id && !data) {
      return <PageLoadingText />;
    }

    return (
      <BeverageForm
        id={data && data.id}
        data={data}
        onCancel={() => history.push('/beverage')}
        onSave={() => history.push('/beverage')}
        onNotification={enqueueSnackbar}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: getBeverageByIdSelector(state, ownProps.id),
  };
};

export default connect(mapStateToProps, {
  setPageTitle: setPageTitleAction,
  enqueueSnackbar: enqueueSnackbarAction,
  fetchData: fetchBeverages,
})(BeverageAddEdit);
