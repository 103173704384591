import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import {
  Typography,
  TextField,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';

import { Prompt, withRouter } from 'react-router';
import _ from 'lodash';
import AreaService from '../../../services/AreaService';
import { userApi } from '../../../api/user';
import ContentPaper from '../../../components/content-paper';
import { translate } from '../../../i18n';
import PageLoadingText from '../../../components/PageLoadingText';
import FormActions from '../../../components/form-actions';
import { parseApiError } from '../../../api/helpers';
import { FormContainer } from '../../../components/forms/container';
import { FormRow } from '../../../components/forms/row';
import { PasswordHelper } from '../../../lib/password-helper';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  buttonBlock: {
    display: 'block',
    width: '140px',
    margin: '0 auto',
    marginBottom: theme.spacing(1),
  },
  buttonDelete: {
    margin: theme.spacing(1),
    color: theme.palette.error.dark,
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  venueControl: {
    minWidth: 120,
    width: '100%',
  },
  helperText: {
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  imagePreview: {
    width: '100px',
    height: '100px',
    backgroundColor: '#fafafa',
    margin: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  img: {
    width: 'auto',
    maxHeight: '90%',
    maxWidth: '90%',
    marginTop: '50%',
    transform: 'translateY(-50%)',
  },

  areaCheckbox: {
    marginLeft: theme.spacing(2),
  },

  areaSeparator: {
    margin: theme.spacing(3, 0, 1),
  },
});

@withStyles(styles)
@withRouter
class UserForm extends Component {
  constructor(props) {
    super(props);

    // Set page title
    const { id, setPageTitle } = this.props;
    let pageTitle = translate('Add employee');
    if (id) {
      pageTitle = translate('Edit employee');
    }
    setPageTitle(pageTitle);

    this.state = {
      id: props.id ? props.id : null,
      data: !_.isEmpty(props.data)
        ? props.data
        : {
            username: '',
            password: PasswordHelper.generate(12),
            pin: PasswordHelper.generatePinCode(4),
            new_password: '',
          },
    };
  }

  componentDidMount() {
    const { id, fetchUserById, fetchVenues } = this.props;

    fetchVenues();
    if (id) {
      fetchUserById(id);
    }
  }

  componentWillReceiveProps(nextProps) {
    // const { id, data } = this.state;

    if (nextProps.id) {
      this.setState({
        id: nextProps.id,
        data: nextProps.data,
      });
    }
  }

  handleSubmit = async ev => {
    if (ev) {
      ev.preventDefault();
    }

    const { data, id } = this.state;
    const { enqueueSnackbar, onSave } = this.props;

    try {
      const response = !id ? await userApi.add(data) : await userApi.update(id, data);

      if (!response.ok) {
        enqueueSnackbar(parseApiError(response).message);
        return;
      }

      this.setState({ hasUnsavedChanges: false });
      enqueueSnackbar('User saved');

      if (onSave) {
        onSave(response);
      }

      this.handleAfterSave();
    } catch (e) {
      enqueueSnackbar('Could not save the user', { options: { variant: 'error' } });
    }
  };

  handleAfterSave = () => {
    const { history } = this.props;
    history.push('/user');
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push('/modifier');
  };

  handleDelete = async () => {
    const { id } = this.state;
    const { enqueueSnackbar, history } = this.props;

    // Reset state
    this.setState({ hasUnsavedChanges: false });

    // Delete AJAX
    try {
      await userApi.deleteById(id);

      enqueueSnackbar('User deleted');
      history.push('/user');
    } catch (e) {
      enqueueSnackbar('Could not delete the user', { options: { variant: 'error' } });
    }
  };

  handleAllowedVenuesChange = venueId => async event => {
    const { data } = this.state;

    venueId = typeof venueId !== 'undefined' ? venueId : event.target.value;

    // Send add or remove command
    if (event.target.checked) {
      await userApi.addUserToVenue(data.id, venueId);
    } else {
      await userApi.removeUserFromVenue(data.id, venueId);
    }

    // Update state
    this.load();
  };

  handleAllowedAreasChange = areaId => async event => {
    const { data } = this.state;

    areaId = typeof areaId !== 'undefined' ? areaId : event.target.value;

    // Send allow / disallow command
    if (event.target.checked) {
      await AreaService.addUserToArea(data.id, areaId);
    } else {
      await AreaService.removeUserFromArea(data.id, areaId);
    }

    // Update state
    this.load();
  };

  handleChange = (name, value) => event => {
    const { data } = this.state;

    value = typeof value !== 'undefined' ? value : event.target.value;
    this.setState({ data: { ...data, [name]: value }, hasUnsavedChanges: true });
  };

  load = () => {
    const { id, fetchUserById } = this.props;
    fetchUserById(id);
  };

  shouldComponentRender = () => {
    const { id, data } = this.state;

    if (id && !data) {
      return false;
    }

    return true;
  };

  render() {
    const { areas, classes, venues } = this.props;
    const { id, data, hasUnsavedChanges } = this.state;

    if (!this.shouldComponentRender()) {
      return <PageLoadingText />;
    }

    const username = data && data.username ? data.username : '';
    let lastAreaVenueHeading = null;

    return (
      <>
        <Prompt
          when={!!hasUnsavedChanges}
          message={translate('You have unsaved changes. Are you sure you want to leave?')}
        />
        <ContentPaper short className="FormBoxPaper">
          <FormContainer>
            <FormRow>
              <TextField
                required
                autoComplete="off"
                name="name"
                label={translate('Name')}
                className={classes.textField}
                value={data.name || ''}
                onChange={this.handleChange('name')}
              />
            </FormRow>
          </FormContainer>

          <FormContainer>
            <FormRow>
              <TextField
                required
                autoComplete="off"
                name="username"
                label={translate('Email')}
                className={classes.textField}
                value={username || ''}
                onChange={this.handleChange('username')}
              />
            </FormRow>
          </FormContainer>

          {!id ? null : (
            <FormContainer>
              <FormRow>
                <TextField
                  required
                  autoComplete="off"
                  name="password"
                  label="New password"
                  className={classes.textField}
                  value={data.new_password || ''}
                  onChange={this.handleChange('new_password')}
                  type="text"
                />
              </FormRow>
            </FormContainer>
          )}

          {data.role === 'owner' ? null : (
            <FormContainer>
              <FormRow>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="role-label-placeholder">{translate('Role')}</InputLabel>
                  <Select
                    value={data.role || ''}
                    onChange={this.handleChange('role')}
                    inputProps={{
                      name: 'role',
                      id: 'role-label-placeholder',
                    }}
                    displayEmpty
                    id="role"
                    name="role">
                    <MenuItem value="user">Cashier</MenuItem>
                    <MenuItem value="manager">Manager</MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                  </Select>
                </FormControl>
              </FormRow>
            </FormContainer>
          )}

          <FormContainer>
            <FormRow>
              <TextField
                required
                autoComplete="off"
                name="pin"
                label="PIN Code"
                className={classes.textField}
                value={data.pin || ''}
                onChange={this.handleChange('pin')}
                type="number"
                inputProps={{
                  minLength: 4,
                  step: 1,
                }}
              />
            </FormRow>
          </FormContainer>

          <FormContainer>
            <FormRow>
              <FormControl className={[classes.formControl, 'mt-4'].join(' ')}>
                <InputLabel htmlFor="timezone_name-label-placeholder">{translate('Timezone')}</InputLabel>
                <Select
                  native
                  value={data.timezone_name || ''}
                  onChange={this.handleChange('timezone_name')}
                  inputProps={{
                    name: 'timezone_name',
                    id: 'timezone_name-label-placeholder',
                  }}
                  displayEmpty
                  name="timezone_name">
                  <option value="Etc/GMT+12">(UTC-12:00) International Date Line West</option>
                  <option value="Etc/GMT+11">(UTC-11:00) Coordinated Universal Time -11</option>
                  <option value="Pacific/Honolulu">(UTC-10:00) Hawaii</option>
                  <option value="America/Anchorage">(UTC-09:00) Alaska</option>
                  <option value="America/Los_Angeles">(UTC-08:00) Pacific Time (US and Canada)</option>
                  <option value="America/Tijuana">(UTC-08:00) Baja California</option>
                  <option value="America/Denver">(UTC-07:00) Mountain Time (US and Canada)</option>
                  <option value="America/Chihuahua">(UTC-07:00) Chihuahua, La Paz, Mazatlan</option>
                  <option value="America/Phoenix">(UTC-07:00) Arizona</option>
                  <option value="America/Regina">(UTC-06:00) Saskatchewan</option>
                  <option value="America/Guatemala">(UTC-06:00) Central America</option>
                  <option value="America/Chicago">(UTC-06:00) Central Time (US and Canada)</option>
                  <option value="America/Mexico_City">(UTC-06:00) Guadalajara, Mexico City, Monterrey</option>
                  <option value="America/New_York">(UTC-05:00) Eastern Time (US and Canada)</option>
                  <option value="America/Bogota">(UTC-05:00) Bogota, Lima, Quito</option>
                  <option value="America/Indianapolis">(UTC-05:00) Indiana (East)</option>
                  <option value="America/Caracas">(UTC-04:30) Caracas</option>
                  <option value="America/Halifax">(UTC-04:00) Atlantic Time (Canada)</option>
                  <option value="America/Cuiaba">(UTC-04:00) Cuiaba</option>
                  <option value="America/Santiago">(UTC-04:00) Santiago</option>
                  <option value="America/La_Paz">(UTC-04:00) Georgetown, La Paz, Manaus, San Juan</option>
                  <option value="America/Asuncion">(UTC-04:00) Asuncion</option>
                  <option value="America/St_Johns">(UTC-03:30) Newfoundland</option>
                  <option value="America/Sao_Paulo">(UTC-03:00) Brasilia</option>
                  <option value="America/Godthab">(UTC-03:00) Greenland</option>
                  <option value="America/Montevideo">(UTC-03:00) Montevideo</option>
                  <option value="America/Cayenne">(UTC-03:00) Cayenne, Fortaleza</option>
                  <option value="America/Buenos_Aires">(UTC-03:00) Buenos Aires</option>
                  <option value="Etc/GMT+2">(UTC-02:00) Mid-Atlantic</option>
                  <option value="Atlantic/Azores">(UTC-01:00) Azores</option>
                  <option value="Atlantic/Cape_Verde">(UTC-01:00) Cabo Verde Is.</option>
                  <option value="Europe/London">(UTC+00:00) Dublin, Edinburgh, Lisbon, London</option>
                  <option value="Atlantic/Reykjavik">(UTC+00:00) Monrovia, Reykjavik</option>
                  <option value="Africa/Casablanca">(UTC+00:00) Casablanca</option>
                  <option value="Etc/GMT">(UTC+00:00) Coordinated Universal Time</option>
                  <option value="Europe/Budapest">(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                  <option value="Europe/Warsaw">(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                  <option value="Europe/Paris">(UTC+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                  <option value="Africa/Lagos">(UTC+01:00) West Central Africa</option>
                  <option value="Europe/Berlin">(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                  <option value="Africa/Windhoek">(UTC+01:00) Windhoek</option>
                  <option value="Europe/Minsk">(UTC+03:00) Minsk</option>
                  <option value="Africa/Cairo">(UTC+02:00) Cairo</option>
                  <option value="Europe/Kiev">(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
                  <option value="Europe/Bucharest">(UTC+02:00) Athens, Bucharest</option>
                  <option value="Asia/Jerusalem">(UTC+02:00) Jerusalem</option>
                  <option value="Asia/Amman">(UTC+02:00) Amman</option>
                  <option value="Asia/Beirut">(UTC+02:00) Beirut</option>
                  <option value="Africa/Johannesburg">(UTC+02:00) Harare, Pretoria</option>
                  <option value="Asia/Damascus">(UTC+02:00) Damascus</option>
                  <option value="Europe/Istanbul">(UTC+02:00) Istanbul</option>
                  <option value="Asia/Riyadh">(UTC+03:00) Kuwait, Riyadh</option>
                  <option value="Asia/Baghdad">(UTC+03:00) Baghdad</option>
                  <option value="Africa/Nairobi">(UTC+03:00) Nairobi</option>
                  <option value="Europe/Kaliningrad">(UTC+02:00) Kaliningrad</option>
                  <option value="Asia/Tehran">(UTC+03:30) Tehran</option>
                  <option value="Europe/Moscow">(UTC+03:00) Moscow, St. Petersburg, Volgograd</option>
                  <option value="Asia/Dubai">(UTC+04:00) Abu Dhabi, Muscat</option>
                  <option value="Asia/Baku">(UTC+04:00) Baku</option>
                  <option value="Asia/Yerevan">(UTC+04:00) Yerevan</option>
                  <option value="Asia/Tbilisi">(UTC+04:00) Tbilisi</option>
                  <option value="Indian/Mauritius">(UTC+04:00) Port Louis</option>
                  <option value="Asia/Kabul">(UTC+04:30) Kabul</option>
                  <option value="Asia/Tashkent">(UTC+05:00) Tashkent</option>
                  <option value="Asia/Karachi">(UTC+05:00) Islamabad, Karachi</option>
                  <option value="Asia/Calcutta">(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                  <option value="Asia/Colombo">(UTC+05:30) Sri Jayawardenepura</option>
                  <option value="Asia/Katmandu">(UTC+05:45) Kathmandu</option>
                  <option value="Asia/Yekaterinburg">(UTC+05:00) Ekaterinburg</option>
                  <option value="Asia/Almaty">(UTC+06:00) Astana</option>
                  <option value="Asia/Dhaka">(UTC+06:00) Dhaka</option>
                  <option value="Asia/Rangoon">(UTC+06:30) Yangon (Rangoon)</option>
                  <option value="Asia/Novosibirsk">(UTC+06:00) Novosibirsk</option>
                  <option value="Asia/Bangkok">(UTC+07:00) Bangkok, Hanoi, Jakarta</option>
                  <option value="Asia/Krasnoyarsk">(UTC+07:00) Krasnoyarsk</option>
                  <option value="Asia/Shanghai">(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                  <option value="Asia/Singapore">(UTC+08:00) Kuala Lumpur, Singapore</option>
                  <option value="Asia/Taipei">(UTC+08:00) Taipei</option>
                  <option value="Australia/Perth">(UTC+08:00) Perth</option>
                  <option value="Asia/Ulaanbaatar">(UTC+08:00) Ulaanbaatar</option>
                  <option value="Asia/Irkutsk">(UTC+08:00) Irkutsk</option>
                  <option value="Asia/Seoul">(UTC+09:00) Seoul</option>
                  <option value="Asia/Tokyo">(UTC+09:00) Osaka, Sapporo, Tokyo</option>
                  <option value="Australia/Darwin">(UTC+09:30) Darwin</option>
                  <option value="Australia/Adelaide">(UTC+09:30) Adelaide</option>
                  <option value="Asia/Yakutsk">(UTC+09:00) Yakutsk</option>
                  <option value="Australia/Sydney">(UTC+10:00) Canberra, Melbourne, Sydney</option>
                  <option value="Australia/Brisbane">(UTC+10:00) Brisbane</option>
                  <option value="Australia/Hobart">(UTC+10:00) Hobart</option>
                  <option value="Pacific/Port_Moresby">(UTC+10:00) Guam, Port Moresby</option>
                  <option value="Asia/Vladivostok">(UTC+10:00) Vladivostok</option>
                  <option value="Pacific/Guadalcanal">(UTC+11:00) Solomon Is., New Caledonia</option>
                  <option value="Asia/Magadan">(UTC+10:00) Magadan</option>
                  <option value="Pacific/Fiji">(UTC+12:00) Fiji</option>
                  <option value="Pacific/Auckland">(UTC+12:00) Auckland, Wellington</option>
                  <option value="Etc/GMT-12">(UTC+12:00) Coordinated Universal Time +12</option>
                  <option value="Pacific/Tongatapu">(UTC+13:00) Nuku&apos;alofa</option>
                  <option value="Pacific/Samoa">(UTC+13:00) Samoa</option>
                </Select>
              </FormControl>
            </FormRow>
          </FormContainer>

          <FormContainer>
            <FormRow>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="country-label-placeholder">{translate('Language')}</InputLabel>
                <Select
                  native
                  value={data.default_language || ''}
                  onChange={this.handleChange('default_language')}
                  inputProps={{
                    name: 'default_language',
                    id: 'default_language-label-placeholder',
                  }}
                  displayEmpty
                  name="default_language">
                  <option value="en">English</option>
                  <option value="es">Espanol</option>
                </Select>
              </FormControl>
            </FormRow>
          </FormContainer>
        </ContentPaper>

        <ContentPaper short className="FormBoxPaper">
          <FormControl component="fieldset" className={classes.venueControl}>
            <FormLabel component="legend">{translate('Venues')}</FormLabel>
            {!id ? (
              <p>{translate('Save the employee account before assigning to venues')}</p>
            ) : (
              <FormGroup>
                {venues &&
                  Object.values(venues).map(venue => {
                    const isChecked = data.venues ? data.venues.indexOf(venue.id) !== -1 : false;

                    return (
                      <FormControlLabel
                        key={`vefu${venue.id}_${data.id}`}
                        control={
                          <Checkbox
                            checked={isChecked}
                            onChange={this.handleAllowedVenuesChange(venue.id)}
                            value={venue.id}
                          />
                        }
                        label={venue.name}
                      />
                    );
                  })}
              </FormGroup>
            )}
          </FormControl>
        </ContentPaper>

        {!id ? null : (
          <ContentPaper short className="FormBoxPaper">
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">{translate('Allocated areas')}</FormLabel>
              <FormGroup>
                {areas &&
                  Object.values(areas).map(d => {
                    // Show only if the venue is checked
                    const venueChecked = data.venues ? data.venues.indexOf(d.venue_id) !== -1 : false;

                    if (!venueChecked) {
                      return null;
                    }

                    let venueHeading = null;
                    if (lastAreaVenueHeading !== d.venue_id) {
                      lastAreaVenueHeading = d.venue_id;

                      venueHeading = (
                        <Typography variant="subtitle2" className={classes.areaSeparator}>
                          {venues[d.venue_id] ? venues[d.venue_id].name : translate('- Empty venue name -')}
                        </Typography>
                      );
                    }

                    return (
                      <React.Fragment key={`aefu${d.id}_${data.id}`}>
                        {venueHeading}
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={data.areas && data.areas.indexOf(d.id) !== -1}
                              onChange={this.handleAllowedAreasChange(d.id)}
                              className={classes.areaCheckbox}
                              value={d.username}
                            />
                          }
                          label={d.name}
                        />
                      </React.Fragment>
                    );
                  })}
              </FormGroup>
            </FormControl>
          </ContentPaper>
        )}

        <FormActions short onDelete={this.handleDelete} onCancel={this.handleAfterSave} onSubmit={this.handleSubmit} />
      </>
    );
  }
}

export default UserForm;
