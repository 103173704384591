import React from 'react';
import _ from 'lodash';
import TextClamp from 'react-string-clamp';

import { withStyles } from '@material-ui/styles';

import { Portal, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import AppState from '../../lib/stores/AppState';
import ApiClient from '../../components/ApiClient';
import { foodImages } from './food-images';

const styles = theme => ({
  root: {
    zIndex: -99,
  },

  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 9999,
    // backgroundColor: '#f5f5f5',
    backgroundColor: '#fff',
    backgroundImage: 'url(/menu-display/menu-page-bg.jpg)',
    backgroundSize: 'cover',
    padding: 0,
    overflow: 'hidden',
  },

  containerResponsive: {
    overflow: 'auto',
  },

  header: {
    height: '54px',
    width: '100%',
    backgroundColor: '#f5f5f5',
    position: 'relative',
    marginBottom: '1vh',
  },

  slogan: {
    position: 'absolute',
    right: '2vw',
    fontSize: 32,
    padding: theme.spacing(1),
  },

  headerImage: {
    height: '10vh',
    marginTop: '2.1vh',
    maxWidth: '100%',
  },

  logoImage: {
    height: '100%',
    maxWidth: '100%',
  },

  logo: {
    position: 'absolute',
    left: '1vw',
    height: '50px',
    top: '5px',
  },

  menuItem: {
    height: '19vh',
    overflow: 'hidden',
    borderRight: '1px solid #494949',
    borderBottom: '1px solid #494949',
    position: 'relative',
    '&:nth-child(3n)': {
      borderRight: '0',
    },
    '&:nth-child(10),&:nth-child(11),&:nth-child(12)': {
      borderBottom: '0',
    },
  },

  categoryCard: {
    fontSize: 12,
    padding: theme.spacing(1, 2),
    borderRadius: 0,
  },

  categoryName: {
    fontFamily: 'Norwester Regular',
    fontSize: 30,
    color: theme.palette.primary.main,
    textAlign: 'center',
    padding: theme.spacing(3, 4, 2),
  },

  contentSeparator: {
    maxWidth: '70%',
    display: 'block',
    margin: '0 auto 10px',
  },

  productName: {
    fontWeight: 700,
    flexGrow: 1,
    fontFamily: 'Open Sans',
    textTransform: 'uppercase',
    color: '#000',
    fontSize: 18,
    marginBottom: 5,
    padding: theme.spacing(0, 2, 0, 0),
  },

  productDescription: {
    color: '#000',
    lineHeight: '15px',
    /* marginBottom: '8px', */
    fontFamily: 'Open Sans',
    fontWeight: 500,
    fontSize: '10px !important',
    margin: theme.spacing(0, 2, 0, 0),
    /* display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden', */
  },

  productPrice: {
    padding: theme.spacing(0, 0, 0, 1),
    color: theme.palette.primary.main,
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: 18,
    /* width: 50, */
    textAlign: 'right',
  },

  productImage: {
    display: 'block',
    margin: '10px auto 5px',
    maxHeight: '140px',
  },

  productRow: {
    marginBottom: 14,
    'page-break-before': 'always',
    '-webkit-column-break-inside': 'avoid',
    'page-break-inside': 'avoid',
    'break-inside': 'avoid-column',
  },

  variants: {
    color: theme.palette.primary.main,
    padding: '0 0 5px',
    fontSize: 16,
    fontWeight: 500,
  },

  itemBody: {
    flex: 1,
    color: '#ffffff',
  },

  pricing: {
    bottom: '1.0vh',
    position: 'absolute',
    fontSize: '1.5vw',
    fontFamily: 'EB Garamond',
    fontWeight: 'bold',
    lineHeight: '1.2',
    color: '#d0b38b',
  },
  abvIbu: {
    bottom: '1.5vh',
    right: '1vw',
    position: 'absolute',
    fontSize: '1.2vw',
    fontFamily: 'EB Garamond',
    fontWeight: 'bold',
    lineHeight: '1.2',
    color: '#d0b38b',
  },
  aiValue: {
    fontWeight: 'normal',
  },

  itemContainer: {},
  placeholder: {
    background: 'transparent',

    '& $itemContainer': {
      opacity: 0,
    },
  },

  masonryGrid: {
    display: 'flex',
    width: 'auto',
    marginLeft: -10,
    padding: 0,
  },
  masonryGridColumn: {
    paddingLeft: 10,
    backgroundClip: 'padding-box',
    borderRight: '1px solid #e8e8e8',

    '&:last-child': {
      borderRight: 0,
    },

    '& > div': {
      marginBottom: 10,
    },
  },

  menuItems: {
    columnCount: 5,
    columnFill: 'auto',
    columnGap: '24px',
    height: '100%',
    webkitFontSmoothing: 'antialiased',
    mozOsxFontSmoothing: 'grayscale',
  },

  menuItemsResponsive: {
    columnCount: 1,
    columnFill: 'auto',
    columnGap: '24px',
    webkitFontSmoothing: 'antialiased',
    mozOsxFontSmoothing: 'grayscale',
    maxWidth: 600,
    margin: '0 auto',
  },
});

class FoodMenu extends React.Component {
  timer = null;

  constructor(props) {
    super(props);

    this.state = {
      data: {},
    };
  }

  componentDidMount() {
    // Set scaling based on where the menu is displayed
    const { responsive } = this.props;
    if (!responsive) {
      document.body.style.zoom = 0.48; // 0.5; // 1 / window.devicePixelRatio;
    }

    AppState.setConfig({
      CurrentPageTitle: 'Food Menu',
      AppBarSearch: false,
    });

    this.loadMenu();

    this.initWsClient();
  }

  componentWillUnmount() {
    // Restore scaling
    document.body.style.zoom = 1;

    clearInterval(this.heartbeatInterval);
  }

  onDisplayCommands(commands) {
    // We do it with if's because we can set priority to the commands
    // based on the required outcome; for example, command reload-screen
    // always has priority because other commands are not relevant anymore;
    commands = _.uniq(commands);
    if (commands.includes('reload-screen')) {
      window.location = window.location;
    }
    if (commands.includes('refresh-display-data')) {
      this.loadMenu();
    }
  }

  initWsClient = () => {
    const displayId = AppState.getConfig('CurrentDisplayId');
    const { hostname, port } = window.location;
    const URL = `wss://${hostname}:${parseInt(port, 10) !== 3000 ? port : 5005}/ws/display/commands/${displayId}`;

    this.wsConnected = false;
    this.ws = null;

    // Init websocket
    this.ws = new WebSocket(URL);

    this.ws.onopen = () => {
      // Start heartbeat check
      this.wsHeartbeat();

      // Send display data
      const message = { displayId };
      this.ws.send(JSON.stringify(message));

      this.wsConnected = true;
    };

    this.ws.onmessage = evt => {
      try {
        const message = JSON.parse(evt.data);

        // Parse remote commands?
        if (message && message.commands) {
          this.onDisplayCommands(message.commands);
        }
      } catch (e) {}
    };

    this.ws.onerror = () => {
      this.wsConnected = false;
      this.ws.close();
    };

    this.ws.onclose = () => {
      this.wsConnected = false;
    };
  };

  loadMenu = () => {
    const { responsive } = this.props;
    const displayId = AppState.getConfig('CurrentDisplayId');

    ApiClient.get({
      endpoint: `/api/display/${displayId}/content/menu`,
      query: {
        includeOnTap: responsive ? 1 : 0,
      },
    }).then(res => {
      // Do we have any commands we need to handle?
      if (res && res.body && res.body.commands) {
        const { commands } = res.body;
        this.onDisplayCommands(commands);
      }

      this.setState(
        {
          data: res.body && res.body.menu ? res.body.menu : '',
        },
        () => {
          this.timer = setTimeout(this.loadMenu.bind(this), 120000);
        },
      );
    });
  };

  wsHeartbeat = () => {
    clearInterval(this.heartbeatInterval);

    this.heartbeatInterval = setInterval(() => {
      if (!this.wsConnected) {
        this.initWsClient();
      }
    }, 10000);
  };

  render() {
    const { classes, responsive } = this.props;
    const { data } = this.state;

    const { categories } = data;

    return (
      <div className={classes.root}>
        <Portal container={document.body}>
          <div className={clsx(classes.container, responsive ? classes.containerResponsive : '')}>
            {/* <div className={classes.header}>
              <div className={classes.logo}>
                <img className={classes.logoImage} src="/menu-display/campfire-logo.png" />
              </div>
              <div className={classes.slogan}>Food menu</div>
            </div> */}

            <div style={{ padding: '12px 24px', height: responsive ? '' : '100%' }}>
              <div className={clsx(!responsive ? classes.menuItems : classes.menuItemsResponsive)}>
                {/*<OnTapCategory classes={classes} />*/}
                {!categories || !categories.length
                  ? null
                  : categories.map(category => {
                      const products = category.products.filter(p => p.enabled);
                      if (!products || !products.length) {
                        return null;
                      }

                      return (
                        <React.Fragment key={`cat-${category.id}`}>
                          <Typography variant="h6" className={classes.categoryName} gutterBottom>
                            {category.name}
                          </Typography>
                          {products.map(p => {
                            const hasImage = foodImages[p.name.toUpperCase()] || null;

                            return (
                              <div className={classes.productRow} key={`product-row-${p.id || p.name}`}>
                                <Grid container direction="row" justify="flex-start" key={`cp-${p.name}`} wrap="nowrap">
                                  <Grid item style={{ flexGrow: 1 }}>
                                    <div className={classes.productName}>{p.name}</div>
                                    {/*p.price || */!p.variants || !p.variants.length ? null : (
                                      <div className={classes.variants}>
                                        {p.variants.map((variant, index) => {

                                          // We skip addons because they should not be on menu
                                          if (variant.type === 'addon') {
                                            return null;
                                          }

                                          // We skip empty prices too - this applies mostly to drinks modifiers
                                          if (!variant.price || parseFloat(variant.price) === 0) {
                                            return null;
                                          }

                                          return (
                                            <span key={`product-variant-${p.id || p.name}-${variant.name}`}>
                                              {`${variant.name.replace('piezas', 'PZ', 'pz')}`} &nbsp;&nbsp;{' '}
                                              {`$${variant.price} ${index < p.variants.length - 1 ? ' / ' : ' '}`}
                                            </span>
                                          );
                                        })}
                                      </div>
                                    )}
                                    {p.description && (
                                      <TextClamp
                                        text={p.description}
                                        lines={2}
                                        className={classes.productDescription}
                                      />
                                    )}
                                  </Grid>
                                  <Grid item>
                                    <div className={classes.productPrice}>{p.price ? `$${p.price}` : ' '}</div>
                                  </Grid>
                                </Grid>

                                {hasImage && (
                                  <img
                                    alt={p.name}
                                    style={hasImage.style ? hasImage.style : {}}
                                    src={`/menu-display/food-pics/${hasImage.image_name}`}
                                    className={classes.productImage}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
              </div>
            </div>
          </div>

          {/* <MediaOverlay /> */}
        </Portal>
      </div>
    );
  }
}

export default withStyles(styles)(FoodMenu);
