const styles = theme => ({
  root: {},

  button: {
    marginRight: theme.spacing(3),
  },

  disabledMenu: {
    color: '#9a9a9a',
  },

  textField: {
    display: 'flex',
  },
});

export default styles;
