const srmList = [
    {
        "value": {
            "srmNumber": 1,
            "color": "#FFE699"
        },
        "label": "SRM 1"
    },
    {
        "value": {
            "srmNumber": 2,
            "color": "#FFD878"
        },
        "label": "SRM 2"
    },
    {
        "value": {
            "srmNumber": 3,
            "color": "#FFCA5A"
        },
        "label": "SRM 3"
    },
    {
        "value": {
            "srmNumber": 4,
            "color": "#FFBF42"
        },
        "label": "SRM 4"
    },
    {
        "value": {
            "srmNumber": 5,
            "color": "#FBB123"
        },
        "label": "SRM 5"
    },
    {
        "value": {
            "srmNumber": 6,
            "color": "#F8A600"
        },
        "label": "SRM 6"
    },
    {
        "value": {
            "srmNumber": 7,
            "color": "#F39C00"
        },
        "label": "SRM 7"
    },
    {
        "value": {
            "srmNumber": 8,
            "color": "#EA8F00"
        },
        "label": "SRM 8"
    },
    {
        "value": {
            "srmNumber": 9,
            "color": "#E58500"
        },
        "label": "SRM 9"
    },
    {
        "value": {
            "srmNumber": 10,
            "color": "#DE7C00"
        },
        "label": "SRM 10"
    },
    {
        "value": {
            "srmNumber": 11,
            "color": "#D77200"
        },
        "label": "SRM 11"
    },
    {
        "value": {
            "srmNumber": 12,
            "color": "#CF6900"
        },
        "label": "SRM 12"
    },
    {
        "value": {
            "srmNumber": 13,
            "color": "#CB6200"
        },
        "label": "SRM 13"
    },
    {
        "value": {
            "srmNumber": 14,
            "color": "#C35900"
        },
        "label": "SRM 14"
    },
    {
        "value": {
            "srmNumber": 15,
            "color": "#BB5100"
        },
        "label": "SRM 15"
    },
    {
        "value": {
            "srmNumber": 16,
            "color": "#B54C00"
        },
        "label": "SRM 16"
    },
    {
        "value": {
            "srmNumber": 17,
            "color": "#B04500"
        },
        "label": "SRM 17"
    },
    {
        "value": {
            "srmNumber": 18,
            "color": "#A63E00"
        },
        "label": "SRM 18"
    },
    {
        "value": {
            "srmNumber": 19,
            "color": "#A13700"
        },
        "label": "SRM 19"
    },
    {
        "value": {
            "srmNumber": 20,
            "color": "#9B3200"
        },
        "label": "SRM 20"
    },
    {
        "value": {
            "srmNumber": 21,
            "color": "#952D00"
        },
        "label": "SRM 21"
    },
    {
        "value": {
            "srmNumber": 22,
            "color": "#8E2900"
        },
        "label": "SRM 22"
    },
    {
        "value": {
            "srmNumber": 23,
            "color": "#882300"
        },
        "label": "SRM 23"
    },
    {
        "value": {
            "srmNumber": 24,
            "color": "#821E00"
        },
        "label": "SRM 24"
    },
    {
        "value": {
            "srmNumber": 25,
            "color": "#7B1A00"
        },
        "label": "SRM 25"
    },
    {
        "value": {
            "srmNumber": 26,
            "color": "#771900"
        },
        "label": "SRM 26"
    },
    {
        "value": {
            "srmNumber": 27,
            "color": "#701400"
        },
        "label": "SRM 27"
    },
    {
        "value": {
            "srmNumber": 28,
            "color": "#6A0E00"
        },
        "label": "SRM 28"
    },
    {
        "value": {
            "srmNumber": 29,
            "color": "#660D00"
        },
        "label": "SRM 29"
    },
    {
        "value": {
            "srmNumber": 30,
            "color": "#5E0B00"
        },
        "label": "SRM 30"
    },
    {
        "value": {
            "srmNumber": 31,
            "color": "#5A0A02"
        },
        "label": "SRM 31"
    },
    {
        "value": {
            "srmNumber": 32,
            "color": "#560A05"
        },
        "label": "SRM 32"
    },
    {
        "value": {
            "srmNumber": 33,
            "color": "#520907"
        },
        "label": "SRM 33"
    },
    {
        "value": {
            "srmNumber": 34,
            "color": "#4C0505"
        },
        "label": "SRM 34"
    },
    {
        "value": {
            "srmNumber": 35,
            "color": "#470606"
        },
        "label": "SRM 35"
    },
    {
        "value": {
            "srmNumber": 36,
            "color": "#440607"
        },
        "label": "SRM 36"
    },
    {
        "value": {
            "srmNumber": 37,
            "color": "#3F0708"
        },
        "label": "SRM 37"
    },
    {
        "value": {
            "srmNumber": 38,
            "color": "#3B0607"
        },
        "label": "SRM 38"
    },
    {
        "value": {
            "srmNumber": 39,
            "color": "#3A070B"
        },
        "label": "SRM 39"
    },
    {
        "value": {
            "srmNumber": 40,
            "color": "#36080A"
        },
        "label": "SRM 40"
    }
]

module.exports = srmList