import { makeActionCreator } from '../../../store/actions';

export const AUDIT_EVENT_FETCH_REQUEST = '@audit_event / FETCH REQUEST';
export const AUDIT_EVENT_FETCH_SUCCESS = '@audit_event / FETCH SUCCESS';
export const AUDIT_EVENT_FETCH_ERROR = '@audit_event / FETCH ERROR';
export const AUDIT_DATABASE_FETCH_REQUEST = '@audit_database / FETCH REQUEST';
export const AUDIT_DATABASE_FETCH_SUCCESS = '@audit_database / FETCH SUCCESS';
export const AUDIT_DATABASE_FETCH_ERROR = '@audit_database / FETCH ERROR';
export const AUDIT_SET_TABLE_FILTER = '@audit / SET TABLE FILTER';

export const setTableFilter = makeActionCreator(AUDIT_SET_TABLE_FILTER, 'name', 'value');
export const fetchAuditEvents = makeActionCreator(AUDIT_EVENT_FETCH_REQUEST);
export const fetchAuditEventsError = makeActionCreator(AUDIT_EVENT_FETCH_ERROR, 'error');
export const fetchAuditEventsSuccess = makeActionCreator(AUDIT_EVENT_FETCH_SUCCESS, 'data');

export const fetchAuditDatabase = makeActionCreator(AUDIT_DATABASE_FETCH_REQUEST);
export const fetchAuditDatabaseError = makeActionCreator(AUDIT_DATABASE_FETCH_ERROR, 'error');
export const fetchAuditDatabaseSuccess = makeActionCreator(AUDIT_DATABASE_FETCH_SUCCESS, 'data');
