import React from 'react';
import Paper from '@material-ui/core/Paper';

const ContentPaper = props => {
  const {
    component,
    square,
    variant,
    children,
    elevation,
    table = false,
    style = {},
  } = props;

  const { short = false, ...rest } = props;

  let maxWidth = 763;
  if (short) {
    maxWidth = 490;
  }
  if (table) {
    maxWidth = 1200;
  }

  return (
    <Paper
      {...rest}
      table={null}
      component={component}
      square={square}
      variant={variant}
      elevation={elevation === null || typeof elevation === 'undefined' ? 1 : elevation}
      style={{
        ...style,
        maxWidth,
      }}>
      {children}
    </Paper>
  );
};

export default ContentPaper;
