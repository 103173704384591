import { connect } from 'react-redux';
import IngredientAddDialog from '../components/ingredient-add-dialog';
import { showIngredientAddDialog, hideIngredientAddDialog } from '../actions';
import { fetchVendors } from '../../vendor/actions';

function mapStateToProps(state) {
  return {
    open: state.ingredients.showAddDialog,
    vendors: state.vendors.entities && state.vendors.entities.vendors ? state.vendors.entities.vendors : {},
  };
}

const mapDispatchToProps = {
  showAddDialog: showIngredientAddDialog,
  hideAddDialog: hideIngredientAddDialog,
  fetchVendors,
};

export default connect(mapStateToProps, mapDispatchToProps)(IngredientAddDialog);
