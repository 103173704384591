import React, { Component } from 'react';

import { withStyles } from '@material-ui/styles';
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  InputAdornment,
  FormControl,
  MenuItem,
  Select,
  Paper,
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Prompt } from 'react-router';
import ContentPaper from '../../components/content-paper';
import Sizes from './Sizes';
import FormActions from '../../components/form-actions';
import { beverageApi } from '../../api/beverage';
import { translate } from '../../i18n';
import ApiClient from '../../components/ApiClient';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(2),
  },
  buttonBlock: {
    display: 'block',
    width: '140px',
    margin: '0 auto',
    marginBottom: theme.spacing(1),
  },
  buttonDelete: {
    margin: theme.spacing(1),
    color: theme.palette.error.dark,
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {},
  formControl: {
    /* marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit, */
    minWidth: 120,
    width: '100%',
  },
  helperText: {
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  imagePreview: {
    width: '180px',
    height: '180px',
    backgroundColor: '#fafafa',
    margin: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  img: {
    width: 'auto',
    maxHeight: '90%',
    maxWidth: '90%',
    marginTop: '50%',
    transform: 'translateY(-50%)',
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`beverage-edit-tabpanel-${index}`}
      aria-labelledby={`beverage-edit-tab-${index}`}
      {...other}>
      <Box px={1} py={3}>
        {children}
      </Box>
    </Typography>
  );
}

@withStyles(styles)
class BeverageForm extends Component {
  constructor(props) {
    super(props);

    this.fileInput = document.createElement('input');
    this.fileInput.setAttribute('type', 'file');
    this.fileInput.addEventListener('change', event => {
      this.handleFileSelected(event);
    });

    this.state = {
      tab: 0,
      id: props.id ? props.id : null,
      data: props.data
        ? props.data
        : {
            name: '',
            producer_name: '',
            image: null,
          },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { id } = this.state;
    if (!id && nextProps.id) {
      this.setState({
        id: nextProps.id,
        data: nextProps.data,
      });
    }
  }

  handleSubmit = async ev => {
    if (ev) {
      ev.preventDefault();
    }

    const { id, data } = this.state;
    const { onNotification, onSave } = this.props;

    try {
      if (!id) {
        const response = await beverageApi.add(data);

        onNotification(translate('Beverage saved'));

        if (onSave) {
          onSave(response);
        }
      } else {
        const response = await beverageApi.update(id, data);

        this.setState({ hasUnsavedChanges: false });
        onNotification(translate('Beverage updated'));

        if (onSave) {
          onSave(response);
        }
      }
    } catch (err) {
      onNotification('Could not save the beverage!', { variant: 'error' });
    }

    return false;
  };

  handleDelete = async () => {
    const { id } = this.state;
    const { onNotification, history } = this.props;

    // Reset form
    this.setState({ hasUnsavedChanges: false });

    // Delete AJAX
    try {
      await beverageApi.deleteById(id);

      onNotification(translate('Beverage'));
      history.push('/beverage');
    } catch (e) {
      onNotification(translate('Could not delete the beverage'), { options: { variant: 'error' } });
    }
  };

  handleFileSelected = event => {
    const { onNotification } = this.props;

    if (!event.target.files || !event.target.files[0]) {
      return false;
    }

    this.setState({ loadingImage: true });
    const req = ApiClient.getPostRequestObject('/api/media/image');
    req.attach('image', event.target.files[0]);

    req.end((err, res) => {
      if (err || !res.ok) {
        onNotification(
          translate('The media file could not be uploaded: {{error}}', {
            error: res.body && res.body.error ? res.body.error : 'Server error',
          }),
          { variant: 'error' },
        );

        return true;
      }

      // All good
      const image = res.body.image ? res.body.image : null;
      const { data } = this.state;

      this.setState({
        data: { ...data, image },
        loadingImage: false,
      });
    });
  };

  handleChange = (name, value) => event => {
    const { data } = this.state;

    value = typeof value !== 'undefined' ? value : event.target.value;

    let newData = { ...data, [name]: value };

    if (name === 'producer_name') {
      if (data.producer) {
        newData = { ...newData, producer: { id: value, name: value } };
      } else {
        newData.producer = {
          id: value,
          name: value,
        };
      }
    }

    this.setState({ data: newData, hasUnsavedChanges: true });
  };

  handleTabChange = (event, value) => {
    this.setState({ tab: value });
  };

  render() {
    const { classes, onCancel } = this.props;

    const { data, tab, loadingImage, hasUnsavedChanges } = this.state;

    const name = data && data.name ? data.name : '';

    return (
      <div>
        <Prompt
          when={!!hasUnsavedChanges}
          message={translate('You have unsaved changes. Are you sure you want to leave?')}
        />
        <ContentPaper className="FormBoxPaper">
          <Tabs value={tab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
            <Tab label="General" />
            <Tab label="Serving Sizes" />
          </Tabs>

          {tab === 0 && (
            <TabPanel>
              <Grid container className={classes.root}>
                <Grid item xs={12} md={4}>
                  <div style={{ textAlign: 'center' }}>
                    <Paper elevation={1} square className={classes.imagePreview}>
                      {!data.image || !data.image.secure_url ? null : (
                        <img alt="" src={data.image.secure_url} className={classes.img} />
                      )}
                    </Paper>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label>
                      <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={() => this.fileInput.click()}
                        disabled={loadingImage}
                        component="span"
                        className={classes.buttonBlock}>
                        Change image
                      </Button>
                    </label>

                    {!data.image || !data.image.secure_url ? null : (
                      <Button
                        size="small"
                        variant="text"
                        color="default"
                        onClick={this.handleChange('image', '')}
                        disabled={loadingImage}
                        component="span"
                        className={classes.buttonBlock}>
                        Remove
                      </Button>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Grid item xs={12} md={4} lg={8}>
                        <TextField
                          required
                          autoComplete="off"
                          name="name"
                          label="Beverage name"
                          className={classes.textField}
                          value={name || ''}
                          onChange={this.handleChange('name')}
                          fullWidth
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid item xs={12} md={4} lg={8}>
                        <TextField
                          name="producer_name"
                          label="Producer"
                          className={classes.textField}
                          value={data.producer_name || ''}
                          onChange={this.handleChange('producer_name')}
                          fullWidth
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={4} lg={4}>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="beverage-type">Beverage type</InputLabel>
                        <Select
                          className={classes.textField}
                          value={data.beverage_type ? data.beverage_type : ''}
                          onChange={this.handleChange('beverage_type')}
                          inputProps={{
                            name: 'beverage_type',
                            id: 'beverage-type',
                            fullwidth: 'true',
                          }}>
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="beer">Beer</MenuItem>
                          <MenuItem value="cider">Cider</MenuItem>
                          <MenuItem value="other">Other</MenuItem>
                          <MenuItem value="wine">Wine</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        autoComplete="off"
                        name="style_name"
                        label="Style name"
                        className={classes.textField}
                        value={data.style_name ? data.style_name : ''}
                        onChange={this.handleChange('style_name')}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} md={4} lg={4} />

                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        type="number"
                        name="abv_percent"
                        label="ABV"
                        className={classes.textField}
                        value={data.abv_percent ? data.abv_percent : ''}
                        onChange={this.handleChange('abv_percent')}
                        /* margin="normal" */
                        fullWidth
                        InputProps={{
                          inputProps: { min: 0, max: 100, step: 0.1 },
                          startAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        type="number"
                        name="ibu"
                        label="IBU"
                        className={classes.textField}
                        value={data.ibu ? data.ibu : ''}
                        onChange={this.handleChange('ibu')}
                        /* margin="normal" */
                        fullWidth
                        InputProps={{ inputProps: { min: 0, max: 120, step: 1 } }}
                      />
                    </Grid>

                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        type="number"
                        name="srm"
                        label="SRM"
                        className={classes.textField}
                        value={data.srm ? data.srm : ''}
                        onChange={this.handleChange('srm')}
                        /* margin="normal" */
                        fullWidth
                        InputProps={{ inputProps: { min: 0, max: 40, step: 0.1 } }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
          )}

          {tab === 1 && (
            <TabPanel>
              <Sizes data={data} onSizesChanged={sizes => this.handleChange('sizes', sizes)()} />
            </TabPanel>
          )}
        </ContentPaper>

        <FormActions onDelete={this.handleDelete} onCancel={onCancel} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default BeverageForm;
