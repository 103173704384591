import React from 'react';

import { withRouter } from 'react-router-dom';
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  withStyles,
} from '@material-ui/core';
import Portal from '@material-ui/core/Portal';
import BeverageService from '../../../services/BeverageService';
import { translate } from '../../../i18n';

const styles = theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(0, 3),
  },
  error: {
    color: theme.palette.error.dark,
    marginTop: theme.spacing(1),
  },
});

@withRouter
@withStyles(styles)
class RecipeCloneDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.name || '',
      error: '',
    };
  }

  handleSubmit = event => {
    // Prevent further propagation
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const { name } = this.state;
    const { onSubmit, hideAddDialog } = this.props;

    if (!name) {
      this.setState({
        error: 'The new recipe name is required',
      });

      return;
    }

    hideAddDialog();

    onSubmit(name);

    this.handleClose();
  };

  handleClose = event => {
    // Prevent further propagation
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const { hideAddDialog } = this.props;
    hideAddDialog();
  };

  render() {
    const { classes, open, hideAddDialog } = this.props;

    return (
      <div className={classes.root}>
        <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="xs">
          <DialogContent>
            <DialogContentText>Enter a name for the new recipe</DialogContentText>
            {!this.state.error ? null : (
              <Typography component="span" className={classes.error}>
                {this.state.error}
              </Typography>
            )}
            <TextField
              error={!!this.state.error}
              value={this.state.name}
              onChange={event => this.setState({ name: event.target.value })}
              autoFocus
              margin="dense"
              id="name"
              label=""
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={hideAddDialog} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Clone recipe
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default RecipeCloneDialog;
