import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Divider, withStyles, IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

import styles from './styles';

@withStyles(styles)
@withRouter
class ActionsMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
      anchorEl: null,
    };
  }

  handleClick = (event) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (event) => {
    event.stopPropagation();
    this.setState({ anchorEl: null });
  };

  triggerDelete = () => {
    this.setState({ deleting: true });
  };

  cancelDelete = () => {
    this.setState({ deleting: false });
  };

  handleDelete = () => {
    const { id, handleDelete } = this.props;
    handleDelete(id);
  };

  handleCompleted = () => {
    const { id, handleCompleted } = this.props;
    handleCompleted(id);
  };

  handleCancel = () => {
    const { id, handleCancel } = this.props;
    handleCancel(id);
  };

  handleContact = () => {
    const { data, handleContact } = this.props;
    handleContact(data);
  };

  render() {
    const { anchorEl, deleting } = this.state;
    const { key, classes, data, history } = this.props;

    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={anchorEl ? key : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          style={{ padding: '5px' }}>
          <MoreVertIcon />
        </IconButton>
        <Menu id={key} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>


          <MenuItem
            onClick={(event) => {
              this.handleClose(event);
              this.handleCompleted();
            }}>
            Mark as completed
          </MenuItem>

          <MenuItem
            onClick={(event) => {
              this.handleClose(event);
              this.handleContact();
            }}>
            Contact client
          </MenuItem>

          <MenuItem
            onClick={(event) => {
              this.handleClose(event);
              history.push(`/order/edit/${data.id_string}`);
            }}>
            Edit
          </MenuItem>

          <Divider />

          <MenuItem
            onClick={(event) => {
              this.handleClose(event);
              this.handleCancel();
            }}>
            Cancel
          </MenuItem>

          <MenuItem
            onClick={(event) => {
              this.handleClose(event);
              this.triggerDelete();
            }}
            className={classes.danger}>
            Remove
          </MenuItem>
        </Menu>

        {!deleting ? null : (
          <ConfirmationDialog
            message={`Are you sure you want to delete the order for '${data.first_name} ${data.last_name}'?`}
            confirm={this.handleDelete}
            cancel={this.cancelDelete}
            show
          />
        )}
      </div>
    );
  }
}

ActionsMenu.defaultProps = {
  data: {
    id_string: '',
  },
};

ActionsMenu.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id_string: PropTypes.string.isRequired,
  }).isRequired,
};

export default ActionsMenu;
