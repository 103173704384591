import React from 'react';
import { Button, withStyles } from '@material-ui/core';

import { withSnackbar } from 'notistack';

import styles from '../styles';
import EnhancedTable from '../../../components/enhanced-table/EnhancedTable';
import ContentPaper from '../../../components/content-paper';
import ActionMenu from './row-actions';
import PageLoadingText from '../../../components/PageLoadingText';
import { translate } from '../../../i18n';

@withSnackbar
@withStyles(styles)
class Menus extends React.Component {
  constructor(props) {
    super(props);

    props.setPageTitle(translate('Menus'));

    const { fetchData } = this.props;
    fetchData();
  }

  handleStatusChange = (id, enabled) => {
    const { updateMenuData } = this.props;
    updateMenuData(id, { is_active: !!enabled });
  };

  handleMassDelete = ids => ids.map(id => this.handleDeleteMenu(id));

  handleDeleteMenu = id => {
    const { deleteMenu } = this.props;
    deleteMenu(id);
  };

  handleCopyMenu = id => {
    const { copyMenu } = this.props;
    copyMenu(id);
  };

  handleRowClick = ({ id }) => {
    const { history } = this.props;
    history.push(`/menu/edit/${id}`);
  };

  handleAdd = () => {
    const { history } = this.props;
    history.push('/menu/add');
  };

  getTableColumns = () => {
    const { classes, loadingIds } = this.props;

    return [
      {
        id: 'menu_name',
        accessor: 'menu_name',
        sortable: true,
        component: 'td',

        label: 'Name',
        renderValue: row =>
          row.is_active ? row.menu_name : <span className={classes.disabledMenu}>{row.menu_name}</span>,
      },
      {
        id: 'description',
        accessor: 'description',

        label: 'Description',
      },
      {
        id: 'id',
        accessor: 'id',
        numeric: false,
        label: '',
        align: 'right',
        padding: 'none',
        renderValue: row => (
          <ActionMenu
            isLoading={loadingIds.indexOf(parseInt(row.id, 10)) !== -1}
            handleDelete={this.handleDeleteMenu}
            handleStatusChange={this.handleStatusChange}
            handleCopy={this.handleCopyMenu}
            id={row.id}
            data={row}
          />
        ),
      },
    ];
  };

  getTableFilters = () => {
    const { setTableFilter, filters } = this.props;

    return [
      {
        label: translate('Status'),
        type: 'select',
        value: filters.status,
        onChange: value => setTableFilter('status', value),
        options: [
          {
            value: '',
            label: translate('All'),
          },
          {
            value: 'active',
            label: translate('Active'),
          },
          {
            value: 'inactive',
            label: translate('Inactive'),
          },
        ],
      },
      {
        label: translate('Search'),
        type: 'search',
        value: filters.search,
        onChange: value => setTableFilter('search', value),
      },
    ];
  };

  shouldComponentRender = () => {
    const { initialLoaded, menusById } = this.props;

    // return !(loading || !initialLoaded);
    if (menusById === null) {
      return false;
    }

    return initialLoaded;
  };

  getLoadingSkeleton = () => {
    return <PageLoadingText />;
    /*
    const { classes } = this.props;
    return (
      <ContentPaper table className={clsx([classes.paper, 'ContentPaper-Loading'])}>
        <TableSkeleton />
      </ContentPaper>
    );
    */
  };

  render() {
    const { classes, menusById } = this.props;

    if (!this.shouldComponentRender()) {
      // return <div className={classes.root}>{this.getLoadingSkeleton()}</div>;
      return null;
    }

    const data = Object.values(menusById);

    return (
      <div className={classes.root}>
        <ContentPaper table className={classes.paper}>
          <EnhancedTable
            toolbarActions={
              <>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleAdd}
                  startIcon={<i className="fal fa-plus" />}>
                  Add menu
                </Button>
              </>
            }
            filters={this.getTableFilters()}
            columns={this.getTableColumns()}
            onRowClick={this.handleRowClick}
            onMassDelete={this.handleMassDelete}
            data={data}
            rowCount={data.length}
            className={classes.table}
            filter={null}
            /* loading={loadingRow} */
            order="asc"
            orderBy="name"
          />
        </ContentPaper>
      </div>
    );
  }
}

export default Menus;
