import { connect } from 'react-redux';
import VendorAddDialog from '../components/vendor-add-dialog';
import { showVendorAddDialog, hideVendorAddDialog } from '../actions';

function mapStateToProps(state) {
  return {
    open: state.vendors.showAddDialog,
  };
}

const mapDispatchToProps = {
  showAddDialog: showVendorAddDialog,
  hideAddDialog: hideVendorAddDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorAddDialog);
