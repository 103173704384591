import ApiClient from "../components/ApiClient";
import AppState from "../lib/stores/AppState";

const VendorService = {

    add(data, callback) {
        if ( callback ) {
            ApiClient.post({
                endpoint: '/api/vendor',
                data: data
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.post({
            endpoint: '/api/vendor',
            data: data
        });
    },

    update(id, data, callback) {
        if ( callback ) {
            ApiClient.patch({
                endpoint: '/api/vendor/' + id,
                data: data
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.patch({
            endpoint: '/api/vendor/' + id,
            data: data
        });
    },

    updateIngredientOrder(id, data) {

        return ApiClient.post({
            endpoint: '/api/vendor/' + id + '/ingredient-order',
            data: data
        });
    },

    delete(id, callback) {
        if ( callback ) {
            ApiClient.delete({
                endpoint: '/api/vendor/' + id,
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.delete({
            endpoint: '/api/vendor/' + id,
        });
    },

    fetchAll(callback) {

        if ( callback ) {
            ApiClient.get({
                endpoint: '/api/vendor/'
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.get({
            endpoint: '/api/vendor/'
        });
    },

    fetch(id, callback) {

        if ( callback ) {
            ApiClient.get({
                endpoint: '/api/vendor/' + id
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.get({
            endpoint: '/api/vendor/' + id
        });
    },
};

export default VendorService;
