import React from 'react';
import { Drawer } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import clsx from 'clsx';
import NavbarOwn from '../../../components/Navbar';
import { styles } from '../styles/drawer';

@withStyles(styles)
class AppDrawer extends React.Component {
  handleDrawerClose = event => {
    const { closeDrawer } = this.props;
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    closeDrawer();
    localStorage.setItem('isDrawerOpen', '0');
  };

  renderDrawerContent = mobile => {
    const { classes, drawerOpen: open } = this.props;

    return (
      <Scrollbars>
        <NavbarOwn
          drawerOpen={open}
          classes={classes}
          isMobile={mobile}
          onClose={mobile ? this.handleDrawerClose : null}
        />
      </Scrollbars>
    );
  };

  render() {
    const { classes, drawerOpen: open } = this.props;
    const drawerContainer = window !== undefined ? () => window.document.body : undefined;

    return (
      <>
        <Hidden smUp implementation="js">
          <Drawer
            container={drawerContainer}
            variant="temporary"
            open={open}
            onClose={event => this.handleDrawerClose(event)}
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}>
            {this.renderDrawerContent(true)}
          </Drawer>
        </Hidden>

        <Hidden xsDown implementation="js">
          <Drawer
            variant="permanent"
            className={clsx([classes.drawer, open && classes.drawerOpen, !open && classes.drawerClose])}
            classes={{
              paper: [classes.drawerPaper, open && classes.drawerOpen, !open && classes.drawerClose].join(' '),
            }}
            open={open}>
            {this.renderDrawerContent(false)}
          </Drawer>
        </Hidden>
      </>
    );
  }
}

export default AppDrawer;
