import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Delete from '@material-ui/icons/Delete';
import DragHandle from '@material-ui/icons/DragIndicator';
import update from 'react-addons-update';
import { convertFromBaseUnit, convertToBaseUnit, translate } from '../../../i18n';
import MeasureUnitSelect from '../../../components/forms/measure-unit-select';
import Droppable from '../../../components/dnd-simple/droppable';
import Draggable from '../../../components/dnd-simple/draggable';
import NumericInputFormat from '../../../components/forms/numeric';

const ManageIngredients = ({ allIngredients, ingredients, onChange, sortedIngredients }) => {
  const [newName, setNewName] = React.useState('');
  const [justAddedNew, setJustAddedNew] = React.useState(false);

  const handleChange = (index, name) => (event, autocompleteValue) => {
    let value = typeof event === 'string' ? event : event.target.value;

    // Special case for ingredient
    if (name === 'ingredient_id') {
      if (autocompleteValue && autocompleteValue.id) {
        value = autocompleteValue.id;
      } else {
        value = autocompleteValue;
      }
    }

    const newIngredients = [...ingredients];

    newIngredients[index] = { ...newIngredients[index], [name]: value };

    // Special case for quantity
    if (name === 'measure_unit' && value !== ingredients[index].measure_unit) {
      const originalInBaseUnit = convertFromBaseUnit(
        newIngredients[index].quantity,
        ingredients[index].measure_unit,
        'numberInput',
      );

      // Do the conversion to the new unit
      let newQuantity = convertToBaseUnit(originalInBaseUnit, value, 'numberInput');

      // If the ingredient quantity is empty string and the new quantity is 0, then set the quantity as empty string
      if (newIngredients[index].quantity === '' && parseFloat(newQuantity) === 0) {
        newQuantity = '';
      }

      newIngredients[index] = { ...newIngredients[index], quantity: newQuantity };
    }

    if (name === 'quantity') {
      // Recalculate size
      const { quantity, measure_unit: measureUnit } = newIngredients[index];
      newIngredients[index] = {
        ...newIngredients[index],
        quantity: convertToBaseUnit(quantity, measureUnit, 'numberInput'),
      };
    }

    // Update some fields
    newIngredients[index] = {
      ...newIngredients[index],
      sort_order: index,
    };

    onChange([...newIngredients]);
  };

  const handleAddNew = (event, value) => {
    if (value && value.id) {
      setJustAddedNew(true);
      onChange([
        ...ingredients,
        { id: null, ingredient_id: value.id, quantity: '', measure_unit: value.unit, sort_order: 9999 },
      ]);
    }
  };

  const handleDrop = (dragIndex, hoverIndex) => {
    let data = [...ingredients];
    const dragCard = data[dragIndex];

    // Reorder
    data = update(data, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    });

    // Calculate sort_order
    data = data.map((i, idx) => ({ ...i, sort_order: idx }));
    onChange([...data]);
  };

  const handleDelete = index => () => {
    const newIngredients = [...ingredients];
    newIngredients.splice(index, 1);

    onChange(newIngredients);
  };

  const isOptionDisabled = option => {
    return ingredients.findIndex(ri => ri.ingredient_id === option.id) !== -1;
  };

  const availableIngredients = Object.values(sortedIngredients);
  /** Exclude already added ingredients */
  // .filter(i => ingredients.findIndex(ri => ri.ingredient_id === i.id) === -1);

  return (
    <div className="ingredients-list">
      <div className="ingredients-row my-2 font-weight-bolder">
        <div className="flex-grow-1" />
        <div className="col-quantity mx-2 text-right">{translate('Quantity')}</div>
        <div className="col-unit ml-2">{translate('Unit')}</div>
        <div className="col-action ml-2" />
      </div>

      <div>
        {ingredients.map((recipeIngredient, index) => {
          const id = recipeIngredient.ingredient_id;
          const ingredient = (allIngredients && allIngredients[id]) || null;
          if (!ingredient) {
            return null;
          }

          // Should we convert the quantity?
          let quantity = recipeIngredient.quantity || recipeIngredient.quantity === 0 ? recipeIngredient.quantity : '';
          if (quantity && parseFloat(quantity) && recipeIngredient.measure_unit) {
            quantity = convertFromBaseUnit(quantity, recipeIngredient.measure_unit);
          }

          return (
            <Droppable key={`ingredient-row-${id}`} dndType="INGREDIENT" onCardDrop={handleDrop} index={index} id={id}>
              <Draggable dndType="INGREDIENT" index={index} id={id}>
                <div className="ingredients-row mb-2">
                  <div className="col-drag-handle">
                    <ListItemIcon size="small" tabIndex={-1} style={{ minWidth: 'auto' }}>
                      <DragHandle fontSize="small" />
                    </ListItemIcon>
                  </div>
                  <div className="flex-grow-1">
                    <Autocomplete
                      options={availableIngredients || []}
                      getOptionLabel={option => (option.name ? option.name : option)}
                      disableClearable
                      openOnFocus
                      fullWidth
                      value={{ id: ingredient.id, name: ingredient.name }}
                      onChange={handleChange(index, 'ingredient_id')}
                      getOptionDisabled={isOptionDisabled}
                      renderInput={params => <TextField {...params} />}
                    />
                  </div>
                  <div className="col-quantity mx-2">
                    {/* <TextField
                      autoFocus={justAddedNew && index === ingredients.length - 1}
                      fullWidth
                      required
                      inputProps={{
                        className: 'text-right',
                      }}
                      type="number"
                      placeholder="0"
                      value={quantity}
                      onChange={handleChange(index, 'quantity')}
                    /> */}
                    <TextField
                      autoFocus={justAddedNew && index === ingredients.length - 1}
                      fullWidth
                      required
                      inputProps={{
                        className: 'text-right',
                      }}
                      InputProps={{
                        inputComponent: NumericInputFormat,
                      }}
                      name="quantity"
                      value={quantity}
                      onChange={handleChange(index, 'quantity')}
                    />
                  </div>
                  <div className="col-unit ml-2">
                    <MeasureUnitSelect
                      fullWidth
                      required
                      value={recipeIngredient.measure_unit}
                      onChange={handleChange(index, 'measure_unit')}
                      name="measure_unit"
                      id="measure_unit-type"
                    />
                  </div>

                  <div className="col-action ml-2 text-right">
                    <IconButton size="small" title={translate('Remove')} onClick={handleDelete(index)} tabIndex={-1}>
                      <Delete />
                    </IconButton>
                  </div>
                </div>
              </Draggable>
            </Droppable>
          );
        })}
      </div>

      <div className="ingredients-row">
        <div className="col-drag-handle" />
        <div className="flex-grow-1">
          <Autocomplete
            options={availableIngredients}
            getOptionLabel={option => (option.name ? option.name : option)}
            getOptionDisabled={isOptionDisabled}
            blurOnSelect
            autoSelect
            clearOnBlur
            clearOnEscape
            openOnFocus
            disableClearable
            onChange={handleAddNew}
            onInputChange={ev => setNewName(ev && ev.target ? ev.target.value : '')}
            inputValue={newName}
            renderInput={params => <TextField {...params} label="" />}
          />
        </div>
        <div className="col-quantity mx-2" />
        <div className="col-unit ml-2" />
        <div className="col-action ml-2" />
      </div>
    </div>
  );
};

export default ManageIngredients;
