import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const Icon = ({ name, light = true, solid, className, padding, fixedWidth = false, spin }) => {
  const theme = useTheme();

  const icon = clsx(
    {
      fa: solid && !light,
      fal: light,
      'fa-fw': fixedWidth,
      'fa-spin': spin,
    },
    `fa-${name}`,
    className || '',
  );

  const style = {};
  if (padding) {
    style.padding = theme.spacing.apply(this, Array.isArray(padding) ? [...padding] : [padding]);
  }

  return <i className={icon} style={style} />;
};

Icon.defaultProps = {
  light: true,
  solid: false,
  spin: false,
  fixedWidth: false,
  padding: null,
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  light: PropTypes.bool,
  solid: PropTypes.bool,
  spin: PropTypes.bool,
  padding: PropTypes.shape(PropTypes.array),
  fixedWidth: PropTypes.bool,
};

export default Icon;
