import { makeActionCreator } from '../../../store/actions';

export const INGREDIENTS_FETCH_REQUEST = '@ingredients / FETCH REQUEST';
export const INGREDIENTS_FETCH_SUCCESS = '@ingredients / FETCH SUCCESS';
export const INGREDIENTS_FETCH_ERROR = '@ingredients / FETCH ERROR';
export const INGREDIENTS_SHOW_ADD_DIALOG = '@ingredients / SHOW ADD DIALOG';
export const INGREDIENTS_HIDE_ADD_DIALOG = '@ingredients / HIDE ADD DIALOG';
export const INGREDIENTS_SET_TABLE_FILTER = '@ingredients / SET TABLE FILTER';

export const fetchIngredients = makeActionCreator(INGREDIENTS_FETCH_REQUEST);
export const fetchIngredientsError = makeActionCreator(INGREDIENTS_FETCH_ERROR, 'error');
export const fetchIngredientsSuccess = makeActionCreator(INGREDIENTS_FETCH_SUCCESS, 'data');
export const showIngredientAddDialog = makeActionCreator(INGREDIENTS_SHOW_ADD_DIALOG);
export const hideIngredientAddDialog = makeActionCreator(INGREDIENTS_HIDE_ADD_DIALOG);

// Table filters
export const setTableFilter = makeActionCreator(INGREDIENTS_SET_TABLE_FILTER, 'name', 'value');
