import React from 'react';
import {withTheme} from "@material-ui/styles";
import { Typography, Box } from '@material-ui/core';

class Dashboard extends React.Component {
    state = {
        open: true,
        venueData: null
    }

    componentDidMount() {

    }



    render() {
        return (
            <Box px={3}>

            </Box> )
    }
}

export default withTheme(Dashboard);
