import { call, put } from 'redux-saga/effects';
import { hideProgressBar, showProgressBar } from '../../ui/actions';
import { parseApiError } from '../../../api/helpers';
import { auditApi } from '../../../api/audit';
import {
  fetchAuditDatabaseError,
  fetchAuditDatabaseSuccess,
  fetchAuditEventsError,
  fetchAuditEventsSuccess,
} from '../actions';

export function* fetchAuditEventsSaga() {
  try {
    yield put(showProgressBar());
    const response = yield call(auditApi.fetchEvents);
    yield put(hideProgressBar());

    if (!response.ok) {
      yield put(fetchAuditEventsError(parseApiError(response).message));
      return;
    }

    yield put(fetchAuditEventsSuccess(response.data.data.results || []));
  } catch (err) {
    yield put(fetchAuditEventsError(parseApiError(err).message));
  }
}
export function* fetchAuditDatabaseSaga() {
  try {
    yield put(showProgressBar());
    const response = yield call(auditApi.fetchDatabaseEvents);
    yield put(hideProgressBar());

    if (!response.ok) {
      yield put(fetchAuditDatabaseError(parseApiError(response).message));
      return;
    }

    yield put(fetchAuditDatabaseSuccess(response.data.data.results || []));
  } catch (err) {
    yield put(fetchAuditDatabaseError(parseApiError(err).message));
  }
}
