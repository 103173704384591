import React, { Component } from 'react';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import TextField from '@material-ui/core/TextField';
import { FormControl, FormLabel, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { Prompt, withRouter } from 'react-router';
import ContentPaper from '../../../components/content-paper';
import { translate } from '../../../i18n';
import { modifierApi } from '../../../api/modifier';
import FormActions from '../../../components/form-actions';
import { FormContainer } from '../../../components/forms/container';
import { FormRow } from '../../../components/forms/row';
import PageLoadingText from '../../../components/PageLoadingText';

import '../styles/modifiers.scss';
import ManageOptions from './manage-options';
import { parseApiError } from '../../../api/helpers';

@withSnackbar
@withRouter
class ModifierForm extends Component {
  constructor(props) {
    super(props);

    // Set page title
    const { id, setPageTitle } = this.props;
    let pageTitle = translate('Add modifier');
    if (id) {
      pageTitle = translate('Edit modifier');
    }
    setPageTitle(pageTitle);

    this.state = {
      id: props.id ? props.id : null,
      data: !_.isEmpty(props.data) ? props.data : { modifier_type: '' },
      newData: {},
    };
  }

  componentDidMount() {
    const { id, fetchModifierById } = this.props;
    if (id) {
      fetchModifierById(id);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { id, data } = this.state;

    // Has the data been loaded and passed over via props?
    if (id && !data.id && nextProps.data) {
      this.setState({
        id: nextProps.id,
        data: nextProps.data,
      });
    }
  }

  handleSubmit = async ev => {
    if (ev) {
      ev.preventDefault();
    }

    const { id, newData } = this.state;
    const { enqueueSnackbar } = this.props;

    try {
      const response = !id ? await modifierApi.add(newData) : await modifierApi.update(id, newData);

      if (!response.ok) {
        enqueueSnackbar(parseApiError(response).message);
        return;
      }

      this.setState({ hasUnsavedChanges: false });

      enqueueSnackbar('Modifier saved');
      this.handleAfterSave(response);
    } catch (e) {
      enqueueSnackbar('Could not save the modifier!', { variant: 'error' });
    }

    return false;
  };

  handleChange = (name, value) => event => {
    const { data, newData } = this.state;

    value = typeof value !== 'undefined' ? value : event.target.value;
    this.setState({
      hasUnsavedChanges: true,
      data: { ...data, [name]: value },
      newData: { ...newData, [name]: value },
    });
  };

  handleOptionsChange = nextOptions => {
    const { data, newData } = this.state;

    this.setState({
      data: { ...data, options: nextOptions },
      newData: { ...newData, options: nextOptions },
    });
  };

  handleDelete = async () => {
    const { id } = this.state;
    const { enqueueSnackbar, history } = this.props;

    // Reset state
    this.setState({ hasUnsavedChanges: false });

    // Delete AJAX
    try {
      await modifierApi.deleteById(id);

      enqueueSnackbar('Modifier deleted');
      history.push('/modifier');
    } catch (e) {
      enqueueSnackbar('Could not delete the modifier', { options: { variant: 'error' } });
    }
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push('/modifier');
  };

  handleAfterSave = () => {
    const { history } = this.props;
    history.push('/modifier');
  };

  canSubmitForm = () => {
    const { data } = this.state;
    const { name, modifier_type } = data;

    return name && modifier_type;
  };

  render() {
    const { id } = this.props;
    const { data, hasUnsavedChanges } = this.state;

    if (id && (!data || !data.id)) {
      return <PageLoadingText />;
    }

    const name = data && data.name ? data.name : '';

    // Sort the options
    const sortedModifierOptions = !data.options
      ? []
      : Object.values(data.options).sort((i1, i2) => {
          if (i1 && i1.sort_order === i2.sort_order) {
            return 0;
          }

          return i1.sort_order < i2.sort_order ? -1 : 1;
        });

    return (
      <form onSubmit={this.handleSubmit} method="POST">
        <Prompt
          when={!!hasUnsavedChanges}
          message={translate('You have unsaved changes. Are you sure you want to leave?')}
        />
        <ContentPaper short className="FormBoxPaper">
          <FormContainer>
            <FormRow>
              <TextField
                required
                fullWidth
                autoComplete="off"
                name="name"
                label={translate('Modifier name')}
                value={name || ''}
                error={hasUnsavedChanges && !name}
                helperText={hasUnsavedChanges && !name ? translate('This field is required') : ''}
                onChange={this.handleChange('name')}
                InputProps={{
                  classes: {
                    input: 'LargeTitleInput',
                  },
                }}
              />
            </FormRow>

            <FormRow>
              <FormControl component="fieldset">
                <FormLabel component="legend" required>
                  {translate('Modifier type')}
                </FormLabel>
                <RadioGroup
                  row
                  required
                  aria-label="modifier type"
                  name="modifier_type"
                  value={data.modifier_type || ''}
                  onChange={this.handleChange('modifier_type')}>
                  <FormControlLabel value="addon" control={<Radio required />} label={translate('Add-on')} />
                  <FormControlLabel value="select" control={<Radio required />} label={translate('Variant')} />
                </RadioGroup>
              </FormControl>
            </FormRow>

            <ManageOptions options={sortedModifierOptions} onChange={this.handleOptionsChange} />
          </FormContainer>
        </ContentPaper>

        <FormActions
          short
          onDelete={this.handleDelete}
          onCancel={this.handleCancel}
          onSubmit={() => {}}
          disableSubmit={!this.canSubmitForm()}
        />
      </form>
    );
  }
}

export default ModifierForm;
