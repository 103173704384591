import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

function NumericInputFormat(props) {
  const { allowNegative = false, decimalScale = 2, decimalSeparator = ',', name, inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      getInputRef={inputRef}
      decimalScale={decimalScale}
      decimalSeparator={decimalSeparator}
      allowNegative={allowNegative}
      isNumericString
      allowedDecimalSeparators={[',', '.']}
      onValueChange={values => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      {...other}
    />
  );
}

NumericInputFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NumericInputFormat;
