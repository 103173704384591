import React from 'react';
import { TextField } from '@material-ui/core';
import { translate } from '../../i18n';
import NumericInputFormat from './numeric';

const StockField = ({ isNumericString, inputProps = {}, InputProps = {}, ...rest}) => {
  return (
    <TextField
      {...rest}
      InputProps={{
        ...InputProps,
        inputProps: {
          fixedDecimalScale: false,
          decimalScale: 3,
          ...inputProps,
        },
        inputComponent: NumericInputFormat,
      }}
    />
  );
};

export default StockField;
