import React from 'react';
import _ from 'lodash';
import CloseIcon from '@material-ui/icons/Close';

import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';

import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';

import Downshift from 'downshift';
import * as PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Popper,
  TextField,
  Typography,
  withMobileDialog,
} from '@material-ui/core';
import { DragSource, DropTarget } from 'react-dnd';
import ReactDOM from 'react-dom';
import BeverageService from '../../services/BeverageService';
import BeverageForm from '../beverage/BeverageForm';
import srmList from '../../components/SrmValuesList';
import DraggableTypes from '../../components/DraggableTypes';

const styles = theme => ({
  attributes: {
    width: '100%',
    marginBottom: '5px',
  },

  bevMeta: {
    marginBottom: 0,
    color: theme.palette.text.secondary,
  },

  bevTitle: {
    /* fontSize: '1.15rem' */
  },

  btnSuccess: {
    color: green[500],
  },
  btnWarning: {
    color: orange[500],
  },

  card: {
    /* marginBottom: theme.spacing.unit * 2, */
    border: `1px solid ${theme.palette.grey[300]}`,
    borderWidth: '0 1px 1px 0',
    borderRadius: 0,
  },
  cardDisabled: {
    backgroundColor: '#fafafa',
  },
  cardContent: {
    /* padding: theme.spacing.unit,
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: '0', */
    /* padding: theme.spacing(1) + 'px !important', */
  },

  cardActions: {
    justifyContent: 'flex-end',
  },

  close: {
    color: theme.textSecondary,
    float: 'right',
    padding: 0,
  },

  errorButton: {
    color: theme.palette.error.dark,
  },

  dragger: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.secondary.default,
    },
  },

  itemTitle: {
    width: '100%',
  },

  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

  menuItem: {
    height: '140px',
    overflow: 'hidden',

    position: 'relative',
  },

  itemContent: {
    flex: '0 0 75%',
    maxWidth: '75%',
    paddingTop: '0',
    paddingRight: '0',
  },

  itemMedia: {
    flex: '0 0 25%',
    maxWidth: '25%',
    paddingTop: '0',
    paddingRight: '0',
  },

  glass: {
    maxWidth: '100%',
    maxHeight: '100px',
    paddingRight: '15px',
    /* height: '18vh', */
    /* width: 'auto' */
  },

  placeholder: {
    background: '#f7f7f7',

    '& $bevTitle': {
      color: theme.palette.text.secondary,
      cursor: 'pointer',
    },

    '& $itemMedia, & $bevMeta, & $attributes': {
      opacity: 0,
    },
  },

  newBeverageSelector: {
    width: '95%',
  },

  priceField: {
    width: '50px',
    display: 'flex',
  },

  paper: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
  },
});

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

function renderBeverage({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = ((selectedItem && selectedItem.name) || '').indexOf(suggestion.name) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.name}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}>
      {suggestion.name}
    </MenuItem>
  );
}
renderBeverage.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

class EditBeveragePopup extends React.Component {
  state = {
    edit: null,
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  render() {
    const { classes, fullScreen, id, data } = this.props;

    return (
      <Dialog fullScreen={fullScreen} open onClose={this.handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <div>
            <IconButton className={classes.close} onClick={this.handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>

            <BeverageForm
              id={id}
              data={data}
              saveLabel="Save and Close"
              cancelLabel="Cancel"
              onSave={this.handleClose}
              onCancel={this.handleClose}
              /* onSave={this.props.onAfterBeverageSaved} */
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

EditBeveragePopup = withStyles(styles)(withMobileDialog()(EditBeveragePopup));

class ActionMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <div style={{ position: 'absolute', top: 0, right: 0 }}>
        <IconButton
          aria-label="More"
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          style={{ padding: '5px' }}>
          <MoreVertIcon />
        </IconButton>
        <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          <MenuItem
            onClick={() => {
              this.handleClose();
              this.props.onEdit();
            }}>
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.handleClose();
              this.props.onRemove();
            }}>
            Remove
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

@withStyles(styles)
class TapItem extends React.Component {
  defaultSizes = [
    {
      label: '300 ml',
      size: '300',
      unit: 'ml',
      price: '',
    },
    {
      label: '355 ml',
      size: '355',
      unit: 'ml',
      price: '',
    },
    {
      label: '473 ml',
      size: '473',
      unit: 'ml',
      price: '',
    },
  ];

  popperNode = null;

  constructor(props) {
    super(props);

    this.saveSizeChanges = _.debounce((id, sizes) => {
      BeverageService.saveSizes(id, sizes, () => {
        this.props.onPricesChanged && this.props.onPricesChanged();
      });
    }, 1500);

    this.state = {
      edit: false,
      sizes:
        props.beverage && props.beverage.sizes && props.beverage.sizes.length
          ? props.beverage.sizes
          : _.cloneDeep(this.defaultSizes),
      beverages: [],
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.placeholder === false && nextProps.placeholder === true) {
      this.setState({
        edit: false,
        sizes: null,
      });
    }
    if (this.props.beverage.id !== nextProps.beverage.id) {
      this.setState({
        sizes:
          nextProps.beverage && nextProps.beverage.sizes && nextProps.beverage.sizes.length
            ? nextProps.beverage.sizes
            : _.cloneDeep(this.defaultSizes),
      });
    }
  }

  getBeverages = value => {
    const inputValue = _.deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    const suggestions = this.props.beverages;

    return inputLength === 0
      ? []
      : suggestions.filter(suggestion => {
          const keep = count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

          // const keep = suggestion.name.toLowerCase().indexOf(inputValue) !== -1;

          if (keep) {
            count += 1;
          }

          return keep;
        });
  };

  handleNewBeverage = item => {
    this.props.onAssignBeverage(this.props.index, item);
  };

  handleRemoveBeverage = () => {
    this.props.onUnassignBeverage(this.props.index);
  };

  handleEdit = () => {
    this.setState({ edit: true });
  };

  handlePriceChange = event => {
    const { target } = event;
    const { beverage } = this.props;
    const { sizes } = this.state;

    if (target && target.dataset && typeof target.dataset.index !== 'undefined') {
      if (sizes && sizes[target.dataset.index]) {
        sizes[target.dataset.index].price = target.value;

        // Save
        this.setState({ sizes }, () => this.saveSizeChanges(beverage.id, sizes));
      }
    }
  };

  render() {
    const { data, beverage, classes } = this.props;
    const { sizes } = this.state;

    let srmColor = '';
    if (beverage.srm) {
      const srmVal = _.find(srmList, o => o.value.srmNumber === Math.round(beverage.srm));
      if (srmVal) {
        srmColor = srmVal.value.color.replace('#', '');
      }
    }

    let subtitle = [];
    if (beverage.producer_name) {
      subtitle.push(beverage.producer_name);
    } else if (beverage.producer && beverage.producer.name) {
      subtitle.push(beverage.producer.name);
    }
    if (beverage.style && beverage.style.style) {
      subtitle.push(beverage.style.style);
    }
    subtitle = subtitle.join(' | ');

    const { connectDropTarget } = this.props;
    const { connectDragSource } = this.props;
    const { connectDragPreview } = this.props;

    return connectDragSource(
      connectDropTarget(
        connectDragPreview(
          <div>
            <Card elevation={0} className={classNames(classes.card, data.placeholder && classes.placeholder)}>
              <CardContent className={classes.cardContent}>
                <Grid container direction="row" justify="flex-start" className={classes.menuItem}>
                  {!beverage.image || !beverage.image.secure_url ? (
                    <Grid item className={classes.itemMedia}>
                      <img className={classes.glass} src={`/icon/beer-glass.svg?color=${srmColor}`} />
                    </Grid>
                  ) : (
                    <Grid item className={classes.itemMedia}>
                      <img className={classes.glass} src={beverage.image.secure_url} />
                    </Grid>
                  )}

                  <Grid item className={classes.itemContent}>
                    <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                      <Box display="flex">
                        <Typography component="div" className={classes.bevMeta}>
                          {subtitle || ''}
                        </Typography>

                        {data.placeholder ? null : (
                          <ActionMenu
                            id={beverage.id}
                            data={beverage}
                            onEdit={this.handleEdit}
                            onRemove={this.handleRemoveBeverage}
                          />
                        )}
                      </Box>

                      <Box display="flex" className={classes.itemTitle}>
                        {beverage.name ? (
                          <Typography variant="h6" component="h6" className={classes.bevTitle}>
                            {beverage.name}
                          </Typography>
                        ) : (
                          <div className={classes.newBeverageSelector}>
                            <Downshift
                              id={`add-new-beer${this.props.index}`}
                              onChange={this.handleNewBeverage}
                              initialSelectedItem={beverage}
                              initialInputValue={beverage.name}
                              itemToString={item => (item ? item.name : '')}>
                              {({
                                getInputProps,
                                getItemProps,
                                getMenuProps,
                                highlightedIndex,
                                inputValue,
                                isOpen,
                                selectedItem,
                              }) => (
                                <div className={classes.container}>
                                  {renderInput({
                                    fullWidth: true,
                                    classes,
                                    InputProps: getInputProps({
                                      placeholder: 'Search a beverage',
                                    }),
                                    ref: node => {
                                      this.popperNode = node;
                                    },
                                  })}
                                  <Popper open={isOpen} anchorEl={this.popperNode}>
                                    <div {...(isOpen ? getMenuProps({}, { suppressRefError: true }) : {})}>
                                      {isOpen ? (
                                        <Paper
                                          square
                                          style={{
                                            marginTop: 8,
                                            width: this.popperNode ? this.popperNode.clientWidth : null,
                                          }}>
                                          {this.getBeverages(inputValue).map((suggestion, index) =>
                                            renderBeverage({
                                              suggestion,
                                              index,
                                              itemProps: getItemProps({ key: suggestion.id, item: suggestion }),
                                              highlightedIndex,
                                              selectedItem,
                                            }),
                                          )}
                                        </Paper>
                                      ) : null}
                                    </div>
                                  </Popper>
                                </div>
                              )}
                            </Downshift>
                          </div>
                        )}
                      </Box>

                      <Box display="flex" className={classes.attributes}>
                        <Grid container>
                          {!beverage.abv_percent ? null : (
                            <Typography component={Grid} item xs={12} sm={4}>
                              ABV&nbsp;&nbsp;
                              <Typography color="textSecondary" component="span" style={{ display: 'inline' }}>
                                {beverage.abv_percent}%
                              </Typography>
                            </Typography>
                          )}
                          {!beverage.ibu ? null : (
                            <Typography component={Grid} item xs={12} sm={4}>
                              IBU&nbsp;&nbsp;
                              <Typography color="textSecondary" component="span" style={{ display: 'inline' }}>
                                {beverage.ibu}
                              </Typography>
                            </Typography>
                          )}
                          {!beverage.srm ? null : (
                            <Typography component={Grid} item xs={12} sm={4}>
                              SRM&nbsp;&nbsp;
                              <Typography color="textSecondary" component="span" style={{ display: 'inline' }}>
                                {beverage.srm}
                              </Typography>
                            </Typography>
                          )}
                        </Grid>
                      </Box>

                      <Box display="flex" className={classes.attributes}>
                        <Grid container>
                          {sizes &&
                            sizes.map((d, i) => {
                              return (
                                <Typography component={Grid} item xs={12} sm={4} key={`scrs${i}${d.size}`}>
                                  <FormControl className={classes.priceField} margin="dense">
                                    <InputLabel>{d.label ? d.label : `${d.size} ${d.unit}`} </InputLabel>
                                    <Input
                                      id={`size-price${i}${d.size}`}
                                      value={d.price}
                                      inputProps={{
                                        'data-index': i,
                                      }}
                                      onChange={this.handlePriceChange}
                                      /* onChange={this.handleChange(i, 'price')} */
                                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    />
                                  </FormControl>
                                  {/* <Typography color="textSecondary" component={'span'}
                                                                    style={{display: 'inline'}}>${d.price}</Typography>{i < sizes.length - 1 ? '' : ''}&nbsp;&nbsp;&nbsp; */}
                                </Typography>
                              );
                            })}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {!this.state.edit ? null : (
              <EditBeveragePopup id={beverage.id} data={beverage} onClose={() => this.setState({ edit: false })} />
            )}
          </div>,
        ),
      ),
    );
  }
}

TapItem.propTypes = {
  data: PropTypes.object.isRequired,
  onAssignBeverage: PropTypes.func.isRequired,
};

// export default TapItem;
export default DropTarget(
  DraggableTypes.TAP_ITEM,
  {
    hover(props, monitor, component) {
      /* if(!monitor.isOver({ shallow: true })) {
                console.log('false', monitor.getItem().index, monitor.getItem().initialIndex)
                props.moveTapItem(monitor.getItem().index, monitor.getItem().initialIndex)
                return null;
            } */
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = ReactDOM.findDOMNode(component);
      if (!node) {
        return null;
      }
      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Get horizontal middle
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      /* const area = [
                hoverBoundingRect.left + (hoverBoundingRect.width/2) - 70,
                hoverBoundingRect.left + (hoverBoundingRect.width/2) + 70,

                hoverBoundingRect.top + (hoverBoundingRect.height/2) - 40,
                hoverBoundingRect.top + (hoverBoundingRect.height/2) + 40,
            ]; */
      const area = [
        hoverBoundingRect.left,
        hoverBoundingRect.left + hoverBoundingRect.width,

        hoverBoundingRect.top,
        hoverBoundingRect.top + hoverBoundingRect.height,
      ];

      // console.log(dragTargetArea)
      if (
        clientOffset.x < area[0] ||
        clientOffset.x > area[1] ||
        clientOffset.y < area[2] ||
        clientOffset.y > area[3]
      ) {
        return false;
      }

      // Time to actually perform the action
      // props.moveTapItem(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    },
  },
  connect => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(
  DragSource(
    DraggableTypes.TAP_ITEM,
    {
      beginDrag: props => ({
        id: props.id,
        index: props.index,
        initialIndex: props.index,
      }),
      endDrag: (props, monitor, component) => {
        // Move card
        props.moveTapItem(monitor.getItem().initialIndex, monitor.getItem().index);
        if (props.handleDragEnd) {
          props.handleDragEnd();
        }
      },
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging(),
    }),
  )(TapItem),
);
