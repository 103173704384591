import React from 'react';
import ReactDOM from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import DraggableTypes from '../../../components/DraggableTypes';
import MenuCategory from '../containers/category';

/**
 * Implements the drag source contract.
 */
const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },

  endDrag(props) {
    if (props.handleDragEnd) {
      props.handleDragEnd();
    }
  },
};

const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = ReactDOM.findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.moveCard(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

function collect(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

function collect2(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

const MenuCategoryDnd = DropTarget(
  DraggableTypes.MENU_CATEGORY,
  cardTarget,
  collect,
)(DragSource(DraggableTypes.MENU_CATEGORY, cardSource, collect2)(MenuCategory));

class CategoriesList extends React.Component {
  render() {
    const { categories, onChange, handleDragEnd, moveCard, moveProductCard } = this.props;

    return categories.map((category, index) => {
      return (
        <MenuCategoryDnd
          key={`category-${category.name}-${category.id}`}
          category={category}
          index={index}
          onChange={onChange}
          handleDragEnd={handleDragEnd}
          moveCard={moveCard}
          moveProductCard={moveProductCard}
        />
      );
    });
  }
}

export default CategoriesList;
