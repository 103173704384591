import React, { Component } from 'react';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import TextField from '@material-ui/core/TextField';
import { Prompt, withRouter } from 'react-router';
import ContentPaper from '../../../components/content-paper';
import { translate } from '../../../i18n';
import { categoryApi } from '../../../api/category';
import FormActions from '../../../components/form-actions';
import { FormContainer } from '../../../components/forms/container';
import { FormRow } from '../../../components/forms/row';
import PageLoadingText from '../../../components/PageLoadingText';

import '../styles/categories.scss';
import { parseApiError } from '../../../api/helpers';

@withSnackbar
@withRouter
class CategoryForm extends Component {
  constructor(props) {
    super(props);

    // Set page title
    const { id, setPageTitle } = this.props;
    let pageTitle = translate('Add category');
    if (id) {
      pageTitle = translate('Edit category');
    }
    setPageTitle(pageTitle);

    this.state = {
      id: props.id ? props.id : null,
      data: !_.isEmpty(props.data) ? props.data : { category_type: '' },
      newData: {},
    };
  }

  componentDidMount() {
    const { id, fetchCategoryById } = this.props;
    if (id) {
      fetchCategoryById(id);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { id, data } = this.state;

    // Has the data been loaded and passed over via props?
    if (id && !data.id && nextProps.data) {
      this.setState({
        id: nextProps.id,
        data: nextProps.data,
      });
    }
  }

  handleSubmit = async ev => {
    if (ev) {
      ev.preventDefault();
    }

    const { id, newData } = this.state;
    const { enqueueSnackbar } = this.props;

    try {
      const response = !id ? await categoryApi.add(newData) : await categoryApi.update(id, newData);

      if (!response.ok) {
        enqueueSnackbar(parseApiError(response).message);
        return;
      }

      this.setState({ hasUnsavedChanges: false });

      enqueueSnackbar('Category saved');
      this.handleAfterSave(response);
    } catch (e) {
      enqueueSnackbar('Could not save the category!', { variant: 'error' });
    }

    return false;
  };

  handleChange = (name, value) => event => {
    const { data, newData } = this.state;

    value = typeof value !== 'undefined' ? value : event.target.value;
    this.setState({
      hasUnsavedChanges: true,
      data: { ...data, [name]: value },
      newData: { ...newData, [name]: value },
    });
  };

  handleDelete = async () => {
    const { id } = this.state;
    const { enqueueSnackbar, history } = this.props;

    // Reset state
    this.setState({ hasUnsavedChanges: false });

    // Delete AJAX
    try {
      await categoryApi.deleteById(id);

      enqueueSnackbar('Category deleted');
      history.push('/category');
    } catch (e) {
      enqueueSnackbar('Could not delete the category', { options: { variant: 'error' } });
    }
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push('/category');
  };

  handleAfterSave = () => {
    const { history } = this.props;
    history.push('/category');
  };

  canSubmitForm = () => {
    const { data } = this.state;
    const { name } = data;

    return name;
  };

  render() {
    const { id } = this.props;
    const { data, hasUnsavedChanges } = this.state;

    if (id && (!data || !data.id)) {
      return <PageLoadingText />;
    }

    const name = data && data.name ? data.name : '';

    return (
      <form onSubmit={this.handleSubmit} method="POST">
        <Prompt
          when={!!hasUnsavedChanges}
          message={translate('You have unsaved changes. Are you sure you want to leave?')}
        />
        <ContentPaper short className="FormBoxPaper">
          <FormContainer>
            <FormRow>
              <TextField
                required
                fullWidth
                autoComplete="off"
                name="name"
                label={translate('Category name')}
                value={name || ''}
                error={hasUnsavedChanges && !name}
                helperText={hasUnsavedChanges && !name ? translate('This field is required') : ''}
                onChange={this.handleChange('name')}
                InputProps={{
                  classes: {
                    input: 'LargeTitleInput',
                  },
                }}
              />
            </FormRow>

          </FormContainer>
        </ContentPaper>

        <FormActions
          short
          onDelete={this.handleDelete}
          onCancel={this.handleCancel}
          onSubmit={() => {}}
          disableSubmit={!this.canSubmitForm()}
        />
      </form>
    );
  }
}

export default CategoryForm;
