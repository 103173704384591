import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Delete from '@material-ui/icons/Delete';
import DragHandle from '@material-ui/icons/DragIndicator';
import update from 'react-addons-update';
import { localize, translate } from '../../../i18n';
import Droppable from '../../../components/dnd-simple/droppable';
import Draggable from '../../../components/dnd-simple/draggable';

const ManageOptions = ({ options, onChange }) => {
  const [newName, setNewName] = React.useState('');

  const handleChange = (index, name) => event => {
    const value = typeof event === 'string' ? event : event.target.value;

    const newOptions = [...options];
    newOptions[index] = { ...newOptions[index], [name]: value };

    // Update some fields
    newOptions[index] = {
      ...newOptions[index],
      // quantity: localize('numberInput', newIngredients[index].quantity, { strip_insignificant_zeros: true }),
      sort_order: index,
    };

    onChange([...newOptions]);
  };

  const handleAddNew = () => {
    onChange([...options, { id: null, option_name: newName, option_price: '', sort_order: 9999 }]);
  };

  const handleDrop = (dragIndex, hoverIndex) => {
    let data = [...options];
    const dragCard = data[dragIndex];

    // Reorder
    data = update(data, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    });

    // Calculate sort_order
    data = data.map((i, idx) => ({ ...i, sort_order: idx }));
    onChange([...data]);
  };

  const handleDelete = index => () => {
    const newOptions = [...options];
    newOptions.splice(index, 1);

    onChange(newOptions);
  };

  return (
    <div className="options-list">
      <div className="options-row my-2 font-weight-bolder">
        <div className="flex-grow-1">{translate('Option name')}</div>
        <div className="col-price mx-2 text-right">{translate('Price')}</div>
        <div className="col-action ml-2" />
      </div>

      <div>
        {options.map((option, index) => {
          const { id, name } = option;

          // Should we convert the price?
          const price = localize('number', option.option_price, { strip_insignificant_zeros: true, precision: 3 });

          return (
            <Droppable
              key={`option-row-${id}-${name}-${index}`}
              dndType="OPTION"
              onCardDrop={handleDrop}
              index={index}
              id={id}>
              <Draggable dndType="OPTION" index={index} id={id}>
                <div className="options-row mb-2">
                  <div className="col-drag-handle">
                    <ListItemIcon size="small" tabIndex={-1} style={{ minWidth: 'auto' }}>
                      <DragHandle fontSize="small" />
                    </ListItemIcon>
                  </div>
                  <div className="flex-grow-1">
                    <TextField
                      required
                      fullWidth
                      autoComplete="off"
                      name="option_name"
                      value={option.option_name || ''}
                      onChange={handleChange(index, 'option_name')}
                    />
                  </div>
                  <div className="col-price mx-2">
                    <TextField
                      fullWidth
                      required
                      inputProps={{
                        className: 'text-right',
                      }}
                      type="number"
                      placeholder="0"
                      value={price || ''}
                      onChange={handleChange(index, 'option_price')}
                    />
                  </div>

                  <div className="col-action ml-2 text-right">
                    <IconButton size="small" title={translate('Remove')} onClick={handleDelete(index)} tabIndex={-1}>
                      <Delete />
                    </IconButton>
                  </div>
                </div>
              </Draggable>
            </Droppable>
          );
        })}
      </div>

      <Button variant="text" color="primary" onClick={handleAddNew} startIcon={<i className="fal fa-plus" />}>
        {translate('Add option')}
      </Button>
    </div>
  );
};

export default ManageOptions;
