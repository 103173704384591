import produce from 'immer';

const INITIAL_STATE = {
  orders: [],
};

export default function orders(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case 'ON_ORDERS_LOADED':
        console.log('ON_ORDERS_LOADED')
        break;

      default:
    }
  });
}
