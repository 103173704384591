import produce from 'immer';
import { denormalize, normalize, schema } from 'normalizr';
import _ from 'lodash';

import {
  DISCOUNTS_FETCH_REQUEST,
  DISCOUNTS_FETCH_SUCCESS,
  DISCOUNTS_FETCH_ERROR,
  DISCOUNTS_HIDE_ADD_DIALOG,
  DISCOUNTS_SHOW_ADD_DIALOG,
  DISCOUNTS_SET_TABLE_FILTER,
  DISCOUNTS_FETCH_DISCOUNT_START,
  DISCOUNTS_FETCH_DISCOUNT_SUCCESS,
} from '../actions';

const INITIAL_STATE = {
  entities: {
    discounts: {},
  },
  entitiesIds: [],
  selected: null,

  // Filters
  filters: {
    search: null,
    type: null,
  },

  // Other state
  showAddDialog: false,
  loading: false,
  initialLoaded: false,
  loadingId: null,
  loadingIds: [],

  fetchError: null,
  saveError: null,
};

// Define the order schema so we can normalize the data and index it properly
const discountsSchema = new schema.Entity('discounts');
const discountListSchema = new schema.Array(discountsSchema);

export default function discounts(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case DISCOUNTS_FETCH_SUCCESS:
        // Normalize the data for better access
        const { data } = action.payload;
        const normalized = normalize(data, discountListSchema);
        draft.entities = normalized.entities;
        draft.entitiesIds = normalized.result;
        draft.loading = false;
        draft.initialLoaded = true;
        draft.fetchError = null;
        break;

      case DISCOUNTS_FETCH_REQUEST:
        draft.loading = true;
        draft.fetchError = null;
        break;

      case DISCOUNTS_FETCH_ERROR:
        draft.fetchError = action.payload.error;
        break;

      case DISCOUNTS_HIDE_ADD_DIALOG:
        draft.showAddDialog = false;
        break;

      case DISCOUNTS_SHOW_ADD_DIALOG:
        draft.showAddDialog = true;
        break;

      case DISCOUNTS_SET_TABLE_FILTER:
        draft.filters[action.payload.name] = action.payload.value;
        break;

      case DISCOUNTS_FETCH_DISCOUNT_START:
        draft.loadingId = action.payload.id;
        break;

      case DISCOUNTS_FETCH_DISCOUNT_SUCCESS:
        // eslint-disable-next-line no-use-before-define
        draft = addOrUpdateDiscountInStore(state, action, draft);

        break;

      default:
    }
  });
}

// Selectors
export function getFilteredDiscounts(state) {
  const { entities, filters } = state.discounts;

  return _.filter(entities.discounts, o => {
    if (filters.search && o.name.toLowerCase().indexOf(filters.search.toLowerCase()) === -1) {
      return false;
    }

    if (filters.type && o.type !== filters.type) {
      return false;
    }

    return true;
  });
}

export const getDiscountByIdSelector = (state, id) => {
  return state.discounts && state.discounts.entities && state.discounts.entities.discounts
    ? state.discounts.entities.discounts[id]
    : {};
};

// Data management functions
const addOrUpdateDiscountInStore = (state, action, draft) => {
  let discountsArr = denormalize(state.entitiesIds, discountListSchema, state.entities);
  const rowIndex = state.entitiesIds.indexOf(parseInt(action.payload.id, 10));

  if (rowIndex === -1) {
    discountsArr = [...discountsArr, action.payload.data];
  } else {
    discountsArr = [...discountsArr.slice(0, rowIndex), action.payload.data, ...discountsArr.slice(rowIndex + 1)];
  }

  const singleDiscount = normalize(discountsArr, discountListSchema);
  draft.entities = singleDiscount.entities;
  draft.entitiesIds = singleDiscount.result;

  // Reset loading
  if (state.loadingId === action.payload.id) {
    draft.loadingId = null;
  }

  return draft;
};
