import React from 'react';

import _ from 'lodash';

import { withRouter } from 'react-router-dom';
import { Typography, Checkbox, FormControlLabel, TextField, withStyles } from '@material-ui/core';

import { FixedSizeList } from 'react-window';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Check, CheckBox, CheckBoxOutlineBlank, Close } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import { withSnackbar } from 'notistack';
import PageLoadingText from '../../components/PageLoadingText';
import LocationService from '../../services/LocationService';
import AreaService from '../../services/AreaService';
import IngredientService from '../../services/IngredientService';

const styles = theme => ({
  root: {
    width: '100%',
  },
  areaIngredientRows: {
    /*boxShadow: 'inset 0 -6px 5px -4px #ccc, inset 0px 6px 6px -5px #ccc',*/
  },
  assignNote: {
    fontSize: theme.typography.pxToRem(13),
    /* flexBasis: '20%',
        flexShrink: 0, */
    fontWeight: 400,
    color: theme.palette.grey[500],
    paddingLeft: theme.spacing(1),
  },
  button: {},
  dialogTitle: {
    padding: theme.spacing(2),
  },
  dialogPaper: {
    minHeight: `calc(100% - ${theme.spacing(12)}px)`,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  error: {
    color: theme.palette.error.dark,
    marginTop: theme.spacing(1),
  },
  formControl: {
    width: '90%',
    fontWeight: 500,
    marginTop: theme.spacing(1),
    /* height: 230,
        overflow: 'auto', */
  },
  formControlLocation: {},

  label: {
    fontWeight: 500,
  },
  labelContainer: {
    margin: theme.spacing(1, 0),
    /* borderBottom: '1px solid', */
  },
  labelLocation: {
    fontSize: 14,
  },
  labelMain: {
    fontWeight: 500,
  },
  locations: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1, 0, 2, 2),
    height: 80,
    overflowY: 'auto',
  },
  locationCheckbox: {
    width: 32,
    height: 32,
    color: '#333333 !important',
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
});

const { hasOwnProperty } = Object.prototype;

/**
 * inlined Object.is polyfill to avoid requiring consumers ship their own
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is
 */
function is(x, y) {
  // SameValue algorithm
  if (x === y) {
    // Steps 1-5, 7-10
    // Steps 6.b-6.e: +0 != -0
    // Added the nonzero y check to make Flow happy, but it is redundant
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  }
  // Step 6.a: NaN == NaN
  return x !== x && y !== y;
}
function shallowEqual(objA, objB) {
  if (is(objA, objB)) {
    return true;
  }

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  for (let i = 0; i < keysA.length; i++) {
    if (!hasOwnProperty.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]])) {
      // console.log(keysA[i], objA[keysA[i]], objB[keysA[i]])
      return false;
    }
  }

  return true;
}

class AreaIngredientRow extends React.PureComponent {
  render() {
    const { data, index, style } = this.props;

    const { labelId, classes, area, ingredients, locations, handleLocationToggle } = data;

    const ingredient = ingredients[index];

    return (
      <div key={labelId} style={style}>
        <>
          <Box style={{ display: 'flex' }} className={classes.formControl}>
            {area.ingredients && _.findIndex(area.ingredients, { id: ingredient.id }) !== -1 ? (
              <Check color="primary" style={{ marginRight: '16px' }} />
            ) : (
              <Close color="disabled" style={{ marginRight: '16px' }} />
            )}
            {ingredient.name}
          </Box>

          <Box className={classes.locations} p={2}>
            <Typography component="p" className={classes.labelLocation} style={{ marginRight: '24px' }}>
              Locations
            </Typography>

            {locations &&
              locations.map(location => {
                const isChecked = location.ingredients && _.findIndex(location.ingredients, { id: ingredient.id }) !== -1;
                return (
                  <FormControlLabel
                    key={labelId + location.id}
                    className={classes.formControlLocation}
                    classes={{
                      label: classes.labelLocation,
                    }}
                    control={
                      <Checkbox
                        className={classes.locationCheckbox}
                        icon={<CheckBoxOutlineBlank style={{ fontSize: 18 }} />}
                        checkedIcon={<CheckBox style={{ fontSize: 18 }} />}
                        onChange={handleLocationToggle(ingredient.id, location.id)}
                        checked={isChecked}
                        tabIndex={-1}
                        disableRipple
                      />
                    }
                    label={location.name}
                  />
                );
              })}
          </Box>
        </>

        <Divider className={classes.divider} />
      </div>
    );
  }
}

@withSnackbar
@withStyles(styles)
@withWidth()
@withRouter
class ManageAreaIngredients extends React.Component {
  constructor(props) {
    super(props);

    const { area, ingredients } = props;

    this.state = {
      ingredients: ingredients || null,
      checked: area.ingredients || [],

      search: '',
      showAssigned: false,
    };
  }

  componentDidMount() {
    IngredientService.fetchAll().then(
      res => {
        if (res && res.body && res.body.data) {
          this.setState({
            ingredients: res.body.data.results,
          });
        }
      },
      () => {},
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.ingredients) {
      this.setState({
        ingredients: nextProps.ingredients,
      });
    }
  }

  handleClose = () => {
    this.setState({ search: '' });
  };

  handleSearch = event => {
    const { value } = event.target;

    this.setState({
      search: value,
    });
  };

  handleLocationToggle = (ingredientId, locationId) => async event => {
    const { checked } = event.target;
    const { enqueueSnackbar, handleChange } = this.props;

    if (checked) {
      try {
        await LocationService.addIngredient(ingredientId, locationId);

        if (handleChange) {
          handleChange();
        }
      } catch (e) {
        enqueueSnackbar('An error occurred. The ingredient was not added to the location.', {
          variant: 'danger',
        });
      }
    } else {
      try {
        await LocationService.removeIngredient(ingredientId, locationId);

        if (handleChange) {
          handleChange();
        }
      } catch (e) {
        enqueueSnackbar('An error occurred. The ingredient was not removed from the location.', {
          variant: 'danger',
        });
      }
    }
  };

  /* shouldComponentUpdate(nextProps, nextState, nextContext) {
        console.log(!shallowEqual( this.props, nextProps) ||
            !shallowEqual( this.state, nextState))
        return !shallowEqual( this.props, nextProps) ||
            !shallowEqual( this.state, nextState)
    } */

  render() {
    const { classes, area, locations } = this.props;

    const { search, showAssigned } = this.state;
    let { ingredients } = this.state;

    // Clean ingredients, leave only unassigned to any area or assigned to the current area
    const otherAreasIngredients = _.filter(ingredients, o => {
      if (!o.areas || !o.areas.length) {
        return false;
      }
      const index = _.find(o.areas, { id: area.id });

      if (index) {
        return false; // we return false because the ingredient is added to this area
      }

      return true;

      // return o.areas && -1 === _.findIndex(o.areas, {id: area.id})
    });

    // Filter ingredients by name
    if (search) {
      ingredients = _.filter(ingredients, o => o.name.toLowerCase().indexOf(search.toLowerCase()) === 0);
    }

    // Filter ingredients by availability
    ingredients = _.filter(ingredients, o => !o.areas || !o.areas.length || _.find(o.areas, { id: area.id }));

    return (
      <div>
        <Box>
          <TextField
            fullWidth
            placeholder="Search ingredients"
            type="search"
            className={classes.textField}
            margin="none"
            value={search}
            onChange={this.handleSearch}
            variant="outlined"
            size="small"
            inputProps={{
              style: {
                padding: 12,
              },
            }}
          />

          {ingredients === null ? (
            <PageLoadingText />
          ) : (
            <FixedSizeList
              className={classes.areaIngredientRows}
              itemData={{
                ingredients,
                labelId: null,
                classes,
                area,
                locations,
                handleLocationToggle: this.handleLocationToggle,
              }}
              itemSize={145}
              itemCount={ingredients.length}
              width="100%"
              height={400}>
              {AreaIngredientRow}
            </FixedSizeList>
          )}

          <Box pt={2}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<KeyboardArrowDownIcon />}
                  checkedIcon={<KeyboardArrowUpIcon />}
                  checked={showAssigned}
                  onChange={() => this.setState({ showAssigned: !showAssigned })}
                  value=""
                  disableRipple
                />
              }
              label={<Typography variant="h6">Ingredients already assigned to other areas</Typography>}
            />
            {!showAssigned || otherAreasIngredients === null || !otherAreasIngredients.length
              ? null
              : otherAreasIngredients.map(ingredient => {
                  const labelId = `check-ingredient-${ingredient.id}-label`;

                  // Does it match the search?
                  if (search && ingredient.name.toLowerCase().indexOf(search.toLowerCase()) !== 0) {
                    return null;
                  }

                  return (
                    <FormControlLabel
                      key={labelId}
                      className={classes.formControl}
                      disabled
                      control={
                        <Checkbox
                          disabled
                          onChange={null}
                          checked
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      }
                      label={
                        <>
                          {ingredient.name} <span className={classes.assignNote}>{ingredient.area && ingredient.area.name}</span>
                        </>
                      }
                    />
                  );
                })}
          </Box>
        </Box>
      </div>
    );
  }
}

ManageAreaIngredients.propTypes = {
  area: PropTypes.object.isRequired,
};

export default ManageAreaIngredients;
