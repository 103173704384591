import React, { Component } from 'react';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import TextField from '@material-ui/core/TextField';
import { Prompt, withRouter } from 'react-router';
import Typography from '@material-ui/core/Typography/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import ContentPaper from '../../../components/content-paper';
import { translate } from '../../../i18n';
import { recipeApi } from '../../../api/recipe';
import FormActions from '../../../components/form-actions';
import { FormContainer } from '../../../components/forms/container';
import { FormRow } from '../../../components/forms/row';
import PageLoadingText from '../../../components/PageLoadingText';

import '../styles/recipes.scss';
import ManageIngredients from './manage-ingredients';
import { parseApiError } from '../../../api/helpers';
import Image from '../../../components/forms/image';
import MeasureUnitSelect from '../../../components/forms/measure-unit-select';
import ProductSmallForm from '../../product/containers/product-small-form';
import Link from '@material-ui/core/Link';

@withSnackbar
@withRouter
class RecipeForm extends Component {
  constructor(props) {
    super(props);

    // Set page title
    const { id, setPageTitle } = this.props;
    let pageTitle = translate('Add recipe');
    if (id) {
      pageTitle = translate('Edit recipe');
    }
    setPageTitle(pageTitle);

    const data = !_.isEmpty(props.data) ? props.data : {};
    this.state = {
      id: props.id ? props.id : null,
      data,
      ingredients: !_.isEmpty(props.recipeIngredients) ? props.recipeIngredients : [],
      newData: {},
      productData: {
        name: data.name,
      },
      productDataDirty: false,
    };
  }

  componentDidMount() {
    const { id, fetchCategories, fetchPrinterAreas, fetchRecipeById, fetchIngredients } = this.props;
    if (id) {
      fetchRecipeById(id);
    }

    fetchIngredients();

    fetchCategories();
    fetchPrinterAreas();
  }

  componentWillReceiveProps(nextProps) {
    const { id, data } = this.state;

    // Has the data been loaded and passed over via props?
    if (id && !data.id && nextProps.data) {
      this.setState({
        id: nextProps.id,
        data: nextProps.data,
        ingredients: nextProps.recipeIngredients,
      });
    }
  }

  handleSubmit = async ev => {
    if (ev) {
      ev.preventDefault();
    }

    const { id, ingredients, newData, productData, productDataDirty } = this.state;
    const { enqueueSnackbar } = this.props;

    try {
      newData.recipe_ingredients = ingredients;
      if (productDataDirty) {
        newData.product = productData;
      }
      const response = !id ? await recipeApi.add(newData) : await recipeApi.update(id, newData);

      if (!response.ok) {
        enqueueSnackbar(parseApiError(response).message);
        return;
      }

      this.setState({ hasUnsavedChanges: false });

      enqueueSnackbar('Recipe saved');
      this.handleAfterSave(response);
    } catch (e) {
      enqueueSnackbar('Could not save the recipe!', { options: { variant: 'error' } });
    }

    return false;
  };

  handleChange = (name, value) => event => {
    const { data, newData } = this.state;
    value = typeof value !== 'undefined' ? value : event.target.value;

    this.setState({
      hasUnsavedChanges: true,
      data: { ...data, [name]: value },
      newData: { ...newData, [name]: value },
    });
  };

  handleIngredientsChange = nextIngredients => {
    const { newData } = this.state;

    this.setState({
      ingredients: nextIngredients,
      newData: { ...newData, recipe_ingredients: nextIngredients },
    });
  };

  handleProductChange = (name, value) => event => {
    const { productData } = this.state;
    value = typeof value !== 'undefined' ? value : event.target.value;

    this.setState({
      hasUnsavedChanges: true,
      productDataDirty: true,
      productData: { ...productData, [name]: value },
    });
  };

  handleDelete = async () => {
    const { id } = this.state;
    const { enqueueSnackbar, history } = this.props;

    // Reset state
    this.setState({ hasUnsavedChanges: false });

    // Delete AJAX
    try {
      await recipeApi.deleteById(id);

      enqueueSnackbar('Recipe deleted');
      history.push('/recipe');
    } catch (e) {
      enqueueSnackbar('Could not delete the recipe', { options: { variant: 'error' } });
    }
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push('/recipe');
  };

  handleAfterSave = () => {
    const { history } = this.props;
    history.push('/recipe');
  };

  handleGotoIngredient = id => event => {
    event.preventDefault();
    const { history } = this.props;
    history.push(`/ingredient/edit/${id}`);
  };

  render() {
    const { id, allIngredients, sortedIngredients } = this.props;
    const { data, ingredients, hasUnsavedChanges, productData } = this.state;

    if (id && (!data || !data.id)) {
      return <PageLoadingText />;
    }

    const name = data && data.name ? data.name : '';

    // Sort the ingredients
    const sortedRecipeIngredients = !ingredients
      ? []
      : Object.values(ingredients).sort((i1, i2) => {
          if (i1 && i1.sort_order === i2.sort_order) {
            return 0;
          }

          return i1.sort_order < i2.sort_order ? -1 : 1;
        });

    return (
      <form onSubmit={this.handleSubmit} method="POST">
        <Prompt
          when={!!hasUnsavedChanges}
          message={translate('You have unsaved changes. Are you sure you want to leave?')}
        />
        <ContentPaper className="FormBoxPaper">
          <FormContainer>
            <div className="d-table align-top">
              <div className="d-table-cell align-top width-25">
                <Image image={data.image || null} onChange={image => this.handleChange('image', image)()} />
              </div>

              <div className="d-table-cell align-top width-75">
                <TextField
                  className="mb-3"
                  required
                  fullWidth
                  autoComplete="off"
                  name="name"
                  label={translate('Recipe name')}
                  value={name || ''}
                  error={hasUnsavedChanges && !name}
                  helperText={hasUnsavedChanges && !name ? translate('This field is required') : ''}
                  onChange={this.handleChange('name')}
                  InputProps={{
                    classes: {
                      input: 'LargeTitleInput',
                    },
                  }}
                />

                <TextField
                  className="mb-3"
                  multiline
                  fullWidth
                  name="description"
                  label={translate('Description')}
                  value={data.description || ''}
                  onChange={this.handleChange('description')}
                />
              </div>
            </div>

            <FormRow>
              <TextField
                multiline
                fullWidth
                name="instructions"
                label={translate('Instructions')}
                value={data.instructions || ''}
                onChange={this.handleChange('instructions')}
              />
            </FormRow>

            {data.id ? null : (
              <FormRow elementClassName="pb-0">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!data.ingredient}
                      onChange={event => {
                        this.handleChange('ingredient')({ target: { value: event.target.checked } });
                      }}
                      name="ingredient"
                    />
                  }
                  label={translate('The recipe is also an Ingredient')}
                />
              </FormRow>
            )}

            {data.id && data.ingredient && (
              <FormRow cols={2}>
                <>
                  <Typography variant="h6">{translate('Ingredient')}</Typography>
                  <Link href="#" onClick={this.handleGotoIngredient(data.ingredient.id)}>
                    {data.ingredient.name}
                  </Link>
                </>
              </FormRow>
            )}

            {!data.ingredient ? null : (
              <div className="mb-3">
                <TextField
                  className="mr-3"
                  type="number"
                  name="total_recipe_size"
                  label={translate('Total recipe size')}
                  value={data.total_recipe_size ? data.total_recipe_size : ''}
                  onChange={this.handleChange('total_recipe_size')}
                  InputProps={{ inputProps: { min: 0 } }}
                />

                <FormControl style={{ minWidth: 90 }}>
                  <InputLabel htmlFor="portion_unit" shrink>
                    {translate('Size unit')}
                  </InputLabel>
                  <MeasureUnitSelect
                    required
                    excludeOthers
                    value={data.recipe_size_unit || ''}
                    onChange={this.handleChange('recipe_size_unit')}
                    name="recipe_size_unit"
                    id="recipe_size_unit"
                  />
                </FormControl>
              </div>
            )}

            {data.id ? null : (
              <FormRow>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!data.is_also_product}
                      onChange={event => {
                        this.handleChange('is_also_product')({ target: { value: event.target.checked } });
                      }}
                      name="is_also_product"
                    />
                  }
                  label={translate('The recipe is also a Product')}
                />
              </FormRow>
            )}

            {!data.id && data.is_also_product ? (
              <ProductSmallForm onChange={this.handleProductChange} product={productData} recipe={data} />
            ) : null}
          </FormContainer>
        </ContentPaper>

        <ContentPaper className="FormBoxPaper">
          <Typography variant="h5" className="mb-1">
            {translate('Ingredients')}
          </Typography>

          <ManageIngredients
            ingredients={sortedRecipeIngredients}
            allIngredients={allIngredients}
            sortedIngredients={sortedIngredients}
            onChange={this.handleIngredientsChange}
          />
        </ContentPaper>

        <FormActions
          onDelete={this.handleDelete}
          onCancel={this.handleCancel}
          onSubmit={() => {}}
          disableSubmit={!name || (data.is_also_product && !productData.printer_area)}
        />
      </form>
    );
  }
}

export default RecipeForm;
