

//import Notifications from './Notifications'

import UserStore from '../lib/stores/UserStore';

import request from 'superagent';


import update from 'react-addons-update';

const defaultOptions = {
    endpoint: null,
    data: null,
    callback: null,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Cache-Control': 'no-cache,no-index,must-revalidate,max-age=-1'
    }
}

var ApiClient = {

    getPostRequestObject: function ( url, options ) {
        if ( !options ) {
            options = {};
        }
        options = update(defaultOptions, {$merge: options });
        options.headers.Authorization = UserStore.getSessionId();

        // Append API root if defined
        options = this._prepareUrls(options);

        return request.post(url)
            .set ( options.headers );
    },

    /**
     * Return a GET request object Using ES6 promises
     * @param type
     * @param options
     */
    get: function ( options ) {

        options = update(defaultOptions, {$merge: options });
        options.headers.Authorization = UserStore.getSessionId();

        // Append API root if defined
        options = this._prepareUrls(options);

        return request.get( options.endpoint )
            .accept('json')
            .type('json')
            .set ( options.headers )
            .query ( options.query );
    },

    post: function ( options, jwt ) {

        options = update(defaultOptions, {$merge: options });
        options.headers.Authorization = UserStore.getSessionId();

        // Append API root if defined
        options = this._prepareUrls(options);

        return request.post( options.endpoint )
            .accept('json')
            .type('json')
            .set ( options.headers )
            .query ( options.query )
            .send( options.data );
    },

    patch: function ( options, jwt ) {

        options = update(defaultOptions, {$merge: options });
        options.headers.Authorization = UserStore.getSessionId();

        // Append API root if defined
        options = this._prepareUrls(options);

        return request.patch( options.endpoint )
            .accept('json')
            .type('json')
            .set ( options.headers )
            .query ( options.query )
            .send( options.data );
    },

    put: function ( options ) {

        options = update(defaultOptions, {$merge: options });
        options.headers.Authorization = UserStore.getSessionId();

        // Append API root if defined
        options = this._prepareUrls(options);

        return request.put( options.endpoint )
            .accept('json')
            .type('json')
            .set ( options.headers )
            .query ( options.query )
            .send( options.data );
    },

    delete: function ( options ) {

        options = update(defaultOptions, {$merge: options });
        options.headers.Authorization = UserStore.getSessionId();

        // Append API root if defined
        options = this._prepareUrls(options);

        return request.delete( options.endpoint )
            .accept('json')
            .type('json')
            .set ( options.headers )
            .query ( options.query );
    },

    onError: function(err) {

        if ( err ) {

            var res = err.response ? err.response : [];

            var error = ''
                + ( res && res.error ? res.error : 'API error' );
            if ( res && res.body && res.body.error ) {
                error = res.body.error;
            }

            error = error.replace(/\n/g, '<br/>');

        }
    },
    /**
     * End ES6 functions
     */



    _handleApiCallFinished: function(err,res) {
        if ( err ) {

            var error = 'An API error occured: '
                + ( res && res.error ? res.error : 'API error' );
            if ( res && res.body && res.body.error ) {
                error = res.body.error;
            }

            error = error.replace(/\n/g, '<br/>');

            //Notifications.showNotific8(error, 'danger', { closeInSeconds: 6 } );
        }
    },

    /** Internally used methos */
    _isEmpty: function (x) {
        return typeof x === 'undefined' || x.length === 0 || x == null;
    },

    _prepareUrls(options) {

        let venueId = localStorage.getItem('venue_id')
        if (!venueId) {
            // Default to 1
            venueId = 1
        }

        if ( venueId && options.endpoint ) {
            if (options.endpoint.startsWith('/api/')
                && !options.endpoint.startsWith('/api/venue/') ) {

                options.endpoint = options.endpoint.replace('/api/', '/api/venue/' + venueId + '/')
            }
        }

        /*options.query = {
            venue: venueId
        }*/

        return options;
    }
}


export default ApiClient;
