import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { isWidthDown } from '@material-ui/core/withWidth';
import withWidth from '@material-ui/core/withWidth/withWidth';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import TableFooter from '@material-ui/core/TableFooter';

import styles from './styles';
import { localize, translate } from '../../../i18n';

@withWidth()
@withStyles(styles)
class DetailsDialog extends React.Component {
  handleClose = () => {
    const { handleClose } = this.props;
    handleClose();
  };

  render() {
    const { classes, width } = this.props;
    let { data } = this.props;
    if (!data) {
      data = {};
    }

    const {
      products_cost: totalProducts,
      total_cost: totalPrice,
      discount_value: totalDiscount,
      delivery_cost: totalDelivery,
      payment_details: paymentDetails,
    } = data.cart_details;

    let productsTable = null;
    if (data.products && data.products.length) {
      productsTable = data.products.map((product, index) => {
        return (
          <TableRow key={`prod-${index}`}>
            <TableCell align="right" style={{ width: 50, background: '#f2f2f2' }}>
              {' '}
              <div className={classes.qtyField}>{product.qty} x</div>
            </TableCell>
            <TableCell>
              <div style={{ fontWeight: 500 }}>{product.name}</div>
              {!product.extras || !product.extras.length ? null : (
                <span className={classes.extras}>
                  Extra: {_.map(product.extras, (o) => o.name) ? _.map(product.extras, (o) => o.name).join(', ') : ''}
                </span>
              )}
            </TableCell>
            <TableCell>
              <i>{product.note}</i>
            </TableCell>
            <TableCell align="right">$ {product.qty * product.price}</TableCell>
          </TableRow>
        );
      });
    }

    let paymentOverview = '';
    if (data.payment_type === 'PayPal') {
      paymentOverview =
        paymentDetails && paymentDetails.orderID ? (
          <div>{` (Order ID: ${paymentDetails.orderID})`}</div>
        ) : (
          <div className={classes.textDanger}> {translate('ERROR: Missing PayPal transaction data')}</div>
        );
    } else if (data.payment_type === 'Stripe') {
      if (!paymentDetails || !paymentDetails.PaymentIntent || !paymentDetails.PaymentIntent.id) {
        paymentOverview = (
          <div className={classes.textDanger}> {translate('ERROR: Missing Stripe transaction data')}</div>
        );
      } else {
        let paymentError = false;
        const amountPaid = paymentDetails.PaymentIntent.amount / 100;
        if (parseFloat(amountPaid) < parseFloat(totalPrice)) {
          paymentError = true;
        }
        paymentOverview = (
          <div className={[paymentError && classes.textDanger].join(' ')}>
            {translate('Payment ID: {{id}}', { id: paymentDetails.PaymentIntent.id })} <br />
            {!paymentError ? null : 'ERROR: '}
            {translate('Amount Paid: {{amount}} {{currency}}', {
              amount: amountPaid,
              currency: paymentDetails.PaymentIntent.currency,
            })}
          </div>
        );
      }
    }

    return (
      <Dialog fullScreen={!!isWidthDown('md', width)} fullWidth maxWidth="md" onClose={this.handleClose} open>
        <DialogTitle>
          {translate('Order details')}
          <div style={{ float: 'right' }}>
            <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <div>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell variant="body" className={classes.colHead}>
                    {translate('First name')}
                  </TableCell>
                  <TableCell>{data.first_name}</TableCell>
                  <TableCell variant="body" className={classes.colHead}>
                    {translate('Last name')}
                  </TableCell>
                  <TableCell>{data.last_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="body" className={classes.colHead}>
                    {translate('Phone')}
                  </TableCell>
                  <TableCell>{data.phone}</TableCell>
                  <TableCell variant="body" className={classes.colHead}>
                    {translate('Email')}
                  </TableCell>
                  <TableCell>{data.email}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell variant="body" className={classes.colHead}>
                    {translate('Order type')}
                  </TableCell>
                  <TableCell>{data.order_type}</TableCell>
                  <TableCell variant="body" className={classes.colHead}>
                    {translate('Payment')}
                  </TableCell>
                  <TableCell>
                    {data.payment_type}
                    {paymentOverview}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="body" className={classes.colHead}>
                    {translate('Include cutlery')}
                  </TableCell>
                  <TableCell>
                    {data.required_cutlery && (data.required_cutlery === true || data.required_cutlery === 'true')
                      ? translate('Yes')
                      : translate('No')}
                  </TableCell>
                  <TableCell variant="body" className={classes.colHead}>
                    {translate('Postcode')}
                  </TableCell>
                  <TableCell>{data.postcode}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="body" className={classes.colHead}>
                    {translate('Address')}
                  </TableCell>
                  <TableCell colSpan={3}>{data.address}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="body" className={classes.colHead}>
                    {translate('Address details')}
                  </TableCell>
                  <TableCell colSpan={3}>{data.address_details}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell variant="body" className={classes.colHead}>
                    {translate('Special instructions')}
                  </TableCell>
                  <TableCell colSpan={3}>{data.instructions}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>

          <div className={classes.productsCard}>
            <h3>{translate('Products')}</h3>
            <Table className={classes.productsTable} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="right">{translate('Quantity')}</TableCell>
                  <TableCell>{translate('Product')}</TableCell>
                  <TableCell>{translate('Instructions')}</TableCell>
                  <TableCell align="right" style={{ minWidth: 80 }}>
                    {translate('Price')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{productsTable}</TableBody>

              <TableFooter>
                <TableRow>
                  <TableCell colSpan={4}>
                    <div className={classes.totalRow}>
                      <span className={classes.totalLabel}>{translate('Products')}</span>
                      <span className={classes.totalValue}>{localize('currency', totalProducts)}</span>
                    </div>
                    <div className={classes.totalRow}>
                      <span className={classes.totalLabel}>{translate('Discount')}</span>
                      <span className={classes.totalValue}>{localize('currency', totalDiscount)}</span>
                    </div>
                    <div className={classes.totalRow}>
                      <span className={classes.totalLabel}>{translate('Delivery')}</span>
                      <span className={classes.totalValue}>{localize('currency', totalDelivery)}</span>
                    </div>
                    <div className={classes.totalRow}>
                      <span className={classes.totalLabel}>
                        <span style={{ fontWeight: 600 }}>{translate('TOTAL')}</span>
                      </span>
                      <span className={classes.totalValue}>
                        <span style={{ fontWeight: 600 }}>{localize('currency', totalPrice)}</span>
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

DetailsDialog.defaultProps = {
  data: {
    cart_details: {},
  },
};

DetailsDialog.propTypes = {
  data: PropTypes.shape({
    cart_details: PropTypes.shape({}).isRequired,
  }),
};

export default DetailsDialog;
