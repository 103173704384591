import { styled } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

export const ButtonWhite = styled(Button)({
  background: '#ffffff',
  color: '#212121',
  '&:hover': {
    backgroundColor: '#fafafa',
  },
});
