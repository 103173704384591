const API_BASE = '/api';

export const ONLINE_MENU_DISPLAY_ID = 'onlinemenu';
export const WEBSITE_ONTAP_DISPLAY_ID = 'campfire.mx';

export const appConfig = {
  API_BASE,
  ONLINE_MENU_DISPLAY_ID,
  WEBSITE_ONTAP_DISPLAY_ID,
};
