import React from 'react';

import update from 'react-addons-update';
import { withStyles } from '@material-ui/styles';
import { withSnackbar } from 'notistack';
import Slider from '@material-ui/core/Slider';
import humanizeDuration from 'humanize-duration';
import {
  Button,
  Radio,
  RadioGroup,
  TextField,
  FormControlLabel,
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
} from '@material-ui/core';
import { connect } from 'react-redux';
import MediaItem from './MediaItem';
import ApiClient from '../../components/ApiClient';
import { translate } from '../../i18n';
import { setPageTitle } from '../ui/actions';

const styles = theme => ({
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  buttonNormal: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  input: {
    display: 'none',
  },
  helperText: {
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },

  slider: {
    display: 'flex',
  },
  sliderContainer: {
    padding: '22px 0',
  },
  formActions: {
    marginTop: theme.spacing(2),
  },
});

function extractYoutubeVideoId(url) {
  const r = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  const m = url.match(r);
  return m && m[1].length === 11 ? m[1] : false;
}

class MediaManager extends React.Component {
  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
    this.open = this.open.bind(this);

    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleYoutubeUrlChange = this.handleYoutubeUrlChange.bind(this);
    this.handleAdDurationChange = this.handleAdDurationChange.bind(this);
    this.handleImageUploaded = this.handleImageUploaded.bind(this);
    this.handleFileSelected = this.handleFileSelected.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
    this.clearCaches = this.clearCaches.bind(this);
    //        this.sendCommand            = this.sendCommand.bind(this)

    this._saveSorting = this._saveSorting.bind(this);

    this.state = {
      open: false,
      name: '',
      media_type: 'image',
      duration: 15,
      input_url: '',
      files: [],

      adsSliderWaitTime: 600,
      adsSliderWaitTimeNice: humanizeDuration(600000),
      youtubeVideoDuration: null,
      youtubeVideoDurationNice: null,
      addNewAdvert: false,
      loadingImage: false,
    };
  }

  close() {
    this.setState({ open: false });
  }

  open() {
    this.load();
    this.setState({ open: true });
  }

  componentDidMount() {
    this.props.setPageTitle(translate('Media manager'));

    this.load();
  }

  load() {
    ApiClient.get({
      endpoint: '/api/media',
    }).then(
      res => {
        const newWaitTime =
          res.body.config && res.body.config.adsSliderWaitTime
            ? res.body.config.adsSliderWaitTime
            : this.state.adsSliderWaitTime;
        this.setState({
          adsSliderWaitTime: newWaitTime,
          adsSliderWaitTimeNice: humanizeDuration(newWaitTime * 1000),
          data: res.body.media ? res.body.media : '',
        });
      },
      err => {
        this.setState({
          loading: false,
          error: true,
          errorMsg:
            err.response && err.response.body && err.response.body.error
              ? err.response.body.error
              : 'API communication error',
        });
      },
    );
  }

  handleSave() {
    const { name, media_type, duration, media_url, ad_image, adsSliderWaitTime } = this.state;
    const data = { name, media_type, duration, media_url };
    data.is_active = 1;

    if (ad_image && ad_image.url) {
      data.image_data = ad_image;
    }

    if (!name || !media_url) {
      window.alert('The creative name and URL/image are required fields.');
      return;
    }

    this.setState({ loading: true });
    ApiClient.put({
      endpoint: '/api/media',
      data: {
        config: {
          adsSliderWaitTime,
        },
        items: data,
      },
    }).then(
      res => {
        this.setState({ loading: false });

        this.props.enqueueSnackbar('Media saved', { variant: 'success' });

        this.setState(
          {
            name: '',
            media_type: 'image',
            duration: 15,
            input_url: '',
            ad_image: null,
            files: [],
            addNewAdvert: false,
            youtubeVideoDuration: null,
            youtubeVideoDurationNice: null,
          },
          () => {
            this.load();
          },
        );

        // setTimeout(() => this.load(), 100)
      },
      err => {
        this.props.enqueueSnackbar('Could not save the media!', { variant: 'error' });
      },
    );
  }

  handleStatus(idx, newStatus) {
    if (typeof idx === 'undefined' || idx === null) {
      return false;
    }

    const { data, adsSliderWaitTime } = this.state;

    // Remove key
    data[idx].is_active = newStatus;

    ApiClient.post({
      endpoint: '/api/media/order',
      data: {
        config: {
          adsSliderWaitTime,
        },
        items: data,
      },
    }).then(
      res => {
        this.load();
      },
      err => {
        this.props.enqueueSnackbar('Could not update the media item', { variant: 'error' });
      },
    );
  }

  clearCaches() {
    ApiClient.get({
      endpoint: '/api/display-config/reset-cache',
    }).then(
      res => {
        this.props.enqueueSnackbar('The command has been sent', { variant: 'success' });
      },
      err => {},
    );
  }

  handleDelete(idx) {
    if (typeof idx === 'undefined' || idx === null) {
      return false;
    }

    const { data } = this.state;
    const { id } = data[idx];

    const confirm = window.confirm('You sure? Can not be undone.');
    if (!confirm) {
      return;
    }

    ApiClient.delete({
      endpoint: `/api/media/${id}`,
    }).then(
      res => {
        this.load();
      },
      err => {
        this.props.enqueueSnackbar('Could not delete the media item', { variant: 'error' });
      },
    );
  }

  handleRadioChange(event, media_type) {
    this.setState({ media_type, input_url: '', youtubeVideoDuration: null, youtubeVideoDurationNice: null });
  }

  handleYoutubeUrlChange(event) {
    const media_url = event.target.value;
    const videoId = extractYoutubeVideoId(media_url);

    if (!videoId) {
      this.setState({
        media_url: '',
        input_url: media_url,
        youtubeVideoId: null,
        youtubeVideoDuration: null,
        youtubeVideoDurationNice: null,
        videoError: 'This does not look like a valid Youtube URL.',
      });
      return;
    }

    const good_video_url = `https://www.youtube.com/embed/${videoId}?rel=0&amp;controls=0&amp;showinfo=0&amp;wmode=opaque&amp;autoplay=1`;

    this.setState({
      ad_url: good_video_url,
      input_url: media_url,
      youtubeVideoId: videoId,
      youtubeVideoDuration: null,
      youtubeVideoDurationNice: null,
      videoError: null,
    });
  }

  handleAdDurationChange(event, duration) {
    this.setState({ duration });
  }

  handleImageUploaded(event) {
    const ad_image = event.target.value;
    ad_image.url = ad_image.url ? ad_image.url : '';
    this.setState({ ad_image, media_url: ad_image.url, input_url: ad_image.url });
  }

  handleFileSelected(event) {
    if (!event.target.files || !event.target.files[0]) {
      return false;
    }

    this.setState({ loadingImage: true });
    const req = ApiClient.getPostRequestObject('/api/media/image');
    req.attach('image', event.target.files[0]);

    req.end((err, res) => {
      if (err || !res.ok) {
        this.props.enqueueSnackbar(
          `The media file could not be uploaded: ${res.body && res.body.error ? res.body.error : 'Server error'}`,
          { variant: 'error' },
        );

        return true;
      }

      // All good
      const ad_image = res.body.image ? res.body.image : null;
      this.setState({
        files: [],
        ad_image,
        media_url: ad_image.secure_url ? ad_image.secure_url : ad_image.url,
        input_url: ad_image.secure_url ? ad_image.secure_url : ad_image.url,
        loadingImage: false,
      });
    });
  }

  handleSort(dragIndex, hoverIndex) {
    const { data } = this.state;
    const dragCard = data[dragIndex];

    this.setState(
      update(this.state, {
        data: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        },
      }),
      function () {},
    );
  }

  _saveSorting() {
    // Save the new order

    const { data, adsSliderWaitTime } = this.state;
    const toSend = {};
    for (const i in data) {
      toSend[data.id] = i;
    }

    ApiClient.post({
      endpoint: '/api/media/order',
      data: {
        config: {
          adsSliderWaitTime,
        },
        items: data,
      },
    }).then(
      res => {},
      err => {
        this.props.enqueueSnackbar('Could not save the media order', { variant: 'error' });
      },
    );
  }

  render() {
    const { files, ad_image } = this.state;
    const { classes } = this.props;

    return (
      <Box px={2}>
        <Grid container spacing={4}>
          <Grid item sm={6}>
            <Typography gutterBottom variant="h6" component="h6">
              Media settings
            </Typography>
            <Card raised={false}>
              <CardContent>
                <div className="">
                  <Box>
                    <Box mb={2}>
                      <Typography variant="overline" component="h6">
                        Configure timing
                      </Typography>

                      <div className={classes.slider}>
                        <Slider
                          min={10}
                          classes={{ root: classes.sliderContainer }}
                          max={3600}
                          step={10}
                          value={this.state.adsSliderWaitTime}
                          onChange={(event, value) => {
                            this.setState({
                              adsSliderWaitTime: value,
                              adsSliderWaitTimeNice: humanizeDuration(value * 1000), // moment.duration(d, 'seconds').humanize()
                            });
                          }}
                          onChangeCommitted={() => {
                            setTimeout(() => {
                              ApiClient.post({
                                endpoint: '/api/media/order',
                                data: {
                                  config: {
                                    adsSliderWaitTime: this.state.adsSliderWaitTime,
                                  },
                                  items: this.state.data,
                                },
                              }).then(
                                res => {
                                  this.props.enqueueSnackbar('Media settings saved', { variant: 'success' });
                                },
                                err => {},
                              );
                            }, 1000);
                          }}
                        />
                      </div>
                      <Typography variant="body2">
                        Every{' '}
                        <strong>{this.state.adsSliderWaitTimeNice ? this.state.adsSliderWaitTimeNice : ''}</strong> a
                        full screen media will be displayed.
                      </Typography>
                    </Box>
                  </Box>
                </div>
              </CardContent>
            </Card>

            <Box my={3}>
              <Typography gutterBottom variant="h6" component="h6">
                Existing media
              </Typography>

              {!this.state.data || !this.state.data.length ? (
                <p>No media created so far.</p>
              ) : (
                this.state.data.map((d, i) => (
                  <MediaItem
                    key={`al${i}`}
                    data={d}
                    handleDelete={this.handleDelete}
                    handleStatus={this.handleStatus}
                    handleDragEnd={this._saveSorting}
                    moveCard={this.handleSort}
                    index={i}
                    id={i}
                  />
                ))
              )}
            </Box>
          </Grid>

          <Grid item sm={6}>
            <Typography gutterBottom variant="h6" component="h6">
              Add new
            </Typography>
            <Card raised={false}>
              <CardContent>
                <Box mb={2}>
                  <TextField
                    required
                    onChange={event => this.setState({ name: event.target.value })}
                    value={this.state.name}
                    label="Creative name"
                    placeholder="eg. Sunday Promotion"
                    fullWidth
                    margin="normal"
                  />
                </Box>

                <div>
                  <Typography variant="overline" component="h6">
                    Media type
                  </Typography>

                  <RadioGroup
                    row
                    aria-label="Media type"
                    name="media_type"
                    /* className={classes.group} */
                    value={this.state.media_type}
                    onChange={this.handleRadioChange}>
                    <FormControlLabel value="image" control={<Radio />} label="Image" />
                    <FormControlLabel value="youtube" control={<Radio />} label="Youtube Video" />
                  </RadioGroup>
                </div>

                {this.state.media_type === 'youtube' ? (
                  <Box mb={2}>
                    <TextField
                      onChange={this.handleYoutubeUrlChange}
                      value={this.state.input_url}
                      label="Youtube video URL"
                      placeholder=""
                      helperText="The video will be displayed in full screen mode and it will automatically play when displayed."
                      fullWidth
                      margin="normal"
                    />

                    {!this.state.videoError ? null : (
                      <div className="innerT half text-danger size12">{this.state.videoError}</div>
                    )}
                  </Box>
                ) : (
                  <Box mb={2}>
                    <Box>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="contained-button-file"
                        onChange={this.handleFileSelected}
                        type="file"
                        value={files}
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          size="small"
                          variant="outlined"
                          color="default"
                          disabled={this.state.loadingImage}
                          component="span"
                          className={classes.button}>
                          Upload image
                        </Button>
                      </label>
                    </Box>
                    {!ad_image || !ad_image.originalname ? null : (
                      <Typography variant="subtitle2" className={classes.helperText}>
                        {ad_image.originalname} ({ad_image.width}x{ad_image.height}px)
                      </Typography>
                    )}
                    <Typography variant="body2" className={classes.helperText}>
                      The image will be displayed full screen.
                    </Typography>
                  </Box>
                )}

                <div className="form-group clearfix">
                  <Typography variant="overline" component="h6">
                    On-Screen duration
                  </Typography>
                  <div className={classes.slider}>
                    <Slider
                      min={5}
                      color="secondary"
                      classes={{ root: classes.sliderContainer }}
                      max={120}
                      step={1}
                      value={this.state.duration ? this.state.duration : 15}
                      onChange={this.handleAdDurationChange}
                    />
                  </div>

                  <Typography variant="body2">
                    Show this media for {this.state.duration ? this.state.duration : 15} seconds.
                  </Typography>
                </div>

                <div className={classes.formActions}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={this.handleSave}
                    disabled={this.state.saveError || this.state.loading || this.state.loadingImage}>
                    Save media
                  </Button>

                  {!this.state.loading ? null : (
                    <div className="inline-block">
                      <i className="fa fa-spin fa-spinner" />
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
MediaManager = connect(null, { setPageTitle })(MediaManager);
MediaManager = withStyles(styles, { withTheme: true })(MediaManager);

export default withSnackbar(MediaManager);
