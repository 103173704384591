import produce from 'immer';
import _ from 'lodash';
import { denormalize, normalize, schema } from 'normalizr';

import {
  BEVERAGES_FETCH_ERROR,
  BEVERAGES_FETCH_REQUEST,
  BEVERAGES_FETCH_SUCCESS,
  BEVERAGES_HIDE_ADD_DIALOG,
  BEVERAGES_SHOW_ADD_DIALOG,
  BEVERAGES_SET_TABLE_FILTER,
} from '../actions';

const INITIAL_STATE = {
  entities: {
    beverages: null,
    producers: {},
  },
  entitiesIds: null,

  // Filters
  filters: {
    search: null,
    producer: null,
    beverage_type: null,
  },

  loading: false,
  loadingId: null,
  loadingIds: [],
  fetchError: null,
  saveError: null,
  selected: null,
  showAddDialog: false,
};

// Define the order schema so we can normalize the data and index it properly
const producerSchema = new schema.Entity('producers');
const beverageSchema = new schema.Entity('beverages', {
  producer: producerSchema,
});
const beverageListSchema = new schema.Array(beverageSchema);

export default function beverage(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case BEVERAGES_FETCH_SUCCESS: {
        // Normalize the data for better access
        const { data } = action.payload;
        const normalized = normalize(data, beverageListSchema);
        draft.entities = normalized.entities;
        draft.entitiesIds = normalized.result;
        draft.loading = false;
        draft.fetchError = null;

        break;
      }

      case BEVERAGES_FETCH_REQUEST:
        draft.loading = true;
        draft.fetchError = null;
        break;

      case BEVERAGES_FETCH_ERROR:
        draft.fetchError = action.payload.error;
        break;

      case BEVERAGES_HIDE_ADD_DIALOG:
        draft.showAddDialog = false;
        break;

      case BEVERAGES_SHOW_ADD_DIALOG:
        draft.showAddDialog = true;
        break;

      case BEVERAGES_SET_TABLE_FILTER:
        draft.filters[action.payload.name] = action.payload.value;
        break;

      default:
    }
  });
}

// Selectors
export function getFilteredBeverage(state) {
  const { entities, filters } = state.beverage;

  if (entities.beverages === null) {
    return null;
  }

  return _.filter(entities.beverages, o => {
    if (filters.search && o.name.toLowerCase().indexOf(filters.search.toLowerCase()) === -1) {
      return false;
    }

    if (filters.producer && o.producer_name !== filters.producer) {
      return false;
    }

    if (filters.beverage_type && o.beverage_type !== filters.beverage_type) {
      return false;
    }

    return true;
  });
}

export const getBeverageByIdSelector = (state, id) => {
  return state.beverage && state.beverage.entities && state.beverage.entities.beverages ? state.beverage.entities.beverages[id] : {};
};
