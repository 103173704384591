import React from 'react';
import { isEqual } from 'lodash';
import { Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from 'clsx';
import StockField from '../../../components/forms/stock-input';
import CurrencyField from '../../../components/forms/currency-field';
import { convertFromBaseUnit, translate } from '../../../i18n';
import MeasureUnitSelect from '../../../components/forms/measure-unit-select';

class InvoiceVendorTable extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { ingredients, invoice } = this.props;

    if (isEqual(invoice, nextProps.invoice) && isEqual(ingredients, nextProps.ingredients)) {
      return false;
    }

    return true;
  }

  handleChange = (vendor, ingredient, stockValue, costValue) => {
    const { onChange } = this.props;

    onChange(vendor, ingredient, {
      quantity: stockValue,
      cost_per_unit: costValue,
    });
  };

  handleChangeUnit = (vendor, ingredient, stockValue, costValue, unit) => {
    const { onChange } = this.props;

    onChange(vendor, ingredient, {
      quantity: stockValue,
      cost_per_unit: costValue,
      unit_measure: unit,
    });
  };

  render() {
    const { classes, editable, ingredients, invoice, invoiceData, vendor } = this.props;

    return (
      <div className="overflow-auto">
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{translate('Ingredient name')}</TableCell>
              <TableCell align="right" className={classes.stockCol}>
                {translate('In Stock')}
              </TableCell>
              <TableCell align="right" className={classes.shortCol}>
                {translate('Quantity')}
              </TableCell>
              <TableCell align="right" className={classes.shortCol}>
                {translate('Cost')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ingredients.map((row, idx) => {
              const ingredient = row;
              let inStock = '';
              if (row.stock_quantity !== null && ingredient.unit) {
                inStock = convertFromBaseUnit(row.stock_quantity, ingredient.unit, 'numberInput');
              }

              let data = invoice[ingredient.id] || null;
              let unit = (data && data.unit_measure) || row.unit || '';
              if (unit && unit.startsWith('case-')) {
                unit = 'case';
              }

              // Is the ingredient already added to the table?
              const invoiceRow = (invoiceData || []).filter(o => o.ingredient_id === row.id);
              if (data === null && invoiceRow && invoiceRow[0] && invoiceRow[0].id) {
                data = {};
                data.quantity = invoiceRow[0].quantity;
                data.cost_per_unit = invoiceRow[0].cost_per_unit;
                data.unit_measure = invoiceRow[0].unit_measure;
              }

              // Determine cost
              let cost = ''; // data === null ? ingredient.cost || '' : data.cost;
              if (data === null || !data.cost_per_unit) {
                if (ingredient.cost) {
                  cost = ingredient.cost;
                }
              } else {
                cost = data.cost_per_unit;
              }

              let measureUnit = row.unit;
              if (data && data.unit_measure) {
                measureUnit = data.unit_measure;
              }

              const defaultStock = data === null ? '' : convertFromBaseUnit(data.quantity || '', measureUnit, 'stock');

              return (
                <TableRow
                  key={`vendor-${vendor.id}-ingredient-${row.id}`}
                  className={clsx(classes.row, idx % 2 && classes.oddRow)}>
                  <TableCell className={classes.nameCol}>
                    {row.name} <span className="text-muted ml-1">{row.unit ? row.unit.replace('-', ' of ') : ''}</span>
                  </TableCell>

                  <TableCell align="right" className={classes.stockCol}>
                    {inStock}
                  </TableCell>
                  <TableCell align="right" className={classes.shortCol}>
                    <StockField
                      disabled={!editable}
                      name={`invoice-stock-${vendor.id}-${row.id}`}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MeasureUnitSelect
                              disableUnderline
                              style={{ minWidth: '80px' }}
                              value={measureUnit}
                              onChange={ev => {
                                this.handleChangeUnit(vendor.id, ingredient, defaultStock, cost, ev.target.value);
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        className: 'text-right',
                      }}
                      defaultValue={defaultStock}
                      onChange={ev => this.handleChange(vendor.id, ingredient, ev.target.value, cost)}
                    />
                  </TableCell>
                  <TableCell align="right" className={classes.shortCol}>
                    <CurrencyField
                      disabled={!editable}
                      name={`invoice-cost-${vendor.id}-${row.id}`}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{`per ${unit}`}</InputAdornment>,
                      }}
                      inputProps={{
                        className: 'text-right',
                      }}
                      defaultValue={cost}
                      onChange={ev => this.handleChange(vendor.id, ingredient, defaultStock, ev.target.value)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default InvoiceVendorTable;
