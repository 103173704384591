import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import RecipeFormComponent from '../components/recipe-form';
import { setPageTitle } from '../../ui/actions';
import { enqueueSnackbar as enqueueSnackbarAction } from '../../snackbar/actions';
import { getRecipeByIdSelector } from '../reducers';
import { fetchRecipeById, fetchRecipes } from '../actions';
import { fetchIngredients as fetchIngredientsAction } from '../../ingredient/actions';
import { fetchCategories } from '../../category/actions';
import { fetchPrinterAreas } from '../../product/actions';

const mapStateToProps = (state, ownProps) => {
  const data = getRecipeByIdSelector(state, ownProps.id);

  const recipeIngredients = [];
  if (data && data.recipe_ingredients) {
    Object.values(data.recipe_ingredients).map(recipientIngredientId => {
      const recipeIngredient =
        (state.recipes.entities.recipe_ingredients &&
          state.recipes.entities.recipe_ingredients[recipientIngredientId]) ||
        null;
      if (recipeIngredient) {
        recipeIngredients.push(recipeIngredient);
      }

      return recipientIngredientId;
    });
  }

  // Prepare sorted ingredients
  let sortedIngredients = state.ingredients.entities.ingredients || null;
  sortedIngredients = sortBy(sortedIngredients, 'name');

  return {
    data,
    recipeIngredients,
    ingredients: state.recipes.entities.ingredients,

    // List of all ingredients
    allIngredients: state.ingredients.entities.ingredients || null,
    sortedIngredients,
  };
};

const mapDispatchToProps = {
  setPageTitle,
  fetchData: fetchRecipes,
  fetchRecipeById,
  fetchIngredients: fetchIngredientsAction,
  fetchCategories,
  fetchPrinterAreas,
  enqueueSnackbar: enqueueSnackbarAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipeFormComponent);
