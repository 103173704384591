import ApiClient from '../../components/ApiClient';

var EventEmitter = require('events').EventEmitter;
var emitter = new EventEmitter();

var _list = [];

export default {
    getBeverages: function() {
        return _list.concat();
    },

    subscribe: function(callback) {
        emitter.on('update', callback);
    },

    unsubscribe: function(callback) {
        emitter.removeListener('update', callback);
    },

    setData: function ( data ) {
        _list = data;
        emitter.emit('update');
    },

    loadFromServer: function () {
        ApiClient.get({
            endpoint: '/api/beverage'

        }).then(res => {

            _list = res.body.beverage && res.body.beverage.results ? res.body.beverage.results : [];
            emitter.emit('update');

        }, (err) => {

        })

    }
};
