import { create } from 'apisauce';
import UserStore from '../lib/stores/UserStore';
import {appConfig} from '../config';

export const createClient = (addAuthHeader = true) => {
  const apiClient = create({
    baseURL: appConfig.API_BASE,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Cache-Control': 'no-cache,no-index,must-revalidate,max-age=-1',
    },
    timeout: 10 * 1000,
  });

  apiClient.addAsyncRequestTransform(request => async () => {
    // Add token auth
    if (addAuthHeader) {
      request.headers.Authorization = await UserStore.getSessionId();
    }

    // Add venue
    let venueId = await localStorage.getItem('venue_id');
    if (!venueId) {
      // Default to a default venue - it works
      venueId = 1;
    }

    if (request.url.indexOf('venue/') === -1) {
      request.url = `venue/${venueId}/${request.url}`;
    }
  });

  return apiClient;
};

export function parseApiError(response, defaultError = 'API communication error') {
  let parsedError = {
    message: defaultError,
  };

  if (response) {
    if (response.data && response.data.error) {
      parsedError = {
        ...parsedError,
        message: response.data.error,
      };
    }

    if (response.data && response.data.errors) {
      parsedError = {
        ...parsedError,
        message: response.data.errors.join('\n'),
      };
    }
  }

  return parsedError;
}
