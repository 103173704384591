import _ from "lodash";
import ApiClient from "../../components/ApiClient";
//var lodash = require('lodash')
var EventEmitter = require('events').EventEmitter;
var emitter = new EventEmitter();

var userData = {
    username: '',
    isLoggedIn: false
};

var _isLoggedIn = 0;
var _sessionId = null;


const UserStore = {

    subscribe: function(callback, event) {
        emitter.on(event ? event : 'update', callback);
    },

    unsubscribe: function(callback, event) {
        emitter.removeListener(event ? event : 'update', callback);
    },

    getUser: function() {
        return userData;
    },

    getVenues: () => {
        return userData.venues;
    },

    getCurrentVenue: () => {
        return localStorage.getItem('venue_id')
    },

    setCurrentVenue: (venueId) => {
        localStorage.setItem('venue_id', venueId);
    },

    getSessionId: function() {
        return localStorage.getItem('campfiretv-sessionid')
    },

    loginUser: function(sessionId, user_data) {
        // If not session ID, try to fetch it from local storage
        if ( !sessionId ) {
            sessionId = localStorage.getItem('campfiretv-sessionid')
        }

        if ( !sessionId ) {
            return false;
        }

        // Log the user in
        userData    = user_data;
        _sessionId   = sessionId
        _isLoggedIn = true;

        // Check if there isn't any global venue set OR, if set, check if it is still available
        let currentVenueId = localStorage.getItem('venue_id');
      currentVenueId = parseInt(currentVenueId, 10);
        if (!currentVenueId) {
            currentVenueId = user_data.venues[0].id;
        } else {
            // Check if the set one exists
            const found = _.find(user_data.venues, {id: currentVenueId})
            if (!found) {
                currentVenueId = user_data.venues[0].id;
            }
        }

        localStorage.setItem('campfiretv-sessionid', sessionId);
        localStorage.setItem('user_data', JSON.stringify(user_data));
      localStorage.setItem('venue_id', currentVenueId);

      const venueData = _.find(user_data.venues, {id: currentVenueId});
      localStorage.setItem('venue_data', JSON.stringify(venueData || {}));
    },

    logoutUser: function() {

        userData    = {};
        _sessionId   = null;
        _isLoggedIn = false;

        // We delete the JWT from local storage
        localStorage.removeItem('campfiretv-sessionid');
        localStorage.removeItem('user_data');

        // Let listeners know the user has logged out
        emitter.emit('logout');
    },

    isLoggedIn: function() {
        return _isLoggedIn && _sessionId && userData ? true : false;
    },

    verifySession: function(sessionId, callback) {
        ApiClient.post({
            endpoint: '/api/auth/verify',
            data: {
                sessionid: sessionId
            }

        }).then(res => {
            // Execute callback
            if ( callback && typeof callback === 'function' ) {
                callback(res.body);
            }
        },
        err => {
            // Execute callback
            if ( callback && typeof callback === 'function' ) {
                callback(null);
            }
        })
    }
};

export default UserStore
