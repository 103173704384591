import React from 'react'
import { Redirect } from 'react-router-dom'
import UserStore from '../../lib/stores/UserStore'
import ApiClient from "../../components/ApiClient";

class LogoutPage extends React.Component {

    UNSAFE_componentWillMount() {
        UserStore.logoutUser();

        ApiClient.get({
            endpoint: '/api/auth/logout'
        }).then((res) => {

        }, (err) => {

        })
    }

    render() {
        return (
            <Redirect to="/login"/>
        )
    }
}

export default LogoutPage
