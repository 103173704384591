import { connect } from 'react-redux';
import ModifierFormComponent from '../components/modifier-form';
import { setPageTitle } from '../../ui/actions';
import { enqueueSnackbar as enqueueSnackbarAction } from '../../snackbar/actions';
import { getModifierByIdSelector } from '../reducers';
import { fetchModifierById, fetchModifiers } from '../actions';

const mapStateToProps = (state, ownProps) => {
  const data = getModifierByIdSelector(state, ownProps.id);

  return {
    data,
  };
};

const mapDispatchToProps = {
  setPageTitle,
  fetchData: fetchModifiers,
  fetchModifierById,
  enqueueSnackbar: enqueueSnackbarAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModifierFormComponent);
