import React from 'react';

import { connect } from 'react-redux';
import PageLoadingText from '../../components/PageLoadingText';
import { translate } from '../../i18n';
import { setPageTitle as setPageTitleAction } from '../ui/actions';
import { enqueueSnackbar as enqueueSnackbarAction } from '../snackbar/actions';
import VendorForm from './VendorForm';
import { fetchVendors } from './actions';
import { getVendorByIdSelector } from './reducers';

class VendorAddEdit extends React.Component {
  constructor(props) {
    super(props);

    const { id, setPageTitle } = this.props;

    let pageTitle = translate('Add vendor');
    if (id) {
      pageTitle = translate('Edit vendor');
    }

    setPageTitle(pageTitle);
  }

  componentDidMount() {
    const { fetchData } = this.props;
    fetchData();
  }

  render() {
    const { id, data, enqueueSnackbar, history } = this.props;

    if (id && !data) {
      return <PageLoadingText />;
    }

    return (
      <VendorForm
        id={data.id}
        data={data}
        onCancel={() => history.push('/vendor')}
        onSave={() => history.push('/vendor')}
        onNotification={enqueueSnackbar}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: getVendorByIdSelector(state, ownProps.id),
  };
};

const mapDispatchToProps = {
  setPageTitle: setPageTitleAction,
  fetchData: fetchVendors,
  enqueueSnackbar: enqueueSnackbarAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorAddEdit);
