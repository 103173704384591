import { connect } from 'react-redux';
import AuditDatabase from '../components/audit-database';
import { fetchAuditDatabase, setTableFilter } from '../actions';
import { setPageTitle } from '../../ui/actions';
import { enqueueSnackbar } from '../../snackbar/actions';
import { getFilteredDatabase, getFilteredEvents } from '../reducers';

function mapStateToProps(state) {
  const database = getFilteredDatabase(state);

  const filters = [
    ...Object.values(state.audit.database || {}).map(d => ({
      action_type: d.action_type,
      object_type: d.object_type,
    })),
  ];

  const typesFilter = [
    ...filters
      .map(d => d.action_type)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a.localeCompare(b))
      .map(c => ({ value: c, label: c })),
  ];

  const objectsFilter = [
    ...filters
      .map(d => d.object_type)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a.localeCompare(b))
      .map(c => ({ value: c, label: c.charAt(0).toUpperCase() + c.replace('_', ' ').slice(1) })),
  ];

  return {
    dataById: database || {},
    data: state.audit.databaseIds,
    filters: state.audit.filters,
    loading: state.audit.loading,
    loadingIds: state.audit.loadingIds,
    initialLoaded: state.audit.initialLoadedDatabase,
    objectsFilter,
    typesFilter,
  };
}

const mapDispatchToProps = {
  fetchData: fetchAuditDatabase,
  setPageTitle,
  setTableFilter,
  enqueueSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditDatabase);
