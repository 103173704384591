import React, { Component } from 'react';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import TextField from '@material-ui/core/TextField';
import { FormControl, FormLabel, Radio, RadioGroup, FormControlLabel, Switch, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Prompt, withRouter } from 'react-router';
import ContentPaper from '../../../components/content-paper';
import { translate } from '../../../i18n';
import { productApi } from '../../../api/product';
import FormActions from '../../../components/form-actions';
import { FormContainer } from '../../../components/forms/container';
import { FormRow } from '../../../components/forms/row';
import PageLoadingText from '../../../components/PageLoadingText';

import '../styles/products.scss';
import { parseApiError } from '../../../api/helpers';
import Image from '../../../components/forms/image';
import CurrencyField from '../../../components/forms/currency-field';

const initialData = {
  name: '',
  is_enabled: true,
  retail_price: 0,
};

@withSnackbar
@withRouter
class ProductForm extends Component {
  constructor(props) {
    super(props);

    // Set page title
    const { id, setPageTitle } = this.props;
    let pageTitle = translate('Add product');
    if (id) {
      pageTitle = translate('Edit product');
    }
    setPageTitle(pageTitle);

    let productType = 'simple';
    if (props.data && props.data.recipe_id) {
      productType = 'recipe';
    }
    if (props.data && props.data.ingredient_id) {
      productType = 'ingredient';
    }

    this.state = {
      id: props.id ? props.id : null,
      productType,
      data: !_.isEmpty(props.data) ? props.data : initialData,
      newData: {},
    };
  }

  componentDidMount() {
    const {
      id,
      fetchProductById,
      fetchIngredients,
      fetchRecipes,
      fetchCategories,
      fetchModifiers,
      fetchPrinterAreas,
    } = this.props;
    if (id) {
      fetchProductById(id);
    }

    fetchIngredients();
    fetchRecipes();
    fetchCategories();
    fetchModifiers();
    fetchPrinterAreas();
  }

  componentWillReceiveProps(nextProps) {
    const { id, data } = this.state;

    let productType = 'simple';
    if (nextProps.data && nextProps.data.recipe_id) {
      productType = 'recipe';
    }
    if (nextProps.data && nextProps.data.ingredient_id) {
      productType = 'ingredient';
    }

    // Has the data been loaded and passed over via props?
    if (id && !data.id && nextProps.data) {
      this.setState({
        id: nextProps.id,
        data: nextProps.data,
        productType,
      });
    }
  }

  handleSubmit = async ev => {
    if (ev) {
      ev.preventDefault();
    }

    const { id, newData } = this.state;
    const { enqueueSnackbar } = this.props;

    try {
      const response = !id ? await productApi.add(newData) : await productApi.update(id, newData);

      if (!response.ok) {
        enqueueSnackbar(parseApiError(response).message);
        return;
      }

      this.setState({ hasUnsavedChanges: false });

      enqueueSnackbar('Product saved');
      this.handleAfterSave(response);
    } catch (e) {
      enqueueSnackbar('Could not save the product!', { variant: 'error' });
    }
  };

  handleChange = (name, value) => (event, autocompleteValue) => {
    const { data, newData } = this.state;

    value = typeof value !== 'undefined' ? value : event ? event.target.value : null;
    if (autocompleteValue && autocompleteValue.printer_group_id) {
      value = autocompleteValue.printer_group_id;
    } else if (autocompleteValue && autocompleteValue.id) {
      value = autocompleteValue.id;
    } else if (autocompleteValue || autocompleteValue === null) {
      value = autocompleteValue;
    }

    this.setState({
      hasUnsavedChanges: true,
      data: { ...data, [name]: value },
      newData: { ...newData, [name]: value },
    });
  };

  handleChangeModifier = (modifier, value) => {
    const { data, newData } = this.state;
    const { product_modifiers: productModifiers } = data;
    let modifiers = [];
    if (productModifiers) {
      modifiers = [...productModifiers];
    }

    const eIndex = modifiers.findIndex(m => m.modifier_id === modifier.id);
    if (eIndex === -1 && value) {
      // Add
      modifiers = [...modifiers, { id: null, modifier_id: modifier.id }];
    } else if (eIndex !== -1 && !value) {
      // Remove
      modifiers.splice(eIndex, 1);
    }

    this.setState({
      hasUnsavedChanges: true,
      data: { ...data, product_modifiers: modifiers },
      newData: { ...newData, product_modifiers: modifiers },
    });
  };

  handleDelete = async () => {
    const { id } = this.state;
    const { enqueueSnackbar, history } = this.props;

    // Reset state
    this.setState({ hasUnsavedChanges: false });

    // Delete AJAX
    try {
      await productApi.deleteById(id);

      enqueueSnackbar('Product deleted');
      history.push('/product');
    } catch (e) {
      enqueueSnackbar('Could not delete the product', { options: { variant: 'error' } });
    }
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push('/product');
  };

  handleAfterSave = () => {
    const { history } = this.props;
    history.push('/product');
  };

  handleChangeType = ev => {
    this.setState({ productType: ev.target.value }, () => {
      const { data, newData } = this.state;

      this.setState({
        hasUnsavedChanges: true,
        data: { ...data, recipe_id: null, ingredient_id: null },
        newData: { ...newData, recipe_id: null, ingredient_id: null },
      });
    });
  };

  canSubmitForm = () => {
    const { data } = this.state;
    const { name } = data;

    return name && data.printer_area; // && (data.recipe_id || data.ingredient_id);
  };

  render() {
    const { id, categories, ingredients, recipes, modifiers, printerAreas } = this.props;
    const { data, hasUnsavedChanges, productType } = this.state;

    if (id && (!data || !data.id)) {
      return <PageLoadingText />;
    }

    const name = data && data.name ? data.name : '';

    let selectedCategory = {};
    if (categories && data.category_id) {
      const category = categories.filter(c => c.id === data.category_id)[0];
      if (category) {
        selectedCategory = category;
      }
    }

    let selectedIngredient = {};
    if (ingredients && data.ingredient_id) {
      const ingredient = ingredients.filter(c => c.id === data.ingredient_id)[0];
      if (ingredient) {
        selectedIngredient = ingredient;
      }
    }

    let selectedRecipe = {};
    if (recipes && data.recipe_id) {
      const recipe = recipes.filter(c => c.id === data.recipe_id)[0];
      if (recipe) {
        selectedRecipe = recipe;
      }
    }

    let selectedArea = null;
    if (printerAreas && data.printer_area) {
      const printerArea = printerAreas.filter(a => a.printer_group_id === parseInt(data.printer_area || 0, 10))[0];
      if (printerArea) {
        selectedArea = printerArea;
      }
    }

    return (
      <form onSubmit={this.handleSubmit} method="POST">
        <Prompt
          when={!!hasUnsavedChanges}
          message={translate('You have unsaved changes. Are you sure you want to leave?')}
        />
        <ContentPaper className="FormBoxPaper">
          <FormContainer>
            <div className="d-table align-top">
              <div className="d-table-cell align-top width-25">
                <Image image={data.image || null} onChange={image => this.handleChange('image', image)()} />
              </div>

              <div className="d-table-cell align-top width-75">
                <TextField
                  className="mb-2"
                  required
                  fullWidth
                  autoComplete="off"
                  name="name"
                  label={translate('Product name')}
                  value={name || ''}
                  error={hasUnsavedChanges && !name}
                  helperText={hasUnsavedChanges && !name ? translate('This field is required') : ''}
                  onChange={this.handleChange('name')}
                  InputProps={{
                    classes: {
                      input: 'LargeTitleInput',
                    },
                  }}
                />

                <TextField
                  className="mb-1"
                  multiline
                  fullWidth
                  name="description"
                  label={translate('Description')}
                  value={data.description || ''}
                  onChange={this.handleChange('description')}
                />

                <div className="d-table">
                  <div className="d-table-cell align-middle width-75">{translate('The product is available')}</div>
                  <div className="d-table-cell align-middle text-right width-25">
                    <Switch
                      color="primary"
                      checked={data.is_enabled}
                      onChange={(event, value) => this.handleChange('is_enabled', value)()}
                      name="is_enabled"
                    />
                  </div>
                </div>
              </div>
            </div>

            <FormRow>
              <FormControl component="fieldset">
                <FormLabel component="legend">{translate('Product type')}</FormLabel>
                <RadioGroup
                  row
                  aria-label="product type"
                  value={productType || 'simple'}
                  onChange={this.handleChangeType}>
                  <FormControlLabel value="simple" control={<Radio required />} label={translate('Simple product')} />
                  <FormControlLabel value="ingredient" control={<Radio required />} label={translate('Ingredient')} />
                  <FormControlLabel value="recipe" control={<Radio required />} label={translate('Recipe')} />
                </RadioGroup>
              </FormControl>
            </FormRow>

            {productType === 'ingredient' && (
              <FormRow>
                <Autocomplete
                  options={ingredients || []}
                  getOptionLabel={ingredient => (ingredient.name ? ingredient.name : '')}
                  disableClearable
                  openOnFocus
                  fullWidth
                  value={selectedIngredient}
                  onChange={this.handleChange('ingredient_id')}
                  renderInput={params => <TextField {...params} label={translate('Ingredient')} />}
                />
              </FormRow>
            )}

            {productType === 'recipe' && (
              <FormRow>
                <Autocomplete
                  options={recipes || []}
                  getOptionLabel={option => (option.name ? option.name : '')}
                  disableClearable
                  openOnFocus
                  fullWidth
                  value={selectedRecipe}
                  onChange={this.handleChange('recipe_id')}
                  renderInput={params => <TextField {...params} label={translate('Recipe')} />}
                />
              </FormRow>
            )}

            <FormRow>
              <Autocomplete
                options={categories ? Object.values(categories) : []}
                getOptionLabel={option => (option.name ? option.name : '')}
                disableClearable
                openOnFocus
                fullWidth
                value={selectedCategory}
                onChange={this.handleChange('category_id')}
                renderInput={params => <TextField {...params} label={translate('Category')} />}
              />

              <TextField
                fullWidth
                name="barcode"
                label={translate('Barcode')}
                value={data.barcode ? data.barcode : ''}
                onChange={this.handleChange('barcode')}
              />
            </FormRow>

            <FormRow className="mb-2" cols={2}>
              <Autocomplete
                id="product-printer-area"
                autoSelect
                fullWidth
                value={selectedArea}
                onChange={this.handleChange('printer_area')}
                getOptionLabel={option => (option.printer_area ? option.printer_area : '')}
                options={printerAreas}
                renderInput={params => (
                  <TextField
                    /* onChange={this.handleChange('printer_area')} */
                    {...params}
                    /* name="printer_area" */
                    label={translate('Printer area')}
                  />
                )}
              />
              {/* <Autocomplete
                id="product-printer-area"
                autoSelect
                freeSolo
                fullWidth
                value={data.printer_area ? data.printer_area : ''}
                inputValue={data.printer_area ? data.printer_area : ''}
                onChange={this.handleChange('printer_area')}
                options={printerAreas}
                renderInput={params => (
                  <TextField
                    onChange={this.handleChange('printer_area')}
                    {...params}
                    name="printer_area"
                    label={translate('Printer area')}
                  />
                )}
              /> */}
            </FormRow>

            <Typography className="mb-2">Pricing</Typography>

            <FormRow>
              <CurrencyField
                fullWidth
                label={translate('Retail price')}
                required
                name="retail_price"
                value={data.retail_price || '0'}
                onChange={this.handleChange('retail_price')}
              />

              <CurrencyField
                fullWidth
                label={translate('Cost override')}
                name="cost_override"
                value={data.cost_override || ''}
                onChange={this.handleChange('cost_override')}
              />
            </FormRow>
          </FormContainer>
        </ContentPaper>

        <ContentPaper className="FormBoxPaper">
          <Typography variant="h5" className="mb-1">
            {translate('Modifiers')}
          </Typography>
          <div>
            {modifiers.map(modifier => {
              return (
                <div key={`product-modifier-${modifier.id}`} className="d-table">
                  <div className="d-table-cell align-middle width-75">{modifier.name}</div>
                  <div className="d-table-cell align-middle text-right width-25">
                    <Switch
                      color="primary"
                      checked={
                        data.product_modifiers &&
                        data.product_modifiers.findIndex(m => m.modifier_id === modifier.id) > -1
                      }
                      onChange={(event, value) => this.handleChangeModifier(modifier, value)}
                      name="modifiers"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </ContentPaper>

        <FormActions
          onDelete={this.handleDelete}
          onCancel={this.handleCancel}
          onSubmit={() => {}}
          disableSubmit={!this.canSubmitForm()}
        />
      </form>
    );
  }
}

export default ProductForm;
