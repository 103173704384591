
import UserStore from '../lib/stores/UserStore';

export default function AdminProtectedComponent ({component: Component, children, ...rest}) {

    const user = UserStore.getUser();
    return UserStore.isLoggedIn() && user && user.role === 'admin'
        ? children
        : null;

}
