import { makeActionCreator } from '../../../store/actions';

export const AREAS_FETCH_REQUEST = '@areas / FETCH REQUEST';
export const AREAS_FETCH_SUCCESS = '@areas / FETCH SUCCESS';
export const AREAS_FETCH_ERROR = '@areas / FETCH ERROR';
export const AREAS_SHOW_ADD_DIALOG = '@areas / SHOW ADD DIALOG';
export const AREAS_HIDE_ADD_DIALOG = '@areas / HIDE ADD DIALOG';
export const AREAS_SET_TABLE_FILTER = '@areas / SET TABLE FILTER';

export const fetchAreas = makeActionCreator(AREAS_FETCH_REQUEST);
export const fetchAreasError = makeActionCreator(AREAS_FETCH_ERROR, 'error');
export const fetchAreasSuccess = makeActionCreator(AREAS_FETCH_SUCCESS, 'data');
export const showAreaAddDialog = makeActionCreator(AREAS_SHOW_ADD_DIALOG);
export const hideAreaAddDialog = makeActionCreator(AREAS_HIDE_ADD_DIALOG);

// Table filters
export const setTableFilter = makeActionCreator(AREAS_SET_TABLE_FILTER, 'name', 'value');
