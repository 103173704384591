import React from 'react';
import {Redirect, Route} from 'react-router-dom'

import UserStore from "../lib/stores/UserStore";

export default function PrivateRoute ({component: Component, authed, ...rest}) {

    return (
        <Route
            {...rest}
            render={(props) => UserStore.isLoggedIn()
                ? <Component {...props} {...rest} />
                : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
        />
    )
}
