import React from 'react';
import { Button, withStyles } from '@material-ui/core';

import { withSnackbar } from 'notistack';

import styles from '../styles';
import EnhancedTable from '../../../components/enhanced-table/EnhancedTable';
import ContentPaper from '../../../components/content-paper';
import ActionMenu from './row-actions';
import PageLoadingText from '../../../components/PageLoadingText';
import { translate } from '../../../i18n';
import { userApi } from '../../../api/user';
import UserAddDialog from '../containers/user-add-dialog';
import Chip from '@material-ui/core/Chip';

@withSnackbar
@withStyles(styles)
class Users extends React.Component {
  constructor(props) {
    super(props);
    const { fetchData, setPageTitle } = this.props;

    this.typeName = 'user';

    setPageTitle(translate('Users'));
    fetchData();
  }

  handleMassDelete = ids => ids.map(id => this.handleDelete(id));

  handleDelete = async id => {
    const { enqueueSnackbar, fetchData } = this.props;

    // Delete AJAX
    try {
      await userApi.deleteById(id);

      // All good
      setTimeout(() => fetchData(), 100);
    } catch (err) {
      enqueueSnackbar(translate(`Could not delete the ${this.typeName}`), { options: { variant: 'error' } });
    }
  };

  handleRowClick = ({ id }) => {
    const { history } = this.props;
    history.push(`/user/edit/${id}`);
  };

  handleAdd = () => {
    const { history } = this.props;
    history.push('/user/add');
  };

  getTableColumns = () => {
    const { classes, loadingIds } = this.props;

    return [
      {
        id: 'name',
        accessor: 'name',
        sortable: true,
        label: translate('Name'),
      },
      {
        id: 'username',
        accessor: 'username',
        label: translate('Username'),
        sortable: true,
      },
      {
        id: 'role',
        accessor: 'role',
        label: translate('Role'),
        renderValue: row => (
          <Chip
            size="small"
            label={row.role}
            className={[classes.chip, (row.role === 'admin' || row.role === 'owner') && classes.chipAdmin].join(' ')}
          />
        ),
      },
      {
        id: 'venues',
        accessor: 'venues',
        label: translate('Assigned venues'),
        renderValue: row => translate('{{count}} venues', { count: row.venues ? row.venues.length : 0 }),
      },
      {
        id: 'id',
        accessor: 'id',
        numeric: false,
        label: '',
        align: 'right',
        padding: 'none',
        renderValue: row => (
          <ActionMenu
            isLoading={loadingIds && loadingIds.indexOf(parseInt(row.id, 10)) !== -1}
            handleDelete={this.handleDelete}
            handleStatusChange={this.handleStatusChange}
            id={row.id}
            data={row}
          />
        ),
      },
    ];
  };

  getTableFilters = () => {
    const { setTableFilter, filters } = this.props;

    return [
      {
        label: translate('Role'),
        type: 'select',
        value: filters.role,
        onChange: value => setTableFilter('role', value),
        options: [
          {
            value: '',
            label: translate('All'),
          },
          {
            value: 'admin',
            label: translate('Admin'),
          },
          {
            value: 'manager',
            label: translate('Manager'),
          },
          {
            value: 'user',
            label: translate('User'),
          },
        ],
      },
      {
        label: translate('Search'),
        type: 'search',
        value: filters.search,
        onChange: value => setTableFilter('search', value),
      },
    ];
  };

  shouldComponentRender = () => {
    const { initialLoaded } = this.props;

    return initialLoaded;
  };

  getLoadingSkeleton = () => {
    return <PageLoadingText />;
  };

  render() {
    const { classes, dataById } = this.props;
    const data = Object.values(dataById);

    if (!this.shouldComponentRender()) {
      return <div className={classes.root}>{this.getLoadingSkeleton()}</div>;
    }

    return (
      <div className={classes.root}>
        <UserAddDialog />
        <ContentPaper table className={classes.paper}>
          <EnhancedTable
            toolbarActions={
              <>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleAdd}
                  startIcon={<i className="fal fa-plus" />}>
                  {translate('Add employee')}
                </Button>
              </>
            }
            filters={this.getTableFilters()}
            columns={this.getTableColumns()}
            onRowClick={this.handleRowClick}
            onMassDelete={this.handleMassDelete}
            data={data}
            rowCount={data.length}
            order="asc"
            orderBy="name"
          />
        </ContentPaper>
      </div>
    );
  }
}

export default Users;
