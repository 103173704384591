import { createClient } from './helpers';

const apiClient = createClient();

function fetchEvents() {
  return apiClient.get('audit/event');
}

function fetchDatabaseEvents() {
  return apiClient.get('audit/database');
}

function fetchApiAccess() {
  return apiClient.get('audit/api-access');
}

function addEvent(data) {
  return apiClient.post('audit/event', data);
}

export const auditApi = {
  fetchEvents,
  fetchApiAccess,
  fetchDatabaseEvents,
  addEvent,
};
