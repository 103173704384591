import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  withStyles,
} from '@material-ui/core';
import { translate } from '../../../i18n';
import { userApi } from '../../../api/user';

const styles = theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(0, 3),
  },
  error: {
    color: theme.palette.error.dark,
    marginTop: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 99,
  },
});

@withStyles(styles)
@withRouter
class AddUserDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      pin: '',
      error: '',
    };
  }

  handleSubmit = async () => {
    const { username, password, pin } = this.state;
    try {
      const response = await userApi.add({
        username,
        password,
        pin,
        role: 'user',
      });

      const { data } = response.data;
      const id = data && data.id;

      if (id) {
        const { hideAddDialog, history } = this.props;
        hideAddDialog();

        history.push(`/user/edit/${id}`);
      }
    } catch (e) {
      this.setState({
        error: translate('An error occurred while saving the data. Please try again.'),
      });
    }
  };

  render() {
    const { error, username, password, pin } = this.state;
    const { classes, open, hideAddDialog } = this.props;

    return (
      <div className={classes.root}>
        <Dialog open={open} onClose={hideAddDialog} aria-labelledby="form-dialog-title" fullWidth>
          <DialogContent>
            <DialogContentText>{translate('Enter the new user name')}</DialogContentText>
            {!error ? null : (
              <Typography component="span" className={classes.error}>
                {error}
              </Typography>
            )}
            <TextField
              autoComplete="off"
              error={!!error}
              value={username}
              onChange={event => this.setState({ username: event.target.value })}
              autoFocus
              margin="dense"
              id="username"
              label={translate('Username')}
              type="text"
              fullWidth
            />

            <TextField
              autoComplete="off"
              error={!!error}
              value={password}
              onChange={event => this.setState({ password: event.target.value })}
              margin="dense"
              id="password"
              label={translate('Password')}
              type="text"
              fullWidth
            />

            <TextField
              autoComplete="off"
              error={!!error}
              value={pin}
              onChange={event => this.setState({ pin: event.target.value })}
              margin="dense"
              id="pin"
              label={translate('PIN code')}
              type="number"
              InputProps={{
                minLength: 4,
                step: 1,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={hideAddDialog} color="secondary">
              {translate('Cancel')}
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              {translate('Continue')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AddUserDialog;
