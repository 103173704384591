import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core';
import DragHandle from '@material-ui/icons/DragIndicator';
import { DragSource, DropTarget } from 'react-dnd';
import ReactDOM from 'react-dom';
import update from 'react-addons-update';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import clsx from 'clsx';
import { Prompt, withRouter } from 'react-router';
import AreaLocationsManage from './AreaLocationsManage';
import ManageAreaIngredients from './ManageAreaIngredients';
import DraggableTypes from '../../components/DraggableTypes';
import IngredientService from '../../services/IngredientService';
import UserService from '../../services/UserService';
import ApiClient from '../../components/ApiClient';
import ContentPaper from '../../components/content-paper';
import { translate } from '../../i18n';
import { ButtonWhite } from '../../components/white-button';
import { areaApi } from '../../api/area';
import FormActions from '../../components/form-actions';
import { beverageApi } from '../../api/beverage';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttonBlock: {
    display: 'block',
    width: '140px',
    margin: '0 auto',
    marginBottom: theme.spacing(1),
  },
  buttonDelete: {
    margin: theme.spacing(1),
    color: theme.palette.error.dark,
  },
  caption: {
    /* marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2), */
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 270,
    margin: theme.spacing(1),
  },
  formControl: {
    /* marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit, */
    minWidth: 120,
    width: '100%',
    margin: theme.spacing(1),
  },
  helperText: {
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  imagePreview: {
    width: '100px',
    height: '100px',
    backgroundColor: '#fafafa',
    margin: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  img: {
    width: 'auto',
    maxHeight: '90%',
    maxWidth: '90%',
    marginTop: '50%',
    transform: 'translateY(-50%)',
  },
  listContainer: {
    maxHeight: '70vh',
    overflow: 'auto',
    background: '#f2f2f2',
    padding: theme.spacing(0, 2),
  },
});

/**
 * Implements the drag source contract.
 */
const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },

  endDrag(props) {
    if (props.handleDragEnd) {
      props.handleDragEnd();
    }
  },
};

const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    if (!component) {
      return;
    }
    const hoverBoundingRect = ReactDOM.findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.moveCard(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

/**
 * Specifies the props to inject into your component.
 */
function collect(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

function collect2(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

let SortableListItem = props => {
  const { data, connectDropTarget, connectDragSource, connectDragPreview, isLast } = props;

  return (
    <ListItem
      disableGutters
      divider={!isLast}
      /* ref={instance => connectDragSource(findDOMNode(instance))} */
    >
      {connectDropTarget(
        connectDragPreview(
          <div style={{ display: 'flex', width: '100%' }}>
            <ListItemText primary={data.name} />

            {connectDragSource(
              <div
                style={{
                  cursor: 'pointer',
                }}>
                <ListItemIcon>
                  <DragHandle />
                </ListItemIcon>
              </div>,
            )}
          </div>,
        ),
      )}
    </ListItem>
  );
};

SortableListItem = DropTarget(
  DraggableTypes.INGREDIENT_ITEM,
  cardTarget,
  collect,
)(DragSource(DraggableTypes.INGREDIENT_ITEM, cardSource, collect2)(SortableListItem));

const isTouchCapable =
  'ontouchstart' in window ||
  (window.DocumentTouch && document instanceof window.DocumentTouch) ||
  navigator.maxTouchPoints > 0 ||
  window.navigator.msMaxTouchPoints > 0;

@withStyles(styles)
class SortableIngredientList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ data: nextProps.data });
    }
  }

  endSort = () => {
    const { data } = this.state;
    const { sortEnd } = this.props;
    if (sortEnd) {
      sortEnd(data);
    }
  };

  handleSort = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragCard = data[dragIndex];

    this.setState(
      update(this.state, {
        data: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        },
      }),
      function () {},
    );
  };

  render() {
    const { data } = this.state;

    return (
      <List>
        {data &&
          data.map((ingredientId, i) => {
            if (!ingredientId) {
              return null;
            }

            const ingredient = ingredientId;

            if (!ingredient) {
              return null;
            }
            return (
              <SortableListItem
                key={`sortable-list-${ingredient.id}`}
                handleDragEnd={this.endSort}
                moveCard={this.handleSort}
                index={i}
                id={i}
                data={ingredient}
                isLast={i + 1 === data.length}
              />
            );
          })}
      </List>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`area-edit-tabpanel-${index}`}
      aria-labelledby={`area-edit-tab-${index}`}
      {...other}>
      <Box px={1} py={3}>
        {children}
      </Box>
    </Typography>
  );
}

@withRouter
@withStyles(styles)
@withWidth()
class AreaForm extends Component {
  constructor(props) {
    super(props);

    let INITIAL_DATA = {
      name: '',
      description: '',
      locations: [],
    };

    if (props.data) {
      INITIAL_DATA = props.data;
    }

    this.state = {
      id: props.id ? props.id : null,
      data: INITIAL_DATA,
      users: [],
      ingredients: [],
      ingredientsLoaded: false,
      activeTab: 0,
      reorderOpen: false,
    };
  }

  componentDidMount() {
    // Load users and ingredients lists
    this.load();

    UserService.fetchAll().then(res => {
      if (res && res.body && res.body.data) {
        this.setState({
          users: res.body.data.results,
        });
      }
    });

    IngredientService.find({}).then(res => {
      if (res && res.body && res.body.data) {
        this.setState({
          ingredients: res.body.data.results,
          ingredientsLoaded: true,
        });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    const { id } = this.state;
    if (!id && nextProps.id) {
      this.setState({
        id: nextProps.id,
        data: nextProps.data,
      });
    }
  }

  handleAllowedUsersChange = value => async event => {
    const { data } = this.state;
    let { allowed_users: allowedUsers } = data;

    value = typeof value !== 'undefined' ? value : event.target.value;

    // Add to array
    if (!allowedUsers) {
      allowedUsers = [];
    }
    if (allowedUsers.indexOf(value) > -1) {
      allowedUsers = [...allowedUsers].filter(o => o !== value);
    } else {
      allowedUsers = [...allowedUsers, value];
    }

    this.setState({ data: { ...data, allowed_users: allowedUsers } });
  };

  handleSubmit = async (ev, showAlerts = true) => {
    if (ev) {
      ev.preventDefault();
    }

    const { id, data } = this.state;
    const { onNotification, onSave } = this.props;

    try {
      if (!id) {
        const response = await areaApi.add(data);

        onNotification(translate('Area saved'));

        if (onSave) {
          onSave(response);
        }
      } else {
        const response = await areaApi.update(id, data);

        this.setState({ hasUnsavedChanges: false });

        if (showAlerts) {
          onNotification(translate('Area updated'));
        }

        if (onSave) {
          onSave(response);
        }
      }
    } catch (e) {
      onNotification(translate('Could not save the area!'), { variant: 'error' });
    }

    return false;
  };

  handleChange = name => event => {
    const { data } = this.state;

    this.setState({ data: { ...data, [name]: event.target.value }, hasUnsavedChanges: true });
  };

  handleIngredientsReorder = async ingredients => {
    const { data } = this.state;

    const newData = { ...data, ingredients: [...ingredients] };

    // Update ingredients list
    await ApiClient.post({
      endpoint: `/api/area/${data.id}/order`,
      data: {
        ingredients: [...ingredients],
      },
    });

    this.setState({
      data: newData,
    });
  };

  handleDelete = async () => {
    const { id } = this.state;
    const { onNotification, history } = this.props;

    // Delete AJAX
    try {
      await areaApi.deleteById(id);

      onNotification('Area deleted');
      history.push('/area');
    } catch (e) {
      onNotification('Could not delete the area', { options: { variant: 'error' } });
    }
  };

  load = async () => {
    const { id } = this.state;
    try {
      const response = await areaApi.getById(id);
      this.setState({
        data: response.data.data,
      });
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  a11yProps(index) {
    return {
      id: `area-edit-tab-${index}`,
      'aria-controls': `area-edit-tabpanel-${index}`,
    };
  }

  render() {
    const { classes, width, onCancel } = this.props;

    const { id, data, hasUnsavedChanges, users, ingredients, ingredientsLoaded, activeTab, reorderOpen } = this.state;

    const name = data && data.name ? data.name : '';
    const allowedUsers = data && data.allowed_users ? data.allowed_users : [];

    const checkedIngredients = data.ingredients;

    return (
      <>
        <Prompt
          when={!!hasUnsavedChanges}
          message={translate('You have unsaved changes. Are you sure you want to leave?')}
        />
        <ContentPaper className="FormBoxPaper">
          <Tabs
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, value) => this.setState({ activeTab: value })}>
            <Tab label="Details" {...this.a11yProps(0)} />
            <Tab label="Ingredients" {...this.a11yProps(1)} />
            <Tab label="Locations" {...this.a11yProps(2)} />
          </Tabs>

          {activeTab !== 0 ? null : (
            <TabPanel value={activeTab} index={0}>
              <Grid container spacing={0}>
                <Grid item xs={12} md={5}>
                  <Grid item xs={12}>
                    <Grid item xs={12} md={10} lg={8}>
                      <TextField
                        required
                        autoComplete="off"
                        name="name"
                        label={translate('Area name')}
                        className={clsx(classes.textField, 'ItemTitleInput')}
                        value={name || ''}
                        onChange={this.handleChange('name')}
                        fullWidth
                        InputProps={{
                          classes: {
                            input: 'LargeTitleInput',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid item xs={12} md={10} lg={8}>
                      <TextField
                        required
                        name="description"
                        label={translate('Description')}
                        className={classes.textField}
                        value={data.description || ''}
                        onChange={this.handleChange('description')}
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset" className={classes.formControl}>
                      <FormLabel component="legend">Employees access</FormLabel>
                      <FormGroup>
                        {users &&
                          users.map(d => {
                            // Skip admins
                            // if (d.role === 'admin') return null;

                            return (
                              <FormControlLabel
                                key={`afu${d.id}-${id}`}
                                control={
                                  <Checkbox
                                    checked={!(allowedUsers.indexOf(d.id) === -1)}
                                    onChange={this.handleAllowedUsersChange(d.id)}
                                    value={d.id}
                                  />
                                }
                                label={d.name ? `${d.name} (${d.username})` : d.username}
                              />
                            );
                          })}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
          )}
          {activeTab !== 1 ? null : (
            <TabPanel value={activeTab} index={1}>
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  className={classes.button}
                  onClick={() => this.setState({ reorderOpen: true })}>
                  Configure inventory order
                </Button>

                <ManageAreaIngredients
                  area={data}
                  ingredients={ingredients}
                  handleChange={this.load}
                  locations={data.locations}
                />

                <Dialog
                  open={reorderOpen}
                  onClose={() => this.setState({ reorderOpen: false })}
                  aria-labelledby="form-dialog-title"
                  fullWidth
                  fullScreen={!!isWidthDown('sm', width)}
                  PaperProps={{
                    className: classes.dialogPaper,
                  }}>
                  <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
                    Inventory ingredient order
                  </DialogTitle>
                  <DialogContent>
                    <Typography component="div" variant="subtitle2" className={classes.caption}>
                      Drag ingredients by handler to re-order them.
                    </Typography>

                    {!ingredientsLoaded ? (
                      <CircularProgress size={24} color="secondary" />
                    ) : (
                      <SortableIngredientList
                        data={checkedIngredients}
                        ingredients={ingredients}
                        sortEnd={this.handleIngredientsReorder}
                      />
                    )}
                  </DialogContent>

                  <DialogActions>
                    <Button onClick={() => this.setState({ reorderOpen: false })} color="secondary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </TabPanel>
          )}

          {activeTab !== 2 ? null : (
            <TabPanel value={activeTab} index={2}>
              <AreaLocationsManage
                handleChange={this.load}
                area={data}
                ingredients={data.ingredients}
                locations={data.locations}
              />
            </TabPanel>
          )}
        </ContentPaper>

        <FormActions onDelete={this.handleDelete} onCancel={onCancel} onSubmit={this.handleSubmit} />
      </>
    );
  }
}

export default AreaForm;
