import { makeActionCreator } from '../../../store/actions';

export const VENDORS_FETCH_REQUEST = '@vendors / FETCH REQUEST';
export const VENDORS_FETCH_SUCCESS = '@vendors / FETCH SUCCESS';
export const VENDORS_FETCH_ERROR = '@vendors / FETCH ERROR';
export const VENDORS_SHOW_ADD_DIALOG = '@vendors / SHOW ADD DIALOG';
export const VENDORS_HIDE_ADD_DIALOG = '@vendors / HIDE ADD DIALOG';
export const VENDORS_SET_TABLE_FILTER = '@vendors / SET TABLE FILTER';

export const fetchVendors = makeActionCreator(VENDORS_FETCH_REQUEST);
export const fetchVendorsError = makeActionCreator(VENDORS_FETCH_ERROR, 'error');
export const fetchVendorsSuccess = makeActionCreator(VENDORS_FETCH_SUCCESS, 'data');
export const showVendorAddDialog = makeActionCreator(VENDORS_SHOW_ADD_DIALOG);
export const hideVendorAddDialog = makeActionCreator(VENDORS_HIDE_ADD_DIALOG);

// Table filters
export const setTableFilter = makeActionCreator(VENDORS_SET_TABLE_FILTER, 'name', 'value');
