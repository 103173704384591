import React from 'react';
import ReactDOM from 'react-dom';
import { DropTarget } from 'react-dnd';

// How the drop target reacts to the drop event
const dropTarget = {
  drop(props, monitor) {
    // Obtain the dragged item
    const { item } = monitor.getItem();
    if (props.onDrop) {
      props.onDrop(item);
    }
  },

  hover(props, monitor, component) {
    if (!component) {
      return null;
    }

    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = ReactDOM.findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    if (props.onCardDrop) {
      props.onCardDrop(dragIndex, hoverIndex);
    }

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

// inject connect.dropTarget() into our component's props
function collect(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

// connect our component using connect.dropTarget (injected using collect())
class Droppable extends React.Component {
  render() {
    const { connectDropTarget, children } = this.props;
    return connectDropTarget(<div style={{ width: '100%' }}>{children}</div>);
  }
}

export default DropTarget(props => props.dndType, dropTarget, collect)(Droppable);
