import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormRow } from '../../../components/forms/row';
import { translate } from '../../../i18n';
import CurrencyField from '../../../components/forms/currency-field';

const ProductSmallForm = ({ categories, product: data, printerAreas, recipe, onChange }) => {
  if (!data) {
    return null;
  }

  let { name = null } = data;
  if (name === null) {
    name = recipe.name;
  }

  let selectedCategory = {};
  if (categories && data.category_id) {
    const category = categories.filter(c => c.id === data.category_id)[0];
    if (category) {
      selectedCategory = category;
    }
  }

  const handleChange = (field, value) => (event, autocompleteValue) => {
    value = typeof value !== 'undefined' ? value : event ? event.target.value : null;
    if (autocompleteValue && autocompleteValue.printer_group_id) {
      value = autocompleteValue.printer_group_id;
    } else if (autocompleteValue && autocompleteValue.id) {
      value = autocompleteValue.id;
    } else if (autocompleteValue || autocompleteValue === null) {
      value = autocompleteValue;
    }

    onChange(field, value)(event);
  };

  let selectedArea = null;
  if (printerAreas && data.printer_area) {
    const printerArea = printerAreas.filter(a => a.printer_group_id === parseInt(data.printer_area || 0, 10))[0];
    if (printerArea) {
      selectedArea = printerArea;
    }
  }

  return (
    <>
      <FormRow>
        <TextField
          required
          fullWidth
          autoComplete="off"
          name="name"
          label={translate('Product name')}
          defaultValue={name || ''}
          error={name !== null && !name}
          helperText={translate('This field is required')}
          onChange={handleChange('name')}
        />
      </FormRow>

      <FormRow>
        <Autocomplete
          options={categories ? Object.values(categories) : []}
          getOptionLabel={option => (option.name ? option.name : '')}
          disableClearable
          openOnFocus
          fullWidth
          value={selectedCategory}
          onChange={handleChange('category_id')}
          renderInput={params => <TextField {...params} label={translate('Category')} />}
        />

        <Autocomplete
          id="product-printer-area"
          autoSelect
          fullWidth
          value={selectedArea}
          onChange={handleChange('printer_area')}
          getOptionLabel={option => (option.printer_area ? option.printer_area : '')}
          options={printerAreas}
          renderInput={params => (
            <TextField
              {...params}
              label={translate('Printer area')}
            />
          )}
        />
      </FormRow>

      <FormRow>
        <CurrencyField
          fullWidth
          label={translate('Retail price')}
          required
          name="retail_price"
          value={data.retail_price || '0'}
          onChange={handleChange('retail_price')}
        />

        <CurrencyField
          fullWidth
          label={translate('Cost override')}
          name="cost_override"
          value={data.cost_override || ''}
          onChange={handleChange('cost_override')}
        />
      </FormRow>
    </>
  );
};

export default ProductSmallForm;
