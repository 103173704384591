import React from 'react';
import { Button, Chip, withStyles } from '@material-ui/core';
import { withSnackbar } from 'notistack';

import styles from '../styles';
import EnhancedTable from '../../../components/enhanced-table/EnhancedTable';
import ContentPaper from '../../../components/content-paper';
import ActionMenu from './row-actions';
import PageLoadingText from '../../../components/PageLoadingText';
import { localize, translate } from '../../../i18n';
import { productApi } from '../../../api/product';
import { parseApiError } from '../../../api/helpers';

@withSnackbar
@withStyles(styles)
class Products extends React.Component {
  constructor(props) {
    super(props);
    const { fetchData, fetchCategories, setPageTitle } = this.props;

    this.typeName = 'product';

    setPageTitle(translate('Products'));
    fetchCategories();
    fetchData();
  }

  handleMassDelete = ids => ids.map(id => this.handleDelete(id));

  handleDelete = async id => {
    const { enqueueSnackbar, fetchData } = this.props;

    // Delete AJAX
    try {
      await productApi.deleteById(id);

      // All good
      setTimeout(() => fetchData(), 100);
    } catch (err) {
      enqueueSnackbar(translate(`Could not delete the ${this.typeName}`), { options: { variant: 'error' } });
    }
  };

  handleRowClick = ({ id }) => {
    const { history } = this.props;
    history.push(`/product/edit/${id}`);
  };

  handleAdd = () => {
    const { history } = this.props;
    history.push('/product/add');
  };

  handleClone = async (data, name) => {
    const { enqueueSnackbar, fetchData } = this.props;

    try {
      const response = await productApi.cloneById(data.id, { name });

      if (!response.ok) {
        enqueueSnackbar(parseApiError(response).message);
        return;
      }

      enqueueSnackbar('Product cloned');

      setTimeout(() => fetchData(), 100);
    } catch (e) {
      enqueueSnackbar('Could not clone the product!', { options: { variant: 'error' } });
    }
  };

  getTableColumns = () => {
    const { loadingIds } = this.props;

    return [
      {
        id: 'name',
        accessor: 'name',
        sortable: true,
        component: 'td',
        label: translate('Name'),
      },
      {
        id: 'category_id',
        accessor: 'category_id',
        label: translate('Category'),
        renderValue: row => (row.category ? row.category.name : translate('None')),
      },
      {
        id: 'retail_price',
        accessor: 'retail_price',
        label: translate('Retail price'),
        numeric: true,
        renderValue: row => localize('number', row.retail_price, { strip_insignificant_zeros: false }),
      },
      {
        id: 'id_type',
        accessor: 'id',
        label: translate('Type'),
        align: 'center',
        renderValue: row => {
          let type = 'Simple';
          if (row.recipe_id) {
            type = 'Recipe';
          }
          if (row.ingredient_id) {
            type = 'Ingredient';
          }
          return <Chip label={translate(type)} />;
        },
      },
      {
        id: 'is_enabled',
        accessor: 'is_enabled',
        label: 'Available',
        align: 'center',
        renderValue: row => (
          <span style={{ color: row.is_enabled ? '' : 'red' }}>{translate(row.is_enabled ? 'Yes' : 'No')}</span>
        ),
      },
      {
        id: 'id',
        accessor: 'id',
        numeric: false,
        label: '',
        align: 'right',
        padding: 'none',
        renderValue: row => (
          <ActionMenu
            isLoading={loadingIds.indexOf(parseInt(row.id, 10)) !== -1}
            handleDelete={this.handleDelete}
            handleClone={this.handleClone}
            id={row.id}
            data={row}
          />
        ),
      },
    ];
  };

  getTableFilters = () => {
    const { setTableFilter, filters } = this.props;
    let { categories } = this.props;
    if (!categories) {
      categories = [];
    }

    return [
      {
        label: translate('Category'),
        type: 'select',
        value: filters.category_id,
        onChange: value => setTableFilter('category_id', value),
        options: [
          {
            value: '',
            label: translate('All'),
          },
          ...Object.values(categories)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(c => ({ value: c.id, label: c.name })),
        ],
      },
      {
        label: translate('Available'),
        type: 'select',
        value: filters.is_enabled,
        onChange: value => setTableFilter('is_enabled', value),
        options: [
          {
            value: '',
            label: translate('All'),
          },
          {
            value: 1,
            label: translate('Yes'),
          },
          {
            value: 0,
            label: translate('No'),
          },
        ],
      },
      {
        label: translate('Search'),
        type: 'search',
        value: filters.search,
        onChange: value => setTableFilter('search', value),
      },
    ];
  };

  shouldComponentRender = () => {
    const { loading, initialLoaded } = this.props;

    return initialLoaded;
  };

  getLoadingSkeleton = () => {
    return <PageLoadingText />;
  };

  render() {
    const { classes, dataById } = this.props;
    const data = Object.values(dataById);

    if (!this.shouldComponentRender()) {
      return <div className={classes.root}>{this.getLoadingSkeleton()}</div>;
    }

    return (
      <div className={classes.root}>
        <ContentPaper table className={classes.paper}>
          <EnhancedTable
            toolbarActions={
              <>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleAdd}
                  startIcon={<i className="fal fa-plus" />}>
                  {translate('Add product')}
                </Button>
              </>
            }
            filters={this.getTableFilters()}
            columns={this.getTableColumns()}
            onRowClick={this.handleRowClick}
            onMassDelete={this.handleMassDelete}
            data={data}
            rowCount={data.length}
            order="asc"
            orderBy="name"
          />
        </ContentPaper>
      </div>
    );
  }
}

export default Products;
