import React from 'react';
import Button from '@material-ui/core/Button';
import { Add, Save } from '@material-ui/icons';
import { Box, Grid, TextField, withStyles } from '@material-ui/core';
import { nanoid } from 'nanoid';
import { Prompt } from 'react-router';
import clsx from 'clsx';
import FabMainAction from '../../../components/FabMainAction';
import CategoriesList from './category-list';

import styles from '../styles';
import PageLoadingText from '../../../components/PageLoadingText';
import ContentPaper from '../../../components/content-paper';
import { translate } from '../../../i18n';

@withStyles(styles)
class MenuEdit extends React.Component {
  constructor(props) {
    super(props);

    props.setPageTitle(props.id ? translate('Edit menu') : translate('Add menu'));

    this.state = {
      form: {},
    };
  }

  componentDidMount() {
    const { fetchMenus, fetchProducts } = this.props;
    if (fetchProducts) {
      fetchProducts();
    }
    if (fetchMenus) {
      fetchMenus();
    }
  }

  endSort = () => {};

  handleSort = (dragIndex, hoverIndex) => {
    const { id, handleSortCategories } = this.props;

    handleSortCategories(id, dragIndex, hoverIndex);
  };

  handleSortProduct = (categoryIndex, dragIndex, hoverIndex) => {
    const { id, handleSortProducts } = this.props;

    handleSortProducts(id, categoryIndex, dragIndex, hoverIndex);
  };

  handleAddCategory = () => {
    const { id, addMenuCategory } = this.props;
    const category = { id: nanoid(), name: '', products: [] };

    addMenuCategory(id, category);
  };

  handleSave = () => {
    const { id, updateMenuData, addMenu } = this.props;
    const { form } = this.state;

    // Mark form as saved to avoid user confirmation
    this.setState({ hasUnsavedChanges: false });

    if (id) {
      updateMenuData(id, form, '/menu');
    } else {
      addMenu(form, true);
    }
  };

  handleCategoryChange = (rowIndex, category) => {
    const { id, updateMenuCategory } = this.props;
    updateMenuCategory(id, rowIndex, category);
  };

  handleDataChange = (name, value) => event => {
    const { form } = this.state;

    value = typeof value !== 'undefined' ? value : event.target.value;
    form[name] = value;

    this.setState({ form, hasUnsavedChanges: true });
  };

  render() {
    const { hasUnsavedChanges } = this.state;
    const { data, id, classes, categories, loadingId } = this.props;

    if (loadingId === id || (id && (!data || !data.id))) {
      return <PageLoadingText />;
    }

    return (
      <div>
        <Prompt
          when={!!hasUnsavedChanges}
          message={translate('You have unsaved changes. Are you sure you want to leave?')}
        />
        <ContentPaper className="FormBoxPaper">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                autoComplete="off"
                name="name"
                label={translate('Menu name')}
                className={clsx(classes.textField)}
                defaultValue={data.menu_name || ''}
                onBlur={this.handleDataChange('menu_name')}
                InputProps={{
                  classes: {
                    input: 'LargeTitleInput',
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={8}>
              <TextField
                multiline
                autoComplete="off"
                name="description"
                label={translate('Description')}
                className={classes.textField}
                defaultValue={data.description || ''}
                onBlur={this.handleDataChange('description')}
              />
            </Grid>
          </Grid>
        </ContentPaper>

        <FabMainAction icon={<Save />} onClick={this.handleSave} />

        {!id ? null : (
          <>
            <Box mt={4} mb={2}>
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                size="small"
                onClick={this.handleAddCategory}>
                <Add />
                {translate('Add category')}
              </Button>
            </Box>

            {categories && (
              <Box>
                <CategoriesList
                  categories={categories}
                  onChange={this.handleCategoryChange}
                  handleDragEnd={this.endSort}
                  moveCard={this.handleSort}
                  moveProductCard={this.handleSortProduct}
                />
              </Box>
            )}
          </>
        )}
      </div>
    );
  }
}

export default MenuEdit;
