import { makeActionCreator } from '../../../store/actions';

export const PRODUCTS_FETCH_REQUEST = '@products / FETCH REQUEST';
export const PRODUCTS_FETCH_SUCCESS = '@products / FETCH SUCCESS';
export const PRODUCTS_FETCH_ERROR = '@products / FETCH ERROR';
export const PRODUCTS_SHOW_ADD_DIALOG = '@products / SHOW ADD DIALOG';
export const PRODUCTS_HIDE_ADD_DIALOG = '@products / HIDE ADD DIALOG';
export const PRODUCTS_SET_TABLE_FILTER = '@products / SET TABLE FILTER';
export const PRODUCTS_FETCH_PRODUCT_START = '@products / FETCH PRODUCT START';
export const PRODUCTS_FETCH_PRODUCT_SUCCESS = '@products / FETCH PRODUCT SUCCESS';
export const PRODUCTS_FETCH_PRINTER_AREAS_START = '@products / FETCH PRINTER AREAS START';
export const PRODUCTS_FETCH_PRINTER_AREAS_SUCCESS = '@products / FETCH PRINTER AREAS SUCCESS';

export const fetchProducts = makeActionCreator(PRODUCTS_FETCH_REQUEST);
export const fetchProductsError = makeActionCreator(PRODUCTS_FETCH_ERROR, 'error');
export const fetchProductsSuccess = makeActionCreator(PRODUCTS_FETCH_SUCCESS, 'data');
export const showProductAddDialog = makeActionCreator(PRODUCTS_SHOW_ADD_DIALOG);
export const hideProductAddDialog = makeActionCreator(PRODUCTS_HIDE_ADD_DIALOG);

export const fetchProductById = makeActionCreator(PRODUCTS_FETCH_PRODUCT_START, 'id');
export const fetchProductByIdSuccess = makeActionCreator(PRODUCTS_FETCH_PRODUCT_SUCCESS, 'id', 'data');

export const fetchPrinterAreas = makeActionCreator(PRODUCTS_FETCH_PRINTER_AREAS_START);
export const fetchPrinterAreasSuccess = makeActionCreator(PRODUCTS_FETCH_PRINTER_AREAS_SUCCESS, 'data');

// Table filters
export const setTableFilter = makeActionCreator(PRODUCTS_SET_TABLE_FILTER, 'name', 'value');
