import { makeActionCreator } from '../../../store/actions';

export const BEVERAGES_FETCH_REQUEST = '@beverages / FETCH REQUEST';
export const BEVERAGES_FETCH_SUCCESS = '@beverages / FETCH SUCCESS';
export const BEVERAGES_FETCH_ERROR = '@beverages / FETCH ERROR';
export const BEVERAGES_SHOW_ADD_DIALOG = '@beverages / SHOW ADD DIALOG';
export const BEVERAGES_HIDE_ADD_DIALOG = '@beverages / HIDE ADD DIALOG';
export const BEVERAGES_SET_TABLE_FILTER = '@venues / SET TABLE FILTER';

export const fetchBeverages = makeActionCreator(BEVERAGES_FETCH_REQUEST);
export const fetchBeveragesError = makeActionCreator(BEVERAGES_FETCH_ERROR, 'error');
export const fetchBeveragesSuccess = makeActionCreator(BEVERAGES_FETCH_SUCCESS, 'data');

export const showBeverageAddDialog = makeActionCreator(BEVERAGES_SHOW_ADD_DIALOG);
export const hideBeverageAddDialog = makeActionCreator(BEVERAGES_HIDE_ADD_DIALOG);

// Table filters
export const setTableFilter = makeActionCreator(BEVERAGES_SET_TABLE_FILTER, 'name', 'value');
