import { call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  addMenuSuccess,
  deleteMenuError,
  deleteMenuSuccess,
  fetchMenus,
  fetchMenusError,
  fetchMenusSuccess,
  getMenuByIdSuccess, rowStartLoading, rowStopLoading,
  saveMenuSuccess,
} from '../actions';
import { menusApi } from '../../../api/menus';
import { parseApiError } from '../../../api/helpers';
import { getMenuByIdSelector } from '../reducers';
import { enqueueSnackbar } from '../../snackbar/actions';
import { hideProgressBar, showProgressBar } from '../../ui/actions';
import { translate } from '../../../i18n';

export function* fetchMenuByIdSaga(action) {
  try {
    const response = yield call(menusApi.getById, action.payload.id);
    if (!response.ok) {
      return;
    }

    yield put(getMenuByIdSuccess(action.payload.id, response.data.data || null));
  } catch (err) {
    yield put(enqueueSnackbar(parseApiError(err).message, { options: { variant: 'error' } }));
  }
}

export function* fetchMenusSaga() {
  try {
    yield put(showProgressBar());
    const response = yield call(menusApi.fetch);
    yield put(hideProgressBar());

    if (!response.ok) {
      yield put(fetchMenusError(parseApiError(response).message));
      return;
    }

    yield put(fetchMenusSuccess(response.data.data || []));
  } catch (err) {
    yield put(fetchMenusError(parseApiError(err).message));
  }
}

export function* addMenuSaga(action) {
  try {
    const { data } = action.payload;
    if (!data) {
      return;
    }

    const response = yield call(menusApi.add, data);
    if (!response.ok) {
      yield put(enqueueSnackbar(parseApiError(response).message, { options: { variant: 'error' } }));
      return;
    }

    yield put(addMenuSuccess(response.data.data));
    // yield put(enqueueSnackbar('The menu was saved'));
    if (action.payload.redirectToEdit) {
      yield put(push(`/menu/edit/${response.data.data.id}`));
    }
  } catch (err) {
    yield put(enqueueSnackbar(parseApiError(err).message, { options: { variant: 'error' } }));
  }
}

export function* copyMenuSaga(action) {
  try {
    const menu = yield select(getMenuByIdSelector, action.payload.id);
    if (!menu) {
      return;
    }

    // Start row loading
    yield put(rowStartLoading(action.payload.id));

    // Clean data and send request
    const data = { ...menu };
    data.id = null;
    data.menu_name = translate('Copy of {{name}}', { name: data.menu_name });

    const response = yield call(menusApi.add, data);

    // Stop row loading
    yield put(rowStopLoading(action.payload.id));

    if (!response.ok) {
      yield put(enqueueSnackbar(parseApiError(response).message, { options: { variant: 'error' } }));
      return;
    }

    yield put(addMenuSuccess(response.data.data));
    yield put(enqueueSnackbar('The menu was copied'));
  } catch (err) {
    yield put(enqueueSnackbar(parseApiError(err).message, { options: { variant: 'error' } }));
  }
}

export function* saveMenuSaga(action) {
  try {
    const menu = yield select(getMenuByIdSelector, action.payload.id);
    if (!menu) {
      return;
    }

    // Combine menu data with update data

    const response = yield call(menusApi.update, action.payload.id, menu);
    if (!response.ok) {
      yield put(enqueueSnackbar(parseApiError(response).message, { options: { variant: 'error' } }));
      return;
    }

    yield put(saveMenuSuccess());
    yield put(enqueueSnackbar('The menu was saved'));
  } catch (err) {
    yield put(enqueueSnackbar(parseApiError(err).message, { options: { variant: 'error' } }));
  }
}

export function* updateMenuSaga(action) {
  try {
    yield call(saveMenuSaga, action);

    const { redirectAfterUpdate } = action.payload;
    if (redirectAfterUpdate) {
      const redirectTo = typeof redirectAfterUpdate === 'string' ? redirectAfterUpdate : '/menu';
      yield put(push(redirectTo));
    }
  } catch (err) {
    yield put(enqueueSnackbar(parseApiError(err).message, { options: { variant: 'error' } }));
  }
}

export function* deleteMenuSaga(action) {
  try {
    const response = yield call(menusApi.deleteById, action.payload.id);

    if (!response.ok) {
      yield put(deleteMenuError(action.payload.id));
      yield put(enqueueSnackbar(parseApiError(response).message));

      // Reload data
      yield put(fetchMenus());
      return;
    }

    yield put(deleteMenuSuccess(response.data.data || []));
  } catch (err) {
    yield put(deleteMenuError(action.payload.id));
    yield put(enqueueSnackbar(parseApiError(err).message));
  }
}
