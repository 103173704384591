import React from 'react';
import {Redirect, Route} from 'react-router-dom'

import UserStore from "../lib/stores/UserStore";

export default function AdminRoute ({component: Component, authed, ...rest}) {

    return (
        <Route
            {...rest}
            render={(props) => UserStore.isLoggedIn() && ['admin', 'owner'].indexOf(UserStore.getUser().role) !== -1
                ? <Component {...props} {...rest} />
                : UserStore.isLoggedIn()
                    ? <Redirect to={{pathname: '/dashboard', state: {from: props.location}}} />
                    : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
        />
    )
}
