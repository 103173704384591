import { connect } from 'react-redux';
import DiscountFormComponent from '../components/discount-form';
import { setPageTitle } from '../../ui/actions';
import { enqueueSnackbar as enqueueSnackbarAction } from '../../snackbar/actions';
import { getDiscountByIdSelector } from '../reducers';
import { fetchDiscountById, fetchDiscounts } from '../actions';

const mapStateToProps = (state, ownProps) => {
  const data = getDiscountByIdSelector(state, ownProps.id);

  return {
    data,
  };
};

const mapDispatchToProps = {
  setPageTitle,
  fetchData: fetchDiscounts,
  fetchDiscountById,
  enqueueSnackbar: enqueueSnackbarAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountFormComponent);
