import { connect } from 'react-redux';
import AppBarComponent from '../components/appbar';
import { closeDrawer, openDrawer, setPageTitle } from '../actions';

const mapStateToProps = state => {
  return {
    pageTitle: state.ui.pageTitle,
    drawerOpen: state.ui.drawerOpen,
  };
};

const AppBar = connect(mapStateToProps, {
  setPageTitle,
  closeDrawer,
  openDrawer,
})(AppBarComponent);

export default AppBar;
