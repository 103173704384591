import produce from 'immer';
import { normalize, schema } from 'normalizr';
import _ from 'lodash';

import {
  INGREDIENTS_FETCH_REQUEST,
  INGREDIENTS_FETCH_SUCCESS,
  INGREDIENTS_FETCH_ERROR,
  INGREDIENTS_HIDE_ADD_DIALOG,
  INGREDIENTS_SHOW_ADD_DIALOG,
  INGREDIENTS_SET_TABLE_FILTER,
} from '../actions';

const INITIAL_STATE = {
  entities: {
    ingredients: {},
  },
  entitiesIds: [],
  selected: null,

  // Filters
  filters: {
    search: null,
    vendor_id: null,
  },

  // Other state
  showAddDialog: false,
  loading: false,
  initialLoaded: false,
  loadingId: null,
  loadingIds: [],

  fetchError: null,
  saveError: null,
};

// Define the order schema so we can normalize the data and index it properly
const vendorSchema = new schema.Entity('vendors');
const ingredientschema = new schema.Entity('ingredients', {
  vendor: vendorSchema,
});
const ingredientListSchema = new schema.Array(ingredientschema);

export default function ingredients(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case INGREDIENTS_FETCH_SUCCESS:
        // Normalize the data for better access
        const { data } = action.payload;
        const normalized = normalize(data, ingredientListSchema);
        draft.entities = normalized.entities;
        draft.entitiesIds = normalized.result;
        draft.loading = false;
        draft.initialLoaded = true;
        draft.fetchError = null;

        break;

      case INGREDIENTS_FETCH_REQUEST:
        draft.loading = true;
        draft.fetchError = null;
        break;

      case INGREDIENTS_FETCH_ERROR:
        draft.fetchError = action.payload.error;
        break;

      case INGREDIENTS_HIDE_ADD_DIALOG:
        draft.showAddDialog = false;
        break;

      case INGREDIENTS_SHOW_ADD_DIALOG:
        draft.showAddDialog = true;
        break;

      case INGREDIENTS_SET_TABLE_FILTER:
        draft.filters[action.payload.name] = action.payload.value;
        break;

      default:
    }
  });
}

// Selectors
export function getFilteredIngredients(state) {
  const { entities, filters } = state.ingredients;

  return _.filter(entities.ingredients, o => {
    if (filters.search && o.name.toLowerCase().indexOf(filters.search.toLowerCase()) === -1) {
      return false;
    }

    if (filters.vendor_id && !o.vendor_id) {
      return false;
    }

    if (filters.vendor_id) {
      if (!o.vendor_id) {
        return false;
      }

      if (o.vendor_id.toString() !== filters.vendor_id.toString()) {
        return false;
      }
    }

    return true;
  });
}

export const getIngredientByIdSelector = (state, id) => {
  return state.ingredients && state.ingredients.entities && state.ingredients.entities.ingredients
    ? state.ingredients.entities.ingredients[id]
    : {};
};
