import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 99,
  },
});

@withStyles(styles)
class FabMainAction extends React.Component {
  render() {
    const { children, classes, color = 'primary', icon = <AddIcon />, onClick, ...rest } = this.props;

    return (
      <Fab {...rest} color={color} className={classes.fab} onClick={onClick}>
        {children || icon}
      </Fab>
    );
  }
}

export default FabMainAction;
