import ApiClient from "../components/ApiClient";
import AppState from "../lib/stores/AppState";

const AreaService = {
    add(data, callback) {
        if ( callback ) {
            ApiClient.post({
                endpoint: '/api/area',
                data: data
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.post({
            endpoint: '/api/area',
            data: data
        });
    },

    fetch(id, callback) {

        if ( callback ) {
            ApiClient.get({
                endpoint: '/api/area/' + id
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            });

            return;
        }

        return ApiClient.get({
            endpoint: '/api/area/' + id
        });
    },

    fetchIngredients(id, callback) {

        if ( callback ) {
            ApiClient.get({
                endpoint: '/api/area/' + id + '/ingredients'
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            });

            return;
        }

        return ApiClient.get({
            endpoint: '/api/area/' + id + '/ingredients'
        });
    },

    fetchAll(callback) {

        if ( callback ) {
            ApiClient.get({
                endpoint: '/api/area/'
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.get({
            endpoint: '/api/area/'
        });
    },

    update(id, data, callback) {
        if ( callback ) {
            ApiClient.patch({
                endpoint: '/api/area/' + id,
                data: data
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.patch({
            endpoint: '/api/area/' + id,
            data: data
        });
    },

    addUserToArea(user_id, area_id, callback) {
        if ( callback ) {
            ApiClient.post({
                endpoint: '/api/area/' + area_id + '/user/' + user_id,
                data: {
                    user_id
                }
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.post({
            endpoint: '/api/area/' + area_id + '/user/' + user_id,
            data: {
                user_id
            }
        });
    },

    removeUserFromArea(user_id, area_id, callback) {
        if ( callback ) {
            ApiClient.delete({
                endpoint: '/api/area/' + area_id + '/user/' + user_id,
            }).then((res) => {
                callback && callback(res);
            }, (err) => {
                callback && callback(null, err);
            })

            return;
        }

        return ApiClient.delete({
            endpoint: '/api/area/' + area_id + '/user/' + user_id,
            data: {
                user_id
            }
        });
    },

    addIngredient(ingredient_id, area_id) {

        return ApiClient.post({
            endpoint: '/api/area/' + area_id + '/ingredient/' + ingredient_id,
            data: {
                ingredient_id
            }
        });
    },

    removeIngredient(ingredient_id, area_id) {

        return ApiClient.delete({
            endpoint: '/api/area/' + area_id + '/ingredient/' + ingredient_id,
            data: {
                ingredient_id
            }
        });
    },
};

export default AreaService;
