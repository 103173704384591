export const styles = (theme) => ({
  root: {
    maxWidth: 380,
    margin: theme.spacing(0, 2),

    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
    },
  },
  action: {
    width: 80,
    textAlign: 'right',
  },
  numberInput: {
    textAlign: 'right',
    maxWidth: 50,
  },
});
