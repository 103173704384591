import React from 'react'
import ReactDOM from 'react-dom';
import {DragSource, DropTarget} from 'react-dnd'
import DragIndicator from '@material-ui/icons/DragIndicator';

import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import DraggableTypes from "../../components/DraggableTypes";

import { green, orange } from '@material-ui/core/colors';

import { Grid, Card, CardContent, CardActions, Button, Typography, Box } from '@material-ui/core';

const styles = theme => ({
    card: {
        marginBottom: theme.spacing(2)
    },
    cardDisabled: {
        backgroundColor: "#fafafa"
    },
    cardContent: {
        padding: theme.spacing(1),
        paddingTop: theme.spacing(2),
        paddingBottom: 0
    },
    cardActions: {
        justifyContent: 'flex-end'
    },
    errorButton: {
        color: theme.palette.error.dark
    },
    btnSuccess: {
        color: green[500]
    },
    btnWarning: {
        color: orange[500]
    },
    dragger: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.secondary.default
        }
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    }
});

/**
 * Implements the drag source contract.
 */
var cardSource = {
    beginDrag: function (props) {
        return {
            id: props.id,
            index: props.index
        };
    },

    endDrag: function(props, monitor, component) {
        if ( props.handleDragEnd ) {
            props.handleDragEnd();
        }
    }
}

var cardTarget = {
    hover: function (props, monitor, component) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ReactDOM.findDOMNode(component).getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }

        // Time to actually perform the action
        props.moveCard(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
}

/**
 * Specifies the props to inject into your component.
 */
function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget()
    };
}

function collect2(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging()
    };
}

class MediaItem extends React.Component {


    render() {
        const {data, index, classes} = this.props;


        var connectDropTarget = this.props.connectDropTarget;
        var connectDragSource = this.props.connectDragSource;
        var connectDragPreview = this.props.connectDragPreview;

        return connectDropTarget(connectDragPreview(
            <div>
                <Card className={classNames(classes.card, !data.is_active && classes.cardDisabled)}>
                    <CardContent className={classes.cardContent}>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <Grid item>
                                {connectDragSource(
                                    <div className={classes.dragger}><Box mr={2}><DragIndicator /></Box></div>)}
                            </Grid>

                            <Grid item>
                                <Typography variant="h6" component="h6">
                                    {data.name}
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                    This <strong>{data.media_type === 'youtube' ? 'Youtube video' : 'image'} </strong>
                                    will show on screen for {data.duration} seconds
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>

                    <CardActions className={classes.cardActions}>

                        {data.is_active ?
                            <Button size="small" className={classes.btnSuccess} style={{}}
                                    onClick={() => this.props.handleStatus(index, 0)}>Active</Button>
                            :
                            <Button size="small" className={classes.btnWarning}
                                    onClick={() => this.props.handleStatus(index, 1)}>Suspended</Button>
                        }

                        <Button size="small" color="default" href={data.media_url} target={'_blank'}>View Creative</Button>

                        <Button size="small"  className={classes.errorButton}
                                onClick={() => this.props.handleDelete(index)}>Delete</Button>
                    </CardActions>
                </Card>
            </div>
        ))
    }
}

MediaItem = withStyles(styles, { withTheme: true })(MediaItem)

export default DropTarget ( DraggableTypes.MEDIA_ITEM, cardTarget, collect )(DragSource(DraggableTypes.MEDIA_ITEM, cardSource, collect2)(MediaItem));