import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Add } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import AreaService from '../../services/AreaService';
import LocationManageItem from './LocationManageItem';
import LocationService from '../../services/LocationService';
import LocationIngredientsSelection from './LocationIngredientsSelection';

const styles = theme => ({
  root: {
    /* margin: 'auto', */
    maxWidth: 600,
  },
  error: {
    color: theme.palette.error.dark,
    marginTop: theme.spacing(1),
  },
});
@withStyles(styles)
class AreaLocationsManage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      name: '',
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, name: '', error: '' });
  };

  handleSubmit = () => {
    const { name } = this.state;
    LocationService.add({
      name,
      area_id: this.props.area.id,
    }).then(
      res => {
        this.handleClose();

        this.props.handleChange && this.props.handleChange();
      },
      err => {
        this.setState({
          error: 'An error occurred while adding the location. Please try again.',
        });
      },
    );
  };

  handleDelete = id => {
    LocationService.delete(id).then(
      res => {
        this.handleClose();

        this.props.handleChange && this.props.handleChange();
      },
      err => {
        this.setState({
          error: 'An error occurred while deleting the location. Please try again.',
        });
      },
    );
  };

  render() {
    const { name } = this.state;

    const { locations, ingredients, classes } = this.props;

    return (
      <div className={classes.root}>
        <Button
          style={{ marginBottom: 16 }}
          size="small"
          variant="outlined"
          color="secondary"
          onClick={this.handleClickOpen}>
          Add location
        </Button>

        <div>
          {locations &&
            locations.map(location => {
              return (
                <div key={`loc-${location.id}`}>
                  <LocationManageItem
                    handleChange={this.props.handleChange}
                    handleDelete={this.handleDelete}
                    location={location}
                    locationsCount={locations.length}
                    ingredients={ingredients}
                  />
                </div>
              );
            })}
        </div>

        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" fullWidth>
          {/* <DialogTitle id="form-dialog-title">Add area</DialogTitle> */}
          <DialogContent>
            <DialogContentText>Please enter the new location name.</DialogContentText>
            {!this.state.error ? null : (
              <Typography component="span" className={classes.error}>
                {this.state.error}
              </Typography>
            )}
            <TextField
              error={!!this.state.error}
              value={name}
              onChange={event => this.setState({ name: event.target.value })}
              autoFocus
              margin="dense"
              id="name"
              label=""
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AreaLocationsManage;
