import React from 'react';
import { connect } from 'react-redux';
import Beverage from '../components/beverage';
import { fetchBeverages, showBeverageAddDialog, hideBeverageAddDialog, setTableFilter } from '../actions';
import { enqueueSnackbar } from '../../snackbar/actions';
import { setPageTitle } from '../../ui/actions';
import { getBeverageByIdSelector, getFilteredBeverage } from '../reducers';

function mapStateToProps(state) {
  const beverages = getFilteredBeverage(state);

  return {
    dataById: beverages,
    data: state.beverage.entitiesIds,
    producers: state.beverage.entities.producers || {},
    selected: state.beverage.selected,
    loading: state.beverage.loading,
    fetchError: state.beverage.fetchError,
    loadingIds: state.beverage.loadingIds,
    filters: state.beverage.filters,
  };
}

const mapDispatchToProps = {
  fetchData: fetchBeverages,
  showAddDialog: showBeverageAddDialog,
  hideAddDialog: hideBeverageAddDialog,
  enqueueSnackbar,
  setPageTitle,
  setTableFilter,
  getBeverageByIdSelector,
};

export default connect(mapStateToProps, mapDispatchToProps)(Beverage);
