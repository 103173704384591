import React from 'react';
import { withStyles, TextField } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Switch from '@material-ui/core/Switch';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import { styles } from './styles';
import ApiClient from '../../../components/ApiClient';
import LoadingContainer from '../../../components/LoadingContainer';
import { hideProgressBar, setPageTitle, showProgressBar } from '../../ui/actions';
import { connect } from 'react-redux';
import { translate } from '../../../i18n';

@withStyles(styles)
class OrderSystemConfiguration extends React.Component {
  constructor(props) {
    super(props);

    props.setPageTitle(translate('Orders settings'));

    this.state = {
      loading: false,
      loadingSettings: [],
      config: {},
    };
  }

  componentDidMount() {
    this.loadConfig();
  }

  loadConfig = async () => {
    this.setState({ loading: true });

    try {
      const response = await ApiClient.get({ endpoint: '/api/order-system/settings' });
      const config = response.body.data;
      this.setState({ loading: false, loadingSettings: [], config });
    } catch (err) {
      this.setState({ loading: false, loadingSettings: [] });
      ApiClient.onError(err);
    }
  };

  saveConfig = async (name, value) => {
    try {
      await ApiClient.post({
        data: [
          {
            name,
            value,
          },
        ],
        endpoint: '/api/order-system/settings',
      });

      const { config } = this.state;
      this.setState({ config: { ...config, [name]: value } });
    } catch (err) {
      this.setState({});
    }
  };

  handleChangeSwitch = (event) => {
    this.saveConfig(event.target.name, event.target.checked ? 'on' : 'off');

    // const { state } = this;
    // this.setState({ ...state, [event.target.name]: event.target.checked });
  };

  handleSaveInput = (event) => {
    this.saveConfig(event.target.name, event.target.value);

    // const { state } = this;
    // this.setState({ ...state, [event.target.name]: event.target.checked });
  };

  render() {
    const { classes } = this.props;
    const { config, loading, loadingSettings } = this.state;

    return (
      <div className={classes.root}>
        <Paper>
          <List>
            <ListItem>
              <ListItemText>{translate('Allow online orders')}</ListItemText>
              <ListItemSecondaryAction className={classes.action}>
                <LoadingContainer loading={loading || loadingSettings.indexOf('ordering_online_status') !== -1}>
                  <Switch
                    color="primary"
                    checked={config.ordering_online_status === 'on'}
                    name="ordering_online_status"
                    onChange={this.handleChangeSwitch}
                  />
                </LoadingContainer>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText>{translate('Delivery service')}</ListItemText>
              <ListItemSecondaryAction className={classes.action}>
                <LoadingContainer
                  loading={loading || loadingSettings.indexOf('ordering_online_delivery_service') !== -1}>
                  <Switch
                    color="primary"
                    checked={config.ordering_online_delivery_service === 'on'}
                    name="ordering_online_delivery_service"
                    onChange={this.handleChangeSwitch}
                  />
                </LoadingContainer>
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem>
              <ListItemText>{translate('Delivery fee')}</ListItemText>
              <ListItemSecondaryAction className={classes.action}>
                <LoadingContainer loading={loading || loadingSettings.indexOf('ordering_online_delivery_fee') !== -1}>
                  <TextField
                    type="number"
                    color="primary"
                    defaultValue={config.ordering_online_delivery_fee}
                    name="ordering_online_delivery_fee"
                    onBlur={this.handleSaveInput}
                    inputProps={{
                      className: classes.numberInput,
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </LoadingContainer>
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem>
              <ListItemText>{translate('Pickup discount')}</ListItemText>
              <ListItemSecondaryAction className={classes.action}>
                <LoadingContainer
                  loading={loading || loadingSettings.indexOf('ordering_online_pickup_discount') !== -1}>
                  <TextField
                    type="number"
                    color="primary"
                    defaultValue={config.ordering_online_pickup_discount}
                    name="ordering_online_pickup_discount"
                    onBlur={this.handleSaveInput}
                    inputProps={{
                      className: classes.numberInput,
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                  />
                </LoadingContainer>
              </ListItemSecondaryAction>
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemText>{translate('PayPal payments')}</ListItemText>
              <ListItemSecondaryAction className={classes.action}>
                <LoadingContainer
                  loading={loading || loadingSettings.indexOf('ordering_online_paypal_payments') !== -1}>
                  <Switch
                    color="primary"
                    checked={config.ordering_online_paypal_payments === 'on'}
                    name="ordering_online_paypal_payments"
                    onChange={this.handleChangeSwitch}
                  />
                </LoadingContainer>
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem>
              <ListItemText>{translate('Stripe payments')}</ListItemText>
              <ListItemSecondaryAction className={classes.action}>
                <LoadingContainer
                  loading={loading || loadingSettings.indexOf('ordering_online_stripe_payments') !== -1}>
                  <Switch
                    color="primary"
                    checked={config.ordering_online_stripe_payments === 'on'}
                    name="ordering_online_stripe_payments"
                    onChange={this.handleChangeSwitch}
                  />
                </LoadingContainer>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Paper>
      </div>
    );
  }
}

const mapDispatchToProps = {
  showProgressBar,
  hideProgressBar,
  setPageTitle,
};

export default connect(null, mapDispatchToProps)(OrderSystemConfiguration);
