import { call, put } from 'redux-saga/effects';

import { fetchUsersSuccess, fetchUsersError, fetchUserByIdSuccess, rowStartLoading, rowStopLoading } from '../actions';
import { parseApiError } from '../../../api/helpers';
import { hideProgressBar, showProgressBar } from '../../ui/actions';
import { userApi } from '../../../api/user';
import { enqueueSnackbar } from '../../snackbar/actions';

export function* startLoadingSaga(id) {
  yield put(rowStartLoading(id));
  yield put(showProgressBar());
}

export function* stopLoadingSaga(id) {
  yield put(rowStopLoading(id));
  yield put(hideProgressBar());
}

export function* fetchUsersSaga() {
  try {
    yield put(showProgressBar());
    const response = yield call(userApi.fetch);
    yield put(hideProgressBar());

    if (!response.ok) {
      yield put(fetchUsersError(parseApiError(response).message));
      return;
    }

    yield put(fetchUsersSuccess(response.data.data.results || []));
  } catch (err) {
    yield put(fetchUsersError(parseApiError(err).message));
  }
}

export function* fetchUserByIdSaga(action) {
  try {
    yield call(startLoadingSaga, action.payload.id);

    const response = yield call(userApi.getById, action.payload.id);

    yield call(stopLoadingSaga, action.payload.id);

    if (!response.ok) {
      return;
    }

    yield put(fetchUserByIdSuccess(action.payload.id, response.data.data || null));
  } catch (err) {
    yield put(enqueueSnackbar(parseApiError(err).message, { options: { variant: 'error' } }));
  }
}
