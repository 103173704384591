import { createClient } from './helpers';

const apiClient = createClient();

function fetch() {
  return apiClient.get('vendor');
}

function add(data) {
  return apiClient.post('vendor', data);
}

function update(id, data) {
  return apiClient.patch(`vendor/${id}`, data);
}

function getById(id) {
  return apiClient.get(`vendor/${id}`);
}

function deleteById(id) {
  return apiClient.delete(`vendor/${id}`);
}

export const vendorApi = {
  fetch,
  add,
  update,
  getById,
  deleteById,
};
