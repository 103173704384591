import ApiClient from "../../components/ApiClient";
import UserStore from "../stores/UserStore";

const SERVICE_WORKER_URL = new URL('/push/push-service-worker.js', window.location.origin).href;

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}


export function initPush() {
    if (!('serviceWorker' in navigator)) {
        // Service Worker isn't supported on this browser, disable or hide UI.
        return;
    }

    if (!('PushManager' in window)) {
        // Push isn't supported on this browser, disable or hide UI.
        return;
    }

    setUpPush();
}

function setUpPush() {
    return Promise.all([
        registerSW(),
        getNotificationPermissionState()
    ]).then(promises => {
        const registration = promises[0];
        const currentPermissionState = promises[1];

        // If permission is denied, we just quit, otherwise the following promise will ask for approval
        if (currentPermissionState === 'denied') {
            console.warn('The notification permission has been blocked. Nothing we can do.');
            return;
        }

        // We execute the promise if the permission hasnt been granted nor denied previously
        let promiseChain = Promise.resolve();
        if (currentPermissionState !== 'granted') {
            promiseChain = askUserForPermission();
        }

        // We have permissions
        promiseChain
            .then(subscribeUserToPush)
            .then(subscription => {
                // Push to server
                if (subscription) {
                    const user = UserStore.getUser();

                    return ApiClient.post({
                        endpoint: `/api/order/add-push-subscription`,
                        data: {
                            subscription
                        },
                    }).then(response => {
                        return subscription;
                    })
                }

                return subscription;
            })
            .then(function(subscription) {
                // We got a subscription AND it was sent to our backend,
                // re-enable our UI and set up state.
                console.log('Saved subscription')
            })
            .catch(err => {
                console.log('Error while asking user for permission')
                console.log(err)

                /*if (-1 !== err.toString().indexOf('A subscription with a different applicationServerKey')) {
                    console.log('should unsubscribe')
                    unsubscribeUserFromPush();
                }*/
            })
    })
        .catch(err => {
            console.log('Error while setting up service worker')
            console.log(err)
        })
}

function registerSW() {
    return navigator.serviceWorker.register(SERVICE_WORKER_URL)

        .then(function(registration) {
            console.log('Service worker successfully registered.');
            return registration;
        })
        .catch(function(err) {
            console.error('Unable to register service worker.', err);
        });
}

function subscribeUserToPush() {
    return getSWRegistration()
        .then(async registration => {

            const response = await ApiClient.get({
                endpoint: '/api/order/get-vapid-keys'
            });
            const vapidPublicKey = await response.body.public_key;

            const subscribeOptions = {
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(vapidPublicKey)
            };

            return registration.pushManager.subscribe(subscribeOptions);
        })
        .then(subscription => {
            console.log('Received PushSubscription: ', JSON.stringify(subscription));
            return subscription;
        })
}

function getSWRegistration() {
    return navigator.serviceWorker.register(SERVICE_WORKER_URL)
}

function getNotificationPermissionState() {
    if (navigator.permissions) {
        return navigator.permissions.query({name: 'notifications'})
            .then((result) => {
                return result.state;
            });
    }

    return new Promise((resolve) => {
        resolve(Notification.permission);
    });
}

export function getPushSubscriptionState() {
    return getSWRegistration()
        .then(function(registration) {
            return registration.pushManager.getSubscription();

        })
        .catch(function(err) {
            return null;
        });
}

function askUserForPermission() {
    return new Promise((resolve, reject) => {
        const permissionPromise = Notification.requestPermission((result) => {
            resolve(result);
        });

        if (permissionPromise) {
            permissionPromise.then(resolve, reject);
        }
    })
        .then((result) => {
            if (result === 'granted') {
                return result;
            } else {
                // Exception is required so the promise is rejected
                throw new Error('Permission not granted.')
            }
        });
}


export function unsubscribeUserFromPush() {
    return getSWRegistration()
        .then(function(registration) {
            // Service worker is active so now we can unsubscribe the user.
            return registration.pushManager.getSubscription();

        })
        .then(function(subscription) {
            if (subscription) {
                console.log('User unsubscribed from Push');
                subscription.unsubscribe();
            }

            return subscription;
        })
        .then(subscription => {
            // Push to server
            if (subscription) {
                return ApiClient.post({
                    endpoint: `/api/order/delete-push-subscription`,
                    data: {
                        subscription
                    },
                }).then(response => {
                    return subscription;
                })
            }

            return subscription;
        })
        .catch(function(err) {
            console.error('Failed to unsubscribe the user', err);
        });
}
