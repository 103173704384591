import { createClient } from './helpers';

const apiClient = createClient();

function fetch() {
  return apiClient.get('area');
}

function add(data) {
  return apiClient.post('area', data);
}

function update(id, data) {
  return apiClient.patch(`area/${id}`, data);
}

function getById(id) {
  return apiClient.get(`area/${id}`);
}

function deleteById(id) {
  return apiClient.delete(`area/${id}`);
}

export const areaApi = {
  fetch,
  add,
  update,
  getById,
  deleteById,
};
