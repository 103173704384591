import { connect } from 'react-redux';
import { getUserByIdSelector } from '../reducers';
import { setPageTitle as setPageTitleAction } from '../../ui/actions';
import { fetchUserById } from '../actions';
import { fetchVenues as fetchVenuesAction } from '../../venue/actions';
import { enqueueSnackbar as enqueueSnackbarAction } from '../../snackbar/actions';
import UserForm from '../components/user-form';

const mapStateToProps = (state, ownProps) => {
  return {
    data: getUserByIdSelector(state, ownProps.id),
    venues: state.venues.entities.venues,
    areas: state.venues.entities.areas,
  };
};

const mapDispatchToProps = {
  setPageTitle: setPageTitleAction,
  fetchUserById,
  fetchVenues: fetchVenuesAction,
  enqueueSnackbar: enqueueSnackbarAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
