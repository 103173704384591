import { createClient } from './helpers';
import ApiClient from '../components/ApiClient';

const apiClient = createClient();

function fetch() {
  return apiClient.get('user');
}

function add(data) {
  return apiClient.post('user', data);
}

function update(id, data) {
  return apiClient.patch(`user/${id}`, data);
}

function getById(id) {
  return apiClient.get(`user/${id}`);
}

function deleteById(id) {
  return apiClient.delete(`user/${id}`);
}

function addUserToVenue(userId, venueId) {
  return ApiClient.post({
    endpoint: `/api/user/${userId}/venue/${venueId}`,
    data: {
      user_id: userId,
      venue_id: venueId,
    },
  });
}

function removeUserFromVenue(userId, venueId) {
  return ApiClient.delete({
    endpoint: `/api/user/${userId}/venue/${venueId}`,
    data: {
      user_id: userId,
      venue_id: venueId,
    },
  });
}

export const userApi = {
  fetch,
  add,
  update,
  getById,
  deleteById,
  addUserToVenue,
  removeUserFromVenue,
};
