import { fade } from '@material-ui/core/styles/colorManipulator';
import theme from '../../../theme/theme';

export const drawerWidth = 290;
export const styles = () => ({
  root: {
    display: 'flex',
    background: '#f5f5f5',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    // boxShadow: '-1px 0px 6px 3px #bfbfbf',
    boxShadow: 'none',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButtonHidden: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    whiteSpace: 'nowrap',
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      top: 48,
      height: 'calc(100% - 48px)',
      paddingBottom: 20,
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },

  appName: {
    width: '100%',
    paddingLeft: '10px',
    color: '#fff',
  },
  appBarSpacer: theme.mixins.toolbar,
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'block',
    /* [theme.breakpoints.up('sm')]: {
            display: 'block',
        }, */
  },
  pageTitle: {
    display: 'block',
    margin: theme.spacing(2, 1),

    [theme.breakpoints.down(1024)]: {
      /* display: 'none', */
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  siteTitle: {
    color: '#fff',
    /* fontWeight: '500', */
    fontSize: 26,
    textAlign: 'center',
    width: '100%',
    /* textTransform: 'uppercase', */
    /* textShadow: '1px 1px 2px #6d0012', */
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  changerAction: {
    marginTop: '0',
    padding: '12px 12px 12px 56px',
    minHeight: '48px',
    display: 'block',
    color: '#313534',
    textDecoration: 'none',
    fontSize: '15px',
  },

  venueChanger: {
    color: '#313534',
    position: 'relative',
    margin: theme.spacing(2, 1, 1, 1),
  },
  venueIcon: {
    position: 'absolute',
    /* marginRight: '.3em', */
    fontSize: '1.75rem',
    left: '10px',
    top: '10px',
    width: '36px',
    textAlign: 'center',
    webkitFontSmoothing: 'antialiased',
    fontWeight: 'normal',
    fontStyle: 'normal',
  },

  profileInfo: {
    display: 'inline-block',
    verticalAlign: 'middle',
    padding: '0 20px 0 10px',
    lineHeight: '15px',
  },

  small: {
    display: 'block',
    opacity: '0.5',
  },
});
