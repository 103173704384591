import React from 'react';
import { connect } from 'react-redux';
import Modifiers from '../components/modifiers';
import { fetchModifiers, hideModifierAddDialog, setTableFilter, showModifierAddDialog } from '../actions';
import { setPageTitle } from '../../ui/actions';
import { getFilteredModifiers } from '../reducers';
import { enqueueSnackbar } from '../../snackbar/actions';
import { fetchIngredients } from '../../ingredient/actions';

function mapStateToProps(state) {
  const modifiers = getFilteredModifiers(state);

  return {
    dataById: modifiers || {},
    data: state.modifiers.entitiesIds || [],
    filters: state.modifiers.filters,
    selected: state.modifiers.selected,
    loading: state.modifiers.loading,
    initialLoaded: state.modifiers.initialLoaded,
    fetchError: state.modifiers.fetchError,
    loadingIds: state.modifiers.loadingIds,
  };
}

const mapDispatchToProps = {
  fetchData: fetchModifiers,
  fetchIngredients,
  setPageTitle,
  setTableFilter,
  showAddDialog: showModifierAddDialog,
  hideAddDialog: hideModifierAddDialog,
  enqueueSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modifiers);
