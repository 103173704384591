import { createClient } from './helpers';

const apiClient = createClient();

function logout() {
  return apiClient.get('auth/logout');
}

function login(username, password) {
  return apiClient.post('auth/login', { username, password });
}

function loginPin(pin) {
  return apiClient.post('auth/login-pin', { pin });
}

export const authApi = {
  logout,
  login,
  loginPin,
};
