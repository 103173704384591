import { createClient } from './helpers';

const apiClient = createClient();

async function addMissingTranslation(data) {
  try {
    await apiClient.post('localization/missing-translation', data);
  } catch (e) {}
}

export const i18nApi = {
  addMissingTranslation,
};
