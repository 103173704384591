import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import UserStore from './lib/stores/UserStore';
import AppState from './lib/stores/AppState';

// Disable double tap zoom
/* var doubleTouchStartTimestamp = 0;
document.addEventListener("touchstart", function(event){
    var now = +(new Date());
    if (doubleTouchStartTimestamp + 500 > now){
        event.preventDefault();
    }
    doubleTouchStartTimestamp = now;
}); */

// Init display random ID
let displayId;
let isEmbedded = false;

// Do we have a display ID in the URL maybe?
if (window.location.pathname.indexOf('/display/embedded') !== -1) {
  // Extract the display ID from the URL, if any
  let params = window.location.search;
  isEmbedded = true;

  if (params && params.indexOf('id=') !== -1) {
    params = params.indexOf('?') === 0 ? params.substr(1) : params;

    params = params.substr(params.indexOf('id=') + 3);

    if (params.indexOf('=') !== -1) {
      displayId = params.substr(0, params.indexOf('='));
    } else {
      displayId = params;
    }
  }

  if (!displayId) {
    displayId = 'embedded';
  }
} else {
  // Stored in the browser maybe?
  displayId = localStorage.getItem('displayId');

  if (!displayId) {
    // Generate new random display ID
    displayId = `${Math.random().toString(36).substr(2, 9)}`;
  }
}

localStorage.setItem('displayId', displayId);
AppState.setConfig('CurrentDisplayId', displayId);
AppState.setConfig('CurrentDisplayIsEmbedded', isEmbedded);

// Close the drawer on refresh
localStorage.setItem('isDrawerOpen', '0');

// Automatically log the user in if there is a session ID stored
const sessionId = UserStore.getSessionId();

// Do we have a valid session?
UserStore.verifySession(sessionId, res => {
  if (!res || !res.user || !res.user.username) {
    UserStore.logoutUser();
  } else {
    UserStore.loginUser(sessionId, res.user);
  }

  // Configure localization
  let venueData = localStorage.getItem('venue_data');
  if (venueData) {
    venueData = JSON.parse(venueData);
  } else {
    venueData = {};
  }
  const localeConfig = {
    language: res && res.user ? res.user.default_language : 'en',
    timezone: res && res.user ? res.user.timezone_name : 'America/Mexico_City',
    measureSystem: venueData.unit_system || null,
  };
  localStorage.setItem('localeConfig', JSON.stringify(localeConfig));
  localStorage.setItem('timezone', localeConfig.timezone);

  ReactDOM.render(<App />, document.getElementById('root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
