import { call, put } from 'redux-saga/effects';

import { fetchAreasSuccess, fetchAreasError } from '../actions';
import { parseApiError } from '../../../api/helpers';
import { hideProgressBar, showProgressBar } from '../../ui/actions';
import { areaApi } from '../../../api/area';

export function* fetchAreasSaga() {
  try {
    yield put(showProgressBar());
    const response = yield call(areaApi.fetch);
    yield put(hideProgressBar());

    if (!response.ok) {
      yield put(fetchAreasError(parseApiError(response).message));
      return;
    }

    yield put(fetchAreasSuccess(response.data.data.results || []));
  } catch (err) {
    yield put(fetchAreasError(parseApiError(err).message));
  }
}
