import React from 'react';

import { withRouter } from 'react-router-dom';
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  withStyles,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import { translate } from '../../../i18n';
import { ingredientApi } from '../../../api/ingredient';
import FormRow from '../../../components/forms/row';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(0, 3),
  },
  error: {
    color: theme.palette.error.dark,
    marginTop: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 99,
  },
  formControl: {
    minWidth: 120,
    display: 'flex',
  },
  textField: {
    display: 'flex',
  },
});

@withStyles(styles)
@withRouter
class AddIngredientDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      vendorId: '',
      error: '',
    };
  }

  componentDidMount() {
    const { fetchVendors } = this.props;
    fetchVendors();
  }

  handleSubmit = async () => {
    const { name, vendorId } = this.state;
    try {
      const response = await ingredientApi.add({
        name,
        producer: '',
        vendor_id: vendorId,
      });

      const { data } = response.data;
      const id = data && data.id;

      if (id) {
        const { onSave = null, hideAddDialog, history } = this.props;
        hideAddDialog();

        if (!onSave) {
          history.push(`/ingredient/edit/${id}`);
        } else {
          onSave();
        }
      }
    } catch (e) {
      this.setState({
        error: translate('An error occurred while saving the data. Please try again.'),
      });
    }
  };

  render() {
    const { error, name, vendorId } = this.state;
    const { classes, open, hideAddDialog, vendors } = this.props;

    return (
      <div className={classes.root}>
        <Dialog open={open} onClose={hideAddDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth="xs">
          <DialogTitle>{translate('Enter the new ingredient data')}</DialogTitle>
          <DialogContent>
            {!error ? null : (
              <Typography component="span" className={classes.error}>
                {error}
              </Typography>
            )}
            <FormRow>
              <TextField
                className={classes.textField}
                label="Ingredient name"
                error={!!error}
                value={name}
                onChange={event => this.setState({ name: event.target.value })}
                autoFocus
                id="name"
                type="text"
                fullWidth
              />
            </FormRow>

            <FormRow style={{ marginTop: 10 }}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="vendor_id">{translate('Vendor')}</InputLabel>
                <Select
                  value={vendorId || ''}
                  onChange={event => this.setState({ vendorId: event.target.value })}
                  inputProps={{
                    name: 'vendor_id',
                    id: 'vendor_id',
                  }}
                  displayEmpty
                  name="vendor_id">
                  <MenuItem value="" />
                  {vendors &&
                    Object.values(vendors)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(vendor => {
                        return (
                          <MenuItem key={vendor.id} value={vendor.id}>
                            {vendor.name}
                          </MenuItem>
                        );
                      })}
                </Select>
              </FormControl>
            </FormRow>
          </DialogContent>
          <DialogActions>
            <Button onClick={hideAddDialog} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AddIngredientDialog;
