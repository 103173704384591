import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {WifiOff} from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";


class NoInternetAlert extends React.Component {

    state = {
        open: true,
        loading: false,
    }

    interval = null

    handleClickOpen() {
        this.setState({open: true});
    }

    handleClose () {
        this.setState({open: false});
    }

    handleRetry() {
        this.setState({loading: true});

        clearTimeout(this.interval)
        this.interval = setTimeout(() => {
            this.setState({loading: false})
        }, 15000)
    }

    render() {
        const {loading} = this.state;

        return (
            <Dialog
                open={this.state.open}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                onClose={this.handleClose.bind(this)}
                aria-labelledby="internet-alert-dialog-title"
                aria-describedby="internet-alert-dialog-description"
            >
                <DialogTitle id="internet-alert-dialog-title"
                    style={{
                        color: '#ff002c'
                    }}>
                    <WifiOff fontSize={'large'} style={{
                        float: 'left',
                        marginRight: '10px',
                        fontSize: '28px',
                    }}/> No Internet</DialogTitle>
                <DialogContent>
                    <DialogContentText id="internet-alert-dialog-description">
                        <br/>Your device lost the internet connection.<br/><br/>
                        This application can not be used without a working internet connection.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {!loading?
                        <Button onClick={this.handleRetry.bind(this)} color="secondary" autoFocus>
                            Retry
                        </Button>
                        :
                        <CircularProgress size={30} style={{
                            marginRight: 8, marginBottom: 6
                        }} color="secondary" />
                    }
                </DialogActions>
            </Dialog>
        );
    }
}

export default NoInternetAlert;