import ApiClient from "../components/ApiClient";
import AppState from "../lib/stores/AppState";

export default {
    sendCommand(id, command, callback) {
        ApiClient.post({
            endpoint: '/api/display/send-command',
            data: [
                {
                    display_id: id,
                    command: command
                },
                /*{
                    display_id: id,
                    command: 'aaaaaa'
                },
                {
                    display_id: 'dummy-id',
                    command: 'dummy-id-com'
                },
                {
                    display_id: 'dummy-id2',
                    command: 'dummy-id2-com'
                },
                {
                    display_id: 'dummy-id2',
                    command: 'dummy-id2-com3'
                }*/
            ]

        }).then((res) => {
            callback && callback(res);
        }, (err) => {

        })
    }
};
