import React from 'react';
import { connect } from 'react-redux';
import Ingredients from '../components/ingredients';
import { fetchIngredients, hideIngredientAddDialog, setTableFilter, showIngredientAddDialog } from '../actions';
import { setPageTitle } from '../../ui/actions';
import { getFilteredIngredients } from '../reducers';
import { enqueueSnackbar } from '../../snackbar/actions';

function mapStateToProps(state) {
  const ingredients = getFilteredIngredients(state);

  return {
    dataById: ingredients || {},
    data: state.ingredients.entitiesIds || [],
    vendors: state.ingredients.entities.vendors || {},
    filters: state.ingredients.filters,
    selected: state.ingredients.selected,
    loading: state.ingredients.loading,
    initialLoaded: state.ingredients.initialLoaded,
    fetchError: state.ingredients.fetchError,
    loadingIds: state.ingredients.loadingIds,
  };
}

const mapDispatchToProps = {
  fetchData: fetchIngredients,
  setPageTitle,
  setTableFilter,
  showAddDialog: showIngredientAddDialog,
  hideAddDialog: hideIngredientAddDialog,
  enqueueSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Ingredients);
