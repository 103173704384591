import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import { translate } from '../../i18n';
import { availableUnits } from '../../i18n/units';

const MeasureUnitSelect = ({ value, onChange, name, id, fullWidth, ...props }) => {
  const renderLabel = unit => (
    <>
      {translate(unit.label)} {/* <span className="text-muted size14 d-inline-block ml-1">{unit.value}</span> */}
    </>
  );

  return (
    <Select
      {...props}
      fullWidth={!!fullWidth}
      value={value || ''}
      onChange={onChange}
      inputProps={{
        name: name || '',
        id: id || '',
      }}>
      <ListSubheader disableSticky>{translate('Weight')}</ListSubheader>
      {availableUnits.weight.map(unit => (
        <MenuItem key={`weight-${unit.value}`} value={unit.value}>
          {renderLabel(unit)}
        </MenuItem>
      ))}
      <ListSubheader disableSticky>{translate('Volume')}</ListSubheader>
      {availableUnits.volume.map(unit => (
        <MenuItem key={`volume-${unit.value}`} value={unit.value}>
          {renderLabel(unit)}
        </MenuItem>
      ))}
      <ListSubheader disableSticky>{translate('Others')}</ListSubheader>
      {availableUnits.other.map(unit => (
        <MenuItem key={`other-${unit.value}`} value={unit.value}>
          {renderLabel(unit)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default MeasureUnitSelect;
