import React from 'react';
import { DragSource } from 'react-dnd';

const itemSource = {
  // When the dragging starts, beginDrag is called.
  // Return a plain JavaScript object that will be available to the drop target
  beginDrag(props) {
    return { id: props.id, index: props.index, item: props };
  },

  endDrag(props) {
    if (props.handleDragEnd) {
      props.handleDragEnd();
    }
  },
};

// inject connect.dragSource() to our compoent
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

// connect our component using connect.dragSource (injected using collect())
class Item extends React.Component {
  render() {
    const { connectDragSource, children } = this.props;
    return connectDragSource(<div>{children}</div>);
  }
}

export default DragSource(props => props.dndType, itemSource, collect)(Item);
