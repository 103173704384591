import React from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import classnames from 'classnames';

import TodayIcon from '@material-ui/icons/TodayOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import Switch from '@material-ui/core/Switch';
import { connect } from 'react-redux';
import ContentPaper from '../../components/content-paper';
import { convertFromBaseUnit, localizeNoCache, translate } from '../../i18n';
import InventoryService from '../../services/InventoryService';
import { setPageTitle } from '../ui/actions';

const styles = theme => ({
  actionMenu: {
    marginLeft: theme.spacing(3),
  },

  backButton: {
    // background: '#fffff',
    padding: '8px 18px 8px 24px',
  },
  button: {
    margin: theme.spacing(0),
    marginRight: theme.spacing(2),
  },

  buttonInline: {
    margin: theme.spacing(0, 2),
  },

  buttonOption: {
    margin: theme.spacing(2, 0),
  },

  buttonIcon: {
    marginRight: theme.spacing(1),
  },

  formControl: {
    width: '100%',
  },

  buttonBox: {
    marginBottom: theme.spacing(2),
  },

  paper: {
    padding: theme.spacing(2, 2),
    marginBottom: theme.spacing(4),
    position: 'relative',
  },

  table: {
    minWidth: 320,
  },

  shortCol: {
    width: 130,
  },
  stockCol: {
    width: 130,
  },
  optionalCol: {
    width: 100,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  nameCol: {
    fontWeight: 500,
  },
  areaCol: {
    width: 200,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  oddRow: {
    backgroundColor: '#f5f5f5',
  },
  row: {
    border: 0,
    '&>th,>td': {
      border: 0,
    },
  },
});

@withStyles(styles)
class ActionMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.actionMenu}>
        <IconButton aria-label="More" aria-haspopup="true" onClick={this.handleClick} style={{ padding: '5px' }}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          <MenuItem
            onClick={() => {
              this.handleClose();
              this.props.onEmail();
            }}>
            Email report
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.handleClose();
              this.props.onDownload();
            }}>
            Download report
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

@withStyles(styles)
@withSnackbar
class InventoryReport extends React.Component {
  state = {
    report: null,
    showAllIngredients: false,
  };

  componentDidMount() {
    this.props.setPageTitle(translate('Order report'));

    InventoryService.getDates().then(res => {
      let dates = [moment().format('YYYY-MM-DD')];
      if (res && res.body && res.body.data && res.body.data.length) {
        dates = res.body.data;
      }

      this.setState(
        {
          dates,
          selectedDate: dates[0],
        },
        () => {
          this.loadReport();
        },
      );
    });
  }

  loadReport() {
    let { dates, selectedDate, showAllIngredients } = this.state;

    // Load selected inventory data

    if (!selectedDate && dates && dates.length) {
      selectedDate = dates[0];
    }

    InventoryService.getOrderReport(selectedDate, {
      all: true,
    }).then(res => {
      this.props.enqueueSnackbar(`${selectedDate} report loaded`, { variant: 'success', autoHideDuration: 3000 });
      this.setState({
        report: res.body.data,
      });
    });
  }

  render() {
    const { classes } = this.props;

    let { selectedDate, showAllIngredients, dates, report } = this.state;

    if (!selectedDate) {
      if (!dates || !dates.length) {
        selectedDate = moment().format('YYYY-MM-DD');
      } else {
        // Last inventory
        selectedDate = moment(dates[0]).format('YYYY-MM-DD');
      }
    }

    // Filter ingredients
    const filtered = _(report).filter(o => showAllIngredients || parseFloat(o.order_amount) > 0);

    // Group report rows by vendor
    const reportGrouped = _(filtered)
      .groupBy(p => p.vendor_name)
      .map((value, key) => ({ vendor: key, vendor_id: value[0] && value[0].vendor_id, ingredients: value }))
      .value();

    const handleDateChange = date => {
      this.setState(
        {
          selectedDate: date && date.format('YYYY-MM-DD'),
        },
        () => this.loadReport(),
      );
    };

    return (
      <>

        <Box mt={1} mb={2}>
          {!dates ? null : (
            <>
              <Typography variant="h6">{translate('Date')}</Typography>

              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  disableFuture
                  open={!!this.state.openDateSelector}
                  /* inputVariant="outlined" */

                  margin="dense"
                  variant="dialog"
                  labelFunc={date => {
                    return date.format('ddd, D MMM YYYY');
                  }}
                  inputVariant="outlined"
                  InputProps={{
                    'aria-label': 'naked',
                    /* disableUnderline: true, */
                    style: {
                      width: 220,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton className={classes.dateIcon}>
                          <TodayIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={selectedDate}
                  onChange={handleDateChange}
                  onOpen={() => this.setState({ openDateSelector: true })}
                  onClose={() => this.setState({ openDateSelector: false })}
                  shouldDisableDate={day => {
                    const index = _.findIndex(dates, function (o) {
                      return day.isSame(o, 'day');
                    });
                    return index === -1;
                  }}
                />
              </MuiPickersUtilsProvider>
            </>
          )}

          <FormControl margin="dense">
            <FormControlLabel
              style={{ marginLeft: 30 }}
              control={
                <Switch
                  color="primary"
                  checked={showAllIngredients}
                  onChange={evt => this.setState({ showAllIngredients: evt.target.checked })}
                  value="1"
                />
              }
              label="Show all inventory ingredients"
            />
          </FormControl>
        </Box>
        <Box mt={1} mb={2}>
          <Button
            onClick={() => {
              InventoryService.getOrderReport(selectedDate, {
                email: 1,
                all: showAllIngredients,
              }).then(res => {
                this.props.enqueueSnackbar('Order report emailed', { variant: 'success' });
              });
            }}
            variant="outlined"
            color="primary"
            className={classes.button}>
            Email report
          </Button>

          <Button
            onClick={() => {
              InventoryService.getOrderReport(selectedDate, {
                download: 'pdf',
                all: showAllIngredients,
              }).then(res => {
                const filename = res.body.data ? res.body.data.filename : '';
                const uri = `/files/download/report/${filename}`;

                window.open(uri, '_blank');
              });
            }}
            variant="outlined"
            color="primary"
            className={classes.button}>
            Download report
          </Button>

          <Button
            onClick={() => {
              InventoryService.getOrderReport(selectedDate, {
                download: 'xls',
                all: showAllIngredients,
              }).then(res => {
                const filename = res.body.data ? res.body.data.filename : '';
                const uri = `/files/download/report/${filename}`;

                window.open(uri, '_blank');
              });
            }}
            variant="outlined"
            color="primary"
            className={classes.button}>
            Download Excel
          </Button>
        </Box>

        {!report === null ? (
          <Box px={3}>
            <Typography>Loading, please wait</Typography>
          </Box>
        ) : !reportGrouped.length ? (
          <Box px={3}>
            <Typography>No inventory was found for the selected date.</Typography>
          </Box>
        ) : (
          reportGrouped.map(vendor => {
            return (
              <ContentPaper table className={classes.paper} key={`paper-${vendor.vendor_id || vendor.vendor}`}>
                <Box style={{ display: 'flex' }}>
                  <Typography variant="h6" color="primary" className={classes.caption}>
                    {vendor.vendor}
                  </Typography>
                  <ActionMenu
                    id={vendor.vendor_id}
                    onEmail={() => {
                      InventoryService.getOrderReport(selectedDate, {
                        vendor_id: vendor.vendor_id,
                        email: 1,
                        all: showAllIngredients,
                      }).then(res => {
                        this.props.enqueueSnackbar('Order report emailed', { variant: 'success' });
                      });
                    }}
                    onDownload={() => {
                      InventoryService.getOrderReport(selectedDate, {
                        vendor_id: vendor.vendor_id,
                        download: 'pdf',
                        all: showAllIngredients,
                      }).then(res => {
                        const filename = res.body.data ? res.body.data.filename : '';
                        const uri = `/files/download/report/${filename}`;

                        window.open(uri, '_blank');
                      });
                    }}
                  />
                </Box>

                <Table size="small" className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{translate('Ingredient name')}</TableCell>
                      <TableCell className={classes.areaCol}>{translate('Area')}</TableCell>
                      <TableCell align="center" className={classes.optionalCol}>
                        {translate('Par')}
                      </TableCell>
                      <TableCell align="right" className={classes.stockCol}>
                        {translate('Stock')}
                      </TableCell>
                      <TableCell align="right" className={classes.optionalCol}>
                        {translate('Unit')}
                      </TableCell>
                      <TableCell align="right" className={classes.shortCol}>
                        {translate('Order Amount')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vendor.ingredients.map((row, idx) => {
                      const ingredient = row;
                      let amount = '-';
                      if (row.stock_amount !== null /* && ingredient.unit */) {
                        // amount = convertFromBaseUnit(row.stock_amount, ingredient.unit, 'numberInput');
                        amount = localizeNoCache('numberInput', row.stock_amount);
                      }

                      return (
                        <TableRow
                          key={`vp${idx}-${row.id}`}
                          className={classnames(classes.row, idx % 2 && classes.oddRow)}>
                          <TableCell component="th" scope="row" className={classes.nameCol}>
                            {row.name}
                          </TableCell>
                          <TableCell className={classes.areaCol}>{row.area_name ? row.area_name : '-'}</TableCell>

                          <TableCell align="center" className={classes.optionalCol}>
                            {localizeNoCache('numberInput', row.par_number ? row.par_number : 0)}
                          </TableCell>
                          <TableCell align="right" className={classes.stockCol}>
                            {amount}
                          </TableCell>
                          <TableCell align="right" className={classes.optionalCol}>
                            {row.unit}
                          </TableCell>
                          <TableCell align="right" className={classes.shortCol}>
                            {localizeNoCache('numberInput', row.order_amount)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </ContentPaper>
            );
          })
        )}
      </>
    );
  }
}

export default connect(null, { setPageTitle })(InventoryReport);
