import { makeActionCreator } from '../../../store/actions';

export const MENUS_FETCH_REQUEST = '@menus / FETCH REQUEST';
export const MENUS_FETCH_SUCCESS = '@menus / FETCH SUCCESS';
export const MENUS_FETCH_ERROR = '@menus / FETCH ERROR';
export const MENUS_SAVE_REQUEST = '@menus / SAVE REQUEST';
export const MENUS_SAVE_SUCCESS = '@menus / SAVE SUCCESS';
export const MENUS_ADD_REQUEST = '@menus / ADD REQUEST';
export const MENUS_ADD_SUCCESS = '@menus / ADD SUCCESS';
export const MENUS_UPDATE_DATA = '@menus / UPDATE DATA';
export const MENUS_COPY_MENU = '@menus / COPY';
export const MENUS_DELETE_REQUEST = '@menus / DELETE START';
export const MENUS_DELETE_SUCCESS = '@menus / DELETE SUCCESS';
export const MENUS_DELETE_ERROR = '@menus / DELETE ERROR';
export const MENUS_SELECTED = '@menus / SELECTED MENU';
export const MENUS_CATEGORIES_SORTED = '@menus / CATEGORIES SORTED';
export const MENUS_PRODUCTS_SORTED = '@menus / CATEGORY PRODUCTS SORTED';
export const MENUS_ADD_CATEGORY = '@menus / ADD CATEGORY';
export const MENUS_UPDATE_CATEGORY = '@menus / UPDATE CATEGORY';
export const MENUS_LOADING_START = '@menus / LOADING START';
export const MENUS_LOADING_STOP = '@menus / LOADING STOP';
export const MENUS_FETCH_MENU_START = '@menus / FETCH MENU START';
export const MENUS_FETCH_MENU_SUCCESS = '@menus / FETCH MENU SUCCESS';
export const MENUS_SET_TABLE_FILTER = '@menus / SET TABLE FILTER';

export const fetchMenus = makeActionCreator(MENUS_FETCH_REQUEST);
export const fetchMenusError = makeActionCreator(MENUS_FETCH_ERROR, 'error');
export const fetchMenusSuccess = makeActionCreator(MENUS_FETCH_SUCCESS, 'data');
export const selectMenu = makeActionCreator(MENUS_SELECTED, 'id');

export const deleteMenu = makeActionCreator(MENUS_DELETE_REQUEST, 'id');
export const deleteMenuSuccess = makeActionCreator(MENUS_DELETE_SUCCESS, 'id');
export const deleteMenuError = makeActionCreator(MENUS_DELETE_ERROR, 'id');
export const getMenuById = makeActionCreator(MENUS_FETCH_MENU_START, 'id');
export const getMenuByIdSuccess = makeActionCreator(MENUS_FETCH_MENU_SUCCESS, 'id', 'data');
export const saveMenu = makeActionCreator(MENUS_SAVE_REQUEST, 'id');
export const saveMenuSuccess = makeActionCreator(MENUS_SAVE_SUCCESS, 'id');
export const addMenu = makeActionCreator(MENUS_ADD_REQUEST, 'data', 'redirectToEdit');
export const addMenuSuccess = makeActionCreator(MENUS_ADD_SUCCESS, 'data');

export const copyMenu = makeActionCreator(MENUS_COPY_MENU, 'id');
export const updateMenuData = makeActionCreator(MENUS_UPDATE_DATA, 'id', 'data', 'redirectAfterUpdate');
export const rowStartLoading = makeActionCreator(MENUS_LOADING_START, 'id');
export const rowStopLoading = makeActionCreator(MENUS_LOADING_STOP, 'id');

// Table filters
export const setTableFilter = makeActionCreator(MENUS_SET_TABLE_FILTER, 'name', 'value');

// Categories
export const addMenuCategory = makeActionCreator(MENUS_ADD_CATEGORY, 'menuId', 'category');
export const updateMenuCategory = makeActionCreator(MENUS_UPDATE_CATEGORY, 'menuId', 'rowIndex', 'category');

export const sortCategories = makeActionCreator(MENUS_CATEGORIES_SORTED, 'menuId', 'dragIndex', 'hoverIndex');
export const sortProducts = makeActionCreator(
  MENUS_PRODUCTS_SORTED,
  'menuId',
  'categoryIndex',
  'dragIndex',
  'hoverIndex',
);
