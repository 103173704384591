import { connect } from 'react-redux';
import AuditEvent from '../components/audit-event';
import { fetchAuditEvents, setTableFilter } from '../actions';
import { setPageTitle } from '../../ui/actions';
import { enqueueSnackbar } from '../../snackbar/actions';
import { getFilteredEvents } from '../reducers';

function mapStateToProps(state) {
  const events = getFilteredEvents(state);
  const filters = [
    ...Object.values(state.audit.events || {}).map(d => ({
      event_name: d.event_name,
      object_type: d.object_type,
      user_name: d.user_name,
      category: d.category,
    })),
  ];

  const categoriesFilter = [
    ...filters
      .map(d => d.category)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a.localeCompare(b))
      .map(c => ({ value: c, label: c })),
  ];

  const eventsFilter = [
    ...filters
      .map(d => d.event_name)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a.localeCompare(b))
      .map(c => ({ value: c, label: c })),
  ];

  const objectsFilter = [
    ...filters
      .map(d => d.object_type)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a.localeCompare(b))
      .map(c => ({ value: c, label: c.toUpperCase() })),
  ];

  const usersFilter = [
    ...filters
      .map(d => d.user_name)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a.localeCompare(b))
      .map(c => ({ value: c, label: c })),
  ];

  return {
    dataById: events || {},
    data: state.audit.eventsIds,
    filters: state.audit.filters,
    loading: state.audit.loading,
    loadingIds: state.audit.loadingIds,
    initialLoaded: state.audit.initialLoadedEvents,
    categoriesFilter,
    objectsFilter,
    usersFilter,
    eventsFilter,
  };
}

const mapDispatchToProps = {
  fetchData: fetchAuditEvents,
  setPageTitle,
  setTableFilter,
  enqueueSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditEvent);
