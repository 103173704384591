import React from 'react';
import { connect } from 'react-redux';
import Products from '../components/products';
import { fetchProducts, hideProductAddDialog, setTableFilter, showProductAddDialog } from '../actions';
import { setPageTitle } from '../../ui/actions';
import { getFilteredProducts } from '../reducers';
import { enqueueSnackbar } from '../../snackbar/actions';
import { fetchIngredients } from '../../ingredient/actions';
import { fetchCategories } from '../../category/actions';

function mapStateToProps(state) {
  const products = getFilteredProducts(state);

  return {
    dataById: products || {},
    data: state.products.entitiesIds || [],
    categories: state.categories.entities.categories,
    filters: state.products.filters,
    selected: state.products.selected,
    loading: state.products.loading,
    initialLoaded: state.products.initialLoaded,
    fetchError: state.products.fetchError,
    loadingIds: state.products.loadingIds,
  };
}

const mapDispatchToProps = {
  fetchData: fetchProducts,
  fetchIngredients,
  fetchCategories,
  setPageTitle,
  setTableFilter,
  showAddDialog: showProductAddDialog,
  hideAddDialog: hideProductAddDialog,
  enqueueSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
