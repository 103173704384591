import React, { Component } from 'react';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import BeveragesStore from '../../lib/stores/BeverageStore';

import PageLoadingText from '../../components/PageLoadingText';
import { translate } from '../../i18n';
import ApiClient from '../../components/ApiClient';
import OnTapItem from './OnTapItem';
import { setPageTitle } from '../ui/actions';

const styles = theme => ({
  card: {
    background: 'transparent',
  },
  cardContent: {},
  emptyTap: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    height: '100px',
    overflow: 'hidden',

    position: 'relative',
  },
  items: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
});

@withStyles(styles)
@withSnackbar
class OnTap extends Component {
  constructor(props) {
    super(props);
    props.setPageTitle(translate('Beer on Tap'));
    this.state = {
      loaded: false,
      data: [],
    };
  }

  load() {
    ApiClient.get({
      endpoint: '/api/beverage/on-tap/',
    }).then(
      res => {
        let data = [];
        for (let i = data.length; i < 12; i++) {
          data.push({
            placeholder: true,
            beverage: {},
          });
        }

        if (res.body.on_tap) {
          if (res.body.on_tap.length < 12) {
            data = _.assign(data, res.body.on_tap);
          } else {
            data = res.body.on_tap;
          }
        }

        this.setState({
          loaded: true,
          data,
        });
      },
      err => {
        this.setState({
          loading: false,
          loaded: true,
          error: true,
          errorMsg:
            err.response && err.response.body && err.response.body.error
              ? err.response.body.error
              : 'API communication error',
        });
      },
    );
  }

  /**
   * Save the on tap configuration
   * @param model_data
   */
  save = model_data => {
    // Save the on tap data

    const data = model_data || this.state.data;

    ApiClient.post({
      endpoint: '/api/beverage/on-tap',
      data,
    }).then(
      res => {
        this.load();
      },
      err => {
        this.load();
        this.props.enqueueSnackbar('Could not save the on tap data', { variant: 'error' });
      },
    );
  };

  /* handleAssignBeverage = index => {
        const
    } */

  updateBeverages = () => {
    this.setState({
      beverages: BeveragesStore.getBeverages(),
    });
  };

  assignBeverage = (index, beverage) => {
    const { data } = this.state;
    data[index] = {
      beverage_id: beverage.id,
      beverage,
    };

    this.setState({ data }, () => this.save(data));
  };

  unassignBeverage = index => {
    const { data } = this.state;
    data[index] = {
      placeholder: true,
      beverage: {},
    };

    this.setState({ data }, () => this.save(data));
  };

  onPricesChanged = () => {
    // this.load();
    this.save();
  };

  moveTapItem = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragCard = data[dragIndex];

    /* this.setState({
            data: update(data, {
                $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
            })
        }) */

    const tmp = data[dragIndex];
    data[dragIndex] = data[hoverIndex];
    data[hoverIndex] = tmp;

    this.setState({
      data,
    });
  };

  componentWillMount() {

  }

  componentWillUnmount() {
    BeveragesStore.unsubscribe(this.updateBeverages);
  }

  componentDidMount() {
    this.load();

    // Subscribe to update events
    BeveragesStore.subscribe(this.updateBeverages);

    // Load data
    BeveragesStore.loadFromServer();
  }

  render() {
    const { classes } = this.props;
    const { data, loaded } = this.state;

    if (!loaded && (!data || !data.length)) {
      return <PageLoadingText />;
    }

    return (
      <div>
        <Grid className={classes.items} direction="row" justify="center" alignItems="center" spacing={0} container>
          {data.map((d, i) => {
            /* if ( d.placeholder ) {
                            return (
                                <Grid item key={'gbi' + i} xs={12} md={4}
                                      className={classes.menuItem}>

                                    <Card className={classes.card}>
                                        <CardContent className={classes.cardContent}>

                                            <Typography onClick={this.handleAssignBeverage(i)} component='div' className={classes.emptyTap}>
                                                <AddIcon style={{color: ''}} fontSize="large" />
                                                <Typography color="textSecondary">Add beverage</Typography>
                                            </Typography>

                                        </CardContent>
                                    </Card>
                                </Grid>
                            );
                        } */

            return (
              <Grid item key={`gbi${i}`} xs={12} md={4} className={classes.menuItem}>
                <OnTapItem
                  key={`otd${i}`}
                  beverages={this.state.beverages}
                  onAssignBeverage={this.assignBeverage}
                  onUnassignBeverage={this.unassignBeverage}
                  onPricesChanged={this.onPricesChanged}
                  moveTapItem={this.moveTapItem}
                  handleDragEnd={this.save}
                  data={d}
                  beverage={d.beverage || {}}
                  index={i}
                  id={i}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
}

// export default (DragDropContext(HTML5Backend))(withSnackbar(OnTap))
export default connect(null, { setPageTitle })(OnTap);
