import { call, put } from 'redux-saga/effects';

import { fetchModifiersSuccess, fetchModifiersError, fetchModifierByIdSuccess } from '../actions';
import { parseApiError } from '../../../api/helpers';
import { hideProgressBar, showProgressBar } from '../../ui/actions';
import { modifierApi } from '../../../api/modifier';
import { enqueueSnackbar } from '../../snackbar/actions';

export function* fetchModifiersSaga() {
  try {
    yield put(showProgressBar());
    const response = yield call(modifierApi.fetch);
    yield put(hideProgressBar());

    if (!response.ok) {
      yield put(fetchModifiersError(parseApiError(response).message));
      return;
    }

    yield put(fetchModifiersSuccess(response.data.data.results || []));
  } catch (err) {
    yield put(fetchModifiersError(parseApiError(err).message));
  }
}

export function* fetchModifierByIdSaga(action) {
  try {
    const response = yield call(modifierApi.getById, action.payload.id);
    if (!response.ok) {
      return;
    }

    yield put(fetchModifierByIdSuccess(action.payload.id, response.data.data || null));
  } catch (err) {
    yield put(enqueueSnackbar(parseApiError(err).message, { options: { variant: 'error' } }));
  }
}
