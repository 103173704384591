import React from 'react';
import { connect } from 'react-redux';
import PageLoadingText from '../../components/PageLoadingText';
import { translate } from '../../i18n';
import VenueForm from './VenueForm';
import { setPageTitle as setPageTitleAction } from '../ui/actions';
import { enqueueSnackbar as enqueueSnackbarAction } from '../snackbar/actions';
import { getVenueByIdSelector } from './reducers';
import { fetchVenues } from './actions';

class VenueAddEdit extends React.Component {
  constructor(props) {
    super(props);

    const { id, setPageTitle } = this.props;

    let pageTitle = translate('Add venue');
    if (id) {
      pageTitle = translate('Edit venue');
    }

    setPageTitle(pageTitle);
  }

  componentDidMount() {
    const { fetchData } = this.props;
    fetchData();
  }

  render() {
    const { id, data, enqueueSnackbar, history } = this.props;

    if (id && !data) {
      return <PageLoadingText />;
    }

    return (
      <VenueForm
        id={data.id}
        data={data}
        onCancel={() => history.push('/venue')}
        onSave={() => history.push('/venue')}
        onNotification={enqueueSnackbar}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: getVenueByIdSelector(state, ownProps.id),
  };
};

const mapDispatchToProps = {
  setPageTitle: setPageTitleAction,
  fetchData: fetchVenues,
  enqueueSnackbar: enqueueSnackbarAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(VenueAddEdit);
