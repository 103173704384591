import { createClient } from './helpers';

const apiClient = createClient();

function fetch() {
  return apiClient.get('category');
}

function add(data) {
  return apiClient.post('category', data);
}

function update(id, data) {
  return apiClient.patch(`category/${id}`, data);
}

function getById(id) {
  return apiClient.get(`category/${id}`);
}

function deleteById(id) {
  return apiClient.delete(`category/${id}`);
}

export const categoryApi = {
  fetch,
  add,
  update,
  getById,
  deleteById,
};
