import React from 'react';

const NoMatch = ({ location }) => (
  <div>
    <h3>
      The page you are looking for can not be found: <code>{location.pathname}</code>
    </h3>
  </div>
);

export default NoMatch;
