export const units = {
  metric: {
    volume: {
      value: 'ml',
      label: 'mL',
    },
    length: {
      value: 'cm',
      label: 'cm',
    },
  },
  imperial: {
    volume: {
      value: 'fl-oz',
      label: 'fl-oz',
    },
    length: {
      value: 'in',
      label: 'Inches',
    },
  },
};

export const availableUnits = {
  volume: [
    {
      value: 'ml',
      label: 'ml',
    },
    {
      value: 'l',
      label: 'liters',
    },
    {
      value: 'fl-oz',
      label: 'fl-oz',
    },
    {
      value: 'gal',
      label: 'gallons',
    },
  ],

  weight: [
    {
      value: 'g',
      label: 'grams',
    },
    {
      value: 'kg',
      label: 'kg',
    },
    {
      value: 'oz',
      label: 'ounces',
    },
    {
      value: 'lb',
      label: 'pounds',
    },
  ],
  other: [
    {
      value: 'pz',
      label: 'piece',
    },
    {
      value: 'portion',
      label: 'portion',
    },
    {
      value: 'case',
      label: 'case',
    },
    ...[2, 3, 4, 5, 6, 8, 10, 12, 24, 36].map(v => ({
      value: `case-${v}`,
      label: `case of ${v}`,
    })),
  ],
};
