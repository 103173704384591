import React from 'react';
import '../styles/reports.scss';
import { DateRangePicker } from 'materialui-daterange-picker';
import { Box, Button, Checkbox, FormControlLabel, Menu, MenuItem, Paper, Popover, Typography } from '@material-ui/core';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { reportsApi } from '../../../api/reports';
import ContentPaper from '../../../components/content-paper';
import { localize, translate } from '../../../i18n';
import EnhancedTable from '../../../components/enhanced-table/EnhancedTable';

class SalesCategories extends React.Component {
  constructor(props) {
    super(props);

    const { setPageTitle } = this.props;
    setPageTitle('Sales by category');

    this.state = {
      dateRange: null,
      groupBy: 'day',
      openRangePicker: false,
      employees: [],
      availableFilters: {},
      employeesFilterAnchor: null,
      loadingReport: false,
      loadedReport: false,
      search: '',
    };
  }

  componentDidMount() {
    this.loadFilters().then(() => {});
  }

  loadFilters = async () => {
    const response = await reportsApi.getFilters();
    const filters = response.data && response.data.data;

    this.setState({ availableFilters: filters, employees: filters.employees });
  };

  handleRangeChange = range => {
    this.setState({ dateRange: range, openRangePicker: null }, () => this.loadReport().then(() => {}));
  };

  toggleDatePicker = event => {
    const { openRangePicker } = this.state;

    this.setState({ openRangePicker: openRangePicker ? null : event.currentTarget });
  };

  loadReport = async () => {
    const { dateRange, employees } = this.state;

    if (!dateRange || !dateRange.startDate || !dateRange.endDate) {
      alert('Invalid date range selected');
      return;
    }

    this.setState({ loadingReport: true });

    const data = {};
    // Prepare request data
    data.timezone = 'America/Mexico_City';
    data.startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
    data.endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
    data.employees = employees;

    const response = await reportsApi.getCategoriesSales(data);

    let reportData = null;

    if (response.ok) {
      reportData = {};
      reportData.totals = response.data.reportTotals;
      reportData.rows = Object.values(response.data.tableRows);
    }

    this.setState({ loadingReport: false, loadedReport: true, reportData });
  };

  getTableColumns = () => {
    return [
      {
        id: 'name',
        accessor: 'name',
        sortable: true,
        component: 'td',
        label: 'Category',
        renderValue: row => <span style={{ fontWeight: 500 }}>{row.name}</span>,
      },
      {
        id: 'quantity',
        accessor: 'quantity',
        sortable: true,
        numeric: true,
        label: 'Items sold',
      },
      {
        id: 'total_income',
        label: 'Net sales',
        sortable: true,
        numeric: true,
        renderValue: row => localize('number', row.total_income, { strip_insignificant_zeros: false }),
      },
      {
        id: 'total_discount',
        label: translate('Discounts'),
        numeric: true,
        sortable: true,
        renderValue: row => localize('number', row.total_discount, { strip_insignificant_zeros: false }),
      },
      {
        id: 'total_profit',
        label: translate('Gross profit'),
        numeric: true,
        sortable: true,
        renderValue: row => localize('number', row.total_profit, { strip_insignificant_zeros: false }),
      },
    ];
  };

  getTableFilters = () => {
    const { search } = this.state;

    return [
      {
        label: translate('Search'),
        type: 'search',
        value: search,
        onChange: value => this.setState({ search: value }),
      },
    ];
  };

  render() {
    const {
      loadingReport,
      loadedReport,
      availableFilters,
      dateRange,
      employees,
      employeesFilterAnchor,
      openRangePicker,
      reportData,
      search,
    } = this.state;

    let rows = null;
    if (reportData && reportData.rows && !loadingReport) {
      rows = [];
      rows = reportData.rows;
      if (search) {
        rows = rows.filter(row => row.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
      }
    }

    return (
      <div className="reports">
        <div className="reports-filters">
          <Paper elevation={2} className="filter-box date-filter d-inline-block">
            <div className="filter-label" onClick={this.toggleDatePicker}>
              <i className="fal fa-calendar-alt filter-icon" />
              {!dateRange || !dateRange.startDate
                ? 'Choose dates'
                : dateRange.label
                ? dateRange.label
                : `${moment(dateRange.startDate).format('DD MMM YYYY')} - ${moment(dateRange.endDate).format(
                    'DD MMM YYYY',
                  )}`}
            </div>
          </Paper>

          <Paper elevation={2} className="filter-box employees-filter d-inline-block">
            <div className="filter-label" onClick={ev => this.setState({ employeesFilterAnchor: ev.currentTarget })}>
              <i className="fal fa-users filter-icon" />
              {`${
                employees.length ===
                (availableFilters && availableFilters.employees && availableFilters.employees.length)
                  ? 'All'
                  : employees.length
              } employees`}
            </div>

            <Menu
              id="simple-menu"
              anchorEl={employeesFilterAnchor}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              open={Boolean(employeesFilterAnchor)}
              onClose={() => this.setState({ employeesFilterAnchor: null })}>
              {availableFilters && availableFilters.employees
                ? availableFilters.employees.map(e => {
                    const checked = employees.indexOf(e) !== -1;

                    return (
                      <MenuItem key={`emp-${e}`} onClick={null}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={checked}
                              onChange={() => {
                                if (checked) {
                                  this.setState({ employees: employees.filter(ee => ee !== e) });
                                } else {
                                  this.setState({ employees: [...employees, e] });
                                }
                              }}
                              color="primary"
                            />
                          }
                          label={e}
                        />
                      </MenuItem>
                    );
                  })
                : null}
            </Menu>
          </Paper>

          <div className="d-inline-block">
            <Button onClick={this.loadReport} variant="contained" color="primary">
              Apply filters
            </Button>
          </div>
        </div>

        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          id={openRangePicker ? 'open-calendar' : undefined}
          open={Boolean(openRangePicker)}
          anchorEl={openRangePicker}>
          <div style={{ maxWidth: '720px' }}>
            <DateRangePicker
              open
              initialDateRange={dateRange}
              toggle={this.toggleDatePicker}
              onChange={this.handleRangeChange}
            />
          </div>
        </Popover>

        <Box p={2}>
          {loadingReport && <CircularProgress variant="indeterminate" size={24} color="secondary" />}
          {loadedReport && !reportData && !loadingReport ? <Typography>No report data was found.</Typography> : null}
        </Box>

        {!rows ? null : (
          <>
            <ContentPaper table className="mt-2">
              <EnhancedTable
                noCheckbox
                toolbarActions={null}
                filters={this.getTableFilters()}
                columns={this.getTableColumns()}
                onRowClick={null}
                onMassDelete={null}
                data={rows}
                rowCount={rows.length}
                order="asc"
                orderBy="name"
              />
            </ContentPaper>
          </>
        )}
      </div>
    );
  }
}

export default SalesCategories;
