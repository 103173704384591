import React from 'react';
import { connect } from 'react-redux';
import Menus from '../components/menus';
import { copyMenu, deleteMenu, fetchMenus, selectMenu, setTableFilter, updateMenuData } from '../actions';
import { setPageTitle } from '../../ui/actions';
import { getFilteredMenus } from '../reducers';

function mapStateToProps(state) {
  const menus = getFilteredMenus(state);

  return {
    menusById: menus,
    menus: state.menus.entitiesIds,
    filters: state.menus.filters,
    selected: state.menus.selected,
    loading: state.menus.loading,
    initialLoaded: state.menus.initialLoaded,
    fetchError: state.menus.fetchError,
    loadingIds: state.menus.loadingIds,
  };
}

const mapDispatchToProps = {
  copyMenu,
  fetchData: fetchMenus,
  selectMenu,
  deleteMenu,
  updateMenuData,
  setPageTitle,
  setTableFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menus);
