import React from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/styles';
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';

import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import TodayIcon from '@material-ui/icons/TodayOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { connect } from 'react-redux';
import AreaService from '../../services/AreaService';
import InventoryService from '../../services/InventoryService';
import InventoryTable from './InventoryTable';
import { translate } from '../../i18n';
import { setPageTitle } from '../ui/actions';

const styles = theme => ({
  backButton: {
    // background: '#fffff',
    padding: '8px 18px 8px 24px',
  },
  button: {
    margin: theme.spacing(0),
    marginRight: theme.spacing(2),
  },

  buttonInline: {
    margin: theme.spacing(0, 0),
  },

  buttonNew: {
    /* margin: theme.spacing(0),
        marginRight: theme.spacing(2), */
    margin: theme.spacing(1, 2, 0, 3),
    marginTop: 10,
    /* right: 0,
        position: 'absolute', */
  },

  buttonOption: {
    margin: theme.spacing(2, 0),
  },

  buttonIcon: {
    marginRight: theme.spacing(1),
  },

  formControl: {
    width: '100%',
  },

  buttonBox: {
    marginBottom: theme.spacing(2),
  },

  dateIcon: {
    color: theme.palette.secondary.main,
  },
});

@withStyles(styles)
@withSnackbar
class Inventory extends React.Component {
  state = {
    newInventoryDialogOpen: false,
    areas: null,
    ingredients: null,
    inventories: null,

    inventoryId: null,
    areaId: this.props.match && this.props.match.params ? this.props.match.params.area_id : null,
  };

  componentDidMount() {
    this.props.setPageTitle(translate('Inventory'));

    AreaService.fetchAll().then(res => {
      if (res && res.body && res.body.data) {
        this.setState({
          areas: res.body.data.results,
        });
      }
    });

    InventoryService.getDates().then(res => {
      let dates = [moment().format('YYYY-MM-DD')];
      if (res && res.body && res.body.data && res.body.data.length) {
        dates = res.body.data;
      }

      this.setState(
        {
          dates,
          selectedDate: dates[0],
        },
        () => {
          this.loadInventory();
        },
      );
    });
  }

  loadInventory() {
    let { dates, selectedDate } = this.state;

    // Load selected inventory data

    if (!selectedDate && dates && dates.length) {
      selectedDate = dates[0];
    }

    InventoryService.getInventoryData(selectedDate).then(res => {
      this.props.enqueueSnackbar(`${selectedDate} inventory loaded`, { variant: 'success', autoHideDuration: 3000 });
      this.setState({
        inventories: res.body.data,
        ingredients: res.body.data,
      });
    });
  }

  handleNewInventoryDialogClose() {
    this.setState({ newInventoryDialogOpen: false });
  }

  handleNewInventoryDialogOpen() {
    this.setState({ newInventoryDialogOpen: true });
  }

  handleNewInventoryAreaChanged(id) {
    const { history } = this.props;
    history && history.push(`/inventory/new/area/${id}`);

    this.handleNewInventoryDialogClose();
  }

  render() {
    const { classes } = this.props;

    const { areas, areaId, inventoryId } = this.state;

    let { selectedDate, dates, inventories, ingredients } = this.state;

    if (!selectedDate) {
      if (!dates || !dates.length) {
        selectedDate = new Date();
      } else {
        // Last inventory
        selectedDate = new Date(dates[0]);
      }
    }

    const handleDateChange = date => {
      this.setState(
        {
          selectedDate: date && date.format('YYYY-MM-DD'),
        },
        () => this.loadInventory(),
      );
    };

    return (
      <>
        <Box px={0} mt={1} mb={3}>
          {!dates ? null : (
            <>
              {/* <Typography variant="h6">Fecha de inventario</Typography> */}

              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  disableFuture
                  open={!!this.state.openDateSelector}
                  /* inputVariant="outlined" */

                  margin="dense"
                  variant="dialog"
                  labelFunc={date => {
                    return date.format('ddd, D MMM YYYY');
                  }}
                  inputVariant="outlined"
                  InputProps={{
                    'aria-label': 'naked',
                    /* disableUnderline: true, */
                    style: {
                      width: 220,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton className={classes.dateIcon}>
                          <TodayIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={selectedDate}
                  onChange={handleDateChange}
                  onOpen={() => this.setState({ openDateSelector: true })}
                  onClose={() => this.setState({ openDateSelector: false })}
                  shouldDisableDate={day => {
                    const index = _.findIndex(dates, function (o) {
                      return day.isSame(o, 'day');
                    });
                    return index === -1;
                  }}
                />
              </MuiPickersUtilsProvider>

              {/* <Button onClick={() => this.setState({openDateSelector: !this.state.openDateSelector})}
                                    variant="text" color="primary" size="large" className={classes.buttonInline}>
                                <TodayIcon className={classes.buttonIcon}/> Cambiar fecha
                            </Button> */}

              <Button
                onClick={this.handleNewInventoryDialogOpen.bind(this)}
                variant="contained"
                color="primary"
                className={classes.buttonNew}>
                <NoteAddOutlinedIcon className={classes.buttonIcon} /> Nuevo
              </Button>

              <Dialog
                maxWidth="xs"
                fullWidth
                open={this.state.newInventoryDialogOpen}
                onClose={this.handleNewInventoryDialogClose.bind(this)}>
                <DialogTitle>Elegir área</DialogTitle>
                <DialogContent>
                  {areas &&
                    areas.map((d, i) => {
                      return (
                        <Button
                          key={`aefu${d.id}_${i}`}
                          className={classes.buttonOption}
                          onClick={this.handleNewInventoryAreaChanged.bind(this, d.id)}
                          fullWidth
                          size="large"
                          variant="contained"
                          color="primary">
                          {d.name}
                        </Button>
                      );
                    })}
                </DialogContent>
              </Dialog>
            </>
          )}
        </Box>

        {/* {areas && areas.map((area, i) => {
                    return (
                        <InventoryArea
                            key={'ia' + area.id + '_' + i}
                            areaId={area.id}
                        />
                    )
                }) */}

        {ingredients === null ? (
          <Box px={3}>
            <Typography>Loading, please wait</Typography>
          </Box>
        ) : !ingredients || !ingredients.length ? (
          <Box px={3}>
            <Typography>No se creó inventario para la fecha seleccionada.</Typography>
          </Box>
        ) : (
          <InventoryTable
            history={this.props.history}
            loadInventory={this.loadInventory.bind(this)}
            areaId={this.state.areaId}
            date={selectedDate}
            ingredients={ingredients}
          />
        )}
      </>
    );
  }
}

export default connect(null, { setPageTitle })(Inventory);
