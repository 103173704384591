import { call, put } from 'redux-saga/effects';

import {
  fetchProductsSuccess,
  fetchProductsError,
  fetchProductByIdSuccess,
  fetchPrinterAreasSuccess,
} from '../actions';
import { parseApiError } from '../../../api/helpers';
import { hideProgressBar, showProgressBar } from '../../ui/actions';
import { productApi } from '../../../api/product';
import { enqueueSnackbar } from '../../snackbar/actions';

export function* fetchProductsSaga() {
  try {
    yield put(showProgressBar());
    const response = yield call(productApi.fetch);
    yield put(hideProgressBar());

    if (!response.ok) {
      yield put(fetchProductsError(parseApiError(response).message));
      return;
    }

    yield put(fetchProductsSuccess(response.data.data.results || []));
  } catch (err) {
    yield put(fetchProductsError(parseApiError(err).message));
  }
}

export function* fetchProductByIdSaga(action) {
  try {
    const response = yield call(productApi.getById, action.payload.id);
    if (!response.ok) {
      return;
    }

    yield put(fetchProductByIdSuccess(action.payload.id, response.data.data || null));
  } catch (err) {
    yield put(enqueueSnackbar(parseApiError(err).message, { options: { variant: 'error' } }));
  }
}

export function* fetchPrinterAreasSaga() {
  try {
    const response = yield call(productApi.fetchPrinterAreas);

    if (!response.ok) {
      return;
    }

    yield put(fetchPrinterAreasSuccess(response.data.data.results || []));
  } catch (err) {
    //
  }
}
