export function makeActionCreator(type, ...argNames) {
  return function (...args) {
    const action = { type };
    if (argNames.length) {
      action.payload = {};
    }
    argNames.forEach((arg, index) => {
      action.payload[argNames[index]] = args[index];
    });
    return action;
  };
}
