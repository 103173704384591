import { connect } from 'react-redux';
import BeverageAddDialog from '../components/beverage-add-dialog';
import { showBeverageAddDialog, hideBeverageAddDialog } from '../actions';

function mapStateToProps(state) {
  return {
    open: state.beverage.showAddDialog,
  };
}

const mapDispatchToProps = {
  showAddDialog: showBeverageAddDialog,
  hideAddDialog: hideBeverageAddDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(BeverageAddDialog);
